export type DocType = {
  key: string;
  file: string;
};

export type AddressType = {
  addressLine1: string;
  city: string;
  district: string;
  state: string;
  zipCode: string;
};

export type MyProfileType = {
  phoneNumber: {
    countryCode: string;
    number: string;
  };
  name: string;
  aadhaarNumber: string;
  documentType: string;
  firstName: string;
  email: string;
  role: string;
  userType: string;
  documentTypes: DocType[];
  aadhaarCardFront: DocType[];
  aadhaarCardBack: DocType[];
  identityProofImage: any;
  userProfileType: string;
  nameOfAuthorizedPerson: string;
  designation: string;
  registration: DocType[];
  currentAddress: AddressType;
  entityType: string;
  permanentAddress: AddressType;
  idProofNUmber: string;

  agencyDetail: {
    name: string;
    state: string;
    streetAddress: string;
    city: string;
    district: string;
  };
  bankAccountDetail: {
    bankName: string;
    accountNumber: string;
    ifscCode: string;
  };
  implementingOfficerDetails: [];
  address: string;
};

export const myProfileValue = {
  phoneNumber: {
    countryCode: "",
    number: "",
  },
  authName: "",
  authDesig: "",
  userProfileType: "",
  registration: [],

  documentTypes: [],
  aadhaarNumber: "",
  aadhaarCardFront: [],
  aadhaarCardBack: [],
  documentType: "",
  firstName: "",
  email: "",
  role: "",
  devType: "",
  currentAddress: {
    addressLine1: "",
    city: "",
    district: "",
    state: "",
    zipCode: "",
  },
  entityType: "",
  permanentAddress: {
    addressLine1: "",
    city: "",
    district: "",
    state: "",
    zipCode: "",
  },
};

export const forgetPasswordValue = {
  phone: {
    number: "",
    countryCode: "+91",
  },
  OTP: "",
};
