import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  container: {
    minHeight: "100%",
    width: "100%",
    minWidth: "62px",
    padding: "40px 16px",
    backgroundColor: "custom.background.dark",
    color: "custom.text.grey.80",
  },
  divider: {
    height: "1px",
    backgroundColor: "rgba(253, 253, 253, 0.10)",
    margin: "24px 0px",
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    cursor: "pointer",
  },
  logoText: {
    color: "custom.text.white.100",
    fontWeight: "600",
  },
  navItemsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginBottom: "16px",
    padding: "8px 16px",
    borderRadius: "8px",
    cursor: "pointer",
  },

  activeNavContainer: {
    backgroundColor: "primary.main",
  },
  navText: {
    color: "custom.text.grey.80",
    fontWeight: 400,
    whiteSpace: "break-spaces",
  },
  activeNavText: {
    color: "custom.text.white.100",
    fontWeight: 600,
    whiteSpace: "break-spaces",
  },
});

export default styles;
