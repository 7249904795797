import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import Checkbox from "src/components/common/FormComponents/Checkbox";
import { getStyles } from "src/styles/theme";
import { postMonitoringComponentDetail } from "src/utils/api/dashboard";
import defaultStyles from "./styles";
import { useComponentName } from "src/hook/monitoring-component";
import { ENUM_MONITORING_TYPE, year } from "src/constants/common";
import { Button, FileUpload } from "src/components/common/FormComponents";
import { SuccessModal } from "src/components/common/modal";

type FormType = {
  isAvailable: boolean;
  preparedAsPerGuideline: boolean;
  images: File[];
};
export function Journal({ param, yearMonth }: any) {
  const styles = getStyles(defaultStyles);

  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");
  const { setComponentName } = useComponentName();

  const form = useForm<FormType>();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
    watch,
  } = form;

  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    heading: "",
    subHeading: "",
  });
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };
  const handlePopUpClose = () => {
    if (openModal.type === "success") {
      setComponentName(undefined);
    } else {
      handleModalClose();
    }
  };

  const { mutate } = useMutation(
    ["landPreparation"],
    ({ data }: { data: any }) => postMonitoringComponentDetail(data),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          type: "success",
          heading: "Success",
          subHeading: "Plantation Journal Detail Submitted Successfully",
        });
      },
      onError: () => {
        setOpenModal({
          open: true,
          type: "unSuccess",
          heading: "Failed",
          subHeading: "Something Went Wrong",
        });
      },
    }
  );

  const onSubmit = (data: any) => {
    const imageKeys = data.images.map((item) => item.key);

    mutate({
      data: {
        type: ENUM_MONITORING_TYPE.PLANTATION_JOURNAL,
        year: param.year,
        month: param.month,
        landOwnershipEpid,
        yearMonth: yearMonth,
        ...data,
        images: imageKeys,
      },
    });
  };
  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        {...styles("formWrapper")}
      >
        <Box {...styles("formElementWrapper", { flexDirection: "column" })}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Checkbox
                label="Is Available"
                name="isAvailable"
                customStyles={{ wrapper: { width: "fit-content" } }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Checkbox
                label="Prepared as per GuideLine"
                name="preparedAsPerGuideline"
                customStyles={{ wrapper: { width: "fit-content" } }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={{ margin: "20px 20px " }}
          >
            <FileUpload
              accept="image/jpeg, image/png, image/webp, image/jpg"
              name="images"
              label="Upload GeoTagged Images (Minimum 2 images are required )"
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              maxLimit={10}
              multiple={true}
              required={true}
              supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
              trigger={trigger}
              rules={{
                validate: {
                  isLength: (value) => {
                    return value && value["length"] >= 2;
                  },
                },
              }}
              dragDropHeading=""
            />
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button text="Submit" type="submit" disabled={!isValid} />
        </Box>

        <SuccessModal
          open={openModal.open}
          heading={openModal.heading}
          subheading={openModal.subHeading}
          closeOnBackDrop
          type={openModal.type}
          handleModalClose={() => handlePopUpClose()}
        />
      </Box>
    </FormProvider>
  );
}
