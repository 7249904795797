import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  statsCardWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
    padding: "15px",
  },
  statsCard: {
    width: "337px",
    height: "150px",
  },

  topHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },

  dashboardTableWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    marginTop: "50px",
    width: "80vw",
  },
});

export default styles;
