import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  otpWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "12px",
  },

  otpBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexBasis: "100%",
    textAlign: "center",

    "& .Mui-error.MuiInputBase-root.MuiInputBase-root": {
      borderColor: "error.main",
    },

    "& .MuiInputBase-root , .Mui-focused.no-border": {
      borderColor: "custom.scrollThumb",
    },

    "& .Mui-focused.border , .filled": {
      borderColor: "primary.main",
    },

    "& .Mui-disabled.MuiInputBase-formControl": {
      borderColor: "custom.disabled",
    },
  },

  otpField: {
    width: {
      xs: "41px",
      sm: "71px",
    },
    height: {
      xs: "41px",
      sm: "71px",
    },
    backgroundColor: "custom.background.otpField",
    border: "1px solid",
    borderColor: "custom.border.otp",
    borderRadius: "4px",
    fontSize: {
      xs: "19.9px",
      sm: "22.1px",
    },

    fontWeight: "500",
    letterSpacing: {
      xs: "-0.08px",
      sm: "-0.09px",
    },
    color: "custom.label",
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },

  hide: {
    display: "none",
  },
});

export default styles;
