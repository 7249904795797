import { Box } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Select } from "src/components/common/FormComponents";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import errorMessage from "src/constants/errorMessage";
import { setConfig, updateUserTypeForm } from "src/redux/actions";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { getRole } from "src/utils/api/roles";
import { getRoutes, optionTypeFormatter } from "src/utils/helper";
import defaultStyles, { StylesClasses } from "./styles";

export type UserDetailsForms = {
  userType: string;
  devType: string;
};

const UserTypeDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [userTypeOptions, setUserTypeOptions] = useState([]);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { formData } = useAppSelector((state) => state.root.userType);

  const { control, formState, handleSubmit } = useForm<UserDetailsForms>({
    mode: "onTouched",
    defaultValues: {
      ...formData,
    },
  });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: UserDetailsForms) => {
    dispatch(
      updateUserTypeForm({
        formData: data,
        roles: userTypeOptions.reduce((value, curr) => {
          return {
            ...value,
            [curr.label]: curr.id,
          };
        }, {}),

        // {
        //   [userTypeOptions[0].label]: userTypeOptions[0].id,
        //   // [userTypeOptions[1].label]: userTypeOptions[1].id,
        // },
      })
    );
    dispatch(setConfig({ ...data }));
    navigate(getRoutes(data));
  };

  useQuery("getRole", () => getRole(), {
    onSuccess: (data) => {
      /**
       * @todo remove this hardcoding
       */

      const firstTwoOptions = data.data.slice(0, 2);
      setUserTypeOptions([
        ...optionTypeFormatter(firstTwoOptions, true),
        // ...tempOptions,
      ]);
    },
    retry: 1,
    refetchOnMount: true,
  });

  console.log(userTypeOptions, "==========");

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="User Type Details"
        hideBackBtn
        isValid={isValid}
      >
        <Box {...styles("input")}>
          <Select
            name="userType"
            label="User Type"
            control={control}
            errors={errors}
            height="52px"
            customStyle={{ wrapper: defaultStyles.selectWrapper }}
            rules={{
              required: errorMessage.required,
            }}
            options={userTypeOptions}
          />
        </Box>
        {/* <Box {...styles("input")}>
          <Select
            name="devType"
            label="Type of Developer"
            control={control}
            height="52px"
            errors={errors}
            customStyle={{ wrapper: defaultStyles.selectWrapper }}
            rules={{ required: errorMessage.required }}
            options={devTypeOptions}
          />
        </Box> */}
      </OnboardingWrapper>
    </Box>
  );
};

export default UserTypeDetails;
