import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  button: {
    width: "5%",
    border: "1px solid blue",
    margin: "1%",
    "&:hover": {
      backgroundColor: "blue",
      color: "white",
    },
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  renderButtonText: {
    display: "block",
    textAlign: "left",
    fontSize: "17px",
  },
  renderButtonText2: {
    display: "block",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "600",
    color: "#36B1A0",
    textDecoration: "none",
  },
});

export default styles;
