import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
} from "@mui/material";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import { getError } from "src/utils/common";

type MultiSelectProps<T> = UseControllerProps<T> &
  SelectProps<T> & {
    options: { label: string; value: string | number; isActive?: boolean }[];
    errors?: FieldErrors;
    customStyle?: CustomStyles<StylesClasses>;
    disabled?: boolean;
    placeholder?: string;
    onInputSelect?: (value: string) => void;
  };

export const MultiSelect = <T extends FieldValues>({
  options,
  rules,
  control,
  name,
  label,
  errors,
  placeholder,
  customStyle,
  disabled,
}: MultiSelectProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyle);
  const error = getError(name, errors);

  return (
    <Box>
      <FormControl {...styles("formcontrol")}>
        <InputLabel {...styles("label")}>{label}</InputLabel>
        <Controller
          control={control}
          rules={rules}
          name={name}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              disabled={disabled}
              input={<OutlinedInput label={label} />}
              value={field.value || []}
              onChange={(e) => field.onChange(e.target.value)}
              notched={false}
              placeholder={placeholder}
              {...styles("select")}
            >
              {options.map((name) => (
                <MenuItem
                  key={name.label}
                  value={name.value}
                  {...styles("menuItems")}
                >
                  {name.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        {!!error && (
          <FormHelperText {...styles("formHelperText")}>
            {error?.message}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};
