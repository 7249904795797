import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import {
  AutoComplete,
  Button,
  DateInput,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { getStyles } from "src/styles/theme";
import {
  getFundApplicationById,
  getLandDetailsById,
} from "src/utils/api/createProjectFlow";
import defaultStyles, { StylesClasses } from "./styles";
import {
  EditFundApplicationAPI,
  fillFundApplicationAPI,
} from "src/utils/api/dashboard";
import { useEffect } from "react";
import ViewForm from "src/components/common/ViewForm";
import { dateAndTimeStamp } from "src/utils/helper";

export type demandNoteFormData = {
  demandRecipt: {
    type: string;
    key: string;
    file: string;
  }[];
};

const ViewFundApplication = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const [searchParams] = useSearchParams();

  const paymentId = searchParams.get("paymentId");

  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(
    "fillFundApllicationForm",
    ({ paymentId, data }: { paymentId: string; data: any }) =>
      EditFundApplicationAPI(paymentId, data),
    {
      onSuccess: () => {
        navigate("/");
      },
      onError: (error: any) => {},
    }
  );

  const { data: landData } = useQuery(
    ["userDetails", paymentId],
    () => getFundApplicationById(paymentId),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!paymentId,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (!!landData) {
      reset({
        estimatedAdvanceWorkCost:
          landData?.data?.fundDetails?.estimatedAdvanceWorkCost,
        estimatedRaisingCost: landData?.data?.fundDetails?.estimatedRaisingCost,
        estimatedMaintenanceCost:
          landData?.data?.fundDetails?.estimatedMaintenanceCost,
        estimatedMonitoringCost:
          landData?.data?.fundDetails?.estimatedMonitoringCost,
        estimatedContingencyCost:
          landData?.data?.fundDetails?.estimatedContingencyCost,
        totalEstimatedCost: landData?.data?.fundDetails?.totalEstimatedCost,
        ecoRestorationCost: landData?.data?.fundDetails?.ecoRestorationCost,
        totalCost: landData?.data?.fundDetails?.totalCost,
        monitoringCost: landData?.data?.fundDetails?.monitoringCost,
        contingencyCost: landData?.data?.fundDetails?.contingencyCost,
        maintenanceCost: landData?.data?.fundDetails?.maintenanceCost,
        raisingCost: landData?.data?.fundDetails?.raisingCost,
        advanceWorkCost: landData?.data?.fundDetails?.advanceWorkCost,
        additionalInfo: landData?.data?.fundDetails?.additionalInfo,
      });
    }
  }, [landData]);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      estimatedAdvanceWorkCost: "",
      estimatedRaisingCost: "",
      estimatedMaintenanceCost: "",
      estimatedMonitoringCost: "",
      estimatedContingencyCost: "",
      totalEstimatedCost: "",
      advanceWorkCost: "",
      monitoringCost: "",
      contingencyCost: "",
      raisingCost: "",
      totalCost: "",
      maintenanceCost: "",
      query: "",
      additionalInfo: "",
    },
  });

  const estimatedAdvanceWorkCost = watch("estimatedAdvanceWorkCost");
  const estimatedRaisingCost = watch("estimatedRaisingCost");
  const estimatedMaintenanceCost = watch("estimatedMaintenanceCost");
  const estimatedMonitoringCost = watch("estimatedMonitoringCost");
  const estimatedContingencyCost = watch("estimatedContingencyCost");

  const advanceWorkCost = watch("advanceWorkCost");
  const raisingCost = watch("raisingCost");
  const maintenanceCost = watch("maintenanceCost");
  const monitoringCost = watch("monitoringCost");
  const contingencyCost = watch("contingencyCost");

  useEffect(() => {
    const totalCost =
      (parseFloat(estimatedAdvanceWorkCost) || 0) +
      (parseFloat(estimatedRaisingCost) || 0) +
      (parseFloat(estimatedMaintenanceCost) || 0) +
      (parseFloat(estimatedMonitoringCost) || 0) +
      (parseFloat(estimatedContingencyCost) || 0);

    setValue("totalEstimatedCost", totalCost.toFixed(2));
  }, [
    estimatedAdvanceWorkCost,
    estimatedRaisingCost,
    estimatedMaintenanceCost,
    estimatedMonitoringCost,
    estimatedContingencyCost,
    setValue,
  ]);

  useEffect(() => {
    const totalCost =
      (parseFloat(advanceWorkCost) || 0) +
      (parseFloat(raisingCost) || 0) +
      (parseFloat(maintenanceCost) || 0) +
      (parseFloat(monitoringCost) || 0) +
      (parseFloat(contingencyCost) || 0);

    setValue("totalCost", totalCost.toFixed(2));
  }, [
    advanceWorkCost,
    raisingCost,
    maintenanceCost,
    monitoringCost,
    contingencyCost,
    setValue,
  ]);

  const handleForm = (data: any) => {
    const excludeFields = ["query", "additionalInfo"];

    // Create a new object with numeric conversion for specific fields
    const numericData = Object.fromEntries(
      Object.entries(data).map(([key, value]) =>
        excludeFields.includes(key) ? [key, value] : [key, Number(value)]
      )
    );
    mutate({
      paymentId,
      data: numericData,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleForm)}>
      <DashboardWrapper
        title="Fund Application Form"
        customStyles={{
          container: { position: "relative" },
          children: { paddingBottom: "80px" },
        }}
        // backPath={`/`}
        showBackBtn
      >
        <Box sx={{ fontSize: "20px", mb: "20px", mt: "20px", fontWeight: 500 }}>
          Fund Application Details
        </Box>

        <Box {...styles("wrapper")}>
          <Box {...styles("sectionWrapper")}>
            <Box {...styles("heading")}>Estimated Amount For 1st Year</Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="estimatedAdvanceWorkCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Estimated Advance Work Cost"
                label="Estimated Advance Work Cost"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="estimatedRaisingCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Estimated Raising/Creation Cost"
                label="Estimated Raising/Creation Cost (If Any)"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="estimatedMaintenanceCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Estimated Maintenance Cost"
                label="Estimated Maintenance Cost (If Any)"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="estimatedMonitoringCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Mointoring Cost"
                label="Mointoring Cost"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="estimatedContingencyCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Contingency Cost"
                label="Contingency Cost"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="totalEstimatedCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Total Cost"
                label="Total Cost"
                type="number"
                readOnly={true}
              />
            </Box>
          </Box>
          <Box {...styles("sectionWrapper")}>
            <Box {...styles("heading")}>Amount Required Now</Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="advanceWorkCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Advance Work Cost"
                label="Advance Work Cost"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="raisingCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Raising/Creation Cost"
                label="Raising/Creation Cost"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="maintenanceCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Maintenance Cost"
                label="Maintenance Cost"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="monitoringCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Mointoring Cost"
                label="Mointoring Cost"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="contingencyCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Contingency Cost"
                label="Contingency Cost"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="totalCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Total Cost"
                label="Total Cost"
                type="number"
                readOnly={true}
              />
            </Box>
          </Box>
          <Box {...styles("input")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: defaultStyles.multilineInput,
              }}
              name="additionalInfo"
              rules={{
                maxLength: {
                  value: 500,
                  message: "More then 500 characters are not allowed",
                },
              }}
              control={control}
              type="text"
              placeholder="(In 500 characters)"
              label="Additional Information"
              multiline
              minRows={6}
            />
          </Box>
        </Box>

        {landData?.data?.fundDetails?.queries?.length > 0 && (
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Fund Transfer Query</Box>
            {landData?.data?.fundDetails?.queries.map((item) => (
              <Box sx={{ paddingTop: "20px" }}>
                <ViewForm
                  label={item?.user?.firstName}
                  value={item?.query}
                  date={dateAndTimeStamp(item.created_at, "IST")}
                />
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ marginTop: "30px" }}>
          <Input
            name="query"
            label="Reply Query"
            control={control}
            errors={errors}
            multiline
            height="360px"
            customStyles={{
              input: {
                height: "auto",
                fontFamily: "Poppins",
                width: { sm: "100%", lg: "60%" },
              },
            }}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Button
            text="Submit"
            type="submit"
            disabled={!isValid}
            customStyles={{
              root: {
                fontSize: "16px",
              },
            }}
          />
        </Box>
      </DashboardWrapper>
    </Box>
  );
};

export default ViewFundApplication;
