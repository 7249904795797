import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {},

  input: {
    margin: "0 0 24px 0",
  },

  selectWrapper: {
    width: { xs: "100%", sm: "60%", lg: "40%" },
  },
});

export default styles;
