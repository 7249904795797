import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "../styles";

type LogoWrapperProps = {
  onClick: () => void;
  open: boolean;
  customStyles?: CustomStyles<StylesClasses>;
};

const LogoWrapper = ({ onClick, open, customStyles }: LogoWrapperProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("logoWrapper")} onClick={onClick}>
      <Box sx={{ display: open ? "none" : "block" }}>
        <MenuIcon sx={{ fontSize: "35px" }} />
      </Box>

      <Box
        component="img"
        src="/assets/images/GCP.png"
        sx={{ display: open ? "block" : "none" }}
      />
      <Box {...styles("logoText")} sx={{ display: open ? "block" : "none" }}>
        GREEN CREDIT
      </Box>
    </Box>
  );
};
export default LogoWrapper;
