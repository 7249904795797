import { AadhaarVerificationFormData } from "src/components/feature/Onboarding/AadhaarVerification";
import { AddressDetailsFormData } from "src/components/feature/Onboarding/AddressDetails";
import { AgencyFormData } from "src/components/feature/Onboarding/AgencyDetails";
import { BankDetailsFormData } from "src/components/feature/Onboarding/BankAccountDetails";
import { ContactDetailsFormData } from "src/components/feature/Onboarding/ContactDetails";
import { DocumentUploadFormData } from "src/components/feature/Onboarding/DocumentUpload";
import { entityDetailsFormData } from "src/components/feature/Onboarding/EntityDetails";
import { implementingOfficerDetailsFormData } from "src/components/feature/Onboarding/ImplementingOfficerDetails";
import { NodalOfficerDetailsFormData } from "src/components/feature/Onboarding/NodalOfficerDetails";
import { OrganizationDetailsFormData } from "src/components/feature/Onboarding/OrganizationDetails";
import { PersonalDetailsFormData } from "src/components/feature/Onboarding/PersonalDetails";
import { UserDetailsForms } from "src/components/feature/Onboarding/UserTypeDetails";
import { RoleIds } from "src/constants/common";
import { getISODate } from "src/utils/helper";

type data = {
  userType: {
    formData: UserDetailsForms;
  };
  personalDetails: {
    formData: Omit<PersonalDetailsFormData, "password"> &
      Partial<Pick<PersonalDetailsFormData, "password">>;
  };
  aadhaarVerification?: {
    formData: AadhaarVerificationFormData;
  };
  documentUpload: {
    formData: DocumentUploadFormData;
  };
  organizationDetails: {
    formData: OrganizationDetailsFormData;
  };
  addressDetails: AddressDetailsFormData;
};

type dataMapping = {
  userType: {
    formData: UserDetailsForms;
  };
  agencyDetails: {
    formData: AgencyFormData;
  };
  bankDetails?: {
    formData: BankDetailsFormData;
  };
  nodalOfficerDetails: {
    formData: NodalOfficerDetailsFormData;
  };
  implementingOfficerDetails: {
    formData: implementingOfficerDetailsFormData;
  };
};

type entityDataMapping = {
  userType: {
    formData: UserDetailsForms;
  };
  entityDetails: {
    formData: entityDetailsFormData;
  };
  organizationDetails?: {
    formData: OrganizationDetailsFormData;
  };
  contactDetails: {
    formData: ContactDetailsFormData;
  };
};

export const getSignUpData = (data: data) => {
  const type = data.userType.formData.devType;
  const {
    userType,
    personalDetails,
    aadhaarVerification,
    documentUpload,
    organizationDetails,
    addressDetails,
  } = data;

  if (type === "ORGANIZATION") {
    return {
      userType: userType.formData.devType,
      role: userType.formData.userType,
      roleId: RoleIds[userType.formData.userType],
      firstName: organizationDetails.formData.AuthorizedRepresentativeName, //  representativeName
      email: organizationDetails.formData.email,
      isEmailVerified: organizationDetails.formData.verifyEmail,
      isPhoneVerified: organizationDetails.formData.verifyPhone || false,
      countryCode: organizationDetails.formData.phone.countryCode,
      phoneNumber: organizationDetails.formData.phone.number,
      password: organizationDetails.formData.password,
      isAddressSame: !!addressDetails.isAddressSame,
      ...(!addressDetails.isAddressSame && {
        currentAddress: {
          addressLine1: addressDetails?.currentAddress.address,
          city: addressDetails?.currentAddress.city,
          district: addressDetails?.currentAddress.district,
          state: addressDetails?.currentAddress.state,
          country: "india",
          isoCode: "INR",
          zipCode: addressDetails?.currentAddress.pinCode,
        },
      }),

      organizationDetail: {
        organizationName: organizationDetails.formData.organizationName,
        organizationType: organizationDetails.formData.organizationType,
        organizationTypeId: organizationDetails.formData.organizationType,
        representativeDesignation:
          organizationDetails.formData.AuthorizedRepresentativeDesignation,

        documentDetail: [
          {
            type: documentUpload.formData.documentType,
            serialNo: documentUpload.formData.documentNumber,
            frontUrl: documentUpload.formData.documentImage[0]?.key,
            backUrl: documentUpload.formData.documentImage[0]?.key,
            isVerified: false,
            validFrom: getISODate(documentUpload.formData.registration),
          },
          ...organizationDetails.formData.organizationCertificates.map(
            ({ key, file }) => ({
              type: "CERTIFICATE",
              serialNo: key,
              frontUrl: key,
              isVerified: false,
            })
          ),
        ],
      },
      permanentAddress: {
        addressLine1: addressDetails?.permanentAddress.address,
        village: "",
        city: addressDetails?.permanentAddress.city,
        district: addressDetails?.permanentAddress.district,
        state: addressDetails?.permanentAddress.state,
        country: "india",
        isoCode: "INR",
        zipCode: addressDetails?.permanentAddress?.pinCode,
      },
      selfDeclaration: Object.keys(addressDetails.selfDeclaration).map(
        (statement) => ({
          name: statement,
          isAccepted: addressDetails.selfDeclaration[statement],
        })
      ),
    };
  }

  return {
    userType: userType.formData.devType,
    role: userType.formData.userType,
    roleId: RoleIds[userType.formData.userType],
    firstName: personalDetails.formData.fullName,
    email: personalDetails.formData.email,
    countryCode: personalDetails.formData.phone.countryCode,
    phoneNumber: personalDetails.formData.phone.number,
    isEmailVerified: personalDetails.formData.verifyEmail,
    isPhoneVerified: personalDetails.formData.verifyPhone || false,
    password: personalDetails.formData.password,
    isAddressSame: !!addressDetails.isAddressSame,
    permanentAddress: {
      addressLine1: addressDetails?.permanentAddress.address,
      // addressLine2: "ddsadas",
      // village: "dsada",
      city: addressDetails?.permanentAddress.city,
      district: addressDetails?.permanentAddress.district,
      state: addressDetails?.permanentAddress.state,
      country: "india",
      isoCode: "INR",
      zipCode: addressDetails.permanentAddress.pinCode, // pin code
      // latitude: "0.3",
      // longitude: "0.7",
    },
    ...(!addressDetails.isAddressSame && {
      currentAddress: {
        addressLine1: addressDetails?.currentAddress.address,
        // addressLine2: "ddsadas",
        // village: "dsada",
        city: addressDetails?.currentAddress.city,
        district: addressDetails?.currentAddress.district,
        state: addressDetails?.currentAddress.state,
        country: "india",
        isoCode: "INR",
        zipCode: addressDetails.permanentAddress.pinCode,
      },
    }),
    aadhaarDetails: {
      type: "AADHAAR",
      serialNo: aadhaarVerification.formData.aadhaarNumber,
      frontUrl: aadhaarVerification.formData.aadhaarCard[0]?.key,
      backUrl: aadhaarVerification.formData.aadhaarCardBack[0]?.key,
      isVerified: aadhaarVerification.formData.isAadhaarVerified,
    },
    selfDeclaration: Object.keys(addressDetails.selfDeclaration).map(
      (statement) => ({
        name: statement,
        isAccepted: addressDetails.selfDeclaration[statement],
      })
    ),
  };
};

export const transformSignUpData = (data: dataMapping) => {
  const type = data.userType.formData.devType;
  const {
    userType,
    agencyDetails,
    bankDetails,
    nodalOfficerDetails,
    implementingOfficerDetails,
  } = data;
  return {
    userType: "INDIVIDUAL",
    role: userType.formData.userType,
    roleId: "e0904453-80e7-4077-82a3-a1ca18405005",
    firstName: nodalOfficerDetails.formData.fullName, //  representativeName
    email: nodalOfficerDetails.formData.email,
    countryCode: nodalOfficerDetails.formData.mobilePhone.countryCode,
    phoneNumber: nodalOfficerDetails.formData.mobilePhone.number,
    password: nodalOfficerDetails.formData.password,
    designation: nodalOfficerDetails.formData.designation,
    agencyDetail: {
      name: agencyDetails.formData.agencyName,
      state: agencyDetails.formData.state,
      streetAddress: agencyDetails.formData.streetAddress,
      city: agencyDetails.formData.city,
      district: agencyDetails.formData.district,
    },
    bankAccountDetail: {
      bankName: bankDetails.formData.bankName,
      accountNumber: bankDetails.formData.accountNumber,
      ifscCode: bankDetails.formData.ifscCode,
      cancelChequeImage: bankDetails.formData.organizationCertificates[0]?.key,
    },
    implementingOfficerDetails:
      implementingOfficerDetails?.formData?.officerDetails?.map(
        (officerDetails) => {
          return {
            firstName: officerDetails.fullName,
            designation: officerDetails.designation,
            district: officerDetails.district,
            division: officerDetails.division,
            landlineNumber: officerDetails.phone.number,
            phoneNumber: officerDetails.mobilePhone.number,
            email: officerDetails.email,
          };
        }
      ),
  };
};

export const entitySignUpData = (data: entityDataMapping) => {
  const type = data.userType.formData.devType;
  const { userType, entityDetails, organizationDetails, contactDetails } = data;

  return {
    userType: "ORGANIZATION",
    role: userType.formData.userType,
    roleId: "e296e7da-0dce-4f6b-90d7-ba44b92d47c1",
    firstName: entityDetails.formData.entityName,
    email: contactDetails.formData.email,
    countryCode: contactDetails.formData.phone.countryCode,
    phoneNumber: contactDetails.formData.mobilePhone.number,
    entityType: organizationDetails.formData.organizationType,
    identityProofNumber: organizationDetails.formData.organizationName,
    declaration: contactDetails.formData.selfDeclaration,
    address: entityDetails.formData.streetAddress,
    password: entityDetails.formData.password,
    nameOfAuthorizedPerson: contactDetails.formData.name,
    designation: contactDetails.formData.designation,
    organizationCertificateImage:
      organizationDetails?.formData?.organizationCertificates[0]?.key,
    identityProofImage: organizationDetails?.formData?.IdentityProof[0]?.key,
  };
};
