import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { implementingOfficerDetailsFormData } from "src/components/feature/Onboarding/ImplementingOfficerDetails";
import { resetFormAction } from "src/redux/createActions/resetForms";

interface InitialState {
  formData: implementingOfficerDetailsFormData;
}

const initialState: InitialState = {
  formData: {
    officerDetails: [],
  },
};

const ImplementingOfficerDetailsSlice = createSlice({
  name: "ImplementingOfficerDetails",
  initialState,
  reducers: {
    updateImplementingOfficerDetails: (
      state,
      action: PayloadAction<implementingOfficerDetailsFormData>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateImplementingOfficerDetails } =
  ImplementingOfficerDetailsSlice.actions;

export default ImplementingOfficerDetailsSlice.reducer;
