import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Box,
  FormControlProps,
  FormHelperText,
  InputBase,
  InputBaseProps,
  InputLabel,
  Paper,
} from "@mui/material";
import { useState } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
} from "react-hook-form";
import errorMessage from "src/constants/errorMessage";
import { CustomStyles, getStyles } from "src/styles/theme";
import { Option } from "src/types/common";
import defaultStyles, { StylesClasses } from "./styles";

type AutoCompleteProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    errors?: FieldErrors;
    customStyles?: CustomStyles<StylesClasses>;
    freeSolo?: boolean;
    options?: Option[];
    loading?: boolean;
    searchLoading?: boolean;
    view?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    height?: string;
    valueObject?: boolean;
    type?: string;
    optionText?: {
      initial: string;
      filled: string;
    };
    // onInputChange?: (
    //   e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    // ) => void;
    onOptionSelect?: (e: any) => void;
  };

type Value<T> = PathValue<T, Path<T>>;

const AutoComplete = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth = true,
  height = "40px",
  readOnly = false,
  errors,
  freeSolo = false,
  rules,
  options = [],
  customStyles,
  variant = "standard",
  placeholder,
  className,
  view = false,
  loading = false,
  searchLoading = false,
  disabled = false,
  // onInputChange,
  type = "text",
  onOptionSelect,
  valueObject = false,
  optionText = {
    initial: "",
    filled: "",
  },
  ...rest
}: AutoCompleteProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [showPaper, setShowPaper] =
    useState<keyof typeof optionText>("initial");

  const [isOpen, setIsOpen] = useState(false);
  const checkIfFilled = (inpVal: string) => {
    inpVal.length ? setShowPaper("filled") : setShowPaper("initial");
  };

  function toggleDropdownIcon(value: boolean) {
    setIsOpen(value);
  }

  const handleKeyDown = (event) => {
    if (type === "number" && (event.key === "e" || event.key === "E")) {
      event.preventDefault();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        if (
          typeof field.value === "string" ||
          typeof field.value === "number"
        ) {
          const optionValue = options.find(
            (item) => item.value === field?.value
          ) as Value<T>;
          field.value = optionValue || field.value || ("" as Value<T>);
        }

        return (
          <Box {...styles("wrapper")}>
            <Autocomplete
              loading={searchLoading}
              disabled={disabled}
              readOnly={readOnly}
              onSelect={() => toggleDropdownIcon(false)}
              options={[...options].sort((a, b) => {
                return -b.label.localeCompare(a.label);
              })}
              fullWidth={fullWidth}
              value={field.value}
              noOptionsText={
                optionText[showPaper] === ""
                  ? "no options"
                  : optionText[showPaper]
              }
              onChange={(_, newValue) => {
                toggleDropdownIcon(false);
                if (!Array.isArray(newValue) && newValue !== null) {
                  typeof newValue === "string"
                    ? field.onChange(newValue)
                    : field.onChange(!valueObject ? newValue.value : newValue);
                } else field.onChange(newValue);

                onOptionSelect && onOptionSelect(newValue);
              }}
              freeSolo={freeSolo}
              id={`input-${name}`}
              PaperComponent={(props) => (
                <Paper
                  elevation={8}
                  sx={{ width: "100%" }}
                  className="paper"
                  {...props}
                />
              )}
              renderInput={(params) => (
                <Box ref={params.InputProps.ref}>
                  <InputLabel
                    shrink
                    className="label"
                    {...styles("label")}
                    htmlFor={`input-${name}`}
                    required={!!rules?.required}
                    {...styles("label", {
                      color: readOnly ? "custom.text.secondary" : "initial",
                    })}
                  >
                    {label}
                  </InputLabel>
                  <InputBase
                    fullWidth={fullWidth}
                    onBlur={(e) => {
                      field.onBlur();
                      toggleDropdownIcon(false);
                    }}
                    onKeyDown={handleKeyDown}
                    type={type}
                    placeholder={placeholder}
                    onClick={() => toggleDropdownIcon(true)}
                    // onClick={() => }
                    endAdornment={
                      <KeyboardArrowDownIcon
                        {...styles("menuOptionIcon", {
                          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        })}
                      />
                    }
                    onChange={(e) => {
                      toggleDropdownIcon(false);
                      checkIfFilled(e.target.value);
                      // onInputChange && onInputChange(e);
                      freeSolo && field.onChange(e.target.value);
                    }}
                    {...styles(["inputLabel", "input"], {
                      color: readOnly ? "custom.text.secondary" : "initial",
                      height: { height },
                    })}
                    error={!!fieldState?.error}
                    inputProps={{
                      ...params.inputProps,
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  />
                </Box>
              )}
            />
            {fieldState?.error?.message && !view && (
              <FormHelperText {...styles("errormsg")}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </Box>
        );
      }}
      rules={{
        ...rules,
        validate: {
          ...rules?.validate,
          isZero: (value) => value !== 0 || errorMessage.required,
        },
      }}
      {...rest}
    />
  );
};

export default AutoComplete;
