import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: "16px",
  },

  label: {
    fontWeight: "600",
  },

  img: {
    // marginTop: "40px",
    width: "auto",
    height: "90px",
    maxWidth: "300px",
  },
});

export default styles;
