import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
  },
  avatar: {
    maxHeight: "30px",
    maxWidth: "30px",
    margin: "auto",
  },

  name: {
    color: "custom.text.profileMenuName",
  },
  dropDown: {},
  dropDownImg: {
    width: "15px",
    marginLeft: "10px",
  },

  dFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  logo: {
    width: "88px",
  },

  icon: {
    width: "16px",
    marginBottom: "4px",
  },

  home: {
    paddingLeft: "10px",
    marginBottom: "4px",
  },

  dropdown: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "12px",
    "&.MuiBox-root": {
      width: "140px",
      backgroundColor: "custom.background.secondary",
    },
    "& a": {
      textDecoration: "none",
      color: "custom.text.secondary",
    },
  },

  dropdownProfile: {
    margin: "28px 47px 0px 24px",
    cursor: "pointer",
    "&:hover": {
      color: "primary.main",
    },
    "&:focus-visible": {
      color: "primary.main",
    },
  },

  dropdownLogout: {
    margin: "0px 59px 28px 24px",
    cursor: "pointer",
    "&:hover": {
      color: "primary.main",
    },
    "&:focus-visible": {
      color: "primary.main",
    },
  },

  divider: {
    margin: "25px 15px",
    width: "70%",
    border: "1px solid #82B7F3",
  },

  /* paperStyles: {
    top: "46px !important",
    left: "calc(100% - 166px)!important",
  }, */
});

export default styles;
