import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  titleBoxContainer: {},
  title: {
    color: "custom.text.toolTipTitle",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
  },
  description: {
    color: "custom.text.toolTipDesc",
    fontSize: "12px",
    fontWeight: "400",
    letterSpacing: "0.28px",
  },
});

export default styles;
