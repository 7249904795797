import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Checkbox from "src/components/common/FormComponents/Checkbox";
import { Input } from "src/components/common/FormComponents/Input";
import { SuccessModal } from "src/components/common/modal";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { updateStepper } from "src/redux/actions";
import {
  setClose,
  setIsError,
  setIsLandFreeFromEncumbrances,
  setStep,
} from "src/redux/slices/newProjectSetup";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { createNewProject, getCSVData } from "src/utils/api/createProjectFlow";
import { transformActivityData } from "src/utils/helper";
import FormWrapper from "../FormWrapper";
import defaultStyles, { StylesClasses } from "../NewForms/styles";
import { GridFormDetails } from "../newTypes";

const ProjectGridForm = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [response, setResponse] = useState({});

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const { formData } = useAppSelector(
    (state) => state.root.selectNewProjectSetup
  );

  const id = useAppSelector(
    (state) =>
      state.root.selectNewProjectSetup.formData.uploadedIds.gpsBoundariesCsv.id
  );

  const { mutate: createProject, isLoading: isProjectCreating } = useMutation(
    "createNewProject",
    (data: any) => createNewProject(data),
    {
      onSuccess: (res) => {
        setOpenModal({
          open: true,
          heading: "Congratulations!",
          subheading: "Land Parcel Successfully Registered!",
          description: "",
          btnText: "Done",
          type: "success",
        });
      },
      onError: (err) => {
        if (err) {
          dispatch(
            setIsError({
              isError: true,
              msg: "Something went wrong",
            })
          );
        }
      },
    }
  );

  const { control, formState, handleSubmit, getValues, setValue } =
    useForm<GridFormDetails>({
      mode: "onTouched",
      defaultValues: formData.otherDetails,
    });

  const { data: csvData } = useQuery(
    ["landDetails", id],
    () => getCSVData(id),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res.data);
        setValue("totalNoOfGrids", csvData?.data?.data?.length);
      },
      onError: (req: AxiosError) => {},
      enabled: !!id,
      refetchOnMount: true,
    }
  );

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: GridFormDetails) => {
    dispatch(updateStepper({ id: 4 }));
  };

  const handleResponseModal = () => {
    if (openModal.type === "success") {
      navigate(path.login, { state: { userId: response["User name"] } });
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <FormWrapper
        btnText="Submit"
        isValid={isValid}
        onNext={() => {
          const otherDetails = getValues();
          createProject(transformActivityData(formData, otherDetails));
        }}
        onBack={() => {
          dispatch(setStep("ProjectImageForm"));
        }}
        isLoading={isProjectCreating}
        subHeading={""}
        currentStepNumber={4}
      >
        <Box {...styles("selectWrapper")}>
          <Box>
            <Box {...styles("selfDeclaration")}>
              Forest Department Undertaking
            </Box>
            <Box
              sx={{
                mb: "40px",
              }}
            >
              <Checkbox
                name="landFreeFromEncumbrances"
                control={control}
                label={"The land is free from all types of encumbrances"}
                rules={{
                  required: true,
                }}
                isManual
                handleChange={(e) => {
                  dispatch(setIsLandFreeFromEncumbrances(e.target.checked));
                }}
              />
              <Checkbox
                name="landSuitableForPlantation"
                control={control}
                label={"The land is suitable for Eco-Restoration"}
                rules={{
                  required: true,
                }}
                isManual
                handleChange={(e) => {
                  dispatch(setIsLandFreeFromEncumbrances(e.target.checked));
                }}
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: defaultStyles.multilineInput,
                }}
                typeof="textarea"
                name="totalNoOfGrids"
                rules={{
                  maxLength: {
                    value: 500,
                    message: "More then 500 characters are not allowed",
                  },
                  required: errorMessage.required,
                }}
                control={control}
                type="text"
                placeholder="(In 500 characters)"
                label="Additional information,(If the proposed plantation block has already been rejected earlier then provide the justification for reuploading and address the comments on the remarks given for rejection)"
                multiline
                minRows={6}
              />
            </Box>
          </Box>
        </Box>
      </FormWrapper>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => {
          handleResponseModal();
          dispatch(setClose());
        }}
        handleModalClose={() => {
          handleModalClose();
          dispatch(setClose());
        }}
      >
        {!!response && (
          <>
            {Object.keys(response).map((item) => (
              <Box {...styles("description")}>
                {item}: {response[item]}
              </Box>
            ))}
          </>
        )}
      </SuccessModal>
    </Box>
  );
};

export default ProjectGridForm;
