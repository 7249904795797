type GuestRouteProps = {
  element: () => JSX.Element;
  path?: string;
  exact?: boolean;
  index?: boolean;
  isAuthRoute?: boolean;
};

const GuestRoute = ({
  element: Component,
  isAuthRoute = false,
  path = "/",
  exact = false,
  index = false,
  ...rest
}: GuestRouteProps) => {
  return <Component {...rest} />;
};

export default GuestRoute;
