import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  headerWrapper: {
    backgroundColor: "custom.text.white.100",
    padding: "14px 24px",
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
  actionFieldWrapper: {
    display: "flex",
    gap: "10px",
    width: "60%",
  },

  inputSearchBar: {
    display: {
      sm: "none",
      xs: "none",
    },
  },
  userType: {
    color: "red",
    fontWeight: "500",
    backgroundColor: "red",
  },
});

export default styles;
