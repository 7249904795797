import { Box, Grid } from "@mui/material";
import { AutoComplete, Button, Input } from "../../../common/FormComponents";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import { useForm } from "react-hook-form";
import errorMessage from "src/constants/errorMessage";
import { units } from "src/constants/common";
import { useMutation, useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { optionTypeFormatter } from "src/utils/helper";
import {
  getProgressActivities,
  getProgressIndicators,
  updateCostEstimateDataAPI,
} from "src/utils/api/dashboard";
import { useEffect, useState } from "react";

export function EditCostEstimateForm({ rowData, selfState, refetch }: any) {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const selectedIndicatorType = rowData.data.indicatorId;

  const { data: progressIndictors } = useQuery(
    ["progressIndictors"],
    getProgressIndicators,
    {
      refetchOnMount: true,
    }
  );

  const Indicators = optionTypeFormatter(progressIndictors?.data?.data);

  const { data: activities } = useQuery(
    ["activities", selectedIndicatorType],
    () => getProgressActivities(selectedIndicatorType),
    {
      enabled: !!selectedIndicatorType,
    }
  );

  const ProgressActivities = optionTypeFormatter(activities?.data?.data);

  const [main, setMain] = useState(null);
  const [sub, setSub] = useState(null);

  useEffect(() => {
    if (!main && Indicators.length > 0) {
      setMain(
        Indicators.find((option) => option.label === rowData.mainComponent)
      );
    }

    if (!sub && ProgressActivities.length > 0) {
      setSub(
        ProgressActivities.find(
          (option) => option.label === rowData.subComponent
        )
      );
    }
  }, [Indicators]);

  const { mutate, isLoading } = useMutation(
    "postUpdatedCostEstimateData",
    ({ updateValue }: { updateValue: any }) =>
      updateCostEstimateDataAPI(rowData.id, updateValue),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log("data updated successfully");
        selfState(false);
        refetch();
      },
      onError: (error: any) => {
        console.log("error occured while updating");
      },
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      indicatorId: null,
      subComponent: null,
      perticularOfItem: rowData.data.perticularOfItem || "",
      unit: rowData.data.unit || "",
      costPerUnit: rowData.data.costPerUnit || 0,
      numberOfUnits: rowData.data.numberOfUnits || 0,
      costRequired: rowData.data.costRequired || 0,
    },
  });

  useEffect(() => {
    if (main && sub) {
      reset({
        indicatorId: main,
        subComponent: sub,
        perticularOfItem: rowData.data.perticularOfItem || "",
        unit: rowData.data.unit || "",
        costPerUnit: rowData.data.costPerUnit || 0,
        numberOfUnits: rowData.data.numberOfUnits || 0,
        costRequired: rowData.data.costRequired || 0,
      });
    }
  }, [main, sub]);

  const onSubmit = (data: any) => {
    mutate({
      updateValue: {
        ...data,
        activityId: data.subComponent.id,
        numberOfUnits: +data.numberOfUnits,
        costRequired: +data.costRequired,
        costPerUnit: +data.costPerUnit,
      },
    });
  };

  return (
    <Box {...styles("wrapper")}>
      <Box
        {...styles("formWrapper")}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <AutoComplete
              options={ProgressActivities}
              rules={{ required: errorMessage.required }}
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="subComponent"
              control={control}
              placeholder="Select Sub Component"
              label="Select Sub Component"
              readOnly
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="perticularOfItem"
              rules={{
                required: errorMessage.required,
                maxLength: {
                  value: 500,
                  message: "More then 500 characters are not allowed",
                },
              }}
              control={control}
              errors={errors}
              placeholder="Particulars of item of works as per cost estimate"
              label="Particulars of item of works as per cost estimate"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AutoComplete
              options={units}
              rules={{ required: errorMessage.required }}
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="unit"
              control={control}
              placeholder="Select Units"
              label="Select Unit"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="numberOfUnits"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Enter Quantity in No.s"
              label="No of units Required"
              type="number"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="costRequired"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Enter Quantity in No.s"
              label="Cost Required(Rs.)"
              type="number"
            />
          </Grid>
        </Grid>
        <Box>
          <Button text="update" type="submit" loading={isLoading} />
        </Box>
      </Box>
    </Box>
  );
}
