import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type NotificationBadgeProps = {
  customStyles?: CustomStyles<StylesClasses>;
  additionalComponent?: React.ReactNode;
  icon?: string | React.ReactElement<any>;
  tabHeaders: {
    label: string;
    value: string;
  }[];
  iconPosition?: "bottom" | "top" | "end" | "start";
  components?: React.ReactNode[];
  onChange?: (e: string) => void;
  tabIndex?: number;
};

const HorizontalTabs = ({
  tabHeaders,
  customStyles,
  additionalComponent,
  icon,
  iconPosition,
  components,
  onChange,
  tabIndex = 0,
}: NotificationBadgeProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [tab, setTab] = useState(tabIndex);

  return (
    <Box>
      <Tabs
        value={tab}
        indicatorColor="primary"
        onChange={(event, tab) => {
          setTab(tab);
          onChange && onChange(tabHeaders[tab]?.value);
        }}
        {...styles("flexContainer")}
      >
        {tabHeaders.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={icon}
            iconPosition={iconPosition}
            {...styles("tab")}
          />
        ))}
        {additionalComponent}
      </Tabs>
      <Box mt={2}>{components[tab]}</Box>
    </Box>
  );
};

export default HorizontalTabs;
