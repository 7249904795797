import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stepperConfig } from "src/constants/common";
import { path } from "src/constants/path";
import { resetFormAction } from "src/redux/createActions/resetForms";
import { Stepper } from "src/types/common";
// import { routePath } from "src/constants/routes";

interface StepperType {
  userType: string;
  devType: string;
}

interface InitialState {
  stepperType: StepperType;
  stepperData: Stepper[];
}

const initialState: InitialState = {
  stepperType: { userType: "", devType: "" },
  stepperData: [
    {
      id: 1,
      isCompleted: false,
      name: "User Type Details",
      isActive: true,
      route: path.onboarding,
    },
    {
      id: 2,
      isCompleted: false,
      name: "Agency Details",
      isActive: false,
      route: path.agencyDetails,
    },
    {
      id: 3,
      isCompleted: false,
      name: "Bank Details",
      isActive: false,
      route: path.bankDetails,
    },
    {
      id: 4,
      isCompleted: false,
      name: "Nodal Officer",
      isActive: false,
      route: path.nodalOfficerDetails,
    },
    {
      id: 5,
      isCompleted: false,
      name: "Implementing Officer",
      isActive: false,
      route: path.implementingOfficerDetails,
    },
  ],
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<StepperType>) => {
      const { userType, devType } = action.payload;

      state.stepperData = stepperConfig.filter((item) => {
        return (
          // item.devType.toLowerCase() === devType.toLowerCase() &&
          item.userType.toLowerCase() === userType.toLowerCase()
        );
      })[0].stepperData;
    },

    updateStepper: (state, action: PayloadAction<{ id: number }>) => {
      let stepper = state.stepperData;

      const currStep = stepper.findIndex(
        (item) => item.id === action.payload.id
      );

      stepper[currStep] = {
        ...stepper[currStep],
        isCompleted: true,
        isActive: false,
      };

      if (action.payload.id + 1 <= stepper.length) {
        stepper[currStep + 1] = {
          ...stepper[currStep + 1],
          isCompleted: false,
          isActive: true,
        };
      }

      state.stepperData = stepper;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.stepperData = initialState.stepperData;
    });
  },
});

export const { updateStepper, setConfig } = stepperSlice.actions;

export default stepperSlice.reducer;
