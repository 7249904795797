import dataProvider from "src/dataProvider";
import { OrganizationType, Roles, VerifyPinCode } from "src/types/api";

let fetcher = dataProvider("openApiInstance");

export const getRole = async () => {
  return fetcher.get<Roles[]>("/roles");
};
export const getOrganizationType = async () => {
  return fetcher.get<OrganizationType[]>("/organization/type");
};

export const getPinCodes = async (districtId: string) => {
  return fetcher.get(`/master/district/${districtId}/pin-code`);
};

export const verifyPinCode = async (data: VerifyPinCode) => {
  return fetcher.post("master/pin-code", data);
};

// New API call

export const getStates = () => {
  return fetcher.get(`/projects/public/states`);
};
