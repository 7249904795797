import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import PopUp from "src/components/common/PopUp";
import { CustomStyles, getStyles } from "src/styles/theme";
import { Button } from "../../FormComponents";
import defaultStyles, { StylesClasses } from "./styles";

type SuccessModalProps = {
  heading?: string;
  subheading?: string;
  description?: string;
  open: boolean;
  btnText?: string;
  type?: string;
  onClick?: () => void;
  handleModalClose?: () => void;
  children?: React.ReactNode;
  customStyle?: CustomStyles<StylesClasses>;
  closeOnBackDrop?: boolean;

  isAlert?: boolean;
};

const SuccessModal = ({
  heading = "Congratulations!",
  subheading = "",
  open,
  handleModalClose,
  onClick,
  description,
  type = "success",
  btnText = "",
  children,
  customStyle,
  closeOnBackDrop = true,

  isAlert = false,
}: SuccessModalProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyle);
  return (
    <PopUp
      open={open}
      handleClose={() => handleModalClose()}
      closeOnBackDrop={closeOnBackDrop}
      actionLeft={{
        hidden: true,
      }}
      actionRight={{
        hidden: true,
      }}
      heading={""}
    >
      <Box {...styles("wrapper")}>
        <Box {...styles("cancelBox")} onClick={handleModalClose}>
          <CloseIcon {...styles("closeIcon")} />
        </Box>

        <Box {...styles("iconWrapper")}>
          <Box
            component={"img"}
            src={
              type === "success" && !isAlert
                ? "/assets/images/check-mark 1.png"
                : "/assets/images/unsuccessfulIcon.png"
            }
            {...styles("img")}
          ></Box>
        </Box>

        <Box {...styles("title")}>{heading}</Box>
        <Box>
          <Box
            {...styles("description", {
              color: isAlert ? "red" : "custom.text.light",
              fontSize: isAlert ? "20px" : "14px",
              fontWeight: isAlert ? "600" : "400",
            })}
          >
            {subheading}
          </Box>
          <Box {...styles("description")}>{description}</Box>
          {children}
        </Box>
        {!!btnText && (
          <Button
            text={btnText}
            type={type === "success" ? "button" : "submit"}
            customStyles={{ contained: defaultStyles.btnDimensions }}
            onClick={onClick}
          />
        )}
      </Box>
    </PopUp>
  );
};

export default SuccessModal;
