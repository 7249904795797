import { createContext, useContext, useState } from "react";

type contextType = {
  componentDeletionState: boolean;
  setComponentDeletionState: React.Dispatch<React.SetStateAction<boolean>>;
};
const DeletionStateContext = createContext<contextType | undefined>(undefined);

export const DeletionStateProvider = ({ children }) => {
  const [componentDeletionState, setComponentDeletionState] = useState(false);

  return (
    <DeletionStateContext.Provider
      value={{ componentDeletionState, setComponentDeletionState }}
    >
      {children}
    </DeletionStateContext.Provider>
  );
};

export const useDeletionState = () => {
  const context = useContext(DeletionStateContext);
  if (context === undefined) {
    throw new Error(
      "useDeletionState must be used within DeletionStateProvider"
    );
  }
  return context;
};
