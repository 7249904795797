import DashboardWrapper from "src/components/common/DashboardWrapper";
import HorizontalTabs from "src/components/common/HorizontalTabs";
import EcoRestorationDashboard from "./ecoRestorationDashboard";
import CostEstimateDashboard from "./costEstimateDashboard";
import { useAppSelector } from "src/redux/store";
import EntityDashboard from "./EntityDashboard";

const MarketWatch = () => {
  const user = useAppSelector((state) => state.root.user);

  return (
    <DashboardWrapper title="Dashboard" subTitle="">
      {user.data.role === "Entity/ Green Credit Applicant" ? (
        <HorizontalTabs
          tabHeaders={[
            { label: "Eco-Restoration Blocks Status Details", value: "0" },
          ]}
          components={[<EntityDashboard />]}
        />
      ) : (
        <HorizontalTabs
          tabHeaders={[
            { label: "Eco-Restoration Blocks", value: "0" },
            { label: "Cost Estimate Details", value: "1" },
          ]}
          components={[<EcoRestorationDashboard />, <CostEstimateDashboard />]}
        />
      )}
    </DashboardWrapper>
  );
};

export default MarketWatch;
