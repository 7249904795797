import { Box, Button, Typography } from "@mui/material";
import { useQuery } from "react-query";
import StatsCard from "src/components/common/StatsCard";
import {
  getDashboardDetails,
  getLandDashboardDetails,
  getTableDashboardDetails,
} from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "../styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getStyles } from "src/styles/theme";
import BarChartComponent from "src/components/common/BarComponent";
import { exportTableToXlsx, formatDataForBarChart } from "src/utils/helper";
import { Collapse } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useState } from "react";
import { DashBoardTable } from "src/components/common/DashboardTable/costEstimateTable";
import { AxiosResponse } from "axios";

const CostEstimateDashboard = () => {
  const [tableRows, setTableRows] = useState([]);

  const [tableState, setTableState] = useState(false);

  const [queryParams, setQueryParams] = useState<{
    projectStateIds?: string[];
    type: string;
  }>({
    projectStateIds: undefined,
    type: "landOwnership",
  });

  const [isTabLoading, setIsTabLoading] = useState(true);

  const { data: tableDashboard, isLoading } = useQuery(
    ["table-landOwnership", queryParams],
    () => getTableDashboardDetails(queryParams),
    {
      onSuccess: (res: AxiosResponse) => {
        setTableRows(
          res?.data?.stats.map((items, idx) => ({
            id: idx,
            sno: idx + 1,
            state: items.state,
            totalAreaGenerateDemandNote: items.totalAreaGenerateDemandNote,
            totalNumberGenerateDemandNote: items.totalNumberGenerateDemandNote,
            totalNumberVerifyDemandNote: items.totalNumberVerifyDemandNote,
            totalAreaVerifyDemandNote: items.totalAreaVerifyDemandNote,
            totalAreaUnderQuery: items.totalAreaUnderQuery,
            totalNumberUnderQuery: items.totalNumberUnderQuery,
            totalAreaNodalUnderQuery: items.totalAreaNodalUnderQuery,
            totalNumberNodalUnderQuery: items.totalNumberNodalUnderQuery,
            totalAreaPaymentPending: items.totalAreaPaymentPending,
            totalNumberPaymentPending: items.totalNumberPaymentPending,
            totalAreaPaymentDone: items.totalAreaPaymentDone,
            totalNumberPaymentDone: items.totalNumberPaymentDone,
            totalAreaRegistered: items.totalAreaRegistered,
            totalNumberRegistered: items.totalNumberRegistered,
            totalNumberDemandNoteGenerated:
              items.totalNumberDemandNoteGenerated,
            totalAreaDemandNoteGenerated: items.totalAreaDemandNoteGenerated,
          }))
        );
        setIsTabLoading(false);
      },
      onError: () => {},
      refetchOnMount: true,
    }
  );

  const dashboardTableData: DashBoardTable = {
    topHeader: [
      "",
      "ALL SELECTED",
      "COST ESTIMATE PENDING",
      "SNO QUERY",
      "VERIFY COST ESTIMATE",
      "ADMIN QUERY",
      "PAYMENT PENDING",
      "PAYMENT DONE",
      "COST ESTIMATE RECEIVED",
    ],
    header: [
      "S.No",
      "Division",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
    ],
    data: tableRows,
  };

  const { data: dashboard, isLoading: dashboardLoading } = useQuery(
    ["landDashboard"],
    () => getLandDashboardDetails(),
    {
      onSuccess: () => {},
      onError: () => {},
      refetchOnMount: true,
    }
  );
  const styles = getStyles(defaultStyles);

  const formattedData = formatDataForBarChart(dashboard?.data?.stats);

  return (
    <>
      <Box {...styles("topHeader")}>
        {!dashboardLoading ? (
          <Box sx={{ width: "75vw", marginBottom: "20px" }}>
            <Box sx={{ width: "80%", marginRight: "80px" }}>
              <BarChartComponent
                data={formattedData}
                label={"Status of Cost Estimates of Eco-Restoration Blocks"}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box {...styles("statsCardWrapper")}>
          {dashboard?.data?.stats.map((stat, index) => (
            <StatsCard
              key={index}
              title={`${stat.description.toUpperCase()}`}
              value={`${stat?.number}`}
              area={stat?.totalArea}
              customStyles={{ valueCard: styles("statsCard").sx }}
            />
          ))}
        </Box>
      </Box>
      <Box {...styles("dashboardTableWrapper")}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" {...styles("heading")}>
            Status of Cost Estimate and Demand Note Under GCP
          </Typography>
          <Button variant="outlined" onClick={() => setTableState(!tableState)}>
            {tableState ? (
              <>
                Compress
                <ArrowUpwardIcon sx={{ marginLeft: "10px" }} />
              </>
            ) : (
              <>
                Expand
                <ArrowDownwardIcon sx={{ marginLeft: "10px" }} />
              </>
            )}
          </Button>
        </Box>
        <Collapse in={tableState}>
          <Button
            variant="outlined"
            onClick={() =>
              exportTableToXlsx(
                dashboardTableData.data,
                dashboardTableData.header,
                dashboardTableData.topHeader
              )
            }
            sx={{ float: "right", marginBottom: "20px" }}
          >
            Export
          </Button>
          <DashBoardTable {...dashboardTableData} />
        </Collapse>
      </Box>
    </>
  );
};

export default CostEstimateDashboard;
