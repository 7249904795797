import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import {
  Button,
  DateInput,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import ViewForm from "src/components/common/ViewForm";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { getStyles } from "src/styles/theme";
import {
  demandDraft,
  getLandDetailsById,
  getProjectById,
  queryHistory,
  verifyDemandDraft,
} from "src/utils/api/dashboard";
import { dateAndTimeStamp } from "src/utils/helper";
import defaultStyles, { StylesClasses } from "../styles";
import ViewImages from "src/components/common/ViewImage";
import Card from "src/components/common/ImageCard";
import { useAppSelector } from "src/redux/store";

const DemandDraft = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const location = useLocation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const user = useAppSelector((state) => state.root.user);

  const { isLoading, mutate } = useMutation(
    "changeStatus",
    ({
      id,
      data,
    }: {
      id: string;
      data: {
        demandNote: number;
        grantedLandKmlId: string;
        paymentBreakdownId: string;
        paymentBreakdownPdf: string;
        geoTaggedImages: any;
        additionalComment: string;
        treeCanopy: number;
        netPlantationArea: number;
        tentativeDateForPlantation: string;
        query: string;
        isEditable: boolean;
      };
    }) => demandDraft({ id, data }),
    {
      onSuccess: () => {
        navigate(path.orderBook);
      },
      onError: () => {},
    }
  );

  const { data: landData, refetch } = useQuery(
    ["landDetails", id],
    () => getLandDetailsById(id),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res.data);
      },
      onError: (req: AxiosError) => {},
      enabled: !!id,
    }
  );

  const projectId = landData?.data?.projectDetail?.id;

  const { data: projectData } = useQuery(
    ["projects", projectId],
    () => getProjectById(projectId),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res);
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
      enabled: !!projectId,
    }
  );

  const { mutate: mutateDemandNoteQueryData } = useMutation(
    "query",
    ({
      landOwnerShipId,
      query,
      type,
      underQuery,
    }: {
      landOwnerShipId: string;
      query: string;
      type: string;
      underQuery: string;
    }) =>
      queryHistory({
        landOwnerShipId,
        query,
        type,
        underQuery,
      }),
    {
      onSuccess: () => {
        refetch();
        navigate("/");
      },
      onError: () => {},
    }
  );

  const projectDetails = projectData?.data?.landSpecification;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onTouched",
    defaultValues: {
      demandNote: "",
      KMLFiles: [],
      paymentBreakdownId: [],
      paymentBreakdownPDFId: [],
      geoTaggedImages: [],
      query: "",
    },
  });

  useEffect(() => {
    if (!!landData) {
      reset({
        demandNote: landData?.data?.demandNote,
        netPlantationArea: landData?.data?.netPlantationArea,
        treeCanopy: landData?.data?.treeCanopy,
        tentativeMonth: landData?.data?.tentativeDateForPlantation,
        additionalComment: landData?.data?.additionalComment,
        KMLFiles: landData?.data?.grantedLandImage
          ? [
              {
                file: landData?.data?.grantedLandImage,
                key: landData?.data?.grantedLandKmlId,
                type: "default",
              },
            ]
          : [],
        paymentBreakdownId: landData?.data?.paymentBreakdownImage
          ? [
              {
                file: landData?.data?.paymentBreakdownImage,
                key: landData?.data?.paymentBreakdownId,
                type: "default",
              },
            ]
          : [],
        paymentBreakdownPDFId: landData?.data?.paymentBreakdownPdf
          ? [
              {
                file: landData?.data?.paymentBreakdownPdfImage,
                key: landData?.data?.paymentBreakdownPdf,
                type: "default",
              },
            ]
          : [],
        geoTaggedImages: landData?.data?.geoTaggedImagesKeys.map((image) => ({
          file: image.imageUrl,
          key: image.key,
          type: "default",
        })),
      });
    }
  }, [landData]);

  const handleForm = (data: any) => {
    mutate({
      id,
      data: {
        demandNote: Number(data.demandNote),
        tentativeDateForPlantation: data.tentativeMonth,
        treeCanopy: Number(data.treeCanopy),
        netPlantationArea: Number(data.netPlantationArea),
        grantedLandKmlId: data?.KMLFiles[0]?.key,
        paymentBreakdownId: data?.paymentBreakdownId[0]?.key,
        paymentBreakdownPdf: data?.paymentBreakdownPDFId[0]?.key,
        geoTaggedImages: data?.geoTaggedImages.map((item) => item.key),
        additionalComment: data?.additionalComment,
        query: data?.query,
        isEditable: false,
      },
    });
  };

  const { mutate: verifyDemandNote } = useMutation(
    "verifyDemandNote",
    ({
      id,
      data,
    }: {
      id: string;
      data: {
        isVerified: boolean;
        remark?: any;
      };
    }) =>
      verifyDemandDraft({
        id,
        data,
      }),
    {
      onSuccess: () => {
        navigate(path.home);
      },
      onError: () => {},
    }
  );

  const downloadFile = () => {
    const filePath = "/Template for Eco restoration cost estimate-GCP-1.xlsx";
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + filePath;
    link.download = "Plantation Estimate template gcp portal.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const disable = user.data.userType === "INDIVIDUAL";

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(handleForm)}
        sx={{ position: "relative" }}
      >
        <DashboardWrapper
          title="Eco-Restoration Cost Estimate"
          customStyles={{
            container: { position: "relative" },
            children: { paddingBottom: "80px" },
          }}
          buttonText="Download Eco-restoration Cost Estimate Template"
          backPath={`/order-book`}
          showBackBtn
          showBtn={true}
          onButtonClick={downloadFile}
        >
          <Box
            sx={{
              fontSize: "24px",
              fontWeight: 500,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>Project ID : {landData?.data?.id} </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                width: "40%",
                position: "absolute",
                right: 0,
              }}
            >
              <Box sx={{ marginBottom: "10px" }}>Note:</Box>
              <Box sx={{ marginBottom: "10px" }}>
                1. While preparing the cost estimates, kindly refer the Office
                Memorandums for modalities of taking up tree plantation and
                guidelines for preparation of cost estimates for eco-restoration
                of degraded forest lands under Green Credit Programme (F.No.
                HSM-12/24/2023-HSM(pt2)-Part(1), E-220847 dated 22.02.2024 and
                12.04.2024 issued by MoEF&CC).{" "}
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                2. Template (excel sheet) attached here contains indicative list
                of items/activities of eco-restoration of degraded forest lands
                can be referred. Items mentioned in the template which are not
                required can be deleted and any other necessary items with its
                unit can be included while preparing site specific estimates.{" "}
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                3. Please don’t include administrative cost of GCP Administrator
                – ICFRE in the estimate.
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                4. State Nodal Officer/Implementing Officer is requested to
                upload the copy of excel sheet (as per above template) and pdf
                file of approved/sanctioned cost estimate (Signed copy) for
                further scrutiny and approval by SNO/Administrator.{" "}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              mt: "30px",
              mb: "30px",
            }}
          >
            Entity Details
          </Box>
          <Box
            sx={{
              fontSize: "18px",
              mt: "30px",
              mb: "30px",
            }}
          >
            <Box sx={{ mb: "10px" }}>
              Entity Name : {landData?.data?.user?.firstName}
            </Box>
            <Box sx={{ mb: "10px" }}>Email : {landData?.data?.user?.email}</Box>
            <Box sx={{ mb: "10px" }}>
              Phone No : {landData?.data?.user?.phoneNumber}
            </Box>
            <Box sx={{ mb: "10px" }}>
              Land Registration No :{" "}
              {landData?.data?.projectDetail?.registrationNo}
            </Box>
            <Box sx={{ mb: "10px" }}>
              Requested Land Area : {Math.abs(landData?.data?.totalArea)}
              {"  "}
              {landData?.data?.areaUnit}
            </Box>
            <Box sx={{ mb: "10px" }}>
              State : {projectData?.data?.address?.stateName}
            </Box>
            <Box sx={{ mb: "10px" }}>
              District : {projectData?.data?.address?.districtName}
            </Box>
            <Box sx={{ mb: "10px" }}>Division : {projectDetails?.division}</Box>
            <Box sx={{ mb: "10px" }}>Range : {projectDetails?.range}</Box>
            <Box sx={{ mb: "10px" }}>Beat: {projectDetails?.beat}</Box>
            <Box sx={{ mb: "10px" }}>
              Compartment Number: {projectDetails?.compartmentNumber}
            </Box>
            <Box sx={{ mb: "10px" }}>
              TreeCanopy Density:{" "}
              {(+projectDetails?.treeCanopyDensity).toFixed(2)}
            </Box>
          </Box>
          <Box {...styles("projectWrapper")}>
            <Box {...styles("input")}>
              <Input
                name="demandNote"
                label="Estimate Amount for Plantation"
                control={control}
                errors={errors}
                rules={{ required: errorMessage.required }}
                placeholder="Enter Estimated Amount of Plantation in INR"
                height="360px"
                type="number"
                readOnly={disable}
                customStyles={{
                  input: {
                    height: "auto",
                    fontFamily: "Poppins",
                    width: { sm: "100%", lg: "100%" },
                  },
                  label: defaultStyles.label,
                }}
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                name="treeCanopy"
                label="TreeCanopy Density"
                control={control}
                errors={errors}
                rules={{ required: errorMessage.required }}
                placeholder="Enter TreeCanopy Density"
                height="360px"
                readOnly={disable}
                type="number"
                customStyles={{
                  input: {
                    height: "auto",
                    fontFamily: "Poppins",
                    width: { sm: "100%", lg: "100%" },
                  },
                  label: defaultStyles.label,
                }}
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                name="netPlantationArea"
                label="Net Planned land Area (in Hectares)"
                control={control}
                readOnly={disable}
                errors={errors}
                rules={{ required: errorMessage.required }}
                placeholder="Enter Net Planned Land Area(in Hectares)"
                height="360px"
                type="number"
                customStyles={{
                  input: {
                    height: "auto",
                    fontFamily: "Poppins",
                    width: { sm: "100%", lg: "100%" },
                  },
                  label: defaultStyles.label,
                }}
              />
            </Box>
            <Box {...styles("input")}>
              <DateInput
                name="tentativeMonth"
                label="Tentative Month to Start Plantation"
                control={control}
                disabled={disable}
                errors={errors}
                max={new Date("10/12/3000")}
                minAge={0}
                rules={{ required: errorMessage.required }}
                clearErrors={clearErrors}
              />
            </Box>

            <Box {...styles("input")}>
              <FileUpload
                key={watch("KMLFiles")[0]?.key}
                name="KMLFiles"
                label="Please upload GPS Boundary (KML file) of land parcel"
                control={control}
                disabled={disable}
                errors={errors}
                required={true}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                maxLimit={1}
                supportedFormatMessage="Please ensure that the KML file should contain closed polygon of land parcel and area of polygon in KML file should not less than the area filled in the form"
                trigger={trigger}
                rules={{
                  validate: {
                    isLength: (value) => {
                      return value && value["length"] >= 1;
                    },
                  },
                }}
                dragDropHeading=""
              />

              <Box {...styles("documentGroup")}>
                <ViewImages
                  fileUrl={landData?.data?.grantedLandImage}
                  label={""}
                />
              </Box>
            </Box>

            <Box {...styles("input")}>
              <FileUpload
                key={watch("paymentBreakdownId")[0]?.key}
                name="paymentBreakdownId"
                label="Ms Excel copy of the cost estimate"
                control={control}
                errors={errors}
                disabled={disable}
                setValue={setValue}
                required={true}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                maxLimit={1}
                supportedFormatMessage="Payment Breakdown for the Estimate Amount for Plantation(Upload Excel file)"
                trigger={trigger}
                rules={{
                  validate: {
                    isLength: (value) => {
                      return value && value["length"] >= 1;
                    },
                  },
                }}
                dragDropHeading=""
              />
              <Box {...styles("documentGroup")}>
                <ViewImages
                  fileUrl={landData?.data?.paymentBreakdownImage}
                  label={""}
                />
              </Box>
            </Box>
            <Box {...styles("input")}>
              <FileUpload
                key={watch("paymentBreakdownPDFId")[0]?.key}
                name="paymentBreakdownPDFId"
                label="PDF of technically sanctioned/approved cost estimate"
                control={control}
                disabled={disable}
                errors={errors}
                required={true}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                maxLimit={1}
                supportedFormatMessage="Payment Breakdown PDF"
                trigger={trigger}
                rules={{
                  validate: {
                    isLength: (value) => {
                      return value && value["length"] >= 1;
                    },
                  },
                }}
                dragDropHeading=""
              />
              {landData?.data?.paymentBreakdownPdfImage && (
                <Box {...styles("documentGroup")}>
                  <ViewImages
                    fileUrl={landData?.data?.paymentBreakdownPdfImage}
                    label={""}
                  />
                </Box>
              )}
            </Box>
            <Box {...styles("input")}>
              <FileUpload
                key={watch("geoTaggedImages")[0]?.key}
                accept="image/jpeg, image/png, image/webp, image/jpg"
                name="geoTaggedImages"
                label="Upload GeoTagged Images"
                control={control}
                disabled={disable}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                required={true}
                clearErrors={clearErrors}
                maxLimit={10}
                multiple={true}
                supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg, Minimum 2 Images required"
                trigger={trigger}
                rules={{
                  validate: {
                    isLength: (value) => {
                      return value && value["length"] >= 2;
                    },
                  },
                }}
                dragDropHeading=""
              />
            </Box>
          </Box>
          {landData?.data?.geoTaggedImages?.length > 0 && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("imageWrapper2")}>
                {landData?.data?.geoTaggedImages?.map((item) => (
                  <Card key={item} title={`GeoTagged Image`} image={item} />
                ))}
              </Box>
            </Box>
          )}

          <Box {...styles("multilineWrapper")}>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: defaultStyles.multilineInput,
                }}
                rules={{
                  required: errorMessage.required,
                  maxLength: {
                    value: 500,
                    message: "More then 500 words are not allowed",
                  },
                }}
                name="additionalComment"
                readOnly={disable}
                control={control}
                type="text"
                placeholder="if you would like to provide additional information about the cost esitmates, Please include it here not More than 500 Words"
                label="Additional Information"
                multiline
                minRows={6}
              />
            </Box>
          </Box>

          {landData?.data?.remark && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Remarks</Box>
              <ViewForm label="Remarks" value={landData?.data?.remark} />
            </Box>
          )}

          {landData?.data?.landOwnerShipQuery.length > 0 && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Administrator Query</Box>
              {landData?.data?.landOwnerShipQuery.map((item) => (
                <Box sx={{ paddingTop: "20px" }}>
                  <ViewForm
                    label={item.user.firstName}
                    value={item.query}
                    date={dateAndTimeStamp(item.created_at, "IST")}
                  />
                </Box>
              ))}
            </Box>
          )}
          {landData?.data?.status !== "GENERATE_DEMAND_NOTE" && (
            <Box {...styles("multilineWrapper")}>
              <Box {...styles("input")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: defaultStyles.multilineInput,
                  }}
                  name="query"
                  rules={{
                    required: errorMessage.required,
                    maxLength: {
                      value: 500,
                      message: "More then 500 characters are not allowed",
                    },
                  }}
                  control={control}
                  type="text"
                  placeholder="(In 500 characters)"
                  label="Query"
                  multiline
                  minRows={6}
                />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "20px",
            }}
          >
            {landData?.data?.status !== "NODAL_VERIFY_DEMAND_NOTE" &&
              user.data.userType !== "INDIVIDUAL" && (
                <Button
                  text="Submit"
                  type="submit"
                  disabled={disable || !isValid}
                  customStyles={{
                    root: {
                      fontSize: "16px",
                    },
                  }}
                />
              )}
          </Box>
        </DashboardWrapper>
      </Box>

      {(landData?.data?.status === "NODAL_VERIFY_DEMAND_NOTE" ||
        landData?.data?.status === "UNDER_QUERY") &&
        user.data.userType === "INDIVIDUAL" && (
          <>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="snoremarks"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="SNO Remarks"
                label="SNO Remarks"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                text="Raise Query"
                onClick={() => {
                  mutateDemandNoteQueryData({
                    landOwnerShipId: id,
                    query: watch("query"),
                    type: "LANDOWNERSHIP",
                    underQuery: "UNDER_QUERY",
                  });
                }}
                disabled={!watch("query")}
                customStyles={{
                  root: {
                    fontSize: "16px",
                  },
                }}
              />
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Button
                  text="Reject"
                  variant="outlined"
                  onClick={() => {
                    verifyDemandNote({
                      id,
                      data: {
                        isVerified: false,
                      },
                    });
                  }}
                  customStyles={{
                    root: {
                      fontSize: "18px",
                      color: "error.main",
                      borderColor: "error.main",
                    },
                  }}
                />
                <Button
                  text="Forward To Admin"
                  type="submit"
                  disabled={!watch("snoremarks")}
                  onClick={() => {
                    verifyDemandNote({
                      id,
                      data: {
                        isVerified: true,
                        remark: watch("snoremarks"),
                      },
                    });
                  }}
                  customStyles={{
                    root: {
                      fontSize: "16px",
                    },
                  }}
                />
              </Box>
            </Box>
          </>
        )}
    </>
  );
};

export default DemandDraft;
