import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    width: { xs: "100%", sm: "80%", lg: "60%" },
  },

  input: {
    flexBasis: "46%",
    margin: "0 0 24px 0",
  },

  phoneNumber: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    gap: "10px",
  },

  phoneCodeError: {
    whiteSpace: "nowrap",
  },

  phoneCode: {
    maxWidth: "50px",
  },

  address: {
    flexBasis: "100%",
  },

  select: {
    flexBasis: "45%",
  },

  checkbox: {
    flexBasis: "100%",
  },

  aadhaarOtpWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexDirection: { xs: "column", sm: "row" },
    gap: "1%",
  },

  otpSend: {
    color: "custom.text.dark",
    opacity: "0.6",
    marginTop: "-15px",
    fontSize: "14px",
    fontWeight: 400,
  },

  aadhaarOtp: {
    marginTop: "25px",
    alignSelf: "center",
    width: "150px",
  },

  sendOTP: {
    color: "custom.text.label",
    fontSize: "16px",
    fontWeight: 600,
    cursor: "pointer",
    width: "80px",
    padding: "10px 0",
    whiteSpace: "nowrap",
    alignSelf: "center",
    marginTop: { xs: "-8px", lg: "25px" },
    position: { xs: "absolute", lg: "static" },
    top: "0px",
    right: "0px",
  },

  otpLabel: {
    marginTop: "0px",
    alignSelf: "center",
    width: "150px",
  },

  fileType: {
    opacity: "0.5",
    fontSize: "13px",
    fontFamily: "400",
  },

  imageBox: {
    gap: "1%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  uploaded: {
    fontWeight: 500,
    color: "#00B449",
  },
});

export default styles;
