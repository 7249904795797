import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AadhaarVerificationFormData } from "src/components/feature/Onboarding/AadhaarVerification";
import { resetFormAction } from "src/redux/createActions/resetForms";

// type FormData = Omit<AadhaarVerificationFormData, "aadhaarOtp"> &
//   Partial<Pick<AadhaarVerificationFormData, "aadhaarOtp">>;
type FormData = AadhaarVerificationFormData;
interface InitialState {
  formData: FormData;
}

const initialState: InitialState = {
  formData: {
    aadhaarNumber: "",
    aadhaarCard: [],
    aadhaarCardBack: [],
    aadhaarOtp: "",
    compareAadhaar: "",
    isAadhaarVerified: false,
  },
};

const AadhaarVerificationSlice = createSlice({
  name: "aadhaarVerification",
  initialState,
  reducers: {
    updateAadhaarVerificationForm: (state, action: PayloadAction<FormData>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateAadhaarVerificationForm } =
  AadhaarVerificationSlice.actions;

export default AadhaarVerificationSlice.reducer;
