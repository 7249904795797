import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  FileUpload,
  Input,
  Select,
} from "src/components/common/FormComponents";
import { useComponentName } from "src/hook/monitoring-component";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import {
  getPlantationDetail,
  postMonitoringComponentDetail,
} from "src/utils/api/dashboard";
import { useMutation, useQuery } from "react-query";
import errorMessage from "src/constants/errorMessage";
import { Option } from "src/types/common";
import { SuccessModal } from "src/components/common/modal";
import {
  ENUM_MONITORING_TYPE,
  KUTIR_MAINTENANCE_TYPE,
  KUTIR_TYPE,
  VANKUTIR_STATUS_TYPE,
} from "src/constants/common";

type SpeciesDetail = {
  speciesId: string;
  casualtyReplaced: number;
  remark?: string;
};
type FormType = {
  vanKutirType: string;
  vanKutirStatus: string;
  watchmanWardPersons: number;
  maintenanceOfFireLine: string;
  weeding: string;
  isFireLineCreated: string;
  remark?: string;
  speciesDetails: SpeciesDetail[];
  images: File[];
};

const fireLineStatus: Option[] = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export function ProtectionForm({ param, yearMonth }: any) {
  const styles = getStyles(defaultStyles);
  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");
  const form = useForm<FormType>();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
    watch,
  } = form;

  const { setComponentName } = useComponentName();

  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    heading: "",
    subHeading: "",
  });
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };
  const handlePopUpClose = () => {
    if (openModal.type === "success") {
      setComponentName(undefined);
    } else {
      handleModalClose();
    }
  };

  const { mutate } = useMutation(
    ["protection"],
    ({ data }: { data: any }) => postMonitoringComponentDetail(data),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          type: "success",
          heading: "Success",
          subHeading:
            "Protection and Maintenance Detail Submitted Successfully",
        });
      },
    }
  );
  const onSubmit = (data: any) => {
    const imageKeys = data.images.map((item) => item.key);

    mutate({
      data: {
        type: ENUM_MONITORING_TYPE.PROTECTION,
        landOwnershipEpid,
        ...param,
        yearMonth: yearMonth,
        vanKutirType: data.vanKutirType,
        vanKutirStatus: data.vanKutirStatus,
        watchmanWardPersons: parseInt(data.watchmanWardPersons, 10),
        isFireLineErected: data.isFireLineCreated ? true : false,
        maintenanceOfFireLine:
          data.isFireLineCreated === "Yes" ? data.maintenanceOfFireLine : "-",
        weeding: data.weeding,
        remark: data.remark,
        images: imageKeys,
        speciesDetails: Object.entries(data.speciesDetails).map((item) => ({
          speciesId: item[0],
          casualtyReplaced: parseInt(item[1]["casualtyReplaced"], 10),
          remark: item[1]["remark"],
        })),
      },
    });
  };

  const { data: plantationData } = useQuery(
    ["plantationDetails", landOwnershipEpid],
    () => getPlantationDetail(landOwnershipEpid),
    {
      onSuccess: () => {},
      onError: () => {},
      enabled: !!landOwnershipEpid,
      //   refetchOnMount: true,
    }
  );

  const isFireLineCreated = watch("isFireLineCreated");

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      {...styles("formWrapper")}
    >
      <Box {...styles("formElementWrapper")}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Select
              name="vanKutirType"
              placeholder="Van Kutir Type"
              label="Van Kutir Type"
              control={control}
              errors={errors}
              options={KUTIR_TYPE}
              rules={{
                required: errorMessage.required,
              }}
              customStyle={{
                wrapper: { mt: "14px" },
                input: styles("formselect").sx,
                label: { fontSize: "14px" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              name="vanKutirStatus"
              placeholder="Van Kutir Status"
              label="Van Kutir Status"
              control={control}
              errors={errors}
              options={VANKUTIR_STATUS_TYPE}
              rules={{
                required: errorMessage.required,
              }}
              customStyle={{
                wrapper: { mt: "14px" },
                input: styles("formselect").sx,
                label: { fontSize: "14px" },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box {...styles("formElementWrapper")}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              name="watchmanWardPersons"
              control={control}
              errors={errors}
              placeholder="Watchmen Ward Engaged (No.)"
              label="Watchmen Ward Engaged (No.)"
              rules={{ required: errorMessage.required }}
              type="number"
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              name="weeding"
              control={control}
              errors={errors}
              placeholder="Weeding"
              label="Weeding"
              rules={{ required: errorMessage.required }}
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {/* FIRE LINE */}
      <Box {...styles("formElementWrapper")}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Select
              name="isFireLineCreated"
              placeholder="Fire Line Created"
              label="Fire Line Created"
              control={control}
              errors={errors}
              options={fireLineStatus}
              rules={{
                required: errorMessage.required,
              }}
              customStyle={{
                wrapper: { mt: "14px" },
                input: styles("formselect").sx,
                label: { fontSize: "14px" },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {isFireLineCreated && isFireLineCreated === "Yes" && (
              <Select
                name="maintenanceOfFireLine"
                placeholder="Maintenance of Fire Line"
                label="Maintenance of Fire Line"
                control={control}
                errors={errors}
                options={KUTIR_MAINTENANCE_TYPE}
                rules={{
                  required: errorMessage.required,
                }}
                customStyle={{
                  wrapper: { mt: "14px" },
                  input: styles("formselect").sx,
                  label: { fontSize: "14px" },
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      {/* TABLE */}
      <Box {...styles("formElementWrapper")}>
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "custom.greyish.3" }}>
              <TableRow>
                <TableCell align="center" sx={{ width: "70px" }}>
                  S. No.{" "}
                </TableCell>
                <TableCell align="center">Botanical Name </TableCell>
                <TableCell align="center">Common Name</TableCell>
                <TableCell align="center">No. of Seedling</TableCell>
                <TableCell align="center" sx={{ width: "200px" }}>
                  Casualty Replaced
                </TableCell>
                <TableCell align="center" sx={{ width: "200px" }}>
                  Observations
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plantationData?.data.data &&
                plantationData?.data.data.map((item, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{item.botanicalName}</TableCell>
                    <TableCell align="center">{item.commonName}</TableCell>
                    <TableCell align="center">
                      {item.numberOfSeedlings}
                    </TableCell>

                    <TableCell align="center">
                      <Input
                        customStyles={{
                          label: styles("inputLabel").sx,
                          wrapper: styles("inputWrapper").sx,
                          input: {
                            borderColor: "#36B1A0;",
                            boxShadow: "unset",
                          },
                          errorMsg: styles("errorMsg").sx,
                        }}
                        name={`speciesDetails.${item.id}.casualtyReplaced`}
                        control={control}
                        errors={errors}
                        placeholder="Casualty Replaced"
                        rules={{ required: errorMessage.required }}
                        type="number"
                      />
                    </TableCell>
                    <TableCell align="center" sx={{ paddingTop: "10px" }}>
                      <Input
                        customStyles={{
                          label: styles("inputLabel").sx,
                          wrapper: styles("inputWrapper").sx,
                          input: {
                            borderColor: "#36B1A0;",
                            boxShadow: "unset",
                          },
                          errorMsg: styles("errorMsg").sx,
                        }}
                        name={`speciesDetails.${item.id}.remark`}
                        control={control}
                        errors={errors}
                        placeholder="Observations"
                        // rules={{
                        //   required: errorMessage.required,
                        // }}
                      />
                    </TableCell>
                    {/* <TableCell align="center" sx={{ paddingTop: "10px" }}>
                      <Input
                        customStyles={{
                          label: styles("inputLabel").sx,
                          wrapper: styles("inputWrapper").sx,
                          input: {
                            borderColor: "#36B1A0;",
                            boxShadow: "unset",
                          },
                          errorMsg: styles("errorMsg").sx,
                        }}
                        name={`speciesDetails.${item.id}.images`}
                        control={control}
                        errors={errors}
                        placeholder="Images"
                        // rules={{ required: errorMessage.required }}
                      />
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box {...styles("formElementWrapper")}>
        <Input
          customStyles={{
            label: styles("inputLabel").sx,
            wrapper: styles("inputWrapper").sx,
            input: {
              borderColor: "#36B1A0;",
              boxShadow: "unset",
            },
            errorMsg: styles("errorMsg").sx,
          }}
          name="remark"
          control={control}
          errors={errors}
          placeholder="Overall Protection and Maintenence Observations"
          label="Overall Protection and Maintenence Observations"
          // rules={{ required: errorMessage.required }}
        />
      </Box>

      <Box>
        <Grid container item spacing={3} xs={12} sx={{ margin: "20px 20px " }}>
          <FileUpload
            accept="image/jpeg, image/png, image/webp, image/jpg"
            name={"images"}
            label="Upload GeoTagged Images (Minimum 2 images are required )"
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            maxLimit={10}
            multiple={true}
            required={true}
            supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
            trigger={trigger}
            rules={{
              validate: {
                isLength: (value) => {
                  return value && value["length"] >= 2;
                },
              },
            }}
            dragDropHeading=""
          />
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button text="Submit" type="submit" disabled={!isValid} />
      </Box>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subHeading}
        closeOnBackDrop
        type={openModal.type}
        handleModalClose={() => handlePopUpClose()}
      />
    </Box>
  );
}
