import { Alert, AlertColor, Snackbar as MatSnackbar } from "@mui/material";

type SnackbarProps = {
  message: string;
  open: boolean;
  severity: AlertColor;
  duration?: number;
  resetSnackbar: () => void;
};

const Snackbar = ({
  message,
  open,
  resetSnackbar,
  severity,
  duration = 3000,
}: SnackbarProps) => {
  return (
    <>
      <MatSnackbar
        open={open}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={resetSnackbar}
      >
        <Alert severity={severity}>{message}</Alert>
      </MatSnackbar>
    </>
  );
};

export default Snackbar;
