import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "src/components/common/FormComponents";
import Password from "src/components/common/FormComponents/Password";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { getStyles } from "src/styles/theme";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import { useMutation } from "react-query";
import { changePassword } from "src/utils/api/dashboard";

export type ChangePasswordFormData = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  userName: string;
};

const ChangePassword = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const { password: passwordRegex } = regex;

  const { isLoading, mutate } = useMutation(
    "changePassword",
    ({ data }: { data }) => changePassword(data),
    {
      onSuccess: () => {
        alert("Password changed successfully!");
        navigate("/");
      },
      onError: (error: any) => {
        alert(error.response?.data?.message || "An error occurred");
      },
    }
  );

  const { control, formState, handleSubmit, getValues, watch } =
    useForm<ChangePasswordFormData>({
      mode: "onTouched",
      defaultValues: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        userName: "",
      },
    });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: ChangePasswordFormData) => {
    if (data.newPassword !== data.confirmNewPassword) {
      alert("Error: New Password and confirm New password do not match.");
      return;
    }
    mutate({
      data: {
        userName: data.userName,
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
    });
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <DashboardWrapper
        title="Change Password"
        buttonIcon="/assets/svg/roundPlusIcon.svg"
        buttonText=""
        onButtonClick={() => {}}
      >
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Input
              name="userName"
              label="GCP User ID"
              placeholder="Enter your username"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              name="currentPassword"
              label="Current Password"
              placeholder="Enter your current password"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("input")}>
            <Password
              name="newPassword"
              label="New Password"
              placeholder="Enter your new password"
              control={control}
              getValues={getValues}
              showHelperText
              customStyles={{
                customError: defaultStyles.pwdErrorMsg,
              }}
              height="52px"
              rules={{
                required: true,
                validate: {
                  isLength: (value: string) => value && value?.length >= 8,
                  isUpper: (value) =>
                    value && passwordRegex.isUpper.test(value.toString()),
                  isLower: (value) =>
                    value && passwordRegex.isLower.test(value.toString()),
                  isNum: (value) =>
                    value && passwordRegex.isNum.test(value.toString()),
                  hasSpecialCharacter: (value) =>
                    value &&
                    passwordRegex.hasSpecialCharacter.test(value.toString()),
                },
              }}
              errors={errors}
            />
          </Box>
          <Box {...styles("input")}>
            <Password
              name="confirmNewPassword"
              label="Confirm New Password"
              placeholder="Re-enter your new password"
              control={control}
              getValues={getValues}
              showHelperText
              customStyles={{
                customError: defaultStyles.pwdErrorMsg,
              }}
              height="52px"
              rules={{
                required: true,
                validate: {
                  isLength: (value: string) => value && value?.length >= 8,
                  isUpper: (value) =>
                    value && passwordRegex.isUpper.test(value.toString()),
                  isLower: (value) =>
                    value && passwordRegex.isLower.test(value.toString()),
                  isNum: (value) =>
                    value && passwordRegex.isNum.test(value.toString()),
                  hasSpecialCharacter: (value) =>
                    value &&
                    passwordRegex.hasSpecialCharacter.test(value.toString()),
                },
              }}
              errors={errors}
            />
          </Box>
        </Box>
        <Button
          text="Change Password"
          type="submit"
          customStyles={{
            root: {
              fontSize: "16px",
            },
          }}
        />
      </DashboardWrapper>
    </Box>
  );
};

export default ChangePassword;
