import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  heading: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "24px",
    marginTop: "24px",
  },

  input: {
    flexBasis: "46%",
    margin: "0 0 24px 0",
  },

  checkbox: {
    width: "100%",
  },

  description: {
    color: "custom.text.light",
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "10px",
    textAlign: "center",
  },

  addressRight: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexBasis: { sm: "100%", lg: "35%" },
  },

  address: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    gap: "3%",
  },

  addressWrapper: {
    flexBasis: { sm: "100%", lg: "35%" },
  },

  selfDec: {
    fontWeight: "600",
    fontSize: "20px",
    marginBottom: "15px",
  },

  checkBoxHeading: {
    color: "error.main",
  },
});

export default styles;
