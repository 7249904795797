import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AgencyFormData } from "src/components/feature/Onboarding/AgencyDetails";
import { resetFormAction } from "src/redux/createActions/resetForms";

interface InitialState {
  formData: AgencyFormData;
}

const initialState: InitialState = {
  formData: {
    agencyName: "",
    state: "",
    streetAddress: "",
    district: "",
    city: "",
  },
};

const AgencyDetailsSlice = createSlice({
  name: "AgencyDetails",
  initialState,
  reducers: {
    updateAgencyDetails: (state, action: PayloadAction<AgencyFormData>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateAgencyDetails } = AgencyDetailsSlice.actions;

export default AgencyDetailsSlice.reducer;
