import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  stepperWrapper: {
    width: "100%",
    position: "sticky",
    justifyContent: "center",
    margin: "0 auto",
    backgroundColor: "custom.background.bodyLight",
  },

  stepComp: {
    pl: 0,
    pr: 0,
    p: "20px 0",
    textAlign: "center",
    alignItems: "center",
    "& .MuiStepConnector-line": {
      borderTopWidth: "4px",
      borderColor: "custom.background.grey",
      alignSelf: "center",
    },
    "& .MuiStepConnector-root": {
      left: "calc(-50% + 36px)",
      right: "calc(50% + 36px)",
      top: { xs: "calc(40% - 15px)", sm: "calc(50% - 15px)" },
    },
    "& .MuiStepConnector-root.Mui-active ": {
      borderColor: "custom.background.grey",
      "& .MuiStepConnector-line ": {
        borderColor: "primary.main",
      },
    },
    "& .Mui-completed": {
      "& .MuiStepConnector-line ": {
        borderColor: "primary.main",
      },
    },
  },

  icon: {
    width: "41px",
    height: "41px",
    backgroundColor: "custom.background.grey",
    color: "custom.text.white.100",
  },

  stepperBtn: {
    margin: 0,
    padding: 0,
    justifyContent: "center",
  },

  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "41px",
    height: "41px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.36,
    color: "custom.text.white.100",
    backgroundColor: "custom.background.grey",
    borderRadius: "50%",
  },

  active: {
    backgroundColor: "custom.background.blue",
  },

  completed: {
    backgroundColor: "primary.main",
  },
});

export default styles;
