import { Box } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  FileUpload,
  Input,
  Select,
} from "src/components/common/FormComponents";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import {
  updateOrganizationDetailsForm,
  updateStepper,
} from "src/redux/actions";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { getOrganizationType } from "src/utils/api/roles";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type OrganizationDetailsFormData = {
  organizationName: string;
  organizationType: string;
  organizationCertificates: {
    type: string;
    key: string;
    file: string;
  }[];
  IdentityProof: {
    type: string;
    key: string;
    file: string;
  }[];
  AuthorizedRepresentativeName: string;
  AuthorizedRepresentativeDesignation: string;
  email: string;
  phone: {
    countryCode: string;
    number: string;
  };
  password: string;
  verifyEmail: boolean;
  verifyPhone: boolean;
  emailOtp: string;
  phoneOtp: string;
};

const OrganizationDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [organizationOptions, setOrganizationOptions] = useState([]);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { password: passwordRegex } = regex;

  const { formData } = useAppSelector(
    (state) => state.root.organizationDetails
  );

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    watch,
  } = useForm<OrganizationDetailsFormData>({
    mode: "onTouched",
    defaultValues: {
      ...formData,
      password: "",
    },
  });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: OrganizationDetailsFormData) => {
    dispatch(updateOrganizationDetailsForm({ ...data }));
    dispatch(updateStepper({ id: 3 }));
    navigate(path.contactDetails);
  };
  const handlePhoneVerification = (otp: string) => {
    setValue("phoneOtp", otp);
    setValue("verifyPhone", true);
  };

  useQuery("getOrgType", () => getOrganizationType(), {
    onSuccess: (data) => {
      setOrganizationOptions(
        data.data.map(({ type, isActive, id }) => ({
          label: type,
          value: id,
          isActive: isActive,
          id: id,
        }))
      );
    },
    retry: 1,
    refetchOnMount: true,
  });

  const handleOtp = (otp: string) => {
    setValue("emailOtp", otp);
    setValue("verifyEmail", true);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Upload ID and Documents"
        isValid={isValid}
        previousRoute={path.onboarding}
      >
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Select
              name="organizationType"
              label="Entity Type"
              control={control}
              errors={errors}
              height="52px"
              customStyle={{ wrapper: defaultStyles.selectWrapper }}
              rules={{ required: errorMessage.required }}
              options={organizationOptions}
            />
          </Box>

          <Box {...styles("input")}>
            <Input
              name="organizationName"
              label="Identity Proof Number(Aadhar/PAN/GSTIN Number)"
              placeholder="Identity Proof Number"
              control={control}
              rules={{ required: errorMessage.required }}
              height="52px"
            />
          </Box>

          <Box {...styles(["input", "fileUpload"])}>
            <FileUpload
              name="IdentityProof"
              label="Upload Aadhar/TAN/PAN/CIN/GSTIN Image"
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              maxLimit={1}
              clearErrors={clearErrors}
              trigger={trigger}
              customStyles={{
                wrapper: defaultStyles.borderStyle,
                fileType: defaultStyles.fileType,
                label: {
                  whiteSpace: "wrap",
                },
              }}
              rules={{
                validate: {
                  isLength: (value) => {
                    return value && value["length"] >= 1;
                  },
                },
              }}
              dragDropHeading=""
            />
          </Box>

          {watch("organizationType") !==
            "0d984b49-b70c-449c-b493-505ffa32c115" &&
            watch("organizationType") !==
              "2349ac97-480d-446d-a01b-5fc02d6617cf" &&
            watch("organizationType") !==
              "adba6d88-c90d-4551-b041-4669530f0c38" && (
              <Box {...styles(["input", "fileUpload"])}>
                <FileUpload
                  name="organizationCertificates"
                  label="Upload Copy of Registration Documents*"
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                  maxLimit={1}
                  trigger={trigger}
                  customStyles={{
                    wrapper: defaultStyles.borderStyle,
                    fileType: defaultStyles.fileType,
                    label: {
                      whiteSpace: "wrap",
                    },
                  }}
                  rules={{
                    validate: {
                      isLength: (value) => {
                        return value && value["length"] >= 1;
                      },
                    },
                  }}
                  dragDropHeading=""
                />
              </Box>
            )}
        </Box>
      </OnboardingWrapper>
    </Box>
  );
};

export default OrganizationDetails;
