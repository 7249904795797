import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    borderRadius: "8px",
    boxSizing: "border-box",
  },

  label: {
    color: "custom.text.label",
    fontSize: "16px",
    fontWeight: 600,
    cursor: "pointer",
    width: "80px",
    padding: "10px 0",
  },

  cancelBox: {
    position: "absolute",
    top: "15px",
    right: "13px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  closeIcon: {
    marginLeft: "10px",
    color: "black",
    fontSize: "30px",
  },

  title: {
    color: "custom.text.dark",
    fontSize: "24px",
    fontWeight: 600,
    textAlign: "center",
  },

  reSend: {
    color: "custom.text.label",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
  },

  reSendBtn: {
    backgroundColor: "transparent",
    border: "none",
    color: "custom.text.label",
    fontSize: "12px",
    fontWeight: 600,
    cursor: "pointer",
  },

  icon: {
    textAlign: "center",
  },

  otpWrapper: {
    display: "flex",
    justifyContent: "center",
  },

  timer: {
    textAlign: "center",
    color: "red",
  },

  cancelBtn: {
    color: "custom.text.dark",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "center",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    width: "50px",
    margin: "auto",
    boxShadow: "none",
  },

  description: {
    color: "custom.text.dark",
    opacity: "0.5",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
  },

  csRoot: {
    minWidth: "174px",
    margin: "0px auto",
  },

  verified: {
    color: "primary.main",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "5px 0",
  },
});

export default styles;
