import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { entitySignUpData } from "src/adapter/signUpData";
import { Input, PhoneNumber } from "src/components/common/FormComponents";
import Checkbox from "src/components/common/FormComponents/Checkbox";
import { SuccessModal } from "src/components/common/modal";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { updateContactDetailsForm, updateStepper } from "src/redux/actions";
import { resetFormAction } from "src/redux/createActions/resetForms";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { signUp } from "src/utils/api/signUp";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type ContactDetailsFormData = {
  fullName: string;
  email: string;
  phone: {
    countryCode: string;
    number: string;
  };
  mobilePhone: {
    countryCode: string;
    number: string;
  };
  name: string;
  designation: string;
  selfDeclaration: boolean;
};

const ContactDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [response, setResponse] = useState({});

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const { formData } = useAppSelector((state) => state.root.contactDetails);

  const { userType, entityDetails, organizationDetails } = useAppSelector(
    (rootState) => rootState.root
  );

  const adminSignUp = useMutation("signUp", (data: any) => signUp(data), {
    onSuccess: (res: AxiosResponse) => {
      setResponse({ "User name": res.data.userName });
      setOpenModal({
        open: true,
        heading: "",
        subheading: "Your account has been created!",
        description:
          "Login details will be sent to your registered Email and Phone Number",
        btnText: "Login",
        type: "success",
      });
      dispatch(resetFormAction({}));
    },

    onError: (err: AxiosError) => {
      setResponse({});
      setOpenModal({
        open: true,
        heading: "Unsuccessful",
        subheading: "",
        description: err.response.data.message || "Something went wrong",
        btnText: "",
        type: "unSuccess",
      });
    },
  });

  const { control, formState, handleSubmit, getValues, setValue, watch } =
    useForm<ContactDetailsFormData>({
      mode: "onTouched",
      defaultValues: {
        ...formData,
      },
    });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: ContactDetailsFormData) => {
    dispatch(updateContactDetailsForm({ ...data }));
    dispatch(updateStepper({ id: 4 }));
    const updatedFormData = entitySignUpData({
      userType,
      entityDetails,
      organizationDetails,
      contactDetails: { formData: data },
    });
    adminSignUp.mutate(updatedFormData);
  };

  const handleResponseModal = () => {
    if (openModal.type === "success") {
      navigate(path.login, { state: { userId: response["User name"] } });
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Contact Details"
        previousRoute={path.onboarding}
        isValid={isValid}
        nextBtnLabel="Finish"
      >
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Input
              name="name"
              label="Name of Authorized Person"
              placeholder="Enter Name of Authorized Person"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              name="designation"
              label="Designation"
              placeholder="Enter your Designation"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles(["input", "withHelperText"])}>
            <PhoneNumber
              control={control}
              errors={errors}
              name="phone"
              label="Contact Number (Landline)"
              isRequired={false}
            />
          </Box>

          <Box {...styles(["input", "withHelperText"])}>
            <PhoneNumber
              control={control}
              errors={errors}
              name="mobilePhone"
              label="Contact Number (Mobile)"
            />
          </Box>

          <Box {...styles(["input", "withHelperText"])}>
            <Input
              name="email"
              label="Email"
              placeholder="Enter your email"
              control={control}
              rules={{
                required: errorMessage.required,
                pattern: {
                  value: regex.email,
                  message: errorMessage.invalidEmail,
                },
              }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box>
            <Box {...styles("selfDec")}>
              Declarations&nbsp;
              <Box component="span" {...styles("checkBoxHeading")}>
                *
              </Box>
            </Box>
            <Checkbox
              name="selfDeclaration"
              control={control}
              label={
                "I hereby declare that the information provided above is true and accurate to the best of my knowledge. I understand that any false information may lead to the rejection of this registration."
              }
              rules={{
                required: true,
              }}
            />
          </Box>
        </Box>
      </OnboardingWrapper>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      >
        {!!response && (
          <>
            {Object.keys(response).map((item) => (
              <Box {...styles("description")}>
                {item}: {response[item]}
              </Box>
            ))}
          </>
        )}
      </SuccessModal>
    </Box>
  );
};

export default ContactDetails;
