import { Verified } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { OtpType } from "src/constants/common";
import { useMutation } from "react-query";
import { Button } from "src/components/common/FormComponents";
import OtpInput from "src/components/common/FormComponents/OtpInput";
import PopUp from "src/components/common/PopUp";
import useTimer from "src/hook/useTimer";
import { CustomStyles, getStyles } from "src/styles/theme";
import { Otp } from "src/types/api";
import { SnackbarType } from "src/types/common";
import { otpVerification, sendOtp } from "src/utils/api/signUp";
import Snackbar from "../../Snackbar";
import defaultStyles, { StylesClasses } from "./styles";

export type OtpModalProps<T> = UseControllerProps<T> & {
  errors: FieldErrors;
  getValues: UseFormGetValues<T>;
  setValue: UseFormSetValue<T>;
  verifyOtp: (data: string, otpToken?: string) => void;
  watch: UseFormWatch<T>;
  label?: string;
  defaultOpen?: boolean;
  resendTimer?: number;
  customStyles?: CustomStyles<StylesClasses>;
  otpFields?: number;
  heading?: string;
  closeOnBackDrop?: boolean;
  subHeading?: string;
  type?: Otp;
  disableSend?: boolean;
  sentOtpVariant?: keyof typeof OtpType;
  isVerified?: boolean;
  openFromOutside?: boolean;
  handleModalFromOutside?: () => void;
  // handleClose: () => void;
};

const OtpModal = <T extends FieldValues>({
  control,
  errors,
  getValues,
  verifyOtp,
  // handleClose,
  label = "",
  defaultOpen = false,
  setValue,
  name,
  resendTimer = 600,
  otpFields = 6,
  customStyles,
  rules,
  heading = "",
  subHeading = "",
  type,
  isVerified = false,
  sentOtpVariant = "EMAIL",
  closeOnBackDrop = true,
  openFromOutside = false,
  handleModalFromOutside,
  disableSend = false,
  watch,
}: OtpModalProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [openModal, setOpenModal] = useState<boolean>(defaultOpen);
  const [resultTime, reset, isTimedOut] = useTimer(resendTimer);
  const [otpToken, setOtpToken] = useState("");
  const [refId, setRefId] = useState("");
  const { min, sec } = resultTime;
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
  });

  const { isLoading: optLoading, mutate } = useMutation(
    "Otp",
    () => sendOtp(type, sentOtpVariant),
    {
      onSuccess: (res: AxiosResponse) => {
        setValue(name, "" as PathValue<T, Path<T>>);
        setShowSnackbar({
          severity: "success",
          message: "OTP sent successfully",
        });
        if (!!res?.headers["x-otp-token"]) {
          setOtpToken(res?.headers["x-otp-token"]);
        }
        setRefId(res?.data?.data?.refId);

        reset();
      },
      onError: (err: AxiosError) => {
        handleModalClose();
        setShowSnackbar({
          severity: "error",
          message: err.response?.data?.message || "Something went wrong",
        });
      },
    }
  );

  const { mutate: verify, isLoading: isVerifying } = useMutation(
    "verifyOtp",
    () =>
      otpVerification(
        { refId: refId, type: type.type, otp: getValues(name) },
        { "x-otp-token": otpToken }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        verifyOtp(
          getValues(name),
          res?.headers["x-otp-token"] ?? res?.headers["x-mobile-token"]
        );
        setOpenModal(false);
      },
      onError: (err: AxiosError) => {
        setShowSnackbar({
          severity: "error",
          message: err.response?.data?.message || "Something went wrong",
        });
      },
    }
  );

  useEffect(() => {
    if (openFromOutside) {
      setOpenModal(true);
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFromOutside]);

  const handleModalClose = () => {
    setOpenModal(false);
    !!handleModalFromOutside && handleModalFromOutside();
  };

  useEffect(() => {
    setButtonDisabled(!isTimedOut);
  }, [isTimedOut]);

  const handleModal = () => {
    setOpenModal(true);
    if (!!type) {
      mutate();
    }
  };

  const handleSubmit = () => {
    verify();
  };

  return (
    <>
      {isVerified && !openFromOutside ? (
        <Box {...styles("verified")}>
          <Verified accentHeight={10} color="primary" /> Verified
        </Box>
      ) : (
        <>
          {!!label && (
            <Box
              {...styles("label", {
                ...(disableSend && {
                  color: "custom.text.dark",
                  opacity: 0.3,
                  cursor: "not-allowed",
                }),
              })}
              onClick={() => !disableSend && handleModal()}
            >
              {label}
            </Box>
          )}
        </>
      )}
      <PopUp
        open={openModal}
        handleClose={handleModalClose}
        actionLeft={{
          hidden: true,
        }}
        actionRight={{
          hidden: true,
        }}
        heading={""}
        loading={optLoading}
        closeOnBackDrop={closeOnBackDrop}
      >
        <Box {...styles("wrapper")}>
          <>
            <Box {...styles("cancelBox")} onClick={handleModalClose}>
              <CloseIcon {...styles("closeIcon")} />
            </Box>

            <Box {...styles("icon")}>
              <Box component="img" src="/assets/images/otpLeafIcon.png" />
            </Box>
            <Box {...styles("title")}>{heading}</Box>
            <Box {...styles("description")}>{subHeading}</Box>
            <Box {...styles("otpWrapper")}>
              <OtpInput
                control={control}
                name={name}
                errors={errors}
                otpFields={otpFields}
                setValue={setValue}
                getValues={getValues}
                rules={rules}
              />
            </Box>
            <Box
              {...styles("reSendBtn")}
              component={"button"}
              disabled={isButtonDisabled}
              onClick={() => {
                if (isTimedOut) {
                  mutate();
                }
              }}
            >
              Resend in&nbsp;
              <Box component={"span"} {...styles("timer")}>
                {min && min > 9 ? min : "0" + min}:
                {sec && sec > 9 ? sec : "0" + sec}s
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Button
                text={"submit"}
                onClick={() => handleSubmit()}
                disabled={getValues(name)?.length !== otpFields || isVerifying}
                loading={isVerifying}
                customStyles={{
                  root: defaultStyles.csRoot,
                }}
              />
            </Box>
            <Button
              text={"Cancel"}
              variant="text"
              onClick={handleModalClose}
              customStyles={{
                root: defaultStyles.cancelBtn,
              }}
            />
          </>
        </Box>
      </PopUp>
      <Snackbar
        open={!!showSnackbar.message}
        message={showSnackbar.message}
        severity={showSnackbar.severity}
        resetSnackbar={() => setShowSnackbar({ ...showSnackbar, message: "" })}
      />
    </>
  );
};

export default OtpModal;
