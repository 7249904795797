import { Box, Tooltip as MuiTooltip } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import { StylesClasses } from "./styles";
import defaultStyles from "./styles";

type TooltipProps = {
  customStyles?: CustomStyles<StylesClasses>;
  title: string;
  description?: string;
  classes?: "error" | "default";
  children: React.ReactNode;
};

export const ToolTip = ({
  customStyles,
  classes = "default",
  children,
  title,
  description,
}: TooltipProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <MuiTooltip
      classes={{ tooltipArrow: classes, arrow: classes }}
      title={
        <Box {...styles("titleBoxContainer")}>
          <Box {...styles("title")}>{title}</Box>
          {description && <Box {...styles("description")}>{description}</Box>}
        </Box>
      }
      arrow={true}
      placement="right"
    >
      <Box>{children}</Box>
    </MuiTooltip>
  );
};

export default ToolTip;
