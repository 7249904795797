import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "3%",
    width: { xs: "50%", sm: "90%", lg: "80%" },
  },

  sectionWrapper: {
    width: "45%",
    marginTop: "40px",
  },

  errorCustom: {},

  customWrapper: {
    "& .MuiInputBase-root": {
      border: "none",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      height: "48px",
      width: "80px",
    },
    minWidth: "80px",
    position: "absolute",
    top: "-5px",
    left: "1px",
    zIndex: "100",
  },

  input: {
    margin: "0 0 24px 0",
    flexBasis: { xs: "100%", sm: "75%", lg: "46%" },
  },
  phoneNumber: {},
  combine: {
    position: "relative",
  },
  inpGroup: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  selectWrapper: {
    minWidth: "100px",
    borderTopRightRadius: "0px",
  },

  fileUpload: {
    flexBasis: "100%",
  },
  borderStyle: {
    borderColor: "custom.border.otpDash",
    border: "2px dashed",
    borderRadius: "12px",
    backgroundColor: "custom.background.secondaryGrey",
  },
  fileType: {
    opacity: "0.5",
    fontSize: "13px",
    fontFamily: "400",
  },
  bottomText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "custom.text.dark",
    opacity: 0.4,
    marginTop: "6px",
  },

  countryCode: {
    "& .MuiInputBase-root": {
      borderTopRightRadius: "0px",
      borderTopRIghtColor: "none",
      borderTopLeftRadius: "12px",
      borderBottomRightRadius: "0px",
      borderBottomRightColor: "none",
      borderBottomLeftRadius: "12px",
    },
    minWidth: "80px",
  },
  phoneCustom: {
    position: "relative",
    top: "-30px",
    "& .MuiInputBase-root": {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    label: {
      position: "relative",
      // left: "-80px",
    },
    minWidth: "80px",
    marginTop: {
      sm: "31px",
      xs: "28px",
    },
  },

  multilineInput: {
    height: "100%",
  },

  fieldHelperText: {
    flexDirection: "column",
    color: "custom.text.dark",
    opacity: "0.6",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
  },

  otpLabel: {
    whiteSpace: "nowrap",
  },

  label: {
    fontSize: "14px",
    mt: "15px",
  },
  date: {
    marginTop: { xs: "0", lg: "16px" },
  },
  formWrapper: {
    margin: "20px 0",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.greyish.6",
    padding: "15px 20px",
    borderRadius: "15px",
    maxWidth: { xs: "100%", lg: "60%" },
  },
  heading: {
    fontSize: "18px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "custom.text.myProfileText",
    marginBottom: "30px",
  },
});

export default styles;
