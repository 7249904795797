import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    flexDirection: { xs: "column", sm: "row" },
    gap: "3%",
  },

  input: {
    flexBasis: { xs: "100%", sm: "75%", lg: "34%" },
    margin: "0 0 24px 0",
  },

  selectWrapper: {
    minWidth: "80px",
    borderTopRightRadius: "0px",
  },

  phoneCodeError: {
    whiteSpace: "nowrap",
  },

  phoneCode: {
    maxWidth: "50px",
  },

  label: {
    fontSize: "14px",
    mt: "15px",
  },

  address: {
    flexBasis: "100%",
  },

  select: {
    flexBasis: "45%",
  },

  checkbox: {
    flexBasis: "100%",
  },

  withHelperText: {
    display: "flex",
    flexDirection: "column",
  },

  fieldHelperText: {
    color: "#272727",
    fontSize: "24px",
    fontWeight: 600,
    marginTop: "20px",
    marginBottom: "40px",
  },

  emailLine: {},
  otpLabel: {
    marginBottom: "8px",
    width: "200px",
    textAlign: "left",
  },

  otpSend: {
    color: "custom.text.dark",
    opacity: "0.6",
    marginTop: "100px",
    fontSize: "18px",
    fontWeight: 500,
  },

  pwdErrorMsg: {
    position: { xs: "static", marginTop: "-6px" },
  },
});

export default styles;
