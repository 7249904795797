import { Box } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type HeaderSectionProps = {
  title?: string;
  description?: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const HeaderSection = ({ customStyles }: HeaderSectionProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("firstHalf")}>
        <Box component={"img"} src="/assets/images/AskhokaImg.svg" />
        <Box component={"img"} src="/assets/images/HeaderIcon1.png" />
        <Box component={"img"} src="/assets/images/HeaderTreeIcon.png" />
      </Box>
      <Box {...styles("secondHalf")}>
        <Box component={"img"} src="/assets/images/GreenCreditLogo.png" />
      </Box>
    </Box>
  );
};

export default HeaderSection;
