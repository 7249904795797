import { Box, CircularProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Input } from "src/components/common/FormComponents";
import ViewImages from "src/components/common/ViewImage";
import { PROJECT_DOCUMENT_TYPE_MAP, devTypes } from "src/constants/common";
import { CustomStyles, getStyles } from "src/styles/theme";
import { getMyProfileData, getProjectById } from "src/utils/api/dashboard";
import { getFormattedDate } from "src/utils/helper";
import defaultStyles, { StylesClasses } from "../../styles";
import { ActivityFormType, ProjectInterface } from "src/types/common";
import { defaultForm } from "../defaultForm";

type TreeProjectProps = {
  projectId: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const TreeDetails = ({ projectId, customStyles }: TreeProjectProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [projectData, setProjectData] = useState<ProjectInterface>(null);
  const [documents, setDocuments] = useState<any>();

  const { isLoading } = useQuery(
    ["projects", projectId],
    () => getProjectById(projectId),
    {
      onSuccess: (res: AxiosResponse) => {
        setProjectData(res.data);
        const groupDocTypes = _.groupBy(res?.data?.documentDetail, "type");
        setDocuments(groupDocTypes);
      },
      onError: (err: AxiosError) => {
        setProjectData(null);
      },
      refetchOnMount: true,
    }
  );

  const userId = projectData?.organizationAffiliationDetail?.userId;

  const { data: userData } = useQuery(
    ["userDetails", userId],
    () => getMyProfileData(userId),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!userId,
    }
  );

  const { control, formState } = useForm<ActivityFormType>({
    defaultValues: { ...defaultForm },
  });
  const { errors } = formState;

  return (
    <Box>
      {!isLoading && !!projectData ? (
        <>
          <Box {...styles("projectName")}>
            Activity Registration No: {projectData?.registrationNo}
          </Box>
          <Box {...styles("userDetails")}>
            <Box {...styles("heading")}>
              {userData?.data?.userType === "ORGANIZATION"
                ? "Entity Details"
                : "Individual Details"}
            </Box>
            <Box {...styles("container")}>
              {/* <Box {...styles("input")}>
                <Input
                  name="userInfo.name"
                  label="Username"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={userData?.data?.firstName}
                  control={control}
                />
              </Box> */}
              <Box {...styles("input")}>
                <Input
                  name="userInfo.name"
                  label={
                    userData?.data?.userType === devTypes.ORGANIZATION
                      ? "Entity Name"
                      : "Individual Name"
                  }
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={
                    userData?.data?.userType === devTypes.ORGANIZATION
                      ? userData?.data?.organizationName
                      : userData?.data?.firstName
                  }
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="userInfo.email"
                  label="Email"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={userData?.data?.email}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="userInfo.phoneNumber"
                  label="Phone Number"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={`${
                    !!userData?.data?.countryCode
                      ? userData?.data?.countryCode
                      : "+91"
                  } ${userData?.data?.phoneNumber}`}
                  control={control}
                />
              </Box>
              {userData?.data?.userType === devTypes.ORGANIZATION && (
                <>
                  <Box {...styles("input")}>
                    <Input
                      name="userInfo.email"
                      label="Entity Type"
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={userData?.data?.organizationType}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="activityDetails.organizationAffiliationDetail.industryTypeId"
                      label="Type of Industry"
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={
                        projectData?.organizationAffiliationDetail
                          ?.industryTypeName
                      }
                      control={control}
                    />
                  </Box>

                  <Box {...styles("input")}>
                    <Input
                      name="userInfo.userName"
                      label="Authorized Representative Name"
                      placeholder=""
                      control={control}
                      type="text"
                      value={userData?.data?.firstName}
                      readOnly
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Box {...styles("userDetails")}>
            <Box {...styles("heading")}>Activity Area Location Details</Box>
            <Box {...styles("container")}>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.address.addressLine1"
                  label="Address of the Plot"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.address?.addressLine1}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.address.state"
                  label="State"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.address?.stateName}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.address.district"
                  label="District"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.address?.districtName}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.address.city"
                  label="Village/City"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.address?.city}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.address.zipCode"
                  label="Pin Code"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.address?.zipCode}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.address.khasraNo"
                  label="Khasra Number"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.address?.khasraNo}
                  control={control}
                />
              </Box>
            </Box>
          </Box>

          <Box {...styles("userDetails")}>
            <Box {...styles("heading")}>Plot/ Site/ Area Type Details</Box>
            <Box {...styles("container")}>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.landArea"
                  label="Site Area"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={`${projectData?.landArea}`}
                  control={control}
                  endAdornment={<Box>{`${projectData?.areaUnit}`}</Box>}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.landCategory"
                  label="Land Use Category"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.landCategory}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.landOwnershipType"
                  label="Ownership"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.landOwnershipType}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.projectStartDate"
                  label="Start Date of Plantation (DD/MM/YYYY)"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={getFormattedDate(projectData?.projectStartDate)}
                  control={control}
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  name="activityDetails.projectDuration"
                  label="Activity Duration (in years)"
                  placeholder=""
                  type="text"
                  readOnly={true}
                  value={projectData?.projectDuration}
                  control={control}
                />
              </Box>
            </Box>
          </Box>

          <Box {...styles("userDetails")}>
            <Box {...styles("heading")}>Tree Specifications</Box>

            <Box {...styles("container")}>
              {projectData?.treeSpecificationDetail
                .filter((item) => item.treeType === 0)
                .map((data, index) => {
                  return (
                    <>
                      <Box {...styles("input")}>
                        <Input
                          name="activityDetails.treeSpecificationDetail"
                          label="Existing Tree Species"
                          placeholder=""
                          type="text"
                          readOnly={true}
                          value={data?.speciesName}
                          control={control}
                        />
                      </Box>
                      <Box {...styles("input")}>
                        <Input
                          name="activityDetails.treeSpecificationDetail"
                          label="Existing Tree count"
                          placeholder=""
                          type="text"
                          readOnly={true}
                          value={data?.treeCount}
                          control={control}
                        />
                      </Box>
                    </>
                  );
                })}

              {projectData?.treeSpecificationDetail
                .filter((item) => item.treeType === 1)
                .map((data) => {
                  return (
                    <>
                      <Box {...styles("input")}>
                        <Input
                          name="activityDetails.treeSpecificationDetail"
                          label="Tree Species Proposed"
                          placeholder=""
                          type="text"
                          readOnly={true}
                          value={data?.treeSpecies.speciesName}
                          control={control}
                        />
                      </Box>
                      <Box {...styles("input")}>
                        <Input
                          name="activityDetails.treeSpecificationDetail"
                          label="Number Of Trees Proposed"
                          placeholder=""
                          type="text"
                          readOnly={true}
                          value={data?.treeCount}
                          control={control}
                        />
                      </Box>
                    </>
                  );
                })}
            </Box>
          </Box>

          <Box {...styles("userDetails")}>
            <Box
              {...styles("heading", {
                marginBottom: "0px",
              })}
            >
              Issuance Schedule
            </Box>
            <Box {...styles("subHeading")}>
              Total Issuance during the life span
            </Box>
            <Box {...styles("container")}>
              {projectData?.provisionalDetails.map((provision, i) => {
                return (
                  <Box {...styles("input")}>
                    <Input
                      // customStyles={{
                      //   label: defaultStyles.label,
                      //   wrapper: defaultStyles.selectWrapper,
                      // }}
                      readOnly={true}
                      name={"userInfo.email"}
                      value={`${provision.percentage}%`}
                      control={control}
                      placeholder={`At the end of ${provision.year} year of plantation`}
                      type="text"
                      label={`At the end of ${provision.year} year of plantation`}
                    />
                  </Box>
                );
              })}
              <Box
                {...styles("heading", {
                  fontSize: "28px",
                  fontWeight: "600",
                  width: "100%",
                  mt: "10px",
                })}
              >
                Total Credits: {projectData?.estimatedCredits}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box {...styles("heading")}>Documents</Box>
            {Object.keys(documents)?.map((doc) => (
              <>
                <Box>{PROJECT_DOCUMENT_TYPE_MAP[doc]}</Box>
                <Box {...styles("documentGroup")}>
                  {documents[doc]?.map((item) => (
                    <ViewImages fileUrl={item.documentUrl.fileUrl} label={""} />
                  ))}
                </Box>
              </>
            ))}
          </Box>
        </>
      ) : (
        <Box {...styles("loading")}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default TreeDetails;
