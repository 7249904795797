import React from "react";
import {
  CardContent,
  CardHeader,
  CardMedia,
  Card as MuiCard,
  Box,
} from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type CardProps = {
  title: string;
  subheader?: string;
  image?: string;
  content?: string;
  children?: React.ReactNode;
  customStyles?: CustomStyles<StylesClasses>;
};

const Card = ({
  title,
  subheader = "",
  image = "",
  content = "",
  children,
  customStyles,
}: CardProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <MuiCard {...styles("wrapper")}>
      <CardHeader {...styles("header")} title={title} subheader={subheader} />
      {!!image && (
        <Box component="a" href={image} download>
          <CardMedia
            {...styles("img")}
            component="img"
            image={image}
            alt={title}
          />
        </Box>
      )}
      <CardContent>{!!content ? content : children}</CardContent>
    </MuiCard>
  );
};

export default Card;
