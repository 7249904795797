import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import {
  FieldErrors,
  FieldValues,
  Path,
  UseControllerProps,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useQuery } from "react-query";
import { Input } from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { CustomStyles, getStyles } from "src/styles/theme";
import { getAddress } from "src/utils/api/address";
import defaultStyles, { StylesClasses } from "./styles";

type AddressFormProps<T> = UseControllerProps<T> & {
  errors: FieldErrors;
  customStyles?: CustomStyles<StylesClasses>;
  heading: string;
  readOnly?: boolean;
  height: string;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  name: string;
};

const AddressForm = <T extends FieldValues>({
  control,
  name,
  errors,
  customStyles,
  heading,
  readOnly = false,
  height,
  watch,
  setValue,
}: AddressFormProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  useQuery(
    ["address", watch(`${name}.pinCode` as Path<T>)],
    (data: any) => getAddress(watch(`${name}.pinCode` as Path<T>)),
    {
      onSuccess: (res: AxiosResponse) => {
        if (res?.data?.[0]?.PostOffice) {
          setValue(
            `${name}.district` as Path<T>,
            res?.data[0]?.PostOffice[0].District
          );
          setValue(
            `${name}.state` as Path<T>,
            res?.data[0]?.PostOffice[0].State
          );
          setValue(
            `${name}.city` as Path<T>,
            res?.data[0]?.PostOffice[0].Division
          );
        }
      },
      onError: (err: AxiosError) => {},
      enabled:
        !!watch(`${name}.pinCode` as Path<T>) &&
        watch(`${name}.pinCode` as Path<T>).length > 5 &&
        watch(`${name}.pinCode` as Path<T>).length < 8,
    }
  );

  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("heading")}>{heading}</Box>
      <Box {...styles(["input", "address"])}>
        <Input
          name={`${name}.address` as Path<T>}
          label="Street Address"
          placeholder="Enter your street address"
          control={control}
          rules={{
            required: errorMessage.required,
          }}
          disabled={readOnly}
          height={height}
        />
      </Box>
      <Box {...styles("input")}>
        <Input
          name={`${name}.pinCode` as Path<T>}
          label="Pin Code"
          type="number"
          placeholder="Enter your pin code"
          control={control}
          height={height}
          rules={{
            required: errorMessage.required,
            minLength: {
              value: 5,
              message: errorMessage.minLength,
            },
            maxLength: {
              value: 8,
              message: errorMessage.maxLength,
            },
          }}
          disabled={readOnly}
        />
      </Box>
      <Box {...styles(["input", "select"])}>
        {/* <AutoComplete
          name={`${name}.state` as Path<T>}
          label="State"
          height={height}
          control={control}
          placeholder="Select your state"
          rules={{ required: errorMessage.required }}
          options={devTypeOptions}
          readOnly={readOnly}
        /> */}
        <Input
          name={`${name}.state` as Path<T>}
          label="State"
          placeholder="Enter your state"
          control={control}
          rules={{
            required: errorMessage.required,
          }}
          disabled={true}
          height={height}
        />
      </Box>
      <Box {...styles(["input", "select"])}>
        {/*  <AutoComplete
          name={`${name}.city` as Path<T>}
          label="City"
          control={control}
          height={height}
          freeSolo
          placeholder="Select your city"
          rules={{ required: errorMessage.required }}
          options={devTypeOptions}
          readOnly={readOnly}
        /> */}
        <Input
          name={`${name}.city` as Path<T>}
          label="Village/City"
          placeholder="Enter your village/city"
          control={control}
          rules={{
            required: errorMessage.required,
          }}
          disabled={true}
          height={height}
        />
      </Box>

      <Box {...styles(["input", "select"])}>
        {/*  <AutoComplete
          name={`${name}.district` as Path<T>}
          label="District"
          control={control}
          height={height}
          freeSolo
          placeholder="Select your district"
          rules={{ required: errorMessage.required }}
          options={devTypeOptions}
          readOnly={readOnly}
        /> */}
        <Input
          name={`${name}.district` as Path<T>}
          label="District"
          placeholder="Enter your district"
          control={control}
          rules={{
            required: errorMessage.required,
          }}
          disabled={true}
          height={height}
        />
      </Box>

      <Box {...styles(["input", "select"])}></Box>
    </Box>
  );
};

export default AddressForm;
