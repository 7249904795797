import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FileUpload, Input } from "src/components/common/FormComponents";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import { path } from "src/constants/path";
import { updateBankDetailsForm, updateStepper } from "src/redux/actions";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type BankDetailsFormData = {
  bankName: string;
  accountNumber: string;
  ifscCode: string;
  organizationCertificates: {
    type: string;
    key: string;
    file: string;
  }[];
};

const BankDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { formData } = useAppSelector((state) => state.root.bankDetails);

  const { password: passwordRegex } = regex;

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
  } = useForm<BankDetailsFormData>({
    mode: "onTouched",
    defaultValues: {
      ...formData,
    },
  });

  // const handleOtp = (otp: string) => {
  //   setValue("emailOtp", otp);
  //   setValue("verifyEmail", true);
  // };

  // const handlePhoneVerification = (otp: string) => {
  //   setValue("phoneOtp", otp);
  //   setValue("verifyPhone", true);
  // };

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: BankDetailsFormData) => {
    dispatch(updateBankDetailsForm({ ...data }));
    dispatch(updateStepper({ id: 3 }));
    navigate(path.nodalOfficerDetails);
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Bank Account Details"
        previousRoute={path.onboarding}
        isValid={isValid}
      >
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Input
              name="bankName"
              label="Name of Bank"
              placeholder="Enter Bank Name"
              control={control}
              // rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              name="accountNumber"
              label="Account Number"
              type="number"
              placeholder=" Enter Account Number"
              control={control}
              // rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              name="ifscCode"
              label="IFSC Code"
              placeholder="Enter IFSC Code"
              control={control}
              // rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles(["input", "fileUpload"])}>
            <FileUpload
              name="organizationCertificates"
              label="Upload Copy of Cancelled Cheque"
              control={control}
              errors={errors}
              required={false}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              maxLimit={5}
              trigger={trigger}
              // customStyles={{
              //   wrapper: defaultStyles.borderStyle,
              //   fileType: defaultStyles.fileType,
              //   label: {
              //     whiteSpace: "wrap",
              //   },
              // }}
              // rules={{
              //   validate: {
              //     isLength: (value) => {
              //       return value && value["length"] >= 1;
              //     },
              //   },
              // }}
              dragDropHeading=""
            />
          </Box>
        </Box>
      </OnboardingWrapper>
    </Box>
  );
};

export default BankDetails;
