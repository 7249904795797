import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    width: "100%",
    margin: "2% 4%",
    backgroundColor: "#EBFAF8",
  },
  autoComplete: {
    mt: "30px",
  },
  inputForm: {
    display: "flex",
    gap: "30px",
    padding: "20px",
    backgroundColor: "#EBFAF8",
    borderRadius: "16px",
  },
  inputLabel: {
    fontSize: "14px",
    mt: "15px",
  },
  inputWrapper: {
    minWidth: "100px",
    borderTopRightRadius: "0px",
    borderColor: "unset",
  },
  errorMsg: {
    bottom: "-22px",
    fontSize: "10px",
  },
  viewWrapper: {
    backgroundColor: "#EBFAF8",
    padding: "20px 10px",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "#36B1A0",
    // mt: "30px ",
  },
  landFieldWrapper: {
    border: "1px solid",
    borderColor: "#FFFF",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "#EBFAF8",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  imageContainer: {
    display: "flex",
    gap: "10px",
    flexDirection: {
      xs: "column",
      md: "row",
    },
  },
  heading: {
    fontSize: "17px",
    fontWeight: "600",
  },
});

export default styles;
