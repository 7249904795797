import { InitialState } from "./types";

export const initialState: InitialState = {
  openProjectView: false,
  isSideBarOpen: false,
  aca: false,
  newTreeDetails: [
    {
      treeSpeciesId: "",
      treeCount: null,
      treeType: 1,
    },
  ],
  existingTrees: [
    {
      speciesName: "",
      treeCount: null,
      treeType: 0,
    },
  ],
  formData: {
    id: null,
    registrationNo: null,
    landTypeId: null,
    activityPurpose: null,
    name: null,
    description: null,
    projectType: null,
    landArea: null,
    areaUnit: "ha",
    availability: "",
    activity: "",
    landOwnershipTypeId: null,
    landCategoryId: null,
    layout: null,
    projectStartDate: null,
    projectDuration: 10,
    status: null,
    assignedCertifierId: null,
    assignedVerifierId: null,
    estimatedCredits: null,
    remarks: null,
    environmentImpact: null,
    provisionalDetails: [],
    organizationAffiliationDetail: {
      id: null,
      userId: null,
      thirdPartyUserId: null,
      // decelerationType: null,
      noOfEmployee: null,
      incomeBracketRangeId: null,
      annualTurnover: null,
      industryTypeId: null,
      projectProfileUrl: null,
      gender: null,
    },

    address: {
      id: null,
      addressLine1: null,
      addressLine2: null,
      village: null,
      city: null,
      district: null,
      state: null,
      country: "India",
      isoCode: null,
      zipCode: "",
      latitude: null,
      longitude: null,
      districtName: null,
      stateName: null,
      khasraNo: null,
      documentUrl: [],
    },
    agencyDetails: {
      executingAgency: null,
      thirdParty: {
        address: null,
        agencyPhoneNo: null,
        city: null,
        district: null,
        docNumber: null,
        docType: null,
        documentDetailUrl: [],
        documentUrl: [],
        email: null,
        fullName: null,
        pinCode: null,
        state: null,
        type: null,
      },
    },
    bankAccountDetail: {
      id: null,
      bankName: "lsajkas",
      ifscCode: "sdnjskad",
      accountHolderName: "sajhjksaj",
      accountNumber: "987612345678",
      branchState: "76ec7f70-5f61-449e-a214-b6ddabf8f54d",
      branchDistrict: "d4616f59-684f-4027-8b79-92fe0da47103",
      branchLocation: "sjjdhnsjdb",
      isVerified: false,
    },

    documentDetail: [],
  },
  PlotAreaDetails: {
    layoutPlantationImg: [],
    ownershipImg: [],
    sourceSaplingImg: [],
  },
  saveImages: {
    DPRimg: [],
    PROJECT_LAYOUT: [],
    WORKING_PERMIT: [],
    PLOT_LAYOUT: [],
    LAND_OWNERSHIP: [],
    SAPLING_RECEIPT: [],
    PAYMENT_RECEIPT: [],
    SELF_DECLARATION_FORM: [],
  },

  apiImages: {
    WORKING_PERMIT: [],
    PLOT_LAYOUT: [],
    LAND_OWNERSHIP: [],
    SAPLING_RECEIPT: [],
    PAYMENT_RECEIPT: [],
    SELF_DECLARATION_FORM: [],
  },

  waterConservationDetail: {
    structureTypeId: null,
    materialDetailId: null,
    potentialCapacity: null,
    constructionDuration: null,
    constructionStartDate: null,
    cost: null,
    layout: null,
    length: null,
    width: null,
    depth: null,
  },

  treeSpecificationDetail: [
    {
      id: null,
      treeSpeciesId: null,
      speciesName: null,
      treeType: null,
      treeCount: null,
      ageOfSaplings: null,
      sourceOfSaplings: null,
      treeSpecies: {
        name: null,
        speciesType: null,
        speciesName: null,
      },
    },
  ],
  declarations: "",
  isError: {
    isError: false,
    msg: "",
  },
  stepName: "SelectProject",
  stepDetail: {
    stepName: "SelectProject",
    totalStep: 3,
    currentStep: 1,
  },
};
