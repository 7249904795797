import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    position: "relative",
  },

  input: {
    height: "52px",
  },

  inputAdornment: {
    position: "absolute",
    right: "10px",
  },

  label: {},

  iconBtn: {
    padding: 0,
  },

  formHelperText: {},

  icon: {
    height: "20px",
    width: "26px",
  },

  customError: {
    display: "flex",
    gap: "2px",
    flexWrap: "wrap",
    bottom: "0px",
    top: "83px",
  },

  pwdValidationMsg: {},
});

export default styles;
