import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    width: "100%",
    position: "relative",
    "& .label": {
      display: "inline",
      fontSize: "15px",
      width: "110px",
      marginBottom: "10px",
    },
  },

  labelPos: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "& .label": {
      fontSize: "14px",
      minWidth: "110px",
    },
  },

  labelPosStyles: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "& .label": {
      minWidth: "0px",
      width: "0px",
    },
  },

  adornment: {
    position: "relative",
    top: "-10px",
  },

  label: {
    position: "static",
    marginBottom: "8px",
  },

  input: {
    // height: "52px",

    color: "red",

    "&.Mui-disabled": {
      WebkitTextFillColor: "#000",
      color: "red",
    },

    flexGrow: 1,
    "& ..css-108a452-MuiInputBase-root": {
      mt: 0,
    },
    "& .MuiInputBase-input": {
      MozAppearance: "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
  },

  errorMsg: {
    whiteSpace: "wrap",
    position: "absolute",
    marginBottom: "15px",
    lineHeight: "0px",
  },

  doneIcon: {
    color: "custom.misc.green",
  },
});

export default styles;
