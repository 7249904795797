export const defaultForm = {
  geoTaggedImages: [],
  userInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    userName: "",
  },
  activityDetails: {
    id: "",
    registrationNo: null,
    name: "",
    description: null,
    projectType: "",
    landArea: null,
    areaUnit: "ha",
    landOwnershipTypeId: "",
    landCategoryId: "",
    layout: null,
    projectStartDate: "",
    projectDuration: null,
    projectSize: "",
    status: "",
    assignedCertifierId: null,
    assignedVerifierId: null,
    assignedReviewerId: null,
    assignedApproverId: null,
    estimatedCredits: null,
    remarks: null,
    environmentImpact: null,
    landOwnershipType: "",
    landCategory: "",
    rainfallZoneMultiplier: "",

    organizationAffiliationDetail: {
      id: "",
      userId: "",
      thirdPartyUserId: null,
      // decelerationType: "",
      noOfEmployee: null,
      incomeBracketRangeId: null,
      annualTurnover: null,
      industryTypeId: "",
      projectProfileUrl: null,
      industryTypeName: "",
    },
    treeSpecificationDetail: [],

    address: {
      id: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      district: "",
      state: "",
      country: "",
      isoCode: "",
      zipCode: "",
      latitude: "",
      longitude: "",
      districtName: "",
      stateName: "",
      khasraNo: "",
    },
    documentDetail: [
      {
        id: "",
        serialNo: null,
        documentUrl: {
          id: "",
          fileUrl: "",
        },
        isVerified: false,
        type: "",
        validFrom: null,
        validTill: null,
      },
    ],
    landFreeFromEncumbrances: true,
    landSuitableForPlantation: true,
  },
};
