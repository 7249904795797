import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  dataGridWrapper: {
    maxHeight: "80vh",
    backgroundColo: "green",
    // overflow: "scroll",
    width: "100%",
    display: "flex",
    borderRadius: "8px 8px",
    "& .MuiDataGrid-root": {
      fontSize: "14px",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "custom.greyish.1",
      "& .MuiDataGrid-columnHeader,.MuiDataGrid-root .MuiDataGrid-cell": {
        outline: "none !important",
      },

      "& .MuiDataGrid-columnHeaderTitle ": {
        color: "tableHead.color",
        textTransform: "uppercase",
        fontWeight: 400,
        fontSize: "18px",
        textWrap: "wrap",
        lineHeight: "1.2",
      },

      "& .MuiDataGrid-cellContent": {
        color: "black",
        fontSize: "17px",
      },
      "& .MuiDataGrid-virtualScrollerContent ": {
        height: "calc(100vh - 450px) !important",
      },
      "& .MuiDataGrid-virtualScroller": {
        overflowY: "auto !important",
      },
    },

    "&.MuiDataGrid-footerContainer": {
      justifyContent: "center !important",
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        position: "relative",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "custom.greyish.3",
        paddingLeft: "20px",

        "&:last-child": {
          borderBottom: "0px",
        },
      },
    },

    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "14px",
      fontWeight: "600",
      color: "#7E7E7E",
    },

    "& .MuiDataGrid-columnHeaders": {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "custom.greyish.3",
      backgroundColor: "custom.greyish.3",
      paddingLeft: "20px",
    },

    "& .MuiDataGrid-main": {
      overflowY: "auto",
      "& .MuiDataGrid-footerContainer": {
        justifyContent: "center !important",
      },
    },

    "&.MuiDataGrid-main": {
      backgroundColor: "green !important",
    },

    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-cell": {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "custom.greyish.3",
      "&:focus,&:focus-within": {
        outline: "none !important",
      },
    },
    "&.MuiDataGrid-row": {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "custom.greyish.3",
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "custom.greyish.3",
    },

    "&.MuiToolbar-root": {
      marginTop: "20px",
    },

    "& .MuiDataGrid-iconButtonContainer": {
      visibility: "visible",
      width: "auto",
      marginLeft: "-8px",
      marginTop: "3px",
    },
    "&	.MuiDataGrid-columnHeader--sortable": {
      "& .MuiDataGrid-columnHeaderTitle": {
        // marginLeft: "20px",
      },
    },
  },

  grid: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "custom.greyish.3",
    backgroundColor: "custom.text.white.100",
  },

  dimension: {
    // minWidth: "16px",
  },

  paginationWrapper: {
    // position: "fixed",
    // bottom: 0,
    margin: "10px auto",
    "& ul": {
      justifyContent: "center !important",
    },
  },

  pagination: {
    marginTop: "10px",
    paddingTop: "10px",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "custom.greyish.3",
    backgroundColor: "white",
  },

  paginationItem: {
    "&.Mui-selected": {
      color: "primary.main",
      backgroundColor: "white",
      fontSize: "14px",
      fontWeight: "600",
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },

  iconStyles: {
    color: "custom.misc.joyBlue",
  },
});

export default styles;
