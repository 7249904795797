import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  valueCard: {
    width: "460px",
    height: "fit-content",
    borderRadius: "8px",
    display: "flex",
    border: " 2px solid #D0F1EC",
    flexDirection: "column",
    padding: "12px",
    backgroundColor: "rgb(154, 216, 207);",
  },
  title: {
    color: "common.black",
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "8px",
  },
  divider: {
    backgroundColor: "#D0F1EC",
  },
  unit: {
    fontSize: "14px",
    paddingLeft: "5px",
  },
  subHeading: {
    fontSize: "16px",
    color: "common.black",
    fontWeight: 400,
  },
  iconHalf: {
    display: "flex",
    gap: "10%",
    padding: "20px 10px",
  },

  icon: {
    width: "30px",
    height: "30px",
  },

  value: {
    color: "custom.text.lighter",
    fontSize: "24px",
    fontWeight: 500,
    letterSpacing: "0.48px",
  },
});

export default styles;
