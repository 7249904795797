import { Box, Dialog, Grid } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import { useForm } from "react-hook-form";
import { getLandDetailsById } from "src/utils/api/createProjectFlow";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import ViewForm from "src/components/common/ViewForm";
import { formatToIndianCurrency } from "src/utils/helper";

import ViewImages from "src/components/common/ViewImage";
import { useReactToPrint } from "react-to-print";

import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "src/redux/store";
import {
  AutoComplete,
  Button,
  FileUpload,
  Input,
  RadioGroup,
  Select,
} from "src/components/common/FormComponents";
import {
  getAllMonitoringDetailsData,
  getMointoringMember,
  getProgressYear,
  postMonitoringComponentDetail,
} from "src/utils/api/dashboard";
import { MonitoringMemberDetailsTable } from "src/components/common/DashboardTable/monitoringDetails";
import { MonitoringMember } from "./MonitoringMember";
import CombinedMonthYearPicker from "src/components/common/MonthYearPicker";
import { PlantationForm } from "./MonitoringForm/plantation";
import { FencingForm } from "./MonitoringForm/fencing";
import EmptyView from "src/components/common/EmptyView";
import { LandPreparationForm } from "./MonitoringForm/landPreparation";
import { LandPreparationView } from "./MonitoringView/landPreparationView";
import { NurseryForm } from "./MonitoringForm/nursery";
import { NurseryView } from "./MonitoringView/nurseryView";
import { useComponentName } from "src/hook/monitoring-component";
import { AllComponentDetails } from "./AllComponentDetails";
import { SmcForm } from "./MonitoringForm/smcForm";
import { IrrigationForm } from "./MonitoringForm/irrigation";
import { ProtectionForm } from "./MonitoringForm/protection";
import {
  AUTOCOMPLETE_OPTION,
  ENUM_MONITORING_TYPE,
  MONTHS,
  RATINGS,
  year,
} from "src/constants/common";
import { OtherForm } from "./MonitoringForm/other";
import { Journal } from "./MonitoringForm/journal";
import { FinalSubmitted } from "./FinalSubmittedView";
import errorMessage from "src/constants/errorMessage";

export const TOPHEADER = [
  "S.no",
  "Name of member",
  "Designation",
  "Phone Number",
  "Email",
];
const AddMonitoringDetails = () => {
  const componentRef = useRef();

  const [searchParams] = useSearchParams();

  const landOwnershipEpid = searchParams.get("id");

  const user = useAppSelector((state) => state.root.user);

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [isMemberAdded, setMemberAdded] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const { componentName, setComponentName } = useComponentName();
  const [isFinalSubmitted, setFinalSubmitted] = useState(false);
  const { data: userData } = useQuery(
    ["userDetails", landOwnershipEpid],
    () => getLandDetailsById(landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!landOwnershipEpid,
      refetchOnMount: true,
    }
  );

  const { data: memberDetails, refetch } = useQuery(
    ["memberDetails", landOwnershipEpid],
    () => getMointoringMember(landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      refetchOnMount: "always",
      refetchOnWindowFocus: true,
    }
  );

  const { data: progressYear } = useQuery(["progressYear"], getProgressYear, {
    refetchOnMount: true,
  });

  const {
    control,
    getValues,
    watch,
    trigger,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      landOwnershipEpid: "",
    },
  });

  const componentParams = {
    landOwnershipEpid,
    year: getValues("year"),
    month: getValues("month"),
  };

  const values = watch();

  const { data: componentData } = useQuery(
    ["component", values],
    () => getAllMonitoringDetailsData(componentParams),
    {
      enabled: !!(values.month && values.year),
      onSuccess: (data: any) => {
        console.log("component", data);
      },
      onError: () => {},
    }
  );

  const { mutate, isSuccess } = useMutation(
    ["fencing"],
    ({ data }: { data: any }) => postMonitoringComponentDetail(data),
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  const [editFormState, setEditFormState] = useState<boolean>(false);
  const handleCostEstimateTableEdit = (row) => {
    setEditFormState(true);
  };

  const handleMonthYearChange = (month, year) => {
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const epiddata = userData?.data;

  const monitoringMemberDetails = memberDetails?.data.data.filter(
    (item) =>
      (user?.data.userType === null && item.type === "ACF") ||
      (user?.data.userType === "ORGANIZATION" &&
        item.type === "ENTITY_REPRESENTATIVE")
  );

  useEffect(() => {
    if (monitoringMemberDetails && monitoringMemberDetails.length !== 0) {
      setMemberAdded(true);
    }
  }, [monitoringMemberDetails]);

  const isYearSelected = watch("year");
  const isMonthSelected = watch("month");
  const isAllFiledSelected = componentName && isYearSelected && isMonthSelected;

  const isIO = user?.data.userType === null;
  const isSNO = user?.data.userType === "INDIVIDUAL";

  const monitorinData = componentData?.data?.data;

  const renderComponent = () => {
    switch (componentName.value) {
      case "Plantation":
        return monitorinData?.plantationMonitoring === null ||
          ((monitorinData?.plantationMonitoring.species === null ||
            monitorinData?.plantationMonitoring.plantationMonitoring ===
              null) &&
            isIO) ? (
          <PlantationForm
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      case "Fencing":
        return monitorinData?.fencingMonitoring === null && isIO ? (
          <FencingForm
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      case "Land-Preparation":
        return isIO && monitorinData?.landPreparationMonitoring === null ? (
          <LandPreparationForm
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      case "Nursery":
        return monitorinData?.nurseryMonitoring === null && isIO ? (
          <NurseryForm
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      case "smc":
        return monitorinData?.smcMonitoring === null && isIO ? (
          <SmcForm
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      case "Irrigation":
        return monitorinData?.irrigationMonitoring === null && isIO ? (
          <IrrigationForm
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      case "Protection":
        return monitorinData?.protectionMonitoring === null ||
          ((monitorinData?.protectionMonitoring.species === null ||
            monitorinData?.protectionMonitoring.protectionMonitoring ===
              null) &&
            isIO) ? (
          <ProtectionForm
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      case "Others":
        return monitorinData?.otherMonitoring === null && isIO ? (
          <OtherForm
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      case "Plantation-Journal":
        return monitorinData?.plantationJournalMonitoring === null && isIO ? (
          <Journal
            param={componentParams}
            yearMonth={`${selectedMonth}-${selectedYear}`}
          />
        ) : (
          <EmptyView
            heading="Data Already Filled"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
      default:
        return (
          <EmptyView
            heading="No Entry Found"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        );
    }
  };

  const docId = getValues("monitoringDoc");
  return (
    <>
      <DashboardWrapper
        title={`Monitoring`}
        buttonText="Print"
        onButtonClick={handlePrint}
        showBtn={false}
        showBackBtn={true}
      >
        <Box>
          {!isMemberAdded && !isSNO && (
            <Button
              disabled={isMemberAdded}
              text={"Add Monitoring Committe Member"}
              onClick={() => handleCostEstimateTableEdit({})} // pass an empty object or the required data
            ></Button>
          )}
          <Box ref={componentRef} id="printable-content">
            <Box {...styles("formWrapper")}>
              <Box sx={{ width: "40%" }}>
                <ViewForm
                  label={"Registration No"}
                  value={epiddata?.projectDetail?.registrationNo}
                />
                <ViewForm label={"EPID"} value={epiddata?.id} />
                <ViewForm
                  label={"State"}
                  value={epiddata?.projectDetail?.address?.stateDetail?.name}
                />
                <ViewForm
                  label={"District"}
                  value={epiddata?.projectDetail?.address?.districtDetail?.name}
                />
                <ViewForm
                  label={"Division"}
                  value={epiddata?.projectDetail?.landSpecification?.division}
                />
                <ViewForm
                  label={"Range"}
                  value={epiddata?.projectDetail?.landSpecification?.range}
                />
                <ViewForm
                  label={"Beat"}
                  value={epiddata?.projectDetail?.landSpecification?.beat}
                />
              </Box>
              <Box sx={{ width: "40%" }}>
                <ViewForm
                  label={"Total Area(ha)"}
                  value={epiddata?.projectDetail?.landSpecification?.totalArea}
                />
                <ViewForm
                  label={"Net Plantation Area(ha)"}
                  value={epiddata?.netPlantationArea}
                />
                <ViewForm
                  label={"Eco-Restoration Cost"}
                  value={formatToIndianCurrency(epiddata?.demandNote)}
                />
              </Box>
              <Box sx={{ width: "30%" }}>
                <Box
                  sx={{
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                >
                  Technically sanctioned/approved cost estimate
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={epiddata?.paymentBreakdownPdfImage}
                      label={"PDF"}
                    />
                  </Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={epiddata?.paymentBreakdownImage}
                      label={"Ms Excel"}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box {...styles("memberListHeading")}>Committee Members </Box>
            <MonitoringMemberDetailsTable
              topHeader={TOPHEADER}
              data={memberDetails?.data.data}
            />
          </Box>
          <Box {...styles("autoComplete", { mt: "40px" })}>
            <Box {...styles("memberListHeading")}>Monitoring </Box>

            {/* FINALSUBMITTED LIST  */}

            <FinalSubmitted />
            {isIO && (
              <Grid container item spacing={3} xs={12} sx={{ mt: "20px" }}>
                <Grid item xs={12} md={3}>
                  <Select
                    options={progressYear?.data?.data || []}
                    sx={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                      borderRadius: "10px",
                    }}
                    name="year"
                    label="Select Year"
                    control={control}
                    rules={{ required: "This field is required" }}
                    placeholder="Select Year"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CombinedMonthYearPicker onChange={handleMonthYearChange} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <AutoComplete
                    options={MONTHS}
                    name="month"
                    label="Monitoring"
                    control={control}
                    rules={{
                      required: errorMessage.required,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <AutoComplete
                    options={AUTOCOMPLETE_OPTION}
                    name="plantationOption"
                    label="Select Component"
                    control={control}
                    onOptionSelect={(selectedOption) => {
                      setComponentName(selectedOption);
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>

          {isAllFiledSelected && renderComponent()}

          {monitorinData?.isFinalSubmittable &&
            isIO &&
            !monitorinData?.isFinalSubmitted && (
              <Box>
                {/* <Box>
              Please upload PDF copy of monitoring report signed by all members
              of monitoring team.
            </Box> */}
                <FileUpload
                  accept="image/jpeg, image/png, image/webp, image/jpg"
                  name="monitoringDoc"
                  label="Upload Monitoring Report PDF"
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                  maxLimit={1}
                  multiple={true}
                  required={true}
                  supportedFormatMessage="Supported formats: .PDF Size Upto 10MB"
                  trigger={trigger}
                  rules={{
                    validate: {
                      isLength: (value) => {
                        return value && value["length"] >= 1;
                      },
                    },
                  }}
                  dragDropHeading=""
                />
                <Box>
                  <Input
                    name="remark"
                    control={control}
                    errors={errors}
                    placeholder="Overall Observations "
                    label="Overall Observations "
                    rules={{
                      required: errorMessage.required,
                    }}
                    customStyles={{
                      label: styles("inputLabel").sx,
                      wrapper: styles("inputWrapper").sx,
                      input: styles("inputBorder").sx,
                    }}
                  />
                  <RadioGroup
                    options={RATINGS}
                    name="rating"
                    label="Final Rating (out of 10)"
                    control={control}
                    rules={{
                      required: errorMessage.required,
                    }}
                    customStyle={{
                      label: { fontSize: "14px", mt: "10px" },
                      wrapper: { gap: "0px" },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    text="Final Submit"
                    type="submit"
                    // disabled={!isValid}
                    onClick={() => {
                      mutate({
                        data: {
                          type: ENUM_MONITORING_TYPE.SUBMIT,
                          document: docId[0]?.key,
                          month: componentParams.month,
                          year: componentParams.year,
                          landOwnershipEpid: componentParams.landOwnershipEpid,
                          remark: getValues("remark"),
                          rating: parseInt(getValues("rating"), 10),
                        },
                      });
                    }}
                    customStyles={{
                      root: {
                        fontSize: "16px",
                      },
                    }}
                  />
                </Box>
              </Box>
            )}

          <Box>
            {isMonthSelected && isYearSelected && (
              <AllComponentDetails
                year={getValues("year")}
                month={getValues("month")}
                finalSubmitted={setFinalSubmitted}
              />
            )}
          </Box>
          {/* Should I remove from here as I showing it in final details
          {monitorinData?.isFinalSubmitted && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("documentGroup")}>
                <ViewImages
                  fileUrl={monitorinData?.finalReportDocumentUrl}
                  label={"Monitoring Report PDF"}
                />
              </Box>
            </Box>
          )} */}
        </Box>

        <Dialog
          open={editFormState}
          onClose={() => {
            setEditFormState(false);
          }}
          fullWidth
          maxWidth="lg"
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              borderRadius: "16px",
            },
          }}
        >
          <MonitoringMember
            selfState={setEditFormState}
            landOwnershipEpid={landOwnershipEpid}
            refetch={refetch}
          />
        </Dialog>
      </DashboardWrapper>
    </>
  );
};

export default AddMonitoringDetails;
