import { Box } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, PhoneNumber } from "src/components/common/FormComponents";
import { OTP } from "src/components/common/modal";
import { OtpType } from "src/constants/common";
import { forgetPasswordValue } from "src/constants/forms";
import { path } from "src/constants/path";
import { getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
export type ForgetPasswordProps = {
  phone: {
    number: string;
    countryCode: string;
  };
  OTP: string;
};

const ForgetPassword = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const [openOtp, setOpenOtp] = useState(false);

  const { control, formState, handleSubmit, watch, setValue, getValues } =
    useForm<ForgetPasswordProps>({
      mode: "onTouched",
      defaultValues: {
        ...forgetPasswordValue,
      },
    });

  const handleOtp = (otp: string, otpToken: string) => {
    navigate(path.resetPassword, {
      state: {
        token: otpToken,
      },
    });
  };
  const { errors, isValid } = formState;

  const handleCloseModal = () => {
    setOpenOtp(false);
  };

  const handleFormSubmit = (data: ForgetPasswordProps) => {
    setOpenOtp(true);
  };

  return (
    <Box {...styles("container")}>
      <Box {...styles("logInWrapper")}>
        <Box {...styles("logo")}>
          <Box component={"img"} src="/assets/images/coloredLeafIcon.png"></Box>
          <Box {...styles("title")}>GREEN CREDIT</Box>
        </Box>
        <Box {...styles("heading")}>Reset your Password</Box>
        <Box {...styles("description")}>
          <Box component="span" {...styles("spanStyle")}>
            Enter the phone number&nbsp;
          </Box>
          that you used when registered to reset the password. You will receive
          an&nbsp;
          <Box component="span" {...styles("spanStyle")}>
            OTP on it.
          </Box>
        </Box>

        <Box
          {...styles("emailField")}
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <PhoneNumber
            control={control}
            name="phone"
            placeholder="Please enter your registered phone number"
          />

          <Button
            text="Send OTP"
            type="submit"
            disabled={!isValid}
            customStyles={{
              root: defaultStyles.loginBtn,
            }}
          />

          <OTP
            name="OTP"
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            watch={watch}
            openFromOutside={openOtp}
            handleModalFromOutside={() => handleCloseModal()}
            verifyOtp={handleOtp}
            type={{
              type: OtpType.MOBILE,
              searchTerm: watch("phone.number"),
            }}
            sentOtpVariant="FORGOT_PASSWORD"
            heading="Verify your user ID"
            subHeading="You might have received an OTP on your respective user ID. Please enter the same, If not click on resend OTP."
            rules={{ required: false }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
