import { Box, Collapse, Dialog } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  AutoComplete,
  Button,
  Input,
  PhoneNumber,
} from "src/components/common/FormComponents";
import { SuccessModal } from "src/components/common/modal";
import ViewForm from "src/components/common/ViewForm";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { resetFormAction } from "src/redux/createActions/resetForms";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import {
  addImplementingOfficerApi,
  getMyProfileData,
} from "src/utils/api/dashboard";
import { getStates } from "src/utils/api/roles";
import { downloadSheet, optionTypeFormatter } from "src/utils/helper";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";
import { getDivisionOptions } from "src/utils/api/createProjectFlow";
import EditImplementingOfficer from "./EditForm";
import { PreviousIO } from "./PreviousIO";

export type implementingdataFormData = {
  firstName: string;
  email: string;
  designation: string;
  district: string;
  division: string;
  landlineNumber: {
    number: string;
    countryCode: string;
  };
  phoneNumber: {
    number: string;
    countryCode: string;
  };
};

const AddImplementingOfficer = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [response, setResponse] = useState({});
  const [editModelStateId, setEditModelStateId] = useState<string>("");

  const [stateOption, setStateOptions] = useState([]);

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const userId = useAppSelector((state) => state.root.user.data.userId);

  const user = useAppSelector((state) => state.root.user);

  const { data } = useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStateOptions(data.data);
    },
    refetchOnMount: true,
  });

  const { data: myProfileData, refetch: refectUserDetails } = useQuery(
    ["myProfile", userId],
    () => getMyProfileData(userId),
    {
      onSuccess: () => {},
      onError: () => {},
      enabled: !!userId,
      refetchOnMount: true,
    }
  );

  const implementingdata = myProfileData?.data?.implementingOfficerDetails;

  const exportData = implementingdata?.map((item) => {
    return {
      name: item?.firstName,
      userName: item?.userName,
      designation: item?.designation,
      division: item?.division,
      landlineNumber: item?.landlineNumber,
      phoneNumber: item?.phoneNumber,
      email: item?.email,
      district: item?.districtName,
    };
  });

  const exportQuery = () => {
    downloadSheet(exportData);
  };

  const addImplementingOfficer = useMutation(
    "signUp",
    ({
      userId,
      implementingOfficerDetails,
    }: {
      userId: any;
      implementingOfficerDetails: any;
    }) => addImplementingOfficerApi(implementingOfficerDetails, userId),
    {
      onSuccess: (res: AxiosResponse) => {
        setResponse({ "User name": res.data.userName });
        setOpenModal({
          open: true,
          heading: "",
          subheading: "",
          description: "Implementing Officer successfully added",
          btnText: "",
          type: "success",
        });
        dispatch(resetFormAction({}));
      },

      onError: (err: AxiosError) => {
        setResponse({});
        setOpenModal({
          open: true,
          heading: "Unsuccessful",
          subheading: "",
          description: err.response.data.message || "Something went wrong",
          btnText: "",
          type: "unSuccess",
        });
      },
    }
  );

  const { control, formState, handleSubmit, reset } =
    useForm<implementingdataFormData>({
      mode: "onTouched",
      defaultValues: {
        firstName: "",
        designation: "",
        district: "",
        division: "",
        landlineNumber: {
          number: "",
          countryCode: "+91",
        },
        phoneNumber: {
          number: "",
          countryCode: "+91",
        },
        email: "",
      },
    });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: any) => {
    addImplementingOfficer.mutate({
      userId,
      implementingOfficerDetails: {
        implementingOfficerDetails: [
          {
            firstName: data.firstName,
            designation: data.designation,
            district: data.district,
            division: data.division,
            landlineNumber: data.landlineNumber.number,
            phoneNumber: data.phoneNumber.number,
            email: data.email,
          },
        ],
      },
    });
  };

  const handleResponseModal = () => {
    if (openModal.type === "success") {
      navigate(path.login, { state: { userId: response["User name"] } });
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
    reset();
    refectUserDetails();
  };

  const district = useMemo(() => {
    const d = myProfileData?.data?.currentAddress?.state; // id, name
    const dist =
      stateOption.find((i) => i.id === d) ||
      stateOption.find((i) => i.name === d);
    return dist?.districts.length ? optionTypeFormatter(dist?.districts) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfileData?.data?.currentAddress?.state, stateOption]);

  const { data: division } = useQuery(
    ["divisionsOption", user.data.stateId],
    () => getDivisionOptions(user.data.stateId)
  );

  const divisionoptions = optionTypeFormatter(division?.data?.rows);
  const [openState, setopenState] = useState(false);
  const [showPreviousIO, setshowPreviousIO] = useState(false);

  const handleViewPreviousIO = (id: string) => {
    setshowPreviousIO(true);
    setEditModelStateId(id);
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <DashboardWrapper
        title="Implementing Officer"
        customStyles={{
          container: { position: "relative" },
          children: { paddingBottom: "80px" },
        }}
        buttonText="Export"
        onButtonClick={exportQuery}
        backPath={`/`}
        showBackBtn
      >
        <Button
          text="Show Implementing Officer Details"
          onClick={() => setopenState(!openState)}
        />
        <>
          <Collapse in={openState} timeout={{ enter: 2000, exit: 1000 }}>
            {implementingdata?.map((item) => (
              <Box {...styles("formWrapper")}>
                <Box sx={{ width: "100%" }}>
                  <ViewForm label="Name" value={item.firstName} />
                  <ViewForm label="Email" value={item?.email} />
                  <ViewForm
                    label="Phone Number"
                    value={`${item?.phoneNumber}`}
                  />
                  <ViewForm
                    label="Landline Number"
                    value={
                      item?.landlineNumber ? `${item?.landlineNumber}` : "-"
                    }
                  />
                  {item?.districtName ? (
                    <ViewForm
                      label="District"
                      value={`${item?.districtName}`}
                    />
                  ) : (
                    <ViewForm label="District" value={`${item?.district}`} />
                  )}
                  <ViewForm label="Division" value={`${item?.division}`} />
                  <ViewForm
                    label="Designation"
                    value={`${item?.designation}`}
                  />
                  <ViewForm label="User Name" value={`${item?.userName}`} />
                  <Button
                    text="Previous Implementing Officer"
                    onClick={() => handleViewPreviousIO(item.id)}
                    customStyles={{ root: { height: "35px", mt: "20px" } }}
                  />
                </Box>
                <EditNoteIcon
                  sx={{
                    color: "grey",
                    cursor: "pointer",
                    height: "36px",
                    width: "36px",
                  }}
                  onClick={() => setEditModelStateId(item.id)}
                />
              </Box>
            ))}
          </Collapse>
          <Box {...styles("projectName")}>Add New Implementing Officer</Box>
          <Box {...styles("wrapper")}>
            <Box {...styles("input")}>
              <Input
                name={`firstName`}
                label="Name"
                placeholder="Enter your full name"
                control={control}
                rules={{ required: errorMessage.required }}
                errors={errors}
                height="52px"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                label="Designation"
                placeholder="Enter Designation"
                name={`designation`}
                control={control}
                rules={{ required: errorMessage.required }}
                errors={errors}
                height="52px"
              />
            </Box>
            <Box {...styles("input")}>
              <AutoComplete
                options={district.length ? district : []}
                rules={{ required: errorMessage.required }}
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name={`district`}
                control={control}
                placeholder="District"
                label="District"
              />
            </Box>
            <Box {...styles("input")}>
              <AutoComplete
                options={divisionoptions.length ? divisionoptions : []}
                rules={{ required: errorMessage.required }}
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="division"
                control={control}
                placeholder=" Enter Division"
                label="Division"
              />
            </Box>
            <Box {...styles(["input", "withHelperText"])}>
              <PhoneNumber
                control={control}
                errors={errors}
                name={`landlineNumber`}
                label="Contact Number (Landline)"
                isRequired={false}
              />
            </Box>
            <Box {...styles(["input", "withHelperText"])}>
              <PhoneNumber
                control={control}
                errors={errors}
                name={`phoneNumber`}
                label="Phone Number"
                isRequired={true}
              />
            </Box>
            <Box {...styles(["input", "withHelperText"])}>
              <Input
                name={`email`}
                label="Email"
                placeholder="Enter your email"
                control={control}
                rules={{
                  required: errorMessage.required,
                  pattern: {
                    value: regex.email,
                    message: errorMessage.invalidEmail,
                  },
                }}
                errors={errors}
                height="52px"
              />
            </Box>
          </Box>
        </>
        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Button
            text="Submit"
            type="submit"
            disabled={!isValid}
            customStyles={{
              root: {
                fontSize: "16px",
              },
            }}
          />
        </Box>
      </DashboardWrapper>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      ></SuccessModal>
      <Dialog
        open={!!editModelStateId}
        onClose={() => {
          setshowPreviousIO(false);
          setEditModelStateId("");
        }}
        fullWidth={!showPreviousIO}
        maxWidth={showPreviousIO ? false : "md"}
        sx={{
          "& .MuiDialog-paper": {
            width: "auto",
          },
        }}
      >
        {showPreviousIO ? (
          <PreviousIO id={editModelStateId} />
        ) : (
          <EditImplementingOfficer id={editModelStateId} />
        )}
      </Dialog>
    </Box>
  );
};

export default AddImplementingOfficer;
