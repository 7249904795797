import { Box } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type ViewFormProps = {
  customStyles?: CustomStyles<StylesClasses>;
  label: string;
  value: string | number;
  date?: string;
};

const ViewForm = ({ label, value, customStyles, date }: ViewFormProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("label")}>{label}:</Box>
      <Box {...styles("value")}>{!!value ? value : "-"}</Box>
      {date && <Box {...styles("date")}>{!!date ? date : "-"}</Box>}
    </Box>
  );
};

export default ViewForm;
