import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    width: "100%",
    marginBottom: "80px",
    marginTop: "-100px",
  },

  bodyWrapper: {
    minHeight: "300px",
    backgroundColor: "custom.background.bodyLight",
    padding: "0px 36px 0px 36px",
  },

  stepper: {
    position: "fixed",
    top: "75px",
    left: "0",
    right: "0",
    zIndex: 2,
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "custom.background.bodyLight",
    margin: "24px 0 0",
    padding: "10px 0",
    position: "fixed",
    bottom: "0px",
    left: "36px",
    right: "36px",
    zIndex: "1",
  },

  btnDimensions: {
    width: "174px",
  },

  children: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.border.light",
  },

  heading: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "custom.border.light",
    color: "custom.text.dark",
    fontWeight: "600",
    fontSize: "24px",
    padding: "16px 36px",
  },

  childrenBody: {
    minHeight: "200px",
    padding: "24px 36px",
  },
});

export default styles;
