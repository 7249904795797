import { Box, Typography } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import {
  AutoComplete,
  Button,
  DateInput,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { getStyles } from "src/styles/theme";
import {
  getFundApplicationById,
  getLandDetailsById,
} from "src/utils/api/createProjectFlow";
import defaultStyles, { StylesClasses } from "./styles";
import { fillFundApplicationAPI } from "src/utils/api/dashboard";
import { useEffect } from "react";
import ViewForm from "src/components/common/ViewForm";

export type demandNoteFormData = {
  demandRecipt: {
    type: string;
    key: string;
    file: string;
  }[];
};

const RaiseFundApplication = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const [searchParams] = useSearchParams();

  const EPid = searchParams.get("id");

  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(
    "fillFundApllicationForm",
    ({ EPid, data }: { EPid: string; data: any }) =>
      fillFundApplicationAPI(EPid, data),
    {
      onSuccess: () => {
        navigate("/");
      },
      onError: (error: any) => {},
    }
  );

  const { data: userData } = useQuery(
    ["userDetails", EPid],
    () => getLandDetailsById(EPid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!EPid,
      refetchOnMount: true,
    }
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      estimatedAdvanceWorkCost: "",
      estimatedMonitoringCost: "",
      estimatedRaisingCost: "",
      estimatedMaintainceCost: "",
      estimatedContingencyCost: "",
      totalEstimatedCost: "",
      advanceWorkCost: "",
      raisingCost: "",
      maintainceCost: "",
      monitoringCost: "",
      contingencyCost: "",
      totalCost: "",
      additionalInfo: "",
    },
  });

  const handleForm = (data: any) => {
    const excludeFields = ["additionalInfo"];

    const numericData = Object.fromEntries(
      Object.entries(data).map(([key, value]) =>
        excludeFields.includes(key) ? [key, value] : [key, Number(value)]
      )
    );
    mutate({
      EPid,
      data: numericData,
    });
  };

  const estimatedAdvanceWorkCost = watch("estimatedAdvanceWorkCost");
  const estimatedRaisingCost = watch("estimatedRaisingCost");
  const estimatedMaintenanceCost = watch("estimatedMaintenanceCost");
  const estimatedMonitoringCost = watch("estimatedMonitoringCost");
  const estimatedContingencyCost = watch("estimatedContingencyCost");

  const advanceWorkCost = watch("advanceWorkCost");
  const raisingCost = watch("raisingCost");
  const maintenanceCost = watch("maintenanceCost");
  const monitoringCost = watch("monitoringCost");
  const contingencyCost = watch("contingencyCost");

  useEffect(() => {
    const totalCost =
      (parseFloat(estimatedAdvanceWorkCost) || 0) +
      (parseFloat(estimatedRaisingCost) || 0) +
      (parseFloat(estimatedMaintenanceCost) || 0) +
      (parseFloat(estimatedMonitoringCost) || 0) +
      (parseFloat(estimatedContingencyCost) || 0);

    setValue("totalEstimatedCost", totalCost.toFixed(2));
  }, [
    estimatedAdvanceWorkCost,
    estimatedRaisingCost,
    estimatedMaintenanceCost,
    estimatedMonitoringCost,
    estimatedContingencyCost,
    setValue,
  ]);

  useEffect(() => {
    const totalCost =
      (parseFloat(advanceWorkCost) || 0) +
      (parseFloat(raisingCost) || 0) +
      (parseFloat(maintenanceCost) || 0) +
      (parseFloat(monitoringCost) || 0) +
      (parseFloat(contingencyCost) || 0);

    setValue("totalCost", totalCost.toFixed(2));
  }, [
    advanceWorkCost,
    raisingCost,
    maintenanceCost,
    monitoringCost,
    contingencyCost,
    setValue,
  ]);

  const totalArea = Number(userData?.data?.totalArea);
  return (
    <Box component="form" onSubmit={handleSubmit(handleForm)}>
      <DashboardWrapper
        title="Fund Application Form"
        customStyles={{
          container: { position: "relative" },
          children: { paddingBottom: "80px" },
        }}
        // backPath={`/`}
        showBackBtn
      >
        <Box sx={{ fontSize: "20px", mb: "20px", mt: "20px", fontWeight: 500 }}>
          Eco-Restoration Block Details
        </Box>

        {userData && (
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Eco-Restoration Blocks Details</Box>
            <ViewForm
              label="Project Registration NO"
              value={userData?.data?.projectDetail?.registrationNo}
            />
            <ViewForm
              label="state"
              value={userData?.data?.projectDetail?.address?.stateDetail?.name}
            />
            <ViewForm
              label="District"
              value={
                userData?.data?.projectDetail?.address?.districtDetail?.name
              }
            />
            <ViewForm
              label="Division"
              value={userData?.data?.projectDetail?.landSpecification?.division}
            />
            <ViewForm
              label="Range"
              value={userData?.data?.projectDetail?.landSpecification?.range}
            />
            <ViewForm label="Cost Estimate ID" value={userData?.data?.id} />

            <ViewForm
              label="Eco-Restoration Cost"
              value={userData?.data?.demandNote}
            />
            <ViewForm
              label="Total Land Area(Hectares)"
              value={totalArea.toFixed(2)}
            />
            <ViewForm
              label="Entity Name"
              value={userData?.data?.user.firstName}
            />
          </Box>
        )}

        <Box {...styles("wrapper")}>
          <Box {...styles("sectionWrapper")}>
            <Box {...styles("heading")}>
              Estimated Amount For 1st Year (In Rupees)
            </Box>
            <Box {...styles("ecoRestroration")}>
              <Typography variant="h6" {...styles("ecoRestrorationHeading")}>
                ECO RESTORATION COST
              </Typography>
              <Box {...styles("input")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                  }}
                  name="estimatedAdvanceWorkCost"
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Advance Work Cost"
                  label="Advance Work Cost"
                  type="number"
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                  }}
                  name="estimatedRaisingCost"
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Raising/Creation Cost"
                  label="Raising/Creation Cost (If Any)"
                  type="number"
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                  }}
                  name="estimatedMaintenanceCost"
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Maintance Cost"
                  label="Maintance Cost (If Any)"
                  type="number"
                />
              </Box>
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="estimatedMonitoringCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Mointoring Cost (If Any)"
                label="Mointoring Cost (If Any)"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="estimatedContingencyCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Contingency Cost (If Any)"
                label="Contingency Cost (If Any)"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="totalEstimatedCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                readOnly={true}
                placeholder="Total Cost"
                label="Total Cost"
                type="number"
              />
            </Box>
          </Box>
          <Box {...styles("sectionWrapper")}>
            <Box {...styles("heading")}>Amount Required Now (In Rupees)</Box>
            <Box {...styles("ecoRestroration")}>
              <Typography variant="h6" {...styles("ecoRestrorationHeading")}>
                ECO RESTORATION COST
              </Typography>
              <Box {...styles("input")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                  }}
                  name="advanceWorkCost"
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Advance Work Cost"
                  label="Advance Work Cost"
                  type="number"
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                  }}
                  name="raisingCost"
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Raising/Creation Cost"
                  label="Raising/Creation Cost (If Any)"
                  type="number"
                />
              </Box>
              <Box {...styles("input")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                  }}
                  name="maintenanceCost"
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Maintance Cost"
                  label="Maintance Cost (If Any)"
                  type="number"
                />
              </Box>
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="monitoringCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Mointoring Cost"
                label="Mointoring Cost (If Any)"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="contingencyCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Contingency Cost"
                label="Contingency Cost (If Any)"
                type="number"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="totalCost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Total Cost"
                label="Total Cost"
                type="number"
                readOnly={true}
              />
            </Box>
          </Box>
        </Box>

        <Box {...styles("input")}>
          <Input
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
              input: defaultStyles.multilineInput,
            }}
            required
            name="additionalInfo"
            rules={{
              required: errorMessage.required,
              maxLength: {
                value: 500,
                message: "More then 500 characters are not allowed",
              },
            }}
            control={control}
            type="text"
            placeholder="(In 500 characters)"
            label="Additional Information"
            multiline
            minRows={6}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Button
            text="Submit"
            type="submit"
            disabled={!isValid}
            customStyles={{
              root: {
                fontSize: "16px",
              },
            }}
          />
        </Box>
      </DashboardWrapper>
    </Box>
  );
};

export default RaiseFundApplication;
