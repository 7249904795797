import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import FileUpload from "src/components/common/FormComponents/FileUplaod";
// import { setProjectAreaDetails, setStep } from "src/redux/slices/newProject";

import {
  setStep,
  uploadedIds,
  uploadImages,
} from "src/redux/slices/newProjectSetup";

import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import FormWrapper from "../FormWrapper";
import defaultStyles, { StylesClasses } from "../NewForms/styles";

export type OrganizationDetailsFormData = {
  KMLFiles: {
    type: string;
    key: string;
    file: string;
  }[];
  csvUpload: {
    type: string;
    key: string;
    file: string;
  }[];
  geoTaggedImages: {
    type: string;
    key: string;
    file: string;
  }[];
};

const ProjectBaselineData = () => {
  const { formData } = useAppSelector(
    (state) => state.root.selectNewProjectSetup
  );

  const fillImages = () => {
    if (
      !!formData.uploadedIds.gpsBoundaries.id &&
      !!formData.uploadedIds.gpsBoundariesCsv.id
    ) {
      return {
        defaultValues: {
          KMLFiles: [
            {
              type: "default",
              file: formData.uploadedIds.gpsBoundaries.url,
              key: formData.uploadedIds.gpsBoundaries.id,
            },
          ],
          csvUpload: [
            {
              type: "default",
              file: formData.uploadedIds.gpsBoundariesCsv.url,
              key: formData.uploadedIds.gpsBoundariesCsv.id,
            },
          ],
        },
      };
    } else {
      return {};
    }
  };

  const {
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState,
  } = useForm<OrganizationDetailsFormData>({
    mode: "onTouched",
    ...fillImages(),
  });

  const dispatch = useDispatch();

  const { errors, isValid } = formState;
  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <FormWrapper
      isValid={isValid}
      onNext={() => {
        const formValues = getValues();
        dispatch(uploadedIds(formValues));
        dispatch(uploadImages(formValues.geoTaggedImages));
        dispatch(setStep("ProjectGridForm"));
      }}
      onBack={() => {
        dispatch(setStep("ProjectBaselineData"));
      }}
      subHeading={"Please upload the following"}
      currentStepNumber={3}
    >
      <Box {...styles(["input", "fileUpload"])}>
        <FileUpload
          // accept=".kml, application/vnd.google-earth.kml+xml"
          name="KMLFiles"
          label="Please upload GPS Boundary (KML file) of land parcel"
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          clearErrors={clearErrors}
          maxLimit={1}
          supportedFormatMessage="Please ensure that the KML file should contain closed polygon of land parcel and area of polygon in KML file should not less than the area filled in the form"
          trigger={trigger}
          rules={{
            validate: {
              isLength: (value) => {
                return value && value["length"] >= 1;
              },
            },
          }}
          dragDropHeading=""
        />
      </Box>
      <Box {...styles(["input", "fileUpload"])}>
        <FileUpload
          accept="image/jpeg, image/png, image/webp, image/jpg"
          name="geoTaggedImages"
          label="Upload GeoTagged Images (Minimum 2 images are required )"
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          clearErrors={clearErrors}
          maxLimit={10}
          multiple={true}
          required={true}
          supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
          trigger={trigger}
          rules={{
            validate: {
              isLength: (value) => {
                return value && value["length"] >= 2;
              },
            },
          }}
          dragDropHeading=""
        />
      </Box>
    </FormWrapper>
  );
};

export default ProjectBaselineData;
