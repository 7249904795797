import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { DashboardWrapper } from "src/components/common/DashboardWrapper";
import { Button, Input } from "src/components/common/FormComponents";
import { SuccessModal } from "src/components/common/modal";
import Snackbar from "src/components/common/Snackbar";
import ViewImages from "src/components/common/ViewImage";
import errorMessage from "src/constants/errorMessage";
import { MyProfileType, myProfileValue } from "src/constants/forms";
import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import {
  getMyProfileData,
  UpdateUserProfile,
  updateUserProfile,
} from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "./styles";

const MyProfile = () => {
  const height = "52px";
  const userId = useAppSelector((state) => state.root.user.data.userId);
  const userType = useAppSelector((state) => state.root.user.data.userType);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const { data: myProfileData } = useQuery(
    ["myProfile", userId],
    () => getMyProfileData(userId),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (err: AxiosError) => {
        setIsError(true);
      },
      enabled: !!userId,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (!!myProfileData?.data) {
      reset({
        firstName:
          userData?.userType === "ORGANIZATION"
            ? userData?.firstName
            : userData?.firstName,
        email: userData?.email,
        phoneNumber: userData.phoneNumber,
        entityType: userData?.entityType,
        designation: userData?.designation,
        nameOfAuthorizedPerson: userData?.nameOfAuthorizedPerson,
        identityProofImage: userData?.identityProofImageUrl.id,
        idProofNUmber: userData?.identityProofNumber,
        documentTypes: getResetValue(docDetails?.frontUrl),
        documentType: docDetails[0]?.type,
        userProfileType: userData?.role,
        userType: userData?.userType,
        name: userData?.forestDetails?.name,
        aadhaarNumber: docDetails[0]?.serialNo,
        address: currAddress?.addressLine1,
        currentAddress: {
          addressLine1: currAddress?.addressLine1,
          city: currAddress?.city,
          district: currAddress?.districtId,
          state: currAddress?.stateId,
        },
        agencyDetail: {
          name: userData?.forestDetails?.name,
          state: currAddress?.stateId,
          streetAddress: currAddress?.addressLine1,
          city: currAddress?.city,
          district: currAddress?.districtId,
        },
        bankAccountDetail: {
          bankName: userData?.forestDetails?.bankDetail?.bankName,
          accountNumber: userData?.forestDetails?.bankDetail?.accountNumber,
          ifscCode: userData?.forestDetails?.bankDetail?.ifscCode,
        },
        implementingOfficerDetails: [],
      });
    }
  }, [myProfileData]);

  const userData = myProfileData?.data;

  const getResetValue = useCallback(
    (key, file?: string) => {
      return myProfileData?.data
        ? [
            {
              key: key,
              file: "", //file ?? key,
            },
          ]
        : [];
    },
    [myProfileData]
  );

  const getOptionsValue = useCallback(
    (label, value?: string) => {
      return userData
        ? [
            {
              label: label,
              value: value ?? label,
            },
          ]
        : [];
    },
    [userData]
  );

  const currAddress = userData?.currentAddress;
  const docDetails = userData?.userDocuments;

  const userDocs = _.groupBy(userData?.userDocuments, "type");

  const {
    control,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    handleSubmit,
    watch,
    reset,
  } = useForm<MyProfileType>({
    mode: "onTouched",
    defaultValues: { ...myProfileValue },
  });

  const adminSignUp = useMutation(
    ["signUp"],
    (data: UpdateUserProfile) => updateUserProfile(data),
    {
      onSuccess: (res: AxiosResponse) => {
        setOpenModal({
          open: true,
          heading: "",
          subheading: "",
          description: "Updated successfully ",
          btnText: "",
          type: "success",
        });
        navigate("/my-profile");
      },

      onError: (err: AxiosError) => {},
    }
  );

  const handleFormSubmit = (data: MyProfileType) => {
    adminSignUp.mutate({ id: userId, data: data });
  };

  const { errors, isValid } = formState;
  const styles = getStyles<StylesClasses>(defaultStyles);

  const handleResponseModal = () => {
    if (openModal.type === "success") {
      navigate("/my-profile");
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
    navigate("/");
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <DashboardWrapper title="My Profile" showBackBtn={true} backPath={"/"}>
        <Box {...styles("wrapper")}>
          <Box {...styles("childWrapper")}>
            <Box {...styles("container")}>
              <Box {...styles("input")}>
                <Input
                  name="bankAccountDetail.ifscCode"
                  label="GCP User ID"
                  placeholder="Enter IFSC Code"
                  control={control}
                  value={userData?.userName}
                  height={height}
                  readOnly
                  customStyles={{ wrapper: defaultStyles.selectWrapper }}
                />
              </Box>
            </Box>
            <Box {...styles("heading")}>personal Details</Box>
            <Box {...styles("container")}>
              {userData?.userType === "ORGANIZATION" && (
                <Box {...styles("input")}>
                  <Input
                    name="entityType"
                    label="Entity Type"
                    placeholder="Entity Type"
                    control={control}
                    value={userData?.entityTypeName}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    height={height}
                    readOnly
                  />
                </Box>
              )}
              {userData?.userType === "ORGANIZATION" ? (
                <Box {...styles("input")}>
                  <Input
                    name="firstName"
                    label="Entity Name"
                    placeholder="Enter Entity Name"
                    control={control}
                    height={height}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  />
                </Box>
              ) : (
                <Box {...styles("input")}>
                  <Input
                    name="agencyDetail.name"
                    label="Implementing Agency Name"
                    placeholder="Enter Agency Name"
                    control={control}
                    height={height}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  />
                </Box>
              )}
              {userData?.userType === "ORGANIZATION" ? (
                <Box {...styles("input")}>
                  <Input
                    name="nameOfAuthorizedPerson"
                    label="Authorized Representative Name"
                    placeholder="Authorized Representative Name"
                    control={control}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    height={height}
                  />
                </Box>
              ) : (
                <Box {...styles("input")}>
                  <Input
                    name="firstName"
                    label="Name"
                    placeholder="Nodal Officer Name"
                    control={control}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    height={height}
                  />
                </Box>
              )}
              <Box {...styles("input")}>
                <Input
                  name="designation"
                  label="Designation"
                  placeholder="Designation"
                  control={control}
                  customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  height={height}
                  rules={{ required: errorMessage.required }}
                />
              </Box>
              <Box {...styles(["input", "withHelperText"])}>
                <Input
                  name="email"
                  label="Email"
                  placeholder="Enter your email"
                  value={userData?.email}
                  control={control}
                  customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  readOnly
                  height={height}
                />
              </Box>
              <Box {...styles(["input", "withHelperText"])}>
                <Input
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Enter your phoneNumber"
                  value={userData?.phoneNumber}
                  control={control}
                  customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  readOnly
                  height={height}
                />
              </Box>
            </Box>
            {userData?.userType === "ORGANIZATION" && (
              <Box {...styles("childWrapper")}>
                <Box {...styles("heading")}>ID Proof Number and Documents</Box>
                <Box {...styles("container")}>
                  <Box {...styles("input")}>
                    <Input
                      name="idProofNUmber"
                      label="ID Proof Number"
                      placeholder="ID Proof Number"
                      control={control}
                      value={userData?.identityProofNumber}
                      height={height}
                      readOnly
                      customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    />
                  </Box>
                </Box>
                <ViewImages
                  fileUrl={userData?.identityProofImageUrl?.fileUrl}
                  label={"Identity Proof Image"}
                />
                {userData?.organizationCertificateImageUrl?.fileUrl && (
                  <ViewImages
                    fileUrl={userData?.organizationCertificateImageUrl?.fileUrl}
                    label={"organization Certificate Image"}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box {...styles("childWrapper")}>
            {userData?.userType !== null && (
              <>
                <Box {...styles("heading")}>Registered address</Box>
                <Box {...styles("container")}>
                  <Box {...styles("input")}>
                    <Input
                      name="agencyDetail.streetAddress"
                      label="Street Address"
                      placeholder="Enter Street Address"
                      control={control}
                      height={height}
                      customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    />
                  </Box>
                  {userData?.userType === "INDIVIDUAL" && (
                    <>
                      <Box {...styles("input")}>
                        <Input
                          name="agencyDetail.city"
                          label="City"
                          placeholder="Enter city"
                          control={control}
                          height={height}
                          customStyles={{
                            wrapper: defaultStyles.selectWrapper,
                          }}
                        />
                      </Box>
                      <Box {...styles("input")}>
                        <Input
                          name="currentAddress.district"
                          label="District"
                          placeholder="Enter district"
                          control={control}
                          value={currAddress?.district}
                          height={height}
                          readOnly
                          customStyles={{
                            wrapper: defaultStyles.selectWrapper,
                          }}
                        />
                      </Box>
                      <Box {...styles("input")}>
                        <Input
                          name="currentAddress.state"
                          label="state"
                          placeholder="Enter state"
                          control={control}
                          value={currAddress?.state}
                          height={height}
                          readOnly
                          customStyles={{
                            wrapper: defaultStyles.selectWrapper,
                          }}
                        />
                      </Box>
                    </>
                  )}
                  {userData?.userType === "INDIVIDUAL" && (
                    <Box {...styles("childWrapper")}>
                      <Box {...styles("heading")}>Bank Details</Box>
                      <Box {...styles("container")}>
                        <Box {...styles("input")}>
                          <Input
                            name="bankAccountDetail.bankName"
                            label="Bank Name"
                            placeholder="Enter Bank Name"
                            control={control}
                            height={height}
                            readOnly={
                              !!userData?.forestDetails?.bankDetail?.bankName
                            }
                            customStyles={{
                              wrapper: defaultStyles.selectWrapper,
                            }}
                          />
                        </Box>
                        <Box {...styles("input")}>
                          <Input
                            name="bankAccountDetail.accountNumber"
                            label="Account Number"
                            placeholder="Enter Account Number"
                            control={control}
                            height={height}
                            readOnly={
                              !!userData?.forestDetails?.bankDetail
                                ?.accountNumber
                            }
                            customStyles={{
                              wrapper: defaultStyles.selectWrapper,
                            }}
                          />
                        </Box>
                        <Box {...styles("input")}>
                          <Input
                            name="bankAccountDetail.ifscCode"
                            label="IFSC Code"
                            placeholder="Enter IFSC Code"
                            control={control}
                            height={height}
                            readOnly={
                              !!userData?.forestDetails?.bankDetail?.ifscCode
                            }
                            customStyles={{
                              wrapper: defaultStyles.selectWrapper,
                            }}
                          />
                        </Box>
                        {userData?.cancelChequeImageUrl?.fileUrl && (
                          <ViewImages
                            fileUrl={userData?.cancelChequeImageUrl?.fileUrl}
                            label={"Cancelled cheque Image"}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                  {/* {userData?.userType === null && (
                <Box {...styles("childWrapper")}>
                  <Box {...styles("heading")}>Implementing Officer Details</Box>
                  <Box {...styles("container")}>
                    <Box {...styles("input")}>
                      <Input
                        name="currentAddress.district"
                        label="Implementing Officer Name"
                        placeholder="Enter district"
                        control={control}
                        value={
                          userData?.implementingOfficerDetails[0]?.firstName
                        }
                        height={height}
                        readOnly
                        customStyles={{ wrapper: defaultStyles.selectWrapper }}
                      />
                    </Box>
                    <Box {...styles("input")}>
                      <Input
                        name="currentAddress.district"
                        label="Designation"
                        placeholder="Enter district"
                        control={control}
                        value={
                          userData?.implementingOfficerDetails[0]?.designation
                        }
                        height={height}
                        readOnly
                        customStyles={{ wrapper: defaultStyles.selectWrapper }}
                      />
                    </Box>
                    <Box {...styles("input")}>
                      <Input
                        name="currentAddress.district"
                        label="Email "
                        placeholder="Enter district"
                        control={control}
                        value={userData?.implementingOfficerDetails[0]?.email}
                        height={height}
                        readOnly
                        customStyles={{ wrapper: defaultStyles.selectWrapper }}
                      />
                    </Box>
                    <Box {...styles("input")}>
                      <Input
                        name="currentAddress.district"
                        label="Phone Number "
                        placeholder="Enter district"
                        control={control}
                        value={
                          userData?.implementingOfficerDetails[0]?.phoneNumber
                        }
                        height={height}
                        readOnly
                        customStyles={{ wrapper: defaultStyles.selectWrapper }}
                      />
                    </Box>
                  </Box>
                </Box>
              )} */}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Snackbar
          severity="error"
          message="Something Went Wrong"
          open={isError}
          duration={2000}
          resetSnackbar={() => setIsError(false)}
        />
        <SuccessModal
          open={openModal.open}
          heading={openModal.heading}
          subheading={openModal.subheading}
          description={openModal.description}
          btnText={openModal.btnText}
          type={openModal.type}
          onClick={() => handleResponseModal()}
          handleModalClose={() => handleModalClose()}
        ></SuccessModal>
        <Button text={"Submit"} type="submit" disabled={!isValid} />
      </DashboardWrapper>
    </Box>
  );
};

export default MyProfile;
