import { Box, FormHelperText, TextFieldProps, InputLabel } from "@mui/material";
import {
  Controller,
  FieldErrors,
  FieldPathValue,
  FieldValues,
  Path,
  UnpackNestedValue,
  UseControllerProps,
} from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import { getError } from "src/utils/common";
import defaultStyles, { StylesClasses } from "./styles";
import RadioInput from "../Radio";

type SelectProps<T> = UseControllerProps<T> &
  TextFieldProps & {
    errors?: FieldErrors;
    customStyle?: CustomStyles<StylesClasses>;
    disabled?: boolean;
    defaultValue?: UnpackNestedValue<FieldPathValue<FieldValues, any>> | string;
    options: {
      label: string;
      value: string;
    }[];
  };

export const RadioGroup = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth = true,
  errors,
  customStyle,
  variant = "outlined",
  options,
  defaultValue = "",
  disabled,
  rules,
  ...rest
}: SelectProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyle);
  const error = getError(name, errors);

  return (
    <>
      <InputLabel
        shrink
        className="label"
        disabled={disabled}
        {...styles("label")}
        htmlFor={name}
        required={!!rules?.required && !!label}
      >
        {label}
      </InputLabel>
      <Box>
        <Controller
          render={({ field }) => (
            <Box {...styles("wrapper")}>
              {options.map((item) => {
                return (
                  <RadioInput
                    label={item.value}
                    value={item.value}
                    handleChange={() => field.onChange(item.value)}
                    checked={field.value === item.value}
                    customStyles={{
                      wrapper: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                );
              })}
            </Box>
          )}
          name={name as Path<T>}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          {...rest}
        />
        {!!error && (
          <FormHelperText {...styles("input")}>{error?.message}</FormHelperText>
        )}
      </Box>
    </>
  );
};
