import { getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import { Box, Link } from "@mui/material";
const NotFound = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("leftSection")}>
        <Box
          {...styles("image")}
          component={"img"}
          src="/assets/images/PageNotFound.png"
          alt="page not found"
        />

        <Box {...styles("errorWrapper")}>
          <Box {...styles("error")}>Error</Box>
          <Box {...styles("pageNotFound")}>Page not found</Box>
        </Box>
      </Box>
      <Box {...styles("rightSection")}>
        <Box>
          <Box {...styles("content")}>
            Not to worry. You can head over to our homepage
            <Link href="/" {...styles("colorAccent")}>
              <Box component="span">Green Credit</Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
