import { Box, Button } from "@mui/material";
import ViewForm from "src/components/common/ViewForm";
import { getStyles } from "src/styles/theme";
import defautlStyles from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "react-query";
import { deleteComponentView } from "src/utils/api/dashboard";
import { SuccessModal } from "src/components/common/modal";
import { useDeletionState } from "src/hook/deletion-state";
import { useState } from "react";
import ViewImages from "src/components/common/ViewImage";

export function JournalView({ data, images, submitted }: any) {
  const styles = getStyles(defautlStyles);

  const { setComponentDeletionState } = useDeletionState();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: () => deleteComponentView(data.id),
    onSuccess: () => {
      console.log("Nursery detail deleted successfully.");
      setComponentDeletionState((prev) => !prev);
    },
    onError: (error) => {
      console.error("Error deleting fencing detail:", error);
    },
  });

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "Delete This Component",
    subheading: "Are you sure?",
    description: "",
    btnText: "Confirm",
    type: "Alert",
  });

  const handleResponseModal = () => {
    mutate();
    handleModalClose();
  };
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  const viewData = {
    "Is Available": data.isAvailable ? "Yes" : "No",
    "Prepared as per Guideline": data.preparedAsPerGuideline ? "Yes" : "No",
  };

  return (
    <Box>
      <Box {...styles("viewWrapper")}>
        {Object.entries(viewData).map((item) => (
          <ViewForm label={item[0]} value={item[1]} />
        ))}
        <Box {...styles("heading")}>Geo Tagged Images</Box>
        <Box {...styles("imageContainer")}>
          {images?.map((item) => (
            <ViewImages fileUrl={item.url} />
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          {!submitted && (
            <Button
              endIcon={<DeleteIcon />}
              onClick={() => setOpenModal((prev) => ({ ...prev, open: true }))}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </Button>
          )}
        </Box>

        {isError && (
          <Box sx={{ color: "red" }}>Failed to delete. Try again.</Box>
        )}
      </Box>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      />
    </Box>
  );
}
