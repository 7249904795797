import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AddressDetailsFormData } from "src/components/feature/Onboarding/AddressDetails";
import { resetFormAction } from "src/redux/createActions/resetForms";

interface InitialState {
  formData: AddressDetailsFormData;
}

const initialState: InitialState = {
  formData: {
    currentAddress: {
      address: "",
      city: "",
      district: "",
      state: "",
      pinCode: "",
    },
    permanentAddress: {
      address: "",
      city: "",
      district: "",
      state: "",
      pinCode: "",
    },
    isAddressSame: false,
    selfDeclaration: {},
  },
};

const AddressDetailsSlice = createSlice({
  name: "addressDetails",
  initialState,
  reducers: {
    updateAddressDetailsForm: (
      state,
      action: PayloadAction<AddressDetailsFormData>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateAddressDetailsForm } = AddressDetailsSlice.actions;

export default AddressDetailsSlice.reducer;
