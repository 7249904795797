import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import { Input } from "../FormComponents";
import defaultStyles, { StylesClasses } from "./styles";
import { UserInfo } from "./UserInfo";
import NotificationBadge from "../NotificationBadge";
import { useAppSelector } from "src/redux/store";
import PlaceIcon from "@mui/icons-material/Place";

type HeaderProps = {
  customStyles?: CustomStyles<StylesClasses>;
};

const Header = ({ customStyles }: HeaderProps) => {
  const user = useAppSelector((state) => state.root.user);

  const { userType, state, division, firstName } = user.data;

  const { control } = useForm();
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("headerWrapper")}>
      {/* <Box {...styles("actionFieldWrapper")}> */}
      {/* <SearchBar getText={() => {}} /> */}
      {/* <Input
          {...styles("inputSearchBar")}
          placeholder="search..."
          name="searchBar"
          control={control}
        />
        <Input
          {...styles("inputSearchBar")}
          placeholder="search..."
          name="searchBar"
          control={control}
        />
      </Box> */}

      <Box sx={{ fontWeight: "600", color: "primary.main" }}>
        {userType === "INDIVIDUAL" ? (
          <>Nodal Officer: {state}</>
        ) : userType === "ORGANIZATION" ? (
          <>Entity: {firstName}</>
        ) : (
          <>Implementing Officer: {division}</>
        )}
      </Box>

      <Box sx={{ display: "flex", gap: 5 }}>
        <UserInfo />
      </Box>
    </Box>
  );
};

export default Header;
