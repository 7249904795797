import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  formHelperText: {
    bottom: "-14px",
    left: "-9px",
  },
  menuItems: {
    borderBottomWidth: "1px",
    borderBottomColor: "custom.border.lightSecondary",
    borderBottomStyle: "solid",
    margin: "0 10px",
    color: "custom.text.light",
  },
  formcontrol: {
    width: "100%",
    mt: "12px",
  },
  label: {
    fontSize: "14px",
    position: "unset",
  },
  select: {
    height: "41px",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "#36B1A0",
  },
});

export default styles;
