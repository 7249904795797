import { Box, CircularProgress, Button as MuiButton } from "@mui/material";
import React from "react";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyle, { StylesClasses } from "./styles";

type ButtonProps = {
  text: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  variant?: "contained" | "outlined" | "text";
  customStyles?: CustomStyles<StylesClasses>;
  src?: string;
  type?: "button" | "reset" | "submit";
  loaderSize?: string;
};

export const Button = ({
  text,
  onClick,
  loading,
  disabled,
  variant = "contained",
  type = "button",
  customStyles,
  src,
  loaderSize = "1.5rem",
}: ButtonProps) => {
  const styles = getStyles<StylesClasses>(defaultStyle, customStyles);

  return (
    <>
      <MuiButton
        {...styles(["root", `${variant}`])}
        color="primary"
        variant={variant}
        onClick={onClick}
        disabled={disabled || loading}
        type={type}
      >
        {loading ? (
          <Box {...styles("iconButtonContainer")}>
            {text}{" "}
            <CircularProgress
              {...styles("circle")}
              size={loaderSize}
              color="secondary"
            />
          </Box>
        ) : (
          <Box {...styles("iconButtonContainer")}>
            {src && (
              <Box
                component="img"
                src={src}
                {...styles("buttonIcon")}
                alt={src}
              />
            )}
            {text}
          </Box>
        )}
      </MuiButton>
    </>
  );
};
