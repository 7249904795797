import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;
const styles = createStyles({
  selectWrapper: {
    marginBottom: "10px",
  },
  label: {
    fontSize: "14px",
    mt: "15px",
  },
  formTitle: {
    fontSize: "22px",
    fontWeight: "500",
  },
  formSubTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "custom.text.black.70",
  },
  sectionTitle: {
    fontWeight: "600",
    fontSize: "14px",
  },
  inputContainer: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    gap: "2%",
    flexWrap: "wrap",
  },

  input: {
    flexBasis: "46%",
  },

  fileUpload: {
    flexBasis: "94%",
  },
});

export default styles;
