import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProjectInterface } from "src/types/common";

type AllProjectsData = {
  projects: {
    count: number;
    rows: ProjectInterface[];
  };
  refetchProjects: boolean;
};

const initialState: AllProjectsData = {
  projects: {
    rows: [],
    count: 0,
  },
  refetchProjects: true,
};
const allProjectsDetails = createSlice({
  name: "allProjectsDetailsSlice",
  initialState,
  reducers: {
    getAllProjectsData: (
      state,
      action: PayloadAction<{ rows: ProjectInterface[]; count: number }>
    ) => {
      state.projects.rows = action.payload.rows;
      state.projects.count = action.payload.count;
    },

    refetchAllProjects: (state, action: PayloadAction<boolean>) => {
      state.refetchProjects = action.payload;
    },
  },
});

export const { getAllProjectsData, refetchAllProjects } =
  allProjectsDetails.actions;
export default allProjectsDetails.reducer;
