import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import allProjectsDetails from "src/redux/slices/dashboard/projects";
import aadhaarVerification from "src/redux/slices/onboarding/aadhaarVerification";
import addressDetails from "src/redux/slices/onboarding/addressDetails";
import personalDetails from "src/redux/slices/onboarding/personalDetails";
import userType from "src/redux/slices/onboarding/userType";
import stepper from "src/redux/slices/stepper";
import projectById from "./slices/dashboard/projectById";
import selectProjectTypeSlice from "./slices/newProject";
import selectNewProjectSetupSlice from "./slices/newProjectSetup";
import agencyDetails from "./slices/onboarding/agencyDetails";
import bankDetails from "./slices/onboarding/bankDetails";
import contactDetails from "./slices/onboarding/contactDetails";
import documentUpload from "./slices/onboarding/documentUpload";
import entityDetail from "./slices/onboarding/entityDetail";
import implementingOfficer from "./slices/onboarding/implementingOfficer";
import nodalOfficerDetails from "./slices/onboarding/nodalOfficerDetails";
import organizationDetails from "./slices/onboarding/organizationDetails";
import state from "./slices/state";
import user from "./slices/userDetails";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"],
};

const userConfig = {
  key: "user",
  storage: storage,
};

const userTypeConfig = {
  key: "userType",
  storage: storage,
};

const stepperConfig = {
  key: "stepper",
  storage: storage,
};

const personalDetailsConfig = {
  key: "personalDetails",
  storage: storage,
};

const aadhaarVerificationConfig = {
  key: "aadhaarVerification",
  storage: storage,
};

const addressDetailsConfig = {
  key: "addressDetails",
  storage: storage,
};

const organizationDetailsConfig = {
  key: "organizationDetails",
  storage: storage,
};
const stateConfig = {
  key: "stateOptions",
  storage: storage,
};

const documentUploadConfig = {
  key: "documentUpload",
  storage: storage,
};

const agencyDetailsConfig = {
  key: "agencyDetails",
  storage: storage,
};

const nodalOfficerDetailsConfig = {
  key: "nodalOfficerDetails",
  storage: storage,
};

const bankDetailsConfig = {
  key: "bankDetails",
  storage: storage,
};

const implementingOfficerDetailsConfig = {
  key: "implementingOfficerDetails",
  storage: storage,
};

const entityDetailsConfig = {
  key: "entityDetail",
  storage: storage,
};

const contactDetailsConfig = {
  key: "contactDetails",
  storage: storage,
};

// const projectByIds = {
//   key: "projectById",
//   storage: storage,
// };

const rootReducer = combineReducers({
  userType: persistReducer(userTypeConfig, userType),
  personalDetails: persistReducer(personalDetailsConfig, personalDetails),
  addressDetails: persistReducer(addressDetailsConfig, addressDetails),
  aadhaarVerification: persistReducer(
    aadhaarVerificationConfig,
    aadhaarVerification
  ),
  organizationDetails: persistReducer(
    organizationDetailsConfig,
    organizationDetails
  ),
  documentUpload: persistReducer(documentUploadConfig, documentUpload),
  implementingOfficerDetails: persistReducer(
    implementingOfficerDetailsConfig,
    implementingOfficer
  ),
  bankDetails: persistReducer(bankDetailsConfig, bankDetails),
  agencyDetails: persistReducer(agencyDetailsConfig, agencyDetails),
  entityDetails: persistReducer(entityDetailsConfig, entityDetail),
  contactDetails: persistReducer(contactDetailsConfig, contactDetails),
  nodalOfficerDetails: persistReducer(
    nodalOfficerDetailsConfig,
    nodalOfficerDetails
  ),
  stepper: persistReducer(stepperConfig, stepper),
  routing: routerReducer,
  user: persistReducer(userConfig, user),
  state: persistReducer(stateConfig, state),
  projectById: projectById,
  selectProjectType: selectProjectTypeSlice,
  selectNewProjectSetup: selectNewProjectSetupSlice,
  allProjects: allProjectsDetails,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;
