import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PersonalDetailsFormData } from "src/components/feature/Onboarding/PersonalDetails";
import { resetFormAction } from "src/redux/createActions/resetForms";

interface InitialState {
  formData: Omit<PersonalDetailsFormData, "password"> &
    Partial<Pick<PersonalDetailsFormData, "password">>;
}

const initialState: InitialState = {
  formData: {
    fullName: "",
    email: "",
    phone: {
      countryCode: "+91",
      number: "",
    },
    verifyPhone: false,
    verifyEmail: false,
    emailOtp: "",
    phoneOtp: "",
  },
};

const PersonDetailsSlice = createSlice({
  name: "PersonDetails",
  initialState,
  reducers: {
    updatePersonDetailsForm: (
      state,
      action: PayloadAction<
        Omit<PersonalDetailsFormData, "password"> &
          Partial<Pick<PersonalDetailsFormData, "password">>
      >
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updatePersonDetailsForm } = PersonDetailsSlice.actions;

export default PersonDetailsSlice.reducer;
