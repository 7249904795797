import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EBFAF8",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.greyish.6",
    padding: "25px",
    alignItems: "center",
    justifyContent: "center",
  },
  formWrapper: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "2%",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  firstFormWrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "3%",
    width: { xs: "50%", sm: "90%", lg: "90%" },
    backgroundColor: "#EBFAF8",
    marginBottom: "30px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.greyish.6",
    padding: "15px 20px",
    borderRadius: "15px",
  },

  subComponentFormWrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    flexWrap: "wrap",
    gap: "3%",
    width: { xs: "50%", sm: "90%", lg: "90%" },
    backgroundColor: "#EBFAF8",
    marginBottom: "30px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.greyish.6",
    padding: "15px 20px",
    borderRadius: "15px",
  },
  flexInput: {
    display: "flex",
    backgroundColor: "#E0F5F2",
    alignItems: "center",
    gap: "15px",
    marginTop: "18px",
    flexBasis: { xs: "100%", sm: "75%", lg: "27%" },
  },

  errorCustom: {},

  customWrapper: {
    "& .MuiInputBase-root": {
      border: "none",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      height: "48px",
      width: "80px",
    },
    minWidth: "80px",
    position: "absolute",
    top: "-5px",
    left: "1px",
    zIndex: "100",
  },
  input: {
    margin: "0 0 24px 0",
    flexBasis: { xs: "100%", sm: "75%", lg: "27%" },
  },
  fullInput: {
    margin: "0 0 24px 0",
    flexBasis: { xs: "100%", sm: "75%", lg: "20%" },
  },
  fullWidhtInput: {
    margin: "0 0 24px 0",
    flexBasis: { xs: "100%", sm: "75%", lg: "60%" },
  },
  documentGroup: {
    display: "flex",
    gap: "2%",
    margin: "10px 0",
  },
  phoneNumber: {},
  combine: {
    position: "relative",
  },
  inpGroup: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  selectWrapper: {
    minWidth: "100px",
    borderTopRightRadius: "0px",
    borderColor: "unset",
  },

  fileUpload: {
    flexBasis: "100%",
  },
  borderStyle: {
    borderColor: "custom.border.otpDash",
    border: "2px dashed",
    borderRadius: "12px",
    backgroundColor: "custom.background.secondaryGrey",
  },
  fileType: {
    opacity: "0.5",
    fontSize: "13px",
    fontFamily: "400",
  },
  bottomText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "custom.text.dark",
    opacity: 0.4,
    marginTop: "6px",
  },

  multilineInput: {
    height: "100%",
  },

  fieldHelperText: {
    flexDirection: "column",
    color: "custom.text.dark",
    opacity: "0.6",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
  },

  otpLabel: {
    whiteSpace: "nowrap",
  },

  label: {
    fontSize: "14px",
    mt: "15px",
  },
  date: {
    marginTop: { xs: "0", lg: "16px" },
  },

  heading: {
    fontSize: "20px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "black",
    margin: "20px 0px",
  },
  renderButtonText2: {
    display: "block",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "600",
    color: "#36B1A0",
    textDecoration: "none",
  },
  componentName: {
    display: "flex",
    ".MuiTypography-root": {
      fontWeight: "500",
    },
  },
});

export default styles;
