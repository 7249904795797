import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getAllMonitoringDetailsData } from "src/utils/api/dashboard";
import { FencingView } from "../MonitoringView/fencing";
import { PlantationView } from "../MonitoringView/plantation";
import { useComponentName } from "src/hook/monitoring-component";
import { SmcView } from "../MonitoringView/smcView";
import { IrrigationView } from "../MonitoringView/irrigationView";
import { LandPreparationView } from "../MonitoringView/landPreparationView";
import { NurseryView } from "../MonitoringView/nurseryView";
import { ProtectionView } from "../MonitoringView/protectionView";
import { useDeletionState } from "src/hook/deletion-state";
import { JournalView } from "../MonitoringView/journalView";
import { OthersView } from "../MonitoringView/othersView";
import { StyleAccordion } from "src/components/common/StyleAccordion";
import { useState } from "react";

export function AllComponentDetails({ year, month }: any) {
  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");
  const { componentName } = useComponentName();
  const queryParam = {
    year,
    month,
    landOwnershipEpid,
  };

  const { componentDeletionState } = useDeletionState();
  const { data } = useQuery(
    [year, month, componentName, componentDeletionState],
    () => getAllMonitoringDetailsData(queryParam),
    {
      onSuccess: (data: any) => {},
    }
  );

  const smc = data?.data?.data.smcMonitoring;
  const fencing = data?.data?.data.fencingMonitoring;
  const irrigation = data?.data?.data.irrigationMonitoring;
  const landPreparation = data?.data?.data.landPreparationMonitoring;
  const nursery = data?.data?.data.nurseryMonitoring;
  const plantation = data?.data?.data.plantationMonitoring;
  const protection = data?.data?.data.protectionMonitoring;
  const journal = data?.data?.data.plantationJournalMonitoring;
  const othersMonitoring = data?.data?.data.otherMonitoring;

  const fencingImages = data?.data?.data.fencingMonitoringImages;
  const irrigationImages = data?.data?.data.irrigationMonitoringImages;
  const landPreparationImagees =
    data?.data?.data.landPreparationMonitoringImages;
  const journalImages = data?.data?.data.plantationJournalMonitoringImages;
  const nurseryImages = data?.data?.data.nurseryMonitoringImages;
  const othersImages = data?.data?.data.otherMonitoringImages;
  const plantationImages = data?.data?.data.plantationMonitoring
    ? data?.data?.data.plantationMonitoring.images
    : [];
  const protectionImages = data?.data?.data.protectionMonitoring?.images
    ? data?.data?.data.protectionMonitoring.images
    : [];
  const smcImages = data?.data?.data.smcMonitoringImages;

  const submitted = data?.data?.data.isFinalSubmitted;

  const [openAccordion, setOpenAccordion] = useState<string | null>("");

  const handleAccordionChange = (label: string) => {
    setOpenAccordion(openAccordion === label ? null : label);
  };
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: "20px" }}
    >
      {smc && (
        <StyleAccordion
          id="smc"
          label="Eco Restoration Operations"
          children={
            <SmcView data={smc} images={smcImages} submitted={submitted} />
          }
          isOpen={openAccordion === "smc"}
          onChange={() => handleAccordionChange("smc")}
        />
      )}
      {fencing && (
        <StyleAccordion
          id="fencing"
          label="Fencing Operations"
          children={
            <FencingView
              data={fencing}
              images={fencingImages}
              submitted={submitted}
            />
          }
          isOpen={openAccordion === "fencing"}
          onChange={() => handleAccordionChange("fencing")}
        />
      )}

      {irrigation && (
        <StyleAccordion
          id="irrigation"
          label="Irrigation Measures"
          children={
            <IrrigationView
              data={irrigation}
              images={irrigationImages}
              submitted={submitted}
            />
          }
          isOpen={openAccordion === "irrigation"}
          onChange={() => handleAccordionChange("irrigation")}
        />
      )}

      {landPreparation && (
        <StyleAccordion
          id="landPreparation"
          label="Land Preparation Activities"
          children={
            <LandPreparationView
              data={landPreparation}
              images={landPreparationImagees}
              submitted={submitted}
            />
          }
          isOpen={openAccordion === "landPreparation"}
          onChange={() => handleAccordionChange("landPreparation")}
        />
      )}

      {plantation && (
        <StyleAccordion
          id="plantation"
          label="Plantation"
          children={
            <PlantationView
              data={plantation}
              submitted={submitted}
              images={plantationImages}
            />
          }
          isOpen={openAccordion === "plantation"}
          onChange={() => handleAccordionChange("plantation")}
        />
      )}

      {nursery && (
        <StyleAccordion
          id="nursery"
          label="Nursery Operations"
          children={
            <NurseryView
              data={nursery}
              images={nurseryImages}
              submitted={submitted}
            />
          }
          isOpen={openAccordion === "nursery"}
          onChange={() => handleAccordionChange("nursery")}
        />
      )}

      {protection && (
        <StyleAccordion
          id="protection"
          label="Protection And Maintenence"
          children={
            <ProtectionView
              data={protection}
              submitted={submitted}
              images={protectionImages}
            />
          }
          isOpen={openAccordion === "protection"}
          onChange={() => handleAccordionChange("protection")}
        />
      )}

      {journal && (
        <StyleAccordion
          id="journal"
          label="Plantation Journal"
          children={
            <JournalView
              data={journal}
              images={journalImages}
              submitted={submitted}
            />
          }
          isOpen={openAccordion === "journal"}
          onChange={() => handleAccordionChange("journal")}
        />
      )}

      {othersMonitoring && (
        <StyleAccordion
          id="others"
          label="Others Monitoring"
          children={
            <OthersView
              data={othersMonitoring}
              images={othersImages}
              submitted={submitted}
            />
          }
          isOpen={openAccordion === "others"}
          onChange={() => handleAccordionChange("others")}
        />
      )}
    </Box>
  );
}
