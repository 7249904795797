import { Box } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Input } from "src/components/common/FormComponents";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { updateStepper } from "src/redux/actions";
import { updateAgencyDetails } from "src/redux/slices/onboarding/agencyDetails";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { getStates } from "src/utils/api/roles";
import { optionTypeFormatter } from "src/utils/helper";

import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type AgencyFormData = {
  agencyName: string;
  state: string;
  streetAddress: string;
  district: string;
  city: string;
};

const AgencyDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const [stateOption, setStateOptions] = useState([]);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { formData } = useAppSelector((state) => state.root.agencyDetails);

  const stateOptions = optionTypeFormatter(stateOption);

  const { password: passwordRegex } = regex;

  const { control, formState, handleSubmit, getValues, setValue, watch } =
    useForm<AgencyFormData>({
      mode: "onTouched",
      defaultValues: {
        ...formData,
      },
    });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: AgencyFormData) => {
    dispatch(updateAgencyDetails({ ...data }));
    dispatch(updateStepper({ id: 2 }));
    navigate(path.bankDetails);
  };

  const { data } = useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStateOptions(data.data);
      // data.data.map((item) => ({
      //   label: item.name,
      //   value: item.name,
      //   id: item.id,
      // }))
    },
    refetchOnMount: true,
  });

  const district = useMemo(() => {
    const d = getValues("state");
    const dist = _.find(stateOption, (i) => i.id === d);
    return dist?.districts.length ? optionTypeFormatter(dist?.districts) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("state")]);

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Agency Details"
        previousRoute={path.onboarding}
        isValid={isValid}
      >
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Input
              name="agencyName"
              label="Name of Agency"
              placeholder="Enter your Name of Agency"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("input")}>
            <AutoComplete
              options={stateOptions}
              onOptionSelect={(e) => {
                if (stateOptions?.length && e) {
                  setValue("district", "");
                }
              }}
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="state"
              rules={{ required: errorMessage.required }}
              control={control}
              placeholder="State"
              label="State"
            />
          </Box>
          <Box {...styles("input")}>
            <AutoComplete
              options={district.length ? district : []}
              rules={{ required: errorMessage.required }}
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="district"
              control={control}
              placeholder="District"
              label="District"
            />
          </Box>
        </Box>
        <Box {...styles("fieldHelperText")}>Head Office Address</Box>
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Input
              name="streetAddress"
              label="Street Address"
              placeholder="Enter Street Address"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              name="city"
              label="City"
              placeholder="Enter your City"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          {/* <Box {...styles("input")}>
            <Input
              name="district"
              label="District"
              placeholder="Enter your District"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box> */}
        </Box>
      </OnboardingWrapper>
    </Box>
  );
};

export default AgencyDetails;
