import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import {
  Button,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import errorMessage from "src/constants/errorMessage";
import { useMutation, useQuery } from "react-query";
import {
  getPlantationDetail,
  postMonitoringComponentDetail,
} from "src/utils/api/dashboard";
import { useSearchParams } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { SuccessModal } from "src/components/common/modal";
import { useComponentName } from "src/hook/monitoring-component";
import { ENUM_MONITORING_TYPE } from "src/constants/common";

export type PlantationDetailType = {
  year: string;
  month: string;
  plantationOption: string;
  avgHeight: string;
  totalSeedling: string;
  avgSurvival: string;
  remark: string;
  [key: string]: string | number;
};
export function PlantationForm({ param, yearMonth }: any) {
  const styles = getStyles(defaultStyles);
  const form = useForm<PlantationDetailType>({
    mode: "onChange",
  });

  const [searchParams] = useSearchParams();

  const landOwnershipEpid = searchParams.get("id");

  const { setComponentName } = useComponentName();
  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    heading: "",
    subHeading: "",
  });
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };
  const handlePopUpClose = () => {
    if (openModal.type === "success") {
      setComponentName(undefined);
    } else {
      handleModalClose();
    }
  };

  const { data: plantationData } = useQuery(
    ["plantationDetails", landOwnershipEpid],
    () => getPlantationDetail(landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!landOwnershipEpid,
      refetchOnMount: true,
    }
  );

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = form;

  const { mutate } = useMutation(
    "createPlantationDetail",
    ({ updateValue }: { updateValue: any }) =>
      postMonitoringComponentDetail(updateValue),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          type: "success",
          heading: "Success",
          subHeading: "Plantation Monitoring Details Uploaded Successfully",
        });
      },
    }
  );

  const onsubmit = (data: any) => {
    const imageKeys = data.images.map((item) => item.key);

    mutate({
      updateValue: {
        type: ENUM_MONITORING_TYPE.PLANTATION,
        landOwnershipEpid,
        year: param.year,
        month: param.month,
        avgSurvival: parseInt(data.avgSurvival, 10),
        avgHeight: parseInt(data.avgHeight, 10),
        remark: data.remark,
        yearMonth: yearMonth,
        speciesDetails: Object.entries(data.inputs).map((item) => {
          return {
            avgSurvival: parseInt(item[1]["survival"], 10),
            avgHeight: parseInt(item[1]["avgHeight"], 10),
            speciesId: item[0],
            remark: item[1]["remark"],
          };
        }),
        images: imageKeys,
      },
    });
  };

  return (
    <Box {...styles("formWrapper")}>
      <Box
        component="form"
        onSubmit={handleSubmit(onsubmit)}
        sx={{
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#EBFAF8",
            padding: "0px 10px 10px 10px",
            borderRadius: "8px",
          }}
        >
          <Box {...styles("inputForm")}>
            <Grid container spacing={3}>
              <Grid container item spacing={3} xs={12}>
                <Grid item xs={12} md={6}>
                  <Input
                    customStyles={{
                      label: styles("inputLabel").sx,
                      wrapper: styles("inputWrapper").sx,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                    }}
                    name="avgHeight"
                    control={control}
                    errors={errors}
                    placeholder="Average Height (m)"
                    label="Average Height (m)"
                    type="number"
                    rules={{ required: errorMessage.required }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    customStyles={{
                      label: styles("inputLabel").sx,
                      wrapper: styles("inputWrapper").sx,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                    }}
                    name="avgSurvival"
                    control={control}
                    errors={errors}
                    placeholder="Average Survival (%)"
                    label="Average Survival (%)"
                    type="number"
                    rules={{
                      required: errorMessage.required,
                      max: {
                        value: 100,
                        message: "Must be less than or equal to 100",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: "40px" }}>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "custom.greyish.3" }}>
                  <TableRow>
                    <TableCell align="center" sx={{ width: "70px" }}>
                      S. No.{" "}
                    </TableCell>
                    <TableCell align="center">Botanical Name </TableCell>
                    <TableCell align="center">Common Name</TableCell>
                    <TableCell align="center">No. of Seedling</TableCell>
                    <TableCell align="center" sx={{ width: "200px" }}>
                      Average Height (m)
                    </TableCell>
                    <TableCell align="center" sx={{ width: "200px" }}>
                      Survival (%)
                    </TableCell>
                    <TableCell align="center" sx={{ width: "200px" }}>
                      Observations
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plantationData?.data.data &&
                    plantationData?.data.data.map((item, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {item.botanicalName}
                        </TableCell>
                        <TableCell align="center">{item.commonName}</TableCell>
                        <TableCell align="center">
                          {item.numberOfSeedlings}
                        </TableCell>

                        <TableCell align="center">
                          <Input
                            customStyles={{
                              label: styles("inputLabel").sx,
                              wrapper: styles("inputWrapper").sx,
                              input: {
                                borderColor: "#36B1A0;",
                                boxShadow: "unset",
                              },
                              errorMsg: styles("errorMsg", { bottom: "-22px" })
                                .sx,
                            }}
                            name={`inputs[${item.id}].avgHeight`}
                            control={control}
                            errors={errors}
                            placeholder="Average Height (m)"
                            rules={{ required: errorMessage.required }}
                            type="number"
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ paddingTop: "10px" }}>
                          <Input
                            customStyles={{
                              label: styles("inputLabel").sx,
                              wrapper: styles("inputWrapper").sx,
                              input: {
                                borderColor: "#36B1A0;",
                                boxShadow: "unset",
                              },
                              errorMsg: styles("errorMsg", { bottom: "-22px" })
                                .sx,
                            }}
                            name={`inputs[${item.id}].survival`}
                            control={control}
                            errors={errors}
                            placeholder="Survival (%)"
                            rules={{
                              required: errorMessage.required,
                              max: {
                                value: 100,
                                message: "Must be less than or equal to 100",
                              },
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message: "Up to 2 decimal places",
                              },
                            }}
                            inputProps={{
                              step: "0.01",
                            }}
                            type="number"
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ paddingTop: "10px" }}>
                          <Input
                            customStyles={{
                              label: styles("inputLabel").sx,
                              wrapper: styles("inputWrapper").sx,
                              input: {
                                borderColor: "#36B1A0;",
                                boxShadow: "unset",
                              },
                              errorMsg: styles("errorMsg", { bottom: "-22px" })
                                .sx,
                            }}
                            name={`inputs[${item.id}].remark`}
                            control={control}
                            errors={errors}
                            placeholder="Enter Observation"
                            rules={{ required: errorMessage.required }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Grid container item spacing={3} xs={12}>
            <Grid item xs={12}>
              <Input
                customStyles={{
                  label: styles("inputLabel").sx,
                  wrapper: styles("inputWrapper").sx,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="remark"
                control={control}
                errors={errors}
                placeholder="Overall Observations"
                label="Overall Observation on Sampling Survival"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={{ margin: "20px 20px " }}
          >
            <FileUpload
              accept="image/jpeg, image/png, image/webp, image/jpg"
              name="images"
              label="Upload GeoTagged Images (Minimum 2 images are required )"
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              maxLimit={10}
              multiple={true}
              required={true}
              supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
              trigger={trigger}
              rules={{
                validate: {
                  isLength: (value) => {
                    return value && value["length"] >= 2;
                  },
                },
              }}
              dragDropHeading=""
            />
          </Grid>
          <Box
            sx={{
              margin: "40px 0px",
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button text="Submit" type="submit" disabled={!isValid} />
          </Box>
        </Box>
        <SuccessModal
          open={openModal.open}
          heading={openModal.heading}
          subheading={openModal.subHeading}
          closeOnBackDrop
          type={openModal.type}
          handleModalClose={() => handlePopUpClose()}
        />
      </Box>
    </Box>
  );
}
