import dataProvider from "src/dataProvider";
import { fileUploadOcrType, fileUploadType } from "src/types/api";

let fetcher = dataProvider("imageUploadInstance");

export const fileUploadOcr = async (data: fileUploadOcrType) => {
  return fetcher.post<fileUploadOcrType>("/upload/image", data);
};

export const fileUpload = async (data: fileUploadType) => {
  return fetcher.post<fileUploadType>("/upload/file", data);
};
