import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Fallback from "src/components/common/Fallback";
import store from "src/redux/store";
import theme from "src/styles/theme";
import "./App.css";
import AppRouter from "./router/AppRouter";
import { LoadScript } from "@react-google-maps/api";
import { LinearProgress } from "@mui/material";
import { ComponentNameProvider } from "./hook/monitoring-component";
import { DeletionStateProvider } from "./hook/deletion-state";

const App = (): JSX.Element => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false, refetchOnMount: false },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadScript
        loadingElement={<LinearProgress />}
        googleMapsApiKey="AIzaSyAyOMZXsDXidzVhWywof3jRCzQ_4IlCzbY"
      >
        <ComponentNameProvider>
          <DeletionStateProvider>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <Suspense fallback={<Fallback />}>
                  <BrowserRouter>
                    <AppRouter />
                  </BrowserRouter>
                </Suspense>
              </Provider>
            </QueryClientProvider>
          </DeletionStateProvider>
        </ComponentNameProvider>
      </LoadScript>
    </ThemeProvider>
  );
};

export default App;
