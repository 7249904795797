import { Box } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import { ProjectType } from "src/constants/common";
import { getStyles } from "src/styles/theme";
import LandLeaseDetails from "./Forms/LandLeaseDetails";
import TreeDetails from "./Forms/TreeDetails";
import WaterDetails from "./Forms/WaterDetails";
import defaultStyles, { StylesClasses } from "./styles";
import { path } from "src/constants/path";

const ProjectDetailView = () => {
  const location = useLocation();
  // const projectId = location?.state?.projectId || "";
  // const projectType = location?.state?.projectType || "";

  const [searchParams] = useSearchParams();

  const projectId = searchParams.get("projectId");

  const projectType = searchParams.get("projectType");

  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <>
      <DashboardWrapper
        title="Eco-Restoration Blocks"
        showBackBtn={true}
        customStyles={{
          container: { position: "relative" },
        }}
        // backPath={path.home}
      >
        <Box {...styles("wrapper")}>
          {projectType === ProjectType.TREE_PLANTATION && (
            <TreeDetails projectId={projectId} />
          )}
          {projectType === ProjectType.WATER_CONSERVATION && (
            <WaterDetails projectId={projectId} />
          )}

          {projectType === ProjectType.LAND_LEASED && (
            <LandLeaseDetails projectId={projectId} />
          )}
        </Box>
      </DashboardWrapper>
    </>
  );
};

export default ProjectDetailView;
