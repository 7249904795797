import { Box, StepButton, StepIconProps, StepLabel } from "@mui/material";
import Step from "@mui/material/Step";
import MuiStepper from "@mui/material/Stepper";
import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useNavigate } from "react-router-dom";
import { Stepper as StepperType } from "src/types/common";

const StepIcon = ({ active, completed, icon }: StepIconProps) => {
  const styles = getStyles(defaultStyles);
  return (
    <Box
      {...styles(["root", !!completed && "completed", !!active && "active"])}
    >
      {icon}
    </Box>
  );
};

const Stepper = () => {
  const styles = getStyles(defaultStyles);

  const navigate = useNavigate();

  const { stepperData } = useAppSelector((state) => state.root.stepper);

  const handleStep = (index: number, step: StepperType) => {
    (step.isActive || step.isCompleted) && navigate(step.route);
  };
  return (
    <MuiStepper
      activeStep={stepperData?.length}
      alternativeLabel
      {...styles("stepperWrapper")}
    >
      {stepperData.map((step, index) => (
        <Step
          key={index}
          active={step.isActive}
          completed={step.isCompleted}
          {...styles("stepComp")}
        >
          <StepButton
            disableRipple
            color="inherit"
            onClick={() => handleStep(index, step)}
            {...styles("stepperBtn")}
          >
            <StepLabel StepIconComponent={StepIcon} />
          </StepButton>
          {step.name}
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
