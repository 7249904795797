import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    backgroundColor: "custom.text.grey.100",
    borderRadius: "12px",
    padding: "24px 36px 48px 36px",
  },
  projectName: {
    fontSize: "24px",
    fontWeight: "600",
    mb: "20px",
  },

  imageWrapper: {
    display: "flex",
    gap: "2%",
    margin: "15px 0",
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3%",
  },

  heading: {
    fontSize: "18px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "#36B1A0",
    marginBottom: "30px",
  },

  fileBox: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
  },

  documentUpload: {},

  userDetails: {},
  personalDetails: {},
  pwdErrorMsg: {},
  withHelperText: {},
  fieldHelperText: {
    flexDirection: "column",
    color: "custom.text.dark",
    opacity: "0.6",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
  },
  fileUpload: {},
  fileUploads: {
    width: "60%",
  },
  borderStyle: {},
  fileType: {},
  registeredAddress: {},
  permanentAddress: {},

  selectWrapper: {
    width: "100%",
  },

  input: {
    // display: "flex",
    flexBasis: { xs: "100%", sm: "75%", lg: "40%" },
    margin: "0 0 24px 0",
  },

  loading: {
    display: "flex",
    justifyContent: "center",
  },
  documentGroup: {
    display: "flex",
    gap: "2%",
    margin: "10px 0",
  },
  subHeading: {
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "custom.text.myProfileText",
    marginBottom: "30px",
  },
  grid: {
    paddingBottom: "10px",
  },
  btnWrapper: {
    display: "flex",
    gap: "5%",
    position: "absolute",
    bottom: "20px",
    right: "20px",
    fontSize: "16px",
  },

  formWrapper: {
    margin: "20px 0",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#36B1A0",
    padding: "15px 20px",
    borderRadius: "15px",
    maxWidth: { xs: "100%", lg: "70%" },
  },

  downloadBtn: {
    color: "primary.main",
    display: "flex",
    justifyContent: "flex-end",
    textDecoration: "none",
    alignItems: "center",
    fontSize: "18px",
    gap: "3px",
    position: "absolute",
    right: "20px",
  },

  group: {
    margin: "5px 0 10px 0px",
  },

  label: {
    fontSize: "16px",
    fontWeight: "600",
    marginRight: "4px",
  },
  imagesWrapper: {
    border: "1px solid #888",
    padding: "10px",
    borderRadius: "8px",
    margin: "10px 0",
  },
  imgContainer: {
    objectFit: "contain",
    display: "flex",
    justifyContent: "center",
  },
  multilineInput: {
    height: "100%",
  },

  multilineWrapper: {
    minWidth: "80px",
    width: "70%",
  },

  projectWrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "3%",
    width: { xs: "50%", sm: "90%", lg: "80%" },
  },

  imageWrapper2: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2%",
    margin: "15px 0",
  },
  infoContainer: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row-reverse",
    marginBottom: "20px",
  },
});

export default styles;
