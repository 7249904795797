import { createSlice } from "@reduxjs/toolkit";

type ProvisionType = {
  year: number;
  percentage: number;
};

type AddressType = {
  addressLine1: string;
  addressLine2: number;
  city: string;
  country: string;
  district: string;
  districtName: string;
  id: string;
  isoCode: string;
  latitude: string;
  longitude: string;
  state: string;
  stateName: string;
  village: number;
  zipCode: string;
};

type TreeSpeciesType = {
  ageOfSaplings?: number;
  id: string;
  sourceOfSaplings?: string;
  speciesName?: string;
  treeCount?: number;
  treeSpecies?: {
    name: string;
    speciesName: string;
    speciesType: string;
  };
  treeSpeciesId?: string;
  treeType?: number;
};

type BankDetail = {
  id?: string;
};
type organizationAffiliationDetail = {
  id?: string;
};

type ProjectData = {
  data: {
    id: string;
    status?: string;
    provisionalDetails?: ProvisionType[];
    address?: AddressType;
    treeSpecificationDetail?: TreeSpeciesType[];
    estimatedCredits?: number;
    projectType?: string;
    rainfallZoneMultiplier?: number;
    bankAccountDetail: BankDetail;
    organizationAffiliationDetail: organizationAffiliationDetail;
    landArea: number;
    areaUnit: string;
    landOwnershipType: string;
    landCategory: string;
    waterConservationDetail: {
      potentialCapacity: number;
      cost: number;
    };
    documentDetail?: any;
  };
};

const initialState: ProjectData = {
  data: {
    id: "",
    status: "",
    landArea: null,
    areaUnit: "",
    landOwnershipType: "",
    landCategory: "",
    provisionalDetails: [],
    treeSpecificationDetail: [],
    estimatedCredits: null,
    projectType: "",
    rainfallZoneMultiplier: null,
    bankAccountDetail: {},
    organizationAffiliationDetail: {},
    waterConservationDetail: {
      potentialCapacity: null,
      cost: null,
    },
    documentDetail: [],
  },
};
const projectData = createSlice({
  name: "projectDataSlice",
  initialState,
  reducers: {
    getProjectById: (state, action) => {
      state.data = action.payload;
    },
    resetProjectById: (state) => {
      state.data = initialState.data;
    },
  },
});

export const { getProjectById, resetProjectById } = projectData.actions;
export default projectData.reducer;
