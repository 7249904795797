import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  customHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
    "& .MuiOutlinedInput-root.Mui-focused": {
      borderColor: "custom.disabled",
    },
  },

  arrowIcon: {
    height: "15px",
    width: "15px",
  },

  select: {
    outline: "none",
    padding: "0",
    height: { xs: "30px", sm: "40px" },
    "& fieldset, svg": {
      display: "none",
    },
    "& .MuiSelect-select.MuiInputBase-input.MuiSelect-outlined": {
      padding: { xs: "8px 16px", sm: "12px 16px" },
    },
  },

  paper: {
    maxHeight: "190px",
    "& .MuiList-root": {
      padding: "5px 0px",
    },
  },

  menu: {
    "& .MuiMenuItem-root": {
      fontSize: { xs: ".8rem", sm: "1rem" },
      minHeight: "auto",
    },
  },

  calendarIconBtn: {
    height: "20px",
    width: "20px",
    mr: { xs: "-12px", sm: "0px" },
  },

  calendarIcon: {
    fontSize: "18px",
    width: "12px",
    height: "12px",
    color: "custom.label",
  },

  disabledIcon: {
    fontSize: "18px",
    width: "12px",
    // height: "12px",
    color: "red",
  },

  calendarWrapper: {
    "&>div": {
      fontSize: "12px",
      color: "custom.text.black['70']",
      boxShadow:
        "0px 6.169811248779297px 6.169811248779297px 0px rgba(0, 0, 0, 0.25)",
    },

    "& .react-datepicker": {
      fontSize: "11px",
      fontFamily: "inherit",
      border: "none",
    },

    "& .react-datepicker__header": {
      marginBottom: "0px",
      backgroundColor: "white",
      borderColor: "white",
      paddingBottom: "0",
    },

    "& .react-datepicker__day-names": {
      margin: "0",
      padding: "0",
      color: "custom.border.black['70']",
      fontWeight: 500,
    },

    "& .react-datepicker__navigation-icon--next ": {
      color: "black",
    },

    "& .react-datepicker__day": {
      borderWidth: "1px",
      borderColor: "custom.border.light",
      borderStyle: "solid",
      fontWeight: 500,
      width: "48px",
      lineHeight: "40px",
      borderRadius: 0,
      backgroundColor: "transparent",
      margin: "1px",
    },

    "& .react-datepicker__day--disabled": {
      color: "custom.misc.gray",
    },

    "& .react-datepicker__month": {
      marginBottom: "8px",
    },

    "& .react-datepicker__day-name": {
      margin: "0 11px",
      fontWeight: 600,
    },

    "& .react-datepicker__day--in-range": {
      color: "custom.text.main",
      backgroundColor: "custom.misc.water",
    },

    "& .react-datepicker__day--range-end": {
      color: "white",
      backgroundColor: "primary.main",
    },

    "& .react-datepicker__day--range-start": {
      color: "white",
      backgroundColor: "primary.main",
    },

    "& .react-datepicker__day--selected": {
      color: "white",
      backgroundColor: "primary.main",
    },

    "& .react-datepicker__header__dropdown": {
      mb: "8px",
    },

    "& .MuiInputBase-root": {
      outline: "none",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "custom.misc.water",
      margin: "5px 10px",
    },
  },
});
export default styles;
