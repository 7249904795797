import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useQuery } from "react-query";
import { getMyProfileData } from "src/utils/api/dashboard";
import EmptyView from "src/components/common/EmptyView";
import { dateAndTimeStamp } from "src/utils/helper";

const topHeader = [
  "S. No.",
  "Name",
  "Email",
  "Mobile Number",
  "Designation",
  "Joining Date",
];
export function PreviousIO({ id }: any) {
  const styles = getStyles(defaultStyles);

  const { data } = useQuery(["previous"], () => getMyProfileData(id), {
    onSuccess: ({ data }: any) => {},
    onError: () => {},
    enabled: !!id,
    refetchOnMount: true,
  });
  const previousIOData = data?.data?.previousUsers;
  console.log("previous", previousIOData);
  return (
    <Box sx={{ padding: "20px", width: "800px" }}>
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "center",
          color: "primary",
        }}
      >
        Previous IO Details
      </Box>
      <TableContainer
        component={Paper}
        sx={{ height: "fit-content", mt: "20px" }}
      >
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead {...styles("header")}>
            <TableRow>
              {topHeader.map((item, index) => (
                <TableCell align="center" {...styles("cell")} key={index}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {previousIOData && previousIOData.length > 0 ? (
            <TableBody>
              {previousIOData &&
                previousIOData.length > 0 &&
                previousIOData.map((item, index) => {
                  if (!item) return null;
                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {item.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {item.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {item.phoneNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {item.designation}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {dateAndTimeStamp(item.joiningDate, "IST")}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <EmptyView buttonText="" heading="No Previous IO Available" />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
