import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {},

  heading: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "24px",
    marginTo: "24px",
    color: "custom.text.secondary",
  },

  input: {
    margin: "0 0 24px 0",
  },

  address: {},

  select: {},

  selectWrapper: {
    // width: "40%",
  },
});

export default styles;
