import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    objectFit: "contain",
    resizeMode: "center",
    width: "100%",
    marginTop: "280px",
  },
  noHeader: {
    height: "100vh",
    margin: "0",
  },
});

export default styles;
