import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  heading: {
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
    margin: "10px ",
  },
  currentYearWrapper: {
    margin: "10px 0px ",
    borderRadius: "8px ",
    border: "1px solid",
    borderColor: "#36B1A0",
    padding: "10px",
    // backgroundColor: "#EBFAF8",
  },
  subHeading: {
    mt: "10px",
    fontSize: "18px",
    fontWeight: "600",
    // color: "#36B1A0",
    color: "darkred",
  },
  formWrapper: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#36B1A0",
    padding: "15px 20px",
    borderRadius: "15px",
    maxWidth: { xs: "100%", lg: "100%" },
    display: "flex",
    backgroundColor: "#EBFAF8",
  },
});

export default styles;
