import {
  addressInstance,
  adminInstance,
  imageUploadInstance,
  openApiInstance,
} from "./axios";

const useApiGateway = process.env.REACT_APP_USE_GATEWAY;

const InstanceTypeMapping = {
  adminInstance: adminInstance,
  openApiInstance: useApiGateway ? adminInstance : openApiInstance,
  imageUploadInstance: useApiGateway ? adminInstance : imageUploadInstance,
  addressInstance: addressInstance,
} as const;

type InstanceTypes = keyof typeof InstanceTypeMapping;
const fetcher = (instanceType: InstanceTypes) => {
  let axios = InstanceTypeMapping[instanceType];

  return {
    get: async <TResData = any>(
      url: string,
      params = {},
      version: string = "v1"
    ) => {
      return axios
        .request<TResData>({
          url: version + url,
          method: "GET",
          params,
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    /**
     * @function post To create a resource
     * @returns Promise
     */
    post: async <TReqData = any, TResData = any>(
      url: string,
      data: TReqData,
      params = {},
      version: string = "v1",
      headers: any = {}
    ) => {
      return axios
        .request<TResData>({
          url: version + url,
          method: "POST",
          data,
          params,
          headers,
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    /**
     * @function put To update a full data of resource
     * @returns Promise
     */
    put: async <TReqData = any, TResData = any>(
      url: string,
      data: TReqData,
      params = {},
      version: string = "v1"
    ) => {
      return axios
        .request<TResData>({
          url: version + url,
          method: "PUT",
          data,
          params,
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    /**
     * @function patch To update partial data of a resource
     * @returns Promise
     */
    patch: async <TReqData = any, TResData = any>(
      url: string,
      data: TReqData,
      params = {},
      version: string = "v1"
    ) => {
      return axios
        .request<TResData>({
          url: version + url,
          method: "PATCH",
          data,
          params,
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    /**
     *@function delete To delete the resource
     * @returns Promise
     */
    delete: async (url: string, params = {}, version: string = "v1") => {
      return axios
        .request({
          url: version + url,
          method: "DELETE",
          params: params,
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
  };
};

export default fetcher;
