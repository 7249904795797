import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  container: {
    "& .MuiPaper-root .MuiDrawer-paper": {
      height: {
        sm: "90vh",
        xs: "100vh",
      },
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      width: {
        lg: "70vw",
        sm: "80vw",
        xs: "100vw",
      },
    },
  },

  crossBtn: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  wrapper: {
    position: "relative",
    padding: "24px 24px 0px 24px",
  },
  drawerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  drawerTitle: {
    color: "custom.text.dark",
    fontSize: "22px",
    fontWeight: "700",
  },
  stepperContainer: {
    marginTop: "10px",
  },
  stepperWrapper: {
    color: "custom.greyish.4",
    fontSize: "14px",
  },
  stepperLabel: {
    color: "custom.greyish.4",
    fontSize: "14px",
    mb: "10px",
  },
  stepperLabelTitle: {
    color: "primary.main",
    fontWeight: "500",
    mb: "10px",
  },
  currentStep: {
    color: "primary.main",
    fontSize: "18px",
  },

  children: {},
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "10px",
  },
});

export default styles;
