import { Box, Grid } from "@mui/material";
import { Button, Input } from "../../../common/FormComponents";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import { useForm } from "react-hook-form";
import errorMessage from "src/constants/errorMessage";
import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { addMointoringMember } from "src/utils/api/dashboard";
import { useAppSelector } from "src/redux/store";

export function MonitoringMember({
  landOwnershipEpid,
  selfState,
  refetch,
}: any) {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const user = useAppSelector((state) => state.root.user);
  const { mutate, isLoading } = useMutation(
    "postUpdatedCostEstimateData",
    ({ updateValue }: { updateValue: any }) => addMointoringMember(updateValue),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log("data updated successfully");
        selfState(false);
        refetch();
      },
      onError: (error: any) => {
        console.log("error occured while updating");
      },
    }
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });

  const moniteringMemberType =
    user.data.userType === null ? "ACF" : "ENTITY_REPRESENTATIVE";
  const onSubmit = (data: any) => {
    mutate({
      updateValue: {
        ...data,
        type: moniteringMemberType, // edit this as per logined type
        landOwnershipEpid: landOwnershipEpid,
      },
    });
  };

  return (
    <Box {...styles("wrapper")}>
      {(user?.data?.userType === null ||
        user?.data?.userType === "ORGANIZATION") && (
        <Box
          {...styles("formWrapper")}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box {...styles("heading")}>Add Monitoring Committe Member </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="name"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Enter Name"
                label="Enter Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="email"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Enter Email"
                label=" Enter Email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="designation"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Enter Designation"
                label="Enter Designation"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="phoneNumber"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Enter Phone Number"
                label="Enter Phone Number"
              />
            </Grid>
          </Grid>
          <Box>
            <Button text="Add Member" type="submit" loading={isLoading} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
