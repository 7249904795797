import { Box, Collapse, Dialog } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  AutoComplete,
  Button,
  Input,
  PhoneNumber,
} from "src/components/common/FormComponents";
import { SuccessModal } from "src/components/common/modal";
import ViewForm from "src/components/common/ViewForm";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { resetFormAction } from "src/redux/createActions/resetForms";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import {
  editImplementingOfficerApi,
  getMyProfileData,
} from "src/utils/api/dashboard";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "../styles";
import Checkbox from "src/components/common/FormComponents/Checkbox";

export type implementingdataFormData = {
  firstName: string;
  email: string;
  designation: string;
  district: string;
  division: string;
  landlineNumber: {
    number: string;
    countryCode: string;
  };
  phoneNumber: {
    number: string;
    countryCode: string;
  };
  newIo: boolean;
};

type Props = {
  id: string;
};

const EditImplementingOfficer = ({ id }: Props) => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [response, setResponse] = useState({});
  const [editModelStateId, setEditModelStateId] = useState<string>("");

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const userId = useAppSelector((state) => state.root.user.data.userId);

  const user = useAppSelector((state) => state.root.user);

  const { data: modalData } = useQuery(
    ["myProfile", editModelStateId],
    () => getMyProfileData(id),
    {
      onSuccess: () => {},
      onError: () => {},
      enabled: !!userId,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (!!modalData) {
      reset({
        firstName: modalData?.data?.firstName,
        designation: modalData?.data?.designation,
        phoneNumber: { number: modalData?.data?.phoneNumber },
        landlineNumber: { number: modalData?.data?.landlineNumber },
        email: modalData?.data?.email,
        division: modalData?.data?.division,
        district: modalData?.data?.userDistrict,
      });
    }
  }, [modalData]);

  const addImplementingOfficer = useMutation(
    "signUp",
    ({
      id,
      implementingOfficerDetails,
    }: {
      id: any;
      implementingOfficerDetails: any;
    }) => editImplementingOfficerApi(implementingOfficerDetails, id),
    {
      onSuccess: (res: AxiosResponse) => {
        setResponse({ "User name": res.data.userName });
        setOpenModal({
          open: true,
          heading: "",
          subheading: "",
          description: "Implementing Officer Information Successfully Added",
          btnText: "",
          type: "success",
        });
        dispatch(resetFormAction({}));
      },

      onError: (err: AxiosError) => {
        setResponse({});
        setOpenModal({
          open: true,
          heading: "Unsuccessful",
          subheading: "",
          description: err.response.data.message || "Something went wrong",
          btnText: "",
          type: "unSuccess",
        });
      },
    }
  );

  const { control, formState, handleSubmit, reset } =
    useForm<implementingdataFormData>({
      mode: "onTouched",
      defaultValues: {
        firstName: "",
        designation: "",
        district: "",
        division: "",
        landlineNumber: {
          number: "",
          countryCode: "+91",
        },
        phoneNumber: {
          number: "",
          countryCode: "+91",
        },
        email: "",
      },
    });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: any) => {
    addImplementingOfficer.mutate({
      id,
      implementingOfficerDetails: {
        firstName: data.firstName,
        designation: data.designation,
        district: data.district,
        division: data.division,
        landlineNumber: data.landlineNumber.number,
        phoneNumber: data.phoneNumber.number,
        email: data.email,
        newIOJoiningDate: new Date(),
      },
    });
  };

  const handleResponseModal = () => {
    if (openModal.type === "success") {
      navigate(path.login, { state: { userId: response["User name"] } });
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
    reset();
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(handleFormSubmit)}
      sx={{ padding: "50px" }}
    >
      <>
        <Box {...styles("projectName")}>Edit Implementing Officer Details</Box>
        <Box {...styles("wrapper")}>
          <Box {...styles("inputPopup")}>
            <Input
              name={`firstName`}
              label="Name"
              placeholder="Enter your full name"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("inputPopup")}>
            <Input
              label="Designation"
              placeholder="Enter Designation"
              name={`designation`}
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("inputPopup")}>
            <Input
              label="District"
              placeholder="Enter Designation"
              name={`district`}
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
              readOnly={true}
            />
          </Box>
          <Box {...styles("inputPopup")}>
            <Input
              label="Division"
              placeholder="Enter Designation"
              name="division"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
              readOnly={true}
            />
          </Box>
          <Box {...styles(["inputPopup", "withHelperText"])}>
            <PhoneNumber
              control={control}
              errors={errors}
              name={`landlineNumber`}
              label="Contact Number (Landline)"
              isRequired={false}
            />
          </Box>
          <Box {...styles(["inputPopup", "withHelperText"])}>
            <PhoneNumber
              control={control}
              errors={errors}
              name={`phoneNumber`}
              label="Phone Number"
              isRequired={true}
            />
          </Box>
          <Box {...styles(["inputPopup", "withHelperText"])}>
            <Input
              name={`email`}
              label="Email"
              placeholder="Enter your email"
              control={control}
              rules={{
                required: errorMessage.required,
                pattern: {
                  value: regex.email,
                  message: errorMessage.invalidEmail,
                },
              }}
              errors={errors}
              height="52px"
            />
          </Box>
        </Box>
      </>
      <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <Button
          text="Submit"
          type="submit"
          disabled={!isValid}
          customStyles={{
            root: {
              fontSize: "16px",
            },
          }}
        />
      </Box>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      ></SuccessModal>
    </Box>
  );
};

export default EditImplementingOfficer;
