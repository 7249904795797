import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    fontSize: "17px",
    marginBottom: "3px",
  },

  label: {
    fontWeight: "600",
    flexBasis: "40%",
    whiteSpace: "wrap",
  },

  value: {
    marginLeft: "4px",
    flexBasis: "46%",
    wordBreak: "break-word",
    fontWeight: "600",
  },

  date: {
    marginLeft: "45px",
    flexBasis: "30%",
    wordBreak: "break-word",
    fontSize: "14px",
  },
});

export default styles;
