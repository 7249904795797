import { Box, CircularProgress } from "@mui/material";
import SideDrawer from "src/components/common/SideDrawer";
import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import PlotAreaDetails from "./NewForms/PlotAreaDetails/index";
import PreviewForm from "./NewForms/PreviewForm";
import ProjectAreaDetails from "./NewForms/ProjectAreaDetails";
import SelectProject from "./NewForms/SelectProject";
import WaterStructureDetails from "./NewForms/WaterStructureDetails";
import PlantationBlock from "./ProjectForms/plantationBlock";
import ProjectBaselineData from "./ProjectForms/ProjectBaselineData";
import ProjectDetails from "./ProjectForms/ProjectDetails";
import ProjectGridForm from "./ProjectForms/ProjectGridForm";
import ProjectImageForm from "./ProjectForms/ProjectImageForm";
import defaultStyles, { StylesClasses } from "./styles";

type RefetchFunction = (variables?: unknown) => Promise<void>;

type CreateProjectProps = {
  loading?: boolean;
  onSideBarClose: () => void;
  refetch?: RefetchFunction | any;
};

export const FormsMap = {
  SelectProject: SelectProject,
  // Organization: Organization,
  ProjectAreaDetails: ProjectAreaDetails,
  // AgencyDetails: AgencyDetails,
  PlotAreaDetails: PlotAreaDetails,
  // BankDetails: BankDetails,
  // GcBankDetails: GcAccountDetails,
  WaterStructureDetails: WaterStructureDetails,
  // SelfDeclaration: SelfDeclaration,
  PreviewForm: PreviewForm,
} as const;

export const NewFormMap = {
  ProjectDetails: ProjectDetails,
  ProjectBaselineData: ProjectBaselineData,
  ProjectImageForm: ProjectImageForm,
  ProjectGridForm: ProjectGridForm,
  // ProjectSuccessForm: ProjectSuccessForm,
  // PreviewForm: PreviewForm,
} as const;

const CreateProject = ({
  loading = false,
  onSideBarClose,
  refetch,
}: CreateProjectProps) => {
  // const { stepName: step, openProjectView } = useAppSelector(
  //   (state) => state.root.selectProjectType
  // );
  const {
    stepName: step,
    showModal,
    projectType,
  } = useAppSelector((state) => state.root.selectNewProjectSetup);

  const user = useAppSelector((state) => state.root.user.data.role);

  const styles = getStyles<StylesClasses>(defaultStyles);

  const Component = NewFormMap[step];

  const title =
    projectType === "TREE_PLANTATION"
      ? "Selection of land for plantation"
      : "Register Eco-Restoration blocks";

  return (
    <SideDrawer
      open={!!showModal || loading}
      onCrossClick={onSideBarClose}
      title={title}
    >
      {loading && (
        <Box {...styles("loaderContainer")}>
          <CircularProgress />
        </Box>
      )}
      {showModal && (
        <>
          {step ? (
            user === "Entity/ Green Credit Applicant" ? (
              <PlantationBlock refetch={refetch} />
            ) : (
              <Component />
            )
          ) : (
            <>Loading...</>
          )}
        </>
      )}
    </SideDrawer>
  );
};

export default CreateProject;
