import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    width: "100%",
    margin: "2% 4%",
    backgroundColor: "#EBFAF8",
  },
  autoComplete: {
    mt: "30px",
  },
  inputForm: {
    display: "flex",
    gap: "30px",
    padding: "20px",
    backgroundColor: "#EBFAF8",
    borderRadius: "16px",
  },
  inputLabel: {
    fontSize: "14px",
    mt: "15px",
  },
  inputWrapper: {
    minWidth: "100px",
    borderTopRightRadius: "0px",
    borderColor: "unset",
  },
  errorMsg: {
    bottom: "-18px",
    fontSize: "12px",
  },
  formWrapper: {
    backgroundColor: "#C8EAE5",
    padding: "20px 10px",
    mt: "40px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    border: "1px solid",
    borderColor: "#36B1A0",
  },
  formElementWrapper: {
    border: "1px solid",
    borderColor: "#FFFF",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "#EBFAF8",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  formselect: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#36B1A0;",
    boxShadow: "unset",
    borderRadius: "10px",
  },
  borderStyle: {
    borderColor: "custom.border.otpDash",
    border: "2px dashed",
    borderRadius: "12px",
    backgroundColor: "custom.background.secondaryGrey",
  },
  fileType: {
    opacity: "0.5",
    fontSize: "13px",
    fontFamily: "400",
  },
});

export default styles;
