import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Input } from "src/components/common/FormComponents";
import { formHeaders } from "src/constants/sampleData";
// import { setProjectAreaDetails, setStep } from "src/redux/slices/newProjectSetup";
import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import FormWrapper from "../FormWrapper";
// import { PlotAreaDetailsForm } from "../types";
import { setBaselineData, setStep } from "src/redux/slices/newProjectSetup";
import { PlotAreaDetailsForm } from "../newTypes";
import defaultStyles, { StylesClasses } from "./styles";
import errorMessage from "src/constants/errorMessage";

const formHeads = formHeaders["PlotAreaDetails"];

const ProjectBaselineData = () => {
  const { formData } = useAppSelector(
    (state) => state.root.selectNewProjectSetup
  );

  const { control, getValues, setValue, formState, watch, reset } =
    useForm<PlotAreaDetailsForm>({
      mode: "onTouched",
      defaultValues: formData.baselineData,
    });

  const dispatch = useDispatch();

  const { errors, isValid } = formState;
  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <FormWrapper
      isValid={isValid}
      onNext={() => {
        const formValues = getValues();
        dispatch(setBaselineData(formValues));
        dispatch(setStep("ProjectImageForm"));
      }}
      onBack={() => {
        dispatch(setStep("ProjectDetails"));
      }}
      subHeading={formHeads.details}
      currentStepNumber={2}
      // showBackBtn={!!!projectData.id}
    >
      <Box {...styles("input")}>
        <Input
          customStyles={{
            label: defaultStyles.label,
            wrapper: defaultStyles.selectWrapper,
            input: defaultStyles.multilineInput,
          }}
          typeof="textarea"
          name="edaphicDetails"
          rules={{
            required: errorMessage.required,
            maxLength: {
              value: 500,
              message: "More then 500 characters are not allowed",
            },
          }}
          control={control}
          type="text"
          placeholder="(In 500 characters)"
          label="Edaphic Details"
          multiline
          minRows={6}
        />
      </Box>

      <Box {...styles("input")}>
        <Input
          customStyles={{
            label: defaultStyles.label,
            wrapper: defaultStyles.selectWrapper,
            input: defaultStyles.multilineInput,
          }}
          name="slopeAndTerrain"
          rules={{
            required: errorMessage.required,
            maxLength: {
              value: 500,
              message: "More then 500 characters are not allowed",
            },
          }}
          control={control}
          type="text"
          placeholder="(In 500 characters)"
          label="Slope And Terrain"
          multiline
          minRows={6}
        />
      </Box>
      <Box {...styles("input")}>
        <Input
          customStyles={{
            label: defaultStyles.label,
            wrapper: defaultStyles.selectWrapper,
            input: defaultStyles.multilineInput,
          }}
          name="geologicalFeatures"
          rules={{
            required: errorMessage.required,
            maxLength: {
              value: 500,
              message: "More then 500 characters are not allowed",
            },
          }}
          control={control}
          type="text"
          placeholder="(In 500 characters)"
          label="Geological Features"
          multiline
          minRows={6}
        />
      </Box>
      <Box {...styles("input")}>
        <Input
          customStyles={{
            label: defaultStyles.label,
            wrapper: defaultStyles.selectWrapper,
            input: defaultStyles.multilineInput,
          }}
          name="hydrologicalConditions"
          rules={{
            required: errorMessage.required,
            maxLength: {
              value: 500,
              message: "More then 500 characters are not allowed",
            },
          }}
          control={control}
          type="text"
          placeholder="(In 500 characters)"
          label="Hydrological Conditions"
          multiline
          minRows={6}
        />
      </Box>
      <Box {...styles("input")}>
        <Input
          customStyles={{
            label: defaultStyles.label,
            wrapper: defaultStyles.selectWrapper,
            input: defaultStyles.multilineInput,
          }}
          name="forestType"
          rules={{
            required: errorMessage.required,
            maxLength: {
              value: 500,
              message: "More then 500 characters are not allowed",
            },
          }}
          control={control}
          type="text"
          placeholder="(In 500 characters)"
          label="Forest Type"
          multiline
          minRows={6}
        />
      </Box>
    </FormWrapper>
  );
};

export default ProjectBaselineData;
