import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  headingRow: {
    border: "1px solid #ddd",
    padding: "4px",
    textAlign: "center",
  },
  row: {
    border: "1px solid #ddd",
    padding: "4px",
    height: "20px",
    textAlign: "center",
  },
});

export default styles;
