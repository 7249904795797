import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./styles";

type FallbackProps = {
  size?: string | number;
};

const Fallback = ({ size = "5rem" }: FallbackProps) => {
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default Fallback;
