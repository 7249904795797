import { Box } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "../styles";

type SwipperHeaderProps = {
  customStyles?: CustomStyles<StylesClasses>;
  onClose: any;
  title?: string;
};

const SwipperHeader = ({
  customStyles,
  onClose,
  title = "Register a New Activity",
}: SwipperHeaderProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <Box {...styles("wrapper")} role="presentation">
      <Box {...styles("drawerWrapper")}>
        <Box {...styles("drawerTitle")}>{title}</Box>
        <Box
          {...styles("crossBtn")}
          component="img"
          src="/assets/svg/crossIcon.svg"
          onClick={onClose}
        />
      </Box>
    </Box>
  );
};

export default SwipperHeader;
