import { Box } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import Chip from "src/components/common/Chip";
import { Button } from "src/components/common/FormComponents";
import { CHIP_STATUS, PROJECT_STATUS } from "src/constants/common";
import { path } from "src/constants/path";
import defaultStyles from "../styles";

export const RenderChip = (params) => {
  return (
    <Chip
      label={PROJECT_STATUS[params.row.chipTitle]}
      status={CHIP_STATUS[params.row.status]}
      description={params.row.remarks}
      showTooltip={PROJECT_STATUS[params.row.chipTitle] === "Rejected"}
    />
  );
};

export const RenderButton = (params) => {
  return (
    <>
      <Box
        component="a"
        href={`${"/activity/details"}?${createSearchParams({
          projectId: params.id,
          projectType: params.row.data.projectType,
        })}`}
        sx={{ ...defaultStyles.renderButtonText2 }}
      >{`${params?.row?.registrationNo}`}</Box>
    </>
  );
};

export const RenderDemandDraft = (params) => {
  const navigate = useNavigate();
  return (
    <>
      {params?.row?.status === "PAYMENT_PENDING" ? (
        <Box
          component="a"
          href={`${path.demandNote}?${createSearchParams({
            id: params.id,
          })}`}
          sx={{ ...defaultStyles.renderButtonText2 }}
        >{`${"Make Payment"}`}</Box>
      ) : (
        <Box sx={{ color: "red" }}>----</Box>
      )}
    </>
  );
};

export const RenderView = (params) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        component="a"
        href={`${"/landownership-details"}?${createSearchParams({
          id: params.row.id,
        })}`}
        sx={{ ...defaultStyles.renderButtonText2 }}
      >{`${params?.row?.id}`}</Box>
    </>
  );
};
