import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    margin: "10px",
  },

  img: {
    width: "250px",
    height: "auto",
    padding: "5px",
  },

  header: {
    padding: "5px 8px",
    "& .MuiCardHeader-title": {
      fontSize: "18px",
      fontWeight: "600",
    },
    "& .MuiCardHeader-subheader": {
      fontSize: "14px",
    },
  },
});

export default styles;
