import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  formControl: {
    mt: "6px",
  },
  inputLabel: {
    position: "relative",
    borderRadius: "20px",
    mb: "0px",
  },
  select: {
    borderRadius: "8px",
    height: "43px",
  },
});

export default styles;
