import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;
const radius = "19px";

const commonStyles = {
  status: {
    borderRadius: radius,
    fontWeight: "500",
    fontSize: "12px",
    backgroundColor: "custom.background.lightBlue",
    color: "custom.text.blue",
  },
};

const styles = createStyles({
  draft: {
    ...commonStyles.status,
    backgroundColor: "custom.background.pending",
    color: "custom.text.pending",
  },
  success: {
    ...commonStyles.status,
    backgroundColor: "custom.background.success",
    color: "custom.text.success",
  },
  error: {
    ...commonStyles.status,
    backgroundColor: "custom.background.error",
    color: "custom.text.error",
  },
  inactive: {
    ...commonStyles.status,
    backgroundColor: "custom.background.grey",
    color: "custom.text.dark",
  },
  active: {
    ...commonStyles.status,
    backgroundColor: "custom.background.success",
    color: "primary.main",
  },
});

export default styles;
