import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetFormAction } from "src/redux/createActions/resetForms";
import { UserTypeFormData } from "src/types/form";

interface InitialState {
  formData: UserTypeFormData;
  roles: {
    [key: string]: string;
  };
}

const initialState: InitialState = {
  formData: {
    userType: "",
    devType: "",
  },
  roles: {},
};

const userTypeSlice = createSlice({
  name: "userType",
  initialState,
  reducers: {
    updateUserTypeForm: (state, action: PayloadAction<InitialState>) => {
      state.formData = {
        ...state.formData,
        ...action.payload.formData,
      };
      state.roles = {
        ...state.roles,
        ...action.payload.roles,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateUserTypeForm } = userTypeSlice.actions;

export default userTypeSlice.reducer;
