import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getSignUpData } from "src/adapter/signUpData";
import Checkbox from "src/components/common/FormComponents/Checkbox";
import { SuccessModal } from "src/components/common/modal";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import Snackbar from "src/components/common/Snackbar";
import { USER_TYPE } from "src/constants/common";
import { path } from "src/constants/path";
import { updateAddressDetailsForm, updateStepper } from "src/redux/actions";
import { resetFormAction } from "src/redux/createActions/resetForms";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { SnackbarType } from "src/types/common";
import { getStatement, signUp } from "src/utils/api/signUp";
import AddressForm from "./AddressForm";
import defaultStyles, { StylesClasses } from "./styles";

type Address = {
  address: string;
  city: string;
  district: string;
  state: string;
  pinCode: string;
};

export type AddressDetailsFormData = {
  currentAddress: Address;
  permanentAddress: Address;
  isAddressSame: boolean;
  selfDeclaration?: {
    [key: string]: boolean;
  };
};

const AddressDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const [showSnackbar, setShowSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
  });

  const user = useAppSelector((state) => state.root.userType.formData.devType);

  const [response, setResponse] = useState({});

  const [statements, setStatements] = useState([]);

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { formData } = useAppSelector(
    (rootState) => rootState.root.addressDetails
  );
  const {
    userType,
    personalDetails,
    aadhaarVerification,
    documentUpload,
    organizationDetails,
  } = useAppSelector((rootState) => rootState.root);

  const { control, formState, handleSubmit, watch, setValue } =
    useForm<AddressDetailsFormData>({
      mode: "onTouched",
      defaultValues: {
        ...formData,
        selfDeclaration: {},
      },
    });
  const isSameAsPermanent = watch("isAddressSame");
  const { errors, isValid } = formState;

  const permanentAddress = watch("permanentAddress");

  const { address, city, district, state, pinCode } = permanentAddress;

  useEffect(() => {
    if (isSameAsPermanent) {
      Object.keys(watch("permanentAddress")).forEach((item: keyof Address) =>
        setValue(`currentAddress.${item}`, permanentAddress[item])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSameAsPermanent, address, city, district, state, pinCode]);

  useQuery("getStatement", getStatement, {
    onSuccess: (res: AxiosResponse) => {
      setStatements(res.data);
    },
    onError: (err: AxiosError) => {},
    refetchOnMount: true,
  });

  const adminSignUp = useMutation("signUp", (data: any) => signUp(data), {
    onSuccess: (res: AxiosResponse) => {
      setResponse({ "User name": res.data.userName });
      setOpenModal({
        open: true,
        heading: "",
        subheading: "Your account has been created!",
        description:
          "Login details will be sent to your registered Email and Phone Number",
        btnText: "Login",
        type: "success",
      });
      dispatch(resetFormAction({}));
    },

    onError: (err: AxiosError) => {
      setResponse({});
      setOpenModal({
        open: true,
        heading: "Unsuccessful",
        subheading: "",
        description: err.response.data.message || "Something went wrong",
        btnText: "",
        type: "unSuccess",
      });
    },
  });

  const handleFormSubmit = (data: AddressDetailsFormData) => {
    dispatch(updateAddressDetailsForm({ ...data }));
    dispatch(updateStepper({ id: 4 }));
    const updatedFormData = getSignUpData({
      userType,
      personalDetails,
      aadhaarVerification,
      documentUpload,
      organizationDetails,
      addressDetails: data,
    });
    adminSignUp.mutate(updatedFormData);
  };

  const handleResponseModal = () => {
    if (openModal.type === "success") {
      navigate(path.login, { state: { userId: response["User name"] } });
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  // const heading =
  //   user === USER_TYPE.INDIVIDUAL ? "Address Details" : "Registered Address";

  const previousRoute =
    user === USER_TYPE.INDIVIDUAL
      ? path.aadhaarVerification
      : path.documentUpload;

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Address Details"
        previousRoute={previousRoute}
        isValid={isValid}
      >
        <Box {...styles("address")}>
          <AddressForm
            heading={
              user === USER_TYPE.INDIVIDUAL
                ? "Permanent Address"
                : "Registered Address"
            }
            name="permanentAddress"
            height="52px"
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            customStyles={{
              address: defaultStyles.address,
              wrapper: defaultStyles.addressWrapper,
            }}
          />
          <Box {...styles("addressRight")}>
            <AddressForm
              heading="Current Address"
              name="currentAddress"
              height="52px"
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              readOnly={isSameAsPermanent}
              customStyles={{
                address: defaultStyles.address,
                wrapper: defaultStyles.addressWrapper,
              }}
            />

            <Box {...styles(["input", "checkbox"])}>
              <Checkbox<AddressDetailsFormData>
                name="isAddressSame"
                control={control}
                label="Same as Permanent address."
              />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box {...styles("selfDec")}>
            Self Declarations&nbsp;
            <Box component="span" {...styles("checkBoxHeading")}>
              *
            </Box>
          </Box>

          {!!statements.length &&
            statements.map((statement) => (
              <Checkbox
                name={`selfDeclaration.${statement.name}`}
                control={control}
                label={statement.statement}
                rules={{
                  required: true,
                }}
              />
            ))}
        </Box>
      </OnboardingWrapper>
      <Snackbar
        open={!!showSnackbar.message}
        message={showSnackbar.message}
        severity={showSnackbar.severity}
        resetSnackbar={() => setShowSnackbar({ ...showSnackbar, message: "" })}
      />
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      >
        {!!response && (
          <>
            {Object.keys(response).map((item) => (
              <Box {...styles("description")}>
                {item}: {response[item]}
              </Box>
            ))}
          </>
        )}
      </SuccessModal>
    </Box>
  );
};

export default AddressDetails;
