import { Box, CircularProgress, Divider } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import MapComponent from "src/components/common/KML";
import ViewForm from "src/components/common/ViewForm";
import ViewImages from "src/components/common/ViewImage";
import { useAppSelector } from "src/redux/store";
import { CustomStyles, getStyles } from "src/styles/theme";
import { getLandDetailsById, getProjectById } from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "../styles";
import {
  dateAndTimeStamp,
  downloadSheet,
  formatToIndianCurrency,
} from "src/utils/helper";
import Card from "src/components/common/ImageCard";
import { Button } from "src/components/common/FormComponents/Buttons";

type WaterProjectProps = {
  projectId: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const LandOwnershipDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [projectData, setProjectData] = useState<any>([]);
  const [documents, setDocuments] = useState<any>();
  const [pfdLink, setPdfLink] = useState<string>();

  const location = useLocation();

  const user = useAppSelector((state) => state.root.user);

  const [searchParams] = useSearchParams();

  const [derivedArray, setDerivedArray] = useState([]);

  const userId = searchParams.get("id");

  const { data: userData, isLoading } = useQuery(
    ["userDetails", userId],
    () => getLandDetailsById(userId),
    {
      onSuccess: (res: AxiosResponse) => {
        setProjectData(res?.data);
      },
      onError: (req: AxiosError) => {},
      enabled: !!userId,
      refetchOnMount: true,
    }
  );

  const projectId = userData?.data?.projectDetail?.id;

  const { data: projectDetails, isLoading: isProjectDetailLoading } = useQuery(
    ["projects", projectId],
    () => getProjectById(projectId),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res);
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
      enabled: !!projectId,
    }
  );

  useEffect(() => {
    const extractedData = projectData?.landOwnerShipQuery?.map((item) => ({
      Name: item.user.firstName,
      Query: item.query,
      created_at: dateAndTimeStamp(item.updated_at, "IST"),
    }));
    setDerivedArray(extractedData);
  }, [projectData]);

  const exportQuery = () => {
    downloadSheet(derivedArray);
  };

  const totalArea = Number(projectData?.totalArea);

  return (
    <Box>
      {!isLoading ? (
        <DashboardWrapper
          title="Cost Estimate and Demand Note Details"
          customStyles={{
            container: { position: "relative" },
            children: { paddingBottom: "80px" },
          }}
          showBackBtn
        >
          <Box {...styles("projectName")}>
            Cost Estimate Reg.No : {userData?.data?.id}
          </Box>

          {user?.data?.userType !== "ORGANIZATION" ? (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Entity Details</Box>
              <ViewForm
                label="Entity Name"
                value={projectData?.user?.firstName}
              />
              <ViewForm label="Email" value={projectData?.user?.email} />
              <ViewForm
                label="Phone Number"
                value={`${projectData?.user?.phoneNumber}`}
              />
            </Box>
          ) : (
            <>
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>State Nodal Officer Details</Box>
                <ViewForm
                  label="Name"
                  value={projectDetails?.data?.nodalOfficer?.firstName}
                />
                <ViewForm
                  label="Email"
                  value={projectDetails?.data?.nodalOfficer?.email}
                />
                <ViewForm
                  label="Phone Number"
                  value={`${projectDetails?.data?.nodalOfficer?.phoneNumber}`}
                />
              </Box>
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Implementing Officer Details</Box>
                <ViewForm
                  label="Name"
                  value={projectDetails?.data?.implementingOfficer?.firstName}
                />
                <ViewForm
                  label="Email"
                  value={projectDetails?.data?.implementingOfficer?.email}
                />
                <ViewForm
                  label="Phone Number"
                  value={`${projectDetails?.data?.implementingOfficer?.phoneNumber}`}
                />
              </Box>
            </>
          )}
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Eco-Restoration Blocks Details</Box>
            <ViewForm
              label="Land Registration No"
              value={projectData?.projectDetail?.registrationNo}
            />
            <ViewForm
              label="Land Type"
              value={projectDetails?.data?.landType}
            />
            <ViewForm
              label="Total Land Area (Hectares)"
              value={projectDetails?.data?.landSpecification?.totalArea}
            />
            <ViewForm
              label="Land Area Requested (Hectares) "
              value={totalArea.toFixed(2)}
            />
            <ViewForm
              label="Net Planned land Area (Hectares)"
              value={projectData?.netPlantationArea}
            />
            <ViewForm
              label="State"
              value={projectDetails?.data?.address?.stateName}
            />
            <ViewForm
              label="District"
              value={projectDetails?.data?.address?.districtName}
            />
            <ViewForm
              label="Division"
              value={projectDetails?.data?.landSpecification?.division}
            />
            <ViewForm
              label="Range"
              value={projectDetails?.data?.landSpecification?.range}
            />
            <ViewForm
              label="Beat"
              value={projectDetails?.data?.landSpecification?.beat}
            />
            <ViewForm
              label="Tree Canopy Density"
              value={(+projectDetails?.data?.landSpecification
                ?.treeCanopyDensity).toFixed(2)}
            />
            <ViewForm
              label="Edaphic Details"
              value={projectDetails?.data?.landSpecification?.edaphicDetails}
            />
            <ViewForm
              label="Forest Type"
              value={projectDetails?.data?.landSpecification?.forestType}
            />
            <ViewForm
              label="Geological Features"
              value={
                projectDetails?.data?.landSpecification?.geologicalFeatures
              }
            />
            <ViewForm
              label="Hydrological Conditions"
              value={
                projectDetails?.data?.landSpecification?.hydrologicalConditions
              }
            />
            <ViewForm
              label="Slope And Terrain"
              value={projectDetails?.data?.landSpecification?.slopeAndTerrain}
            />
            <ViewForm
              label="Additional Information"
              value={projectDetails?.data?.landSpecification?.description}
            />

            <Divider sx={{ marginTop: "25px" }}></Divider>
            <Box {...styles("documentGroup")}>
              <ViewImages
                fileUrl={
                  projectDetails?.data?.documentDetail[0]?.documentUrl?.fileUrl
                }
                label={"KML File of Eco-Restoration Block"}
              />
            </Box>
            <MapComponent
              kmlUrl={
                projectDetails?.data?.documentDetail[0]?.documentUrl?.fileUrl
              }
            />
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>GeoTagged Images</Box>
            <Box {...styles("imageWrapper2")}>
              {projectDetails?.data?.images.length ? (
                <>
                  {projectDetails?.data?.images?.map((item) => (
                    <Card
                      key={item?.imageUrl?.fileUrl}
                      title={`GeoTagged Image`}
                      image={item?.imageUrl?.fileUrl}
                    />
                  ))}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          {projectDetails?.data?.projectQuery &&
            user?.data?.userType !== "ORGANIZATION" && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("infoContainer")}>
                  <Button
                    onClick={exportQuery}
                    text={""}
                    variant="outlined"
                    src="/assets/svg/export.svg"
                    customStyles={{
                      root: {
                        minWidth: "40px",
                      },
                    }}
                  />
                  <Box {...styles("heading")}>Project Query</Box>
                </Box>
                {projectDetails?.data?.projectQuery.map((item) => (
                  <Box sx={{ paddingTop: "20px" }}>
                    <ViewForm
                      label={item.user.firstName}
                      value={item.query}
                      date={dateAndTimeStamp(item.created_at, "IST")}
                    />
                  </Box>
                ))}
              </Box>
            )}
          {user.data.userType === "ORGANIZATION" ? (
            <Box>
              {(projectData?.status === "PAYMENT_PENDING" ||
                projectData?.status === "APPROVED" ||
                projectData?.status === "VERIFICATION_PENDING") && (
                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>Demand Note Details</Box>
                  <ViewForm
                    label="Eco-Restoration Cost"
                    value={`${formatToIndianCurrency(projectData?.demandNote)}`}
                  />
                  <ViewForm
                    label="Admin Charges"
                    value={`${formatToIndianCurrency(
                      projectData?.icfreCharges
                    )}`}
                  />
                  <ViewForm
                    label="Demand Note Amount"
                    value={`${formatToIndianCurrency(
                      +`${Number(projectData?.demandNote)}` +
                        +`${Number(projectData?.icfreCharges)}`
                    )}`}
                  />
                  <ViewForm
                    label="Tentative Month to Start Plantation"
                    value={`${projectData?.tentativeDateForPlantation}`}
                  />
                  <Box {...styles("formWrapper")}>
                    <Box {...styles("heading")}>
                      PDF of technically sanctioned/approved cost estimate
                    </Box>
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={projectData?.paymentBreakdownPdfImage}
                        label={""}
                      />
                    </Box>
                  </Box>
                  <Box {...styles("formWrapper")}>
                    <Box {...styles("heading")}>
                      Ms Excel copy of the cost estimate
                    </Box>
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={projectData?.paymentBreakdownImage}
                        label={""}
                      />
                    </Box>
                  </Box>
                  <Box {...styles("heading")}>
                    Update KML File at Cost Estimate
                  </Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.grantedLandImage}
                      label={""}
                    />
                  </Box>
                  {!isProjectDetailLoading && !isLoading && (
                    <MapComponent kmlUrl={projectData?.grantedLandImage} />
                  )}
                  <Box {...styles("formWrapper")}>
                    <Box {...styles("heading")}>Demand Note PDF</Box>
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={projectData?.uploadedDemandNote}
                        label={""}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              {projectData?.demandNote && (
                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>Demand Note Details</Box>
                  <ViewForm
                    label="Eco-Restoration Cost"
                    value={`${formatToIndianCurrency(projectData?.demandNote)}`}
                  />
                  <ViewForm
                    label="Admin Charges"
                    value={`${formatToIndianCurrency(
                      projectData?.icfreCharges
                    )}`}
                  />
                  <ViewForm
                    label="Demand Note Amount"
                    value={`${formatToIndianCurrency(
                      +`${Number(projectData?.demandNote)}` +
                        +`${Number(projectData?.icfreCharges)}`
                    )}`}
                  />
                  <ViewForm
                    label="Tentative Month to Start Plantation"
                    value={`${projectData?.tentativeDateForPlantation}`}
                  />
                  <Box {...styles("formWrapper")}>
                    <Box {...styles("heading")}>
                      PDF of technically sanctioned/approved cost estimate
                    </Box>
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={projectData?.paymentBreakdownPdfImage}
                        label={""}
                      />
                    </Box>
                  </Box>
                  <Box {...styles("formWrapper")}>
                    <Box {...styles("heading")}>
                      Ms Excel copy of the cost estimate
                    </Box>
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={projectData?.paymentBreakdownImage}
                        label={""}
                      />
                    </Box>
                  </Box>
                  <Box {...styles("heading")}>
                    Update KML File at Cost Estimate
                  </Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.grantedLandImage}
                      label={""}
                    />
                  </Box>
                  {!isProjectDetailLoading && !isLoading && (
                    <MapComponent kmlUrl={projectData?.grantedLandImage} />
                  )}
                  <Box {...styles("formWrapper")}>
                    <Box {...styles("heading")}>Demand Note PDF</Box>
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={projectData?.uploadedDemandNote}
                        label={""}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {projectData?.landOwnerShipQuery &&
            user?.data?.userType !== "ORGANIZATION" && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("infoContainer")}>
                  <Button
                    onClick={exportQuery}
                    text={""}
                    variant="outlined"
                    src="/assets/svg/export.svg"
                    customStyles={{
                      root: {
                        minWidth: "40px",
                      },
                    }}
                  />
                  <Box {...styles("heading")}>
                    Eco-Restoration Cost Estimate Query
                  </Box>
                </Box>
                {projectData?.landOwnerShipQuery.map((item) => (
                  <Box sx={{ paddingTop: "20px" }}>
                    <ViewForm
                      label={item.user.firstName}
                      value={item.query}
                      date={dateAndTimeStamp(item.created_at, "IST")}
                    />
                  </Box>
                ))}
              </Box>
            )}
          {projectData?.bankDetail &&
            user?.data?.userType === "ORGANIZATION" && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Transaction Details</Box>
                <ViewForm
                  label="Eco-Restoration Cost Paid"
                  value={formatToIndianCurrency(
                    projectData?.ecoRestorationCost
                  )}
                />
                <ViewForm
                  label="TDS on Eco-Restoration"
                  value={formatToIndianCurrency(
                    projectData?.ecoRestorationCostTDS
                  )}
                />
                <ViewForm
                  label="Admin Charges Paid"
                  value={formatToIndianCurrency(projectData?.adminCharges)}
                />
                <ViewForm
                  label="TDS on Admin Charges"
                  value={formatToIndianCurrency(projectData?.adminChargesTDS)}
                />
                <ViewForm
                  label="Total TDS "
                  value={formatToIndianCurrency(
                    +projectData?.ecoRestorationCostTDS +
                      +projectData?.adminChargesTDS
                  )}
                />
                <ViewForm
                  label="Total Amount Paid"
                  value={formatToIndianCurrency(projectData?.amountPaid)}
                />
                <ViewForm
                  label="Account Number"
                  value={projectData?.bankDetail?.accountNumber}
                />
                <ViewForm
                  label="Bank Name"
                  value={projectData?.bankDetail?.bankName}
                />
                <ViewForm
                  label="IFSC Code"
                  value={projectData?.bankDetail?.ifscCode}
                />
                <ViewForm
                  label="Transaction Date"
                  value={projectData?.transactionDate}
                />
                <ViewForm
                  label="Transaction Type"
                  value={projectData?.transactionType}
                />
                <ViewForm
                  label="Transaction Number"
                  value={projectData?.transactionNumber}
                />
                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>Transaction Receipt </Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.paymentImageUrl}
                      label={""}
                    />
                  </Box>
                </Box>
                <ViewForm
                  label="Additional Information"
                  value={projectData?.additionalInfo}
                />
              </Box>
            )}

          {projectData?.paymentAcknowledgmentReceipt &&
            user?.data?.userType === "ORGANIZATION" && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Payment Verification Details</Box>
                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>Payment Receipt</Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.paymentAcknowledgmentReceipt}
                      label={""}
                    />
                  </Box>
                </Box>
                <ViewForm
                  label="Payment Remark"
                  value={projectData?.paymentRemark}
                />
              </Box>
            )}

          {projectData?.remark && user?.data?.userType !== "ORGANIZATION" && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Admin Remarks</Box>
              <ViewForm label="Remarks" value={projectData?.remark} />
            </Box>
          )}
        </DashboardWrapper>
      ) : (
        <Box {...styles("loading")}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default LandOwnershipDetails;
