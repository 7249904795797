import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import Header from "src/components/common/Header";
import SideBar from "src/components/common/SideBar";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";

const DefaultLayout = () => {
  const styles = getStyles(defaultStyles);
  return (
    <>
      <Box {...styles("container")}>
        <SideBar />
        <Box {...styles("outletWrapper")}>
          <Header />
          <Box {...styles("outletContainer")}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DefaultLayout;
