import { Box, Grid, Typography } from "@mui/material";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useForm, FormProvider } from "react-hook-form";
import {
  Button,
  FileUpload,
  Input,
  Select,
} from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import Checkbox from "src/components/common/FormComponents/Checkbox";
import { useMutation } from "react-query";
import { postMonitoringComponentDetail } from "src/utils/api/dashboard";
import { useSearchParams } from "react-router-dom";
import { SuccessModal } from "src/components/common/modal";
import { useState } from "react";
import { useComponentName } from "src/hook/monitoring-component";
import { ENUM_MONITORING_TYPE, SIGNBOARD_TYPE } from "src/constants/common";

type FormType = {
  pitsRemark: string;
  signBoardRemark: string;
  siteClearingRemark: string;
  soilImprovementRemark: string;
  preparationOfSiteMp: boolean;
  soilAnalysis: boolean;
  isSignBoardPlaced: boolean;
  isSignBoardAccordingToTemplate: boolean;
  lantanaRemoval: boolean;
  otherWeedRemoval: boolean;
  isSoilImportedFromOtherPlace: boolean;
  isFertilizerApplied: boolean;
  isOrganicManureApplied: boolean;
  isMulchingCarriedOut: boolean;
  signBoardType: string;
  numberOfPits: number;
  pitSpacing: number;
  pitSize: string;
  images: {
    [key: string]: string[];
  }[];
};

const ImageLabel = [
  {
    label: "Pitting",
    value: "pitting",
  },
  {
    label: "Sign Board",
    value: "signBoard",
  },
  {
    label: "Site Clearing",
    value: "siteClearing",
  },
  {
    label: "Soil Improvement",
    value: "soilImprovement",
  },
];

export function LandPreparationForm({ param, yearMonth }: any) {
  const styles = getStyles(defaultStyles);

  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");

  const form = useForm<FormType>({
    defaultValues: {
      preparationOfSiteMp: false,
      soilAnalysis: false,
      isSignBoardPlaced: false,
      isSignBoardAccordingToTemplate: false,
      lantanaRemoval: false,
      otherWeedRemoval: false,
      isSoilImportedFromOtherPlace: false,
      isFertilizerApplied: false,
      isOrganicManureApplied: false,
      isMulchingCarriedOut: false,
    },
  });
  const { setComponentName } = useComponentName();

  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    heading: "",
    subHeading: "",
  });
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };
  const handlePopUpClose = () => {
    if (openModal.type === "success") {
      setComponentName(undefined);
    } else {
      handleModalClose();
    }
  };
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
    watch,
  } = form;

  const { mutate } = useMutation(
    ["landPreparation"],
    ({ data }: { data: any }) => postMonitoringComponentDetail(data),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          type: "success",
          heading: "Success",
          subHeading: "Land Preparation Detail Submitted Successfully",
        });
      },
      onError: () => {
        setOpenModal({
          open: true,
          type: "unSuccess",
          heading: "Failed",
          subHeading: "Something Went Wrong",
        });
      },
    }
  );
  const onSubmit = (data: any) => {
    // const imageKeys = data.images.map((item) => item.key);
    const pitImage = data?.images[0];
    const signImage = data?.images[1];
    const siteImage = data?.images[2];
    const soilImage = data?.images[3];

    let pitImageValue = pitImage ? Object.entries(pitImage)[0][1] : null;
    let signImageValue = signImage ? Object.entries(signImage)[0][1] : null;
    let siteImageValue = siteImage ? Object.entries(siteImage)[0][1] : null;
    let soilImageValue = soilImage ? Object.entries(soilImage)[0][1] : null;

    if (Array.isArray(pitImageValue)) {
      pitImageValue = pitImageValue.map((item) => item.key);
    }
    if (Array.isArray(signImageValue)) {
      signImageValue = signImageValue.map((item) => item.key);
    }

    if (Array.isArray(siteImageValue)) {
      siteImageValue = siteImageValue.map((item) => item.key);
    }
    if (Array.isArray(soilImageValue)) {
      soilImageValue = soilImageValue.map((item) => item.key);
    }

    // console.log("land preparation", (Object.entries(data?.images[0])[0][1] as Array).map((item)=>item.file));
    mutate({
      data: {
        type: ENUM_MONITORING_TYPE.LAND_PREPARATION,
        year: param.year,
        month: param.month,
        landOwnershipEpid,
        yearMonth: yearMonth,
        numberOfPits: parseInt(data.numberOfPits, 10),
        pitSpacing: parseInt(data.pitSpacing, 10),
        pitsRemark: data.pitsRemark,
        signBoardRemark: data.signBoardRemark,
        siteClearingRemark: data.siteClearingRemark,
        soilImprovementRemark: data.soilImprovementRemark,
        preparationOfSiteMp: data.preparationOfSiteMp,
        soilAnalysis: data.soilAnalysis,
        isSignBoardPlaced: data.isSignBoardPlaced,
        isSignBoardAccordingToTemplate: data.isSignBoardAccordingToTemplate,
        lantanaRemoval: data.lantanaRemoval,
        otherWeedRemoval: data.otherWeedRemoval,
        isSoilImportedFromOtherPlace: data.isSoilImportedFromOtherPlace,
        isFertilizerApplied: data.isFertilizerApplied,
        isOrganicManureApplied: data.isOrganicManureApplied,
        isMulchingCarriedOut: data.isMulchingCarriedOut,
        signBoardType: data.signBoardType,
        pitSize: data.pitSize,
        pitsImages: pitImageValue,
        signBoardImages: signImageValue,
        siteClearingImages: siteImageValue,
        soilImprovementImages: soilImageValue,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        {...styles("formWrapper")}
      >
        <Box {...styles("formElementWrapper")}>
          <Box sx={{ fontSize: "20px", fontWeight: "600" }}>Pitting</Box>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: styles("inputLabel").sx,
                  wrapper: styles("inputWrapper").sx,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="numberOfPits"
                control={control}
                errors={errors}
                placeholder="Number of Pits"
                label="Number of Pits"
                type="number"
                rules={{ required: errorMessage.required }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: styles("inputLabel").sx,
                  wrapper: styles("inputWrapper").sx,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="pitSpacing"
                control={control}
                errors={errors}
                placeholder="Pits Spacing (mxm)"
                label="Pits Spacing (mxm)"
                type="number"
                rules={{
                  required: errorMessage.required,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: styles("inputLabel").sx,
                  wrapper: styles("inputWrapper").sx,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="pitSize"
                control={control}
                errors={errors}
                placeholder="Pits Size (m³)"
                label="Pits Size (m³)"
                rules={{
                  required: errorMessage.required,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: styles("inputLabel").sx,
                  wrapper: styles("inputWrapper").sx,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="pitsRemark"
                control={control}
                errors={errors}
                placeholder="Pits Observations"
                label="Pits Observations"
                rules={{
                  required: errorMessage.required,
                }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* TYPES OF SIGN BOARD */}
        <Box {...styles("formElementWrapper")}>
          <Box sx={{ fontSize: "20px", fontWeight: "600" }}>Sign Board</Box>
          <Checkbox
            label="Sign Board Placed"
            name="isSignBoardPlaced"
            customStyles={{ wrapper: { width: "fit-content" } }}
          />
          {watch("isSignBoardPlaced") && (
            <Select
              name="signBoardType"
              placeholder="Type of Sign Board"
              label="Type of Sign Board"
              control={control}
              errors={errors}
              options={SIGNBOARD_TYPE}
            />
          )}
          <Checkbox
            label="Sign Board According to Template"
            name="isSignBoardAccordingToTemplate"
            customStyles={{ wrapper: { width: "fit-content" } }}
            disabled={
              !!watch("signBoardType") && !!watch("isSignBoardPlaced")
                ? false
                : true
            }
          />
          <Input
            customStyles={{
              label: styles("inputLabel").sx,
              wrapper: styles("inputWrapper").sx,
              input: {
                borderColor: "#36B1A0;",
                boxShadow: "unset",
              },
              errorMsg: styles("errorMsg").sx,
            }}
            name="signBoardRemark"
            control={control}
            errors={errors}
            label="Sign Board Observations"
            placeholder="Sign Board Observations"
            rules={{
              required: errorMessage.required,
            }}
          />
        </Box>

        {/* SITE CLEAREING */}
        <Box {...styles("formElementWrapper")}>
          <Box sx={{ fontSize: "20px", fontWeight: "600" }}>Site Clearing</Box>

          <Checkbox
            label="Lentana Is Removed"
            name="lantanaRemoval"
            customStyles={{ wrapper: { width: "fit-content" } }}
          />
          <Checkbox
            label="Other Weeds Are Removed"
            name="otherWeedRemoval"
            customStyles={{ wrapper: { width: "fit-content" } }}
          />
          <Input
            customStyles={{
              label: styles("inputLabel").sx,
              wrapper: styles("inputWrapper").sx,
              input: {
                borderColor: "#36B1A0;",
                boxShadow: "unset",
              },
              errorMsg: styles("errorMsg").sx,
            }}
            name="siteClearingRemark"
            control={control}
            errors={errors}
            label="Site Clearing Observations"
            placeholder="Site Clearing Observations"
            rules={{
              required: errorMessage.required,
            }}
          />
        </Box>

        {/* SOIL IMPROVEMENT */}
        <Box {...styles("formElementWrapper")}>
          <Box sx={{ fontSize: "20px", fontWeight: "600" }}>
            Soil Improvement
          </Box>

          <Checkbox
            label="Soil Imported from Other Place"
            name="isSoilImportedFromOtherPlace"
            customStyles={{ wrapper: { width: "fit-content" } }}
          />
          <Checkbox
            label="Fertilizers Applied"
            name="isFertilizerApplied"
            customStyles={{ wrapper: { width: "fit-content" } }}
          />
          <Checkbox
            label="Organic Manure Applied"
            name="isOrganicManureApplied"
            customStyles={{ wrapper: { width: "fit-content" } }}
          />
          <Checkbox
            label="Mulching Carried Out"
            name="isMulchingCarriedOut"
            customStyles={{ wrapper: { width: "fit-content" } }}
          />
          <Input
            customStyles={{
              label: styles("inputLabel").sx,
              wrapper: styles("inputWrapper").sx,
              input: {
                borderColor: "#36B1A0;",
                boxShadow: "unset",
              },
              errorMsg: styles("errorMsg").sx,
            }}
            name="soilImprovementRemark"
            control={control}
            errors={errors}
            label="Soil Improvement Observations"
            placeholder="Soil Improvement Observations"
            rules={{
              required: errorMessage.required,
            }}
          />
        </Box>

        <Box {...styles("formElementWrapper")}>
          <Box sx={{ fontSize: "18px", fontWeight: "600" }}>
            Upload GeoTagged Images
          </Box>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={{ margin: "20px 20px " }}
          >
            <Grid item xs={12} md={6}>
              {ImageLabel?.map((item, index) => (
                <FileUpload
                  accept="image/jpeg, image/png, image/webp, image/jpg"
                  name={`images.${index}.${item.value}`}
                  label={`Upload ${item.label} GeoTagged Images (Minimum 2 images are required )`}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                  maxLimit={10}
                  multiple={true}
                  required={true}
                  supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
                  trigger={trigger}
                  rules={{
                    validate: {
                      isLength: (value) => {
                        return value && value["length"] >= 2;
                      },
                    },
                  }}
                  dragDropHeading=""
                />
              ))}
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button text="Submit" type="submit" disabled={!isValid} />
        </Box>

        <SuccessModal
          open={openModal.open}
          heading={openModal.heading}
          subheading={openModal.subHeading}
          closeOnBackDrop
          type={openModal.type}
          handleModalClose={() => handlePopUpClose()}
        />
      </Box>
    </FormProvider>
  );
}
