import { saveAs } from "file-saver";
import { RoleIds } from "src/constants/common";
import { path } from "src/constants/path";
import { ProjectFormDataTypes } from "src/redux/slices/newProjectSetup/types";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";

type GetRoutes = {
  userType: string;
  devType: string;
};

export const capitalize = (label: string) =>
  label?.charAt(0).toUpperCase() + label.slice(1);

export const getFormattedDate = (date: string, isDashed?: boolean) => {
  const newDate = new Date(date);
  const month =
    newDate.getUTCMonth() + 1 < 10
      ? `0${newDate.getUTCMonth() + 1}`
      : `${newDate.getUTCMonth() + 1}`;

  const day =
    newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();

  const year = newDate.getFullYear();

  if (isDashed) {
    return `${year}-${month}-${day}`;
  }
  return `${day}/${month}/${year}`;
};

export const getRoutes = (data: GetRoutes) => {
  const { userType, devType } = data;

  const routeConfig = {
    "Implementing Agency": path.agencyDetails,
    "Entity/ Green Credit Applicant": path.entityDetails,
  };
  return routeConfig[userType];
};

export const localDateToIso = (inputDate: string) => {
  const dateObject = new Date(inputDate);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  const seconds = dateObject.getSeconds().toString().padStart(2, "0");
  const milliseconds = dateObject.getMilliseconds().toString().padStart(3, "0");

  const timezoneOffsetMinutes = dateObject.getTimezoneOffset();

  const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffsetMinutes / 60))
    .toString()
    .padStart(2, "0");
  const timezoneOffsetSign = timezoneOffsetMinutes < 0 ? "+" : "-";

  const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneOffsetSign}${timezoneOffsetHours}:00`;

  return isoDateString;
};

export const getISODate = (date: string) => {
  if (!!date) {
    const [year, month, day] = date?.split("-").map(Number);

    const newDate = new Date(year, month - 1, day);

    const utcFormat = newDate.toISOString();

    return utcFormat;
  }

  return undefined;
};

export const dateAndTimeStamp = (dateStr: string, timeZone = "UTC") => {
  const date = new Date(dateStr); // Assuming you have a Date object

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    // fractionalSecondDigits: false,
    hour12: true,
    timeZone: timeZone, // Specify the desired time zone, for example, 'America/New_York'
  };

  const formattedDate = date.toLocaleString("en-US", options);

  const [newDate, time] = formattedDate.split(",");

  const [month, day, year] = newDate.split("/");

  return `${day}/${month}/${year}, ${time}`;
};

export const getSignUpData = (data: any, addressDetails: any) => {
  const type = data.userType.formData.devType;
  const {
    userType,
    personalDetails,
    aadhaarVerification,
    documentUpload,
    organisationDetails,
  } = data;
  if (type === "ORGANIZATION") {
    return {
      userType: userType.formData.devType,
      role: userType.formData.userType,
      roleId: RoleIds[userType.formData.userType],
      firstName: organisationDetails.formData.AuthorisedRepresentativeName, //  representativeName
      email: organisationDetails.formData.email,
      countryCode: organisationDetails.formData.phone.countryCode,
      phoneNumber: organisationDetails.formData.phone.number,
      password: organisationDetails.formData.password,
      isAddressSame: !!addressDetails.isAddressSame,
      ...(!addressDetails.isAddressSame && {
        currentAddress: {
          addressLine1: addressDetails?.currentAddress.address,
          city: addressDetails?.currentAddress.city,
          district: addressDetails?.currentAddress.district,
          state: addressDetails?.currentAddress.state,
          country: "india",
          isoCode: "INR",
          zipCode: addressDetails?.currentAddress.pinCode,
        },
      }),

      organizationDetail: {
        organizationName: organisationDetails.formData.organisationName,
        organizationType: organisationDetails.formData.organisationType,
        organizationTypeId: organisationDetails.formData.organisationType,
        representativeDesignation:
          organisationDetails.formData.AuthorisedRepresentativeDesignation,

        documentDetail: [
          {
            type: documentUpload.formData.documentType,
            serialNo: documentUpload.formData.documentImage[0]?.key,
            frontUrl: documentUpload.formData.documentImage[0]?.file,
            backUrl: documentUpload.formData.documentImage[0]?.file,
            isVerified: false,
            validFrom: getISODate(documentUpload.formData.registration),
          },
          ...organisationDetails.formData.organisationCertificates.map(
            (certificate) => ({
              type: "CERTIFICATE",
              serialNo: certificate.key,
              frontUrl: certificate.file,
              isVerified: false,
            })
          ),
        ],
      },
      permanentAddress: {
        addressLine1: addressDetails?.permanentAddress.address,
        village: "",
        city: addressDetails?.permanentAddress.city,
        district: addressDetails?.permanentAddress.district,
        state: addressDetails?.permanentAddress.state,
        country: "india",
        isoCode: "INR",
        zipCode: addressDetails?.permanentAddress?.pinCode,
      },
      selfDeclaration: Object.keys(addressDetails.selfDeclaration).map(
        (statement) => ({
          name: statement,
          isAccepted: addressDetails.selfDeclaration[statement],
        })
      ),
    };
  }

  return {
    userType: userType.formData.devType,
    role: userType.formData.userType,
    roleId: RoleIds[userType.formData.userType],
    firstName: personalDetails.formData.fullName,
    email: personalDetails.formData.email,
    countryCode: personalDetails.formData.phone.countryCode,
    phoneNumber: personalDetails.formData.phone.number,
    password: personalDetails.formData.password,
    isAddressSame: !!addressDetails.isAddressSame,
    permanentAddress: {
      addressLine1: addressDetails?.permanentAddress.address,
      // addressLine2: "ddsadas",
      // village: "dsada",
      city: addressDetails?.permanentAddress.city,
      district: addressDetails?.permanentAddress.district,
      state: addressDetails?.permanentAddress.state,
      country: "india",
      isoCode: "INR",
      zipCode: addressDetails.permanentAddress.pinCode, // pin code
      // latitude: "0.3",
      // longitude: "0.7",
    },
    ...(!addressDetails.isAddressSame && {
      currentAddress: {
        addressLine1: addressDetails?.currentAddress.address,
        // addressLine2: "ddsadas",
        // village: "dsada",
        city: addressDetails?.currentAddress.city,
        district: addressDetails?.currentAddress.district,
        state: addressDetails?.currentAddress.state,
        country: "india",
        isoCode: "INR",
        zipCode: addressDetails.permanentAddress.pinCode,
      },
    }),
    aadhaarDetails: {
      type: "AADHAAR",
      serialNo: aadhaarVerification.formData.aadhaarNumber,
      frontUrl: aadhaarVerification.formData.aadhaarCard[0]?.file,
      backUrl: aadhaarVerification.formData.aadhaarCardBack[0]?.file,
      isVerified: aadhaarVerification.formData.isAadhaarVerified,
    },
    selfDeclaration: Object.keys(addressDetails.selfDeclaration).map(
      (statement) => ({
        name: statement,
        isAccepted: addressDetails.selfDeclaration[statement],
      })
    ),
  };
};

export const optionTypeFormatter = (arr, key = false) => {
  if (!arr?.length) return [];
  return arr?.map(({ name, id, isActive }) => {
    return {
      label: name,
      value: key ? name : id,
      isActive: isActive,
      id: id,
    };
  });
};

export const localDateConverter = (dateString: string) => {
  const date = new Date(dateString);
  // return date.toLocaleString();
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear()
  );
};

export const dateFiveYearsAgo = () => {
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  currentDate.setFullYear(currentDate.getFullYear() - 5);
  return currentDate;
};

export const transformActivityData = (
  formData: ProjectFormDataTypes,
  otherDetails: {
    totalNoOfGrids: number;
    landFreeFromEncumbrances: boolean;
  }
) => {
  const initialDetails = formData.initialDetails;
  const baselineData = formData.baselineData;
  const uploadedIds = formData.uploadedIds;
  const uploadImages = formData.uploadedImages;

  return {
    name: "Land Based Project",
    projectType: "LAND_LEASED",
    landArea: Number(initialDetails.areaSize),
    areaUnit: "ha",
    landTypeId: initialDetails.kindOfDegradedLand,
    landSpecification: {
      ...baselineData,
      numberOfGrid: Number(otherDetails.totalNoOfGrids),
      divisionId: initialDetails.division,
      compartmentNumber: Number(initialDetails.compartment),
      beat: initialDetails.beta,
      range: initialDetails.range,
      treeCanopyDensity: +initialDetails.treeCanopyDensity,
      totalArea: Number(initialDetails.areaSize),
      description: otherDetails.totalNoOfGrids,
      grossArea: Number(initialDetails.grossareaSize),
    },
    address: {
      district: initialDetails.districtName,
      state: initialDetails.stateName,
      latitude:
        initialDetails?.coordinates?.lat == ""
          ? null
          : initialDetails?.coordinates?.lat,
      longitude:
        initialDetails?.coordinates?.long == ""
          ? null
          : initialDetails?.coordinates?.long,
      khasraNo: initialDetails.khasraNumber,
    },
    documentDetail: [
      {
        documentUrl: uploadedIds.gpsBoundaries.id,
        type: "LAND_KML_FILE",
      },
    ],
    images: uploadImages.map((item) => item.key),
  };
};

export function downloadSheet(data: any) {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  saveAs(blob, "data.xlsx");
}

export function filterObject(obj) {
  const result = {};
  for (const key in obj) {
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      const nestedObj = filterObject(obj[key]);
      if (Object.keys(nestedObj).length !== 0) {
        result[key] = nestedObj;
      }
    } else if (obj[key] !== "") {
      result[key] = obj[key];
    }
  }
  return result;
}

export const formatDataForBarChart = (stats) => {
  return stats?.map((stat) => ({
    name: stat.description,
    uv: stat.number,
    pv: Number(stat.totalArea),
    amt: stat.totalArea,
  }));
};

export const formatToIndianCurrency = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return formattedNumber.replace("₹", "₹");
};

export async function exportEcoRestorationTableToXlsx(data, header, topHeader) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("DashboardTable");

  // Define styles
  const centerStyle = {
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  const greyHeaderStyle = {
    fill: {
      type: "pattern" as const,
      pattern: "solid" as const,
      fgColor: { argb: "FFDDDDDD" as const },
    },
    font: { bold: true },
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  // Add top header row and apply styles and merges
  let topHeaderColStart = 1;
  topHeader.forEach((headerTitle, index) => {
    worksheet.getCell(1, topHeaderColStart).value = headerTitle;
    worksheet.getCell(1, topHeaderColStart).style = greyHeaderStyle;
    worksheet.mergeCells(1, topHeaderColStart, 1, topHeaderColStart + 1); // Merging cells for the top header
    topHeaderColStart += 2; // Move to the next pair of columns
  });

  // Add and style header row
  const headerRowIndex = 2; // The header row will be the second row
  worksheet.addRow(header); // Add header values
  header.forEach((item, index) => {
    worksheet.getCell(headerRowIndex, index + 1).style = greyHeaderStyle;
  });

  // Add data rows with center alignment
  data.forEach((row) => {
    worksheet
      .addRow([
        row.sno,
        row.state,
        row.totalNumberRegistered,
        row.totalAreaRegistered,
        row.totalNumberNodalVerificationPending,
        row.totalAreaNodalVerificationPending,
        row.totalNumberVerificationPending,
        row.totalAreaVerificationPending,
        row.totalNumberNodalUnderQuery,
        row.totalAreaNodalUnderQuery,
        row.totalNumberUnderQuery,
        row.totalAreaUnderQuery,
        row.totalNumberApproved,
        row.totalAreaApproved,
        row.totalNumberRejected,
        row.totalAreaRejected,
      ])
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = centerStyle;
      });
  });

  // Generate the file and save it
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "DashboardTable.xlsx"
  );
}

export async function exportTableToXlsx(data, header, topHeader) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("DashboardTable");

  // Define styles
  const centerStyle = {
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  const greyHeaderStyle = {
    fill: {
      type: "pattern" as const,
      pattern: "solid" as const,
      fgColor: { argb: "FFDDDDDD" as const },
    },
    font: { bold: true },
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  // Add top header row and apply styles and merges
  let topHeaderColStart = 1;
  topHeader.forEach((headerTitle, index) => {
    worksheet.getCell(1, topHeaderColStart).value = headerTitle;
    worksheet.getCell(1, topHeaderColStart).style = greyHeaderStyle;
    worksheet.mergeCells(1, topHeaderColStart, 1, topHeaderColStart + 1); // Merging cells for the top header
    topHeaderColStart += 2; // Move to the next pair of columns
  });

  // Add and style header row
  const headerRowIndex = 2; // The header row will be the second row
  worksheet.addRow(header); // Add header values
  header.forEach((item, index) => {
    worksheet.getCell(headerRowIndex, index + 1).style = greyHeaderStyle;
  });

  data.forEach((row) => {
    worksheet
      .addRow([
        row.sno,
        row.state,
        row.totalNumberRegistered,
        row.totalAreaRegistered,
        row.totalNumberGenerateDemandNote,
        row.totalAreaGenerateDemandNote,
        row.totalNumberNodalUnderQuery,
        row.totalAreaNodalUnderQuery,
        row.totalNumberVerifyDemandNote,
        row.totalAreaVerifyDemandNote,
        row.totalNumberUnderQuery,
        row.totalAreaUnderQuery,
        row.totalNumberPaymentPending,
        row.totalAreaPaymentPending,
        row.totalNumberPaymentDone,
        row.totalAreaPaymentDone,
        row.totalNumberDemandNoteGenerated,
        row.totalAreaDemandNoteGenerated,
      ])
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = centerStyle;
      });
  });

  // Generate the file and save it
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "DashboardTable.xlsx"
  );
}

export async function exportEntityTableToXlsx(data, header, topHeader) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("DashboardTable");

  // Define styles
  const centerStyle = {
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  const greyHeaderStyle = {
    fill: {
      type: "pattern" as const,
      pattern: "solid" as const,
      fgColor: { argb: "FFDDDDDD" as const },
    },
    font: { bold: true },
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  // Add top header row and apply styles and merges
  let topHeaderColStart = 1;
  topHeader.forEach((headerTitle, index) => {
    worksheet.getCell(1, topHeaderColStart).value = headerTitle;
    worksheet.getCell(1, topHeaderColStart).style = greyHeaderStyle;
    worksheet.mergeCells(1, topHeaderColStart, 1, topHeaderColStart + 1); // Merging cells for the top header
    topHeaderColStart += 2; // Move to the next pair of columns
  });

  // Add and style header row
  const headerRowIndex = 2; // The header row will be the second row
  worksheet.addRow(header); // Add header values
  header.forEach((item, index) => {
    worksheet.getCell(headerRowIndex, index + 1).style = greyHeaderStyle;
  });

  data.forEach((row) => {
    worksheet
      .addRow([
        row.sno,
        row.state,
        row.totalNumber,
        row.totalArea,
        row.totalNumberDemandNotePending,
        row.totalAreaDemandNotePending,
        row.totalNumberPaymentPending,
        row.totalAreaPaymentPending,
        row.totalNumberApproved,
        row.totalAreaApproved,
      ])
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = centerStyle;
      });
  });

  // Generate the file and save it
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "DashboardTable.xlsx"
  );
}
