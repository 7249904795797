import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Input } from "src/components/common/FormComponents";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { updateStepper } from "src/redux/actions";
import { updateEntityDetails } from "src/redux/slices/onboarding/entityDetail";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { getStates } from "src/utils/api/roles";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

import { useState } from "react";

import { useQuery } from "react-query";
import Password from "src/components/common/FormComponents/Password";

export type entityDetailsFormData = {
  entityName: string;
  state: string;
  streetAddress: string;
  district: string;
  city: string;
  password: string;
};

const EntityDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [stateOptions, setStateOptions] = useState([]);

  const { formData } = useAppSelector((state) => state.root.entityDetails);

  const { password: passwordRegex } = regex;

  const { control, formState, handleSubmit, getValues, setValue, watch } =
    useForm<entityDetailsFormData>({
      mode: "onTouched",
      defaultValues: {
        ...formData,
      },
    });

  const { data } = useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStateOptions(
        data.data.map((item) => ({
          label: item.name,
          value: item.name,
        }))
      );
    },
    refetchOnMount: true,
  });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: entityDetailsFormData) => {
    dispatch(updateEntityDetails({ ...data }));
    dispatch(updateStepper({ id: 2 }));
    navigate(path.organizationDetails);
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Entity Details"
        previousRoute={path.onboarding}
        isValid={isValid}
      >
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Input
              name="entityName"
              label="Entity Name (Company Name)"
              placeholder="Enter your Entity Name"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          {/* <Box {...styles("input")}>
            <Select
              name="state"
              label="State/UT"
              control={control}
              errors={errors}
              height="52px"
              customStyle={{ wrapper: defaultStyles.selectWrapper }}
              rules={{ required: errorMessage.required }}
              options={stateOptions}
            />
          </Box> */}
          <Box {...styles("input")}>
            <Input
              name="streetAddress"
              label="Address (Company Address)"
              placeholder="Enter Street Address"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          {/* <Box {...styles("input")}>
            <Input
              name="city"
              label="City"
              placeholder="Enter your City"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box> */}
          {/* <Box {...styles("input")}>
            <Input
              name="district"
              label="District"
              placeholder="Enter your District"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box> */}
          <Box {...styles("input")}>
            <Password
              name="password"
              label="Password"
              placeholder="Enter your password"
              control={control}
              getValues={getValues}
              showHelperText
              customStyles={{
                customError: defaultStyles.pwdErrorMsg,
              }}
              height="52px"
              rules={{
                required: true,
                validate: {
                  isLength: (value: string) => value && value.length >= 8,
                  isUpper: (value) =>
                    value && passwordRegex.isUpper.test(value.toString()),
                  isLower: (value) =>
                    value && passwordRegex.isLower.test(value.toString()),
                  isNum: (value) =>
                    value && passwordRegex.isNum.test(value.toString()),
                  hasSpecialCharacter: (value) =>
                    value &&
                    passwordRegex.hasSpecialCharacter.test(value.toString()),
                },
              }}
              errors={errors}
            />
          </Box>
        </Box>
      </OnboardingWrapper>
    </Box>
  );
};

export default EntityDetails;
