import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Input, PhoneNumber } from "src/components/common/FormComponents";
import Password from "src/components/common/FormComponents/Password";
import { OTP } from "src/components/common/modal";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import { OtpType } from "src/constants/common";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { updatePersonDetailsForm, updateStepper } from "src/redux/actions";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type PersonalDetailsFormData = {
  fullName: string;
  email: string;
  phone: {
    countryCode: string;
    number: string;
  };
  password: string;
  verifyPhone: boolean;
  verifyEmail: boolean;
  emailOtp: string;
  phoneOtp: string;
};

const PersonalDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { formData } = useAppSelector((state) => state.root.personalDetails);

  const { password: passwordRegex } = regex;

  const { control, formState, handleSubmit, getValues, setValue, watch } =
    useForm<PersonalDetailsFormData>({
      mode: "onTouched",
      defaultValues: {
        ...formData,
        password: "",
      },
    });

  const handleOtp = (otp: string) => {
    setValue("emailOtp", otp);
    setValue("verifyEmail", true);
  };

  const handlePhoneVerification = (otp: string) => {
    setValue("phoneOtp", otp);
    setValue("verifyPhone", true);
  };

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: PersonalDetailsFormData) => {
    dispatch(updatePersonDetailsForm({ ...data }));
    dispatch(updateStepper({ id: 2 }));
    navigate(path.bankDetails);
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Agency Details"
        previousRoute={path.onboarding}
        // isValid={
        //   isValid &&
        //   (!!watch("email") ? !!watch("verifyEmail") : true) &&
        //   !!watch("verifyPhone")
        // }
        isValid={isValid}
      >
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Input
              name="fullName"
              label="Full Name"
              placeholder="Enter your full name"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>

          <Box {...styles("input")}>
            <Password
              name="password"
              label="Password"
              placeholder="Enter your password"
              control={control}
              getValues={getValues}
              showHelperText
              customStyles={{
                customError: defaultStyles.pwdErrorMsg,
              }}
              height="52px"
              rules={{
                required: true,
                validate: {
                  isLength: (value: string) => value && value.length >= 8,
                  isUpper: (value) =>
                    value && passwordRegex.isUpper.test(value.toString()),
                  isLower: (value) =>
                    value && passwordRegex.isLower.test(value.toString()),
                  isNum: (value) =>
                    value && passwordRegex.isNum.test(value.toString()),
                  hasSpecialCharacter: (value) =>
                    value &&
                    passwordRegex.hasSpecialCharacter.test(value.toString()),
                },
              }}
              errors={errors}
            />
          </Box>

          <Box {...styles(["input", "withHelperText"])}>
            <PhoneNumber
              control={control}
              errors={errors}
              name="phone"
              disabled={!!watch("verifyPhone")}
            />

            <Box {...styles("fieldHelperText")}>
              OTP will be sent on your mobile number for verification
            </Box>

            <OTP
              name="phoneOtp"
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
              isVerified={!!watch("verifyPhone")}
              type={{
                type: OtpType.MOBILE,
                phoneNumber: `${watch("phone.number")}`,
              }}
              verifyOtp={handlePhoneVerification}
              heading="Verify your phone number"
              subHeading="You might have received an OTP on your phone number. Please enter the same, If not click on resend OTP."
              label="Verify Phone Number"
              customStyles={{
                label: defaultStyles.otpLabel,
              }}
              rules={{ required: false }}
            />
          </Box>

          <Box {...styles(["input", "withHelperText"])}>
            <Input
              name="email"
              label="Email"
              placeholder="Enter your email"
              control={control}
              disabled={watch("verifyEmail")}
              rules={{
                pattern: {
                  value: regex.email,
                  message: errorMessage.invalidEmail,
                },
              }}
              errors={errors}
              height="52px"
            />
            <Box {...styles("fieldHelperText")}>
              OTP will be sent on your email for verification
            </Box>
            <OTP
              name="emailOtp"
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
              verifyOtp={handleOtp}
              isVerified={watch("verifyEmail")}
              type={{
                type: OtpType.EMAIL,
                email: watch("email"),
              }}
              label="Verify Email"
              heading="Verify your email"
              subHeading="You might have received an OTP on your email. Please enter the same, If not click on resend OTP."
              customStyles={{
                label: defaultStyles.otpLabel,
              }}
              rules={{ required: false }}
            />
          </Box>
        </Box>
      </OnboardingWrapper>
    </Box>
  );
};

export default PersonalDetails;
