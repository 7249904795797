import {
  createTheme,
  responsiveFontSizes,
  SxProps,
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    [key: string]: any;
  }
  interface TypographyVariantsOptions {
    [key: string]: any;
  }
  interface Palette {
    [key: string]: any;
  }
  interface PaletteOptions {
    [key: string]: any;
  }
}

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: "#36B1A0",
    },
    secondary: {
      main: "#FDFDFD",
    },
    success: {
      main: "#073706",
    },
    error: {
      main: "#E12020",
    },
    custom: {
      background: {
        body: "#F4F5F7",
        bodyLight: "#FDFDFD",
        blue: "#3874CB",
        grey: "#AFAFAF",
        lightGrey: "#D3D3D3",
        secondaryGrey: "#FAFAFA",
        dark: "#00240E",
        pending: "#FFEAC2",
        success: "#CCFFE1",
        error: "#FFD5CC",
        badge: "#5F38F9",
        otpField: "#F0FFF6",
        hero: "#A7D8C0",
        lightBlue: "#DCEDFE",
      },

      greyish: {
        1: "#EEEEEE",
        2: "#7E7E7E",
        3: "#EAECF0",
        4: "#929295",
        5: "#E8EAF0",
        6: "#B5B5B5",
        7: "#E1E4EA",
        8: "#00103799",
        9: "#E1E4EA",
        10: "#00103799",
      },

      header: {
        background: "#BDD7DB",
      },

      border: {
        light: "#ECECEC",
        lightSecondary: "#EDEDED",
        otp: "#D3F8E2",
        otpDash: "#E2E2E2",
        background: "#EBF9F7",
        FileUploadBorder: "#BBECE5",
        primary: "#D9D9D9",
        secondary: "#E0E0E0",
      },

      rgba: {
        tableCell: "rgba(0, 16, 55, 0.60)",
      },

      text: {
        dark: "#272727",
        secondary: "#888",
        light: "#27272799",
        lighter: "#001037",
        toolTipTitle: "#343434",
        toolTipDesc: "#6D6D6D",
        black: {
          100: "#000000",
          70: "#000000B3",
        },
        grey: {
          100: "#FDFDFD",
          80: "#FDFDFDCC",
          60: "#777",
        },
        gray: "#B7B7B7",
        white: {
          100: "#FFFFFF",
          80: "#FFFFFFCC",
        },
        blue: "#066DD5",
        label: "#36B1A0",
        link: "#1393F2",
        pending: "#E89900",
        success: "#008034",
        error: "#F80000",
        profileMenuName: "#002046",
        myProfileText: "#8E9095",
      },
      disabled: {
        btn: "#B0B0B0",
      },
    },
  },

  typography: {
    poppins: {
      regular: {
        fontFamily: "Poppins",
        fontWeight: "400",
      },

      light: {
        fontFamily: "Poppins",
        fontWeight: "300",
      },

      medium: {
        fontFamily: "Poppins",
        fontWeight: "500",
      },

      semiBold: {
        fontFamily: "Poppins",
        fontWeight: "600",
      },

      bold: {
        fontFamily: "Poppins",
        fontWeight: "700",
      },
    },
    montserrat: {
      light: {
        fontFamily: "Montserrat",
        fontWeight: "300",
      },
      medium: {
        fontFamily: "Montserrat",
        fontWeight: "500",
      },
      semiBold: {
        fontFamily: "Montserrat",
        fontWeight: "600",
      },
      bold: {
        fontFamily: "Montserrat",
        fontWeight: "700",
      },
    },
    roboto: {
      light: {
        fontFamily: "Roboto",
        fontWeight: "300",
      },
      regular: {
        fontFamily: "Roboto",
        fontWeight: "400",
      },
      medium: {
        fontFamily: "Roboto",
        fontWeight: "500",
      },
      bold: {
        fontFamily: "Roboto",
        fontWeight: "700",
      },
    },
    fontFamily: "Poppins",
  },
});

/**
 * Mui components styles overrides
 */
theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& *": {
            "&::-webkit-scrollbar ": {
              margin: "15px",
              width: "8px",
              height: "8px",
              borderRadius: "100px",
            },
            "&::-webkit-scrollbar-track": {
              // backgroundColor: "white",
              borderRadius: "100px",
              borderWidth: "1px",
              borderStyle: "solid",
              maxHeight: "3px",
              borderColor: theme.palette.custom.background.grey,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.custom.background.grey,
              borderRadius: "100px",
              borderWidth: "1px",
              maxHeight: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              zoom: 1.1,
            },
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },

    MuiSelect: {
      select: {
        padding: "0",
        "&.MuiInputBase-root": {
          "&.MuiSelect-select": {
            padding: 0,
          },
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
        "&.Mui-focused": {
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
        },
      },
      root: {
        fontSize: "0.9rem",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.9rem",
      },
    },

    MuiSwipeableDrawer: {
      styleOverrides: {
        root: {
          height: "90vh",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
          },
          color: theme.palette.custom.text.dark,
          fontWeight: "600",
          "&.Mui-focused": {
            color: theme.palette.custom.text.dark,
          },
          transform: "none",
          marginBottom: "8px",
        },
        asterisk: {
          marginLeft: "6px",
          color: theme.palette.error.main,
          "&$error": {
            color: theme.palette.error.main,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltipArrow: {
          fontSize: "1.5em",
          background: "#FFEBEB",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#F80000",
          boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.12)",
          padding: "16px",
          borderRadius: "5px",
          zIndex: 999,
          "&.error": {
            background: "#FFEBEB",
            border: "1px solid #F80000",
          },
        },
        arrow: {
          color: "#FFEBEB",
          "&.error": {
            color: "#FFEBEB",
            zIndex: 0,
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "label + &": {
            marginBottom: "8px",
            [theme.breakpoints.down("sm")]: {
              marginTop: "",
            },
          },
          boxShadow: "3px 7px 11px 0px rgba(0, 0, 0, 0.04)",
          height: "40px",
          boxSizing: "border-box",
          borderRadius: "12px",
          backgroundColor: theme.palette.secondary.main,
          position: "relative",
          borderWidth: 1,
          borderStyle: "solid",
          fontSize: "14px",
          padding: "10px 8px",
          borderColor: "#E2E2E299",
          background: theme.palette.secondary.main,
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            height: "43px",
          },
          "&.Mui-error": {
            borderColor: `${theme.palette.error.main} !important`,
            color: theme.palette.error.main,
          },
          "&.Mui-focused": {
            borderColor: theme.palette.custom.border.light,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontWeight: "500",
          color: theme.palette.error.main,
          position: "absolute",
          bottom: "-18px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            bottom: "-20px",
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.primary.light,
              },
            },
          },
        },
      },
    },
  },
});

const customTypography = {};

theme = {
  ...theme,
  typography: {
    ...theme.typography,
    ...customTypography,
  },
};

theme = responsiveFontSizes(theme);

export default theme;

export type Styles<T extends string = string> = Record<T, SxProps>;
export type CustomStyles<T extends string = string> = Partial<Styles<T>>;

export const getStyles = <T extends string = string>(
  mainStyles: Styles,
  customStyles?: CustomStyles
) => {
  return (names: T | T[], sx?: SxProps): { sx: SxProps } => {
    let ms: SxProps = {},
      cs: SxProps = {},
      es = sx ?? {};

    if (Array.isArray(names)) {
      ms = {};

      cs = {};

      names.forEach((name) => {
        ms = {
          ...ms,

          ...mainStyles[name],
        } as SxProps;

        const newCs = customStyles?.[name];

        if (newCs)
          cs = {
            ...cs,

            ...newCs,
          } as SxProps;
      });
    } else {
      ms = mainStyles[names];

      cs = customStyles?.[names] ?? {};
    }

    const resultantStyles = { ...ms, ...es, ...cs } as SxProps;

    return { sx: resultantStyles };
  };
};

export const createStyles = <T extends string>(data: Styles<T>) => data;
