import { OtpType } from "src/constants/common";
import dataProvider from "src/dataProvider";
import {
  Login,
  Otp,
  OtpVerification,
  ResetPasswordType,
  SignUpIndividual,
  SignUpOrganizationData,
  VerifyDocument,
} from "src/types/api";

let fetcher = dataProvider("openApiInstance");

export const signIn = async (data: Login) => {
  return fetcher.post("/auth/login", data);
};

export const getStatement = async () => {
  return fetcher.get("/auth/statement");
};

export const signUp = async (
  data: SignUpOrganizationData | SignUpIndividual
) => {
  return fetcher.post<SignUpOrganizationData | SignUpIndividual>(
    "/auth/sign-up",
    data
  );
};

export const sendOtp = async (data: Otp, otpVariant: string) => {
  return fetcher.post<Otp>(
    otpVariant === OtpType.FORGOT_PASSWORD
      ? "/auth/forgot-password"
      : "/user/send-otp",
    data
  );
};

export const otpVerification = async (data, headers) => {
  return fetcher.post<OtpVerification>(
    "/user/verify-otp",
    data,
    {},
    "v1",
    headers
  );
};
export const resetPassword = async (data: ResetPasswordType, headers) => {
  return fetcher.post<ResetPasswordType>(
    "/auth/reset-password",
    data,
    {},
    "v1",
    headers
  );
};

export const verifyDocument = async (data: VerifyDocument) => {
  return fetcher.post<VerifyDocument>("/user/verification", data);
};
