import { routes } from "./routes.module";

const routesData = {
  defaultRoutes: [
    routes.home,
    routes.notFound,
    routes.marketWatch,
    routes.myProfile,
    routes.projectDetailView,
    routes.demandNote,
    routes.orderBook,
    routes.demandNoteView,
    routes.landOwnerShipDetailView,
    routes.addImplementingOfficer,
    routes.changePassword,
    routes.fundApplication,
    routes.fillFundApplication,
    routes.fundApplicationDetails,
    routes.viewFundApplicationDetails,
    routes.ecoRestorationProgress,
    routes.costEstimateDetails,
    routes.advanceWorkProgress,
    routes.costEstimateDigital,
    routes.updateProgressDetails,
    routes.monitoring,
    routes.addMonitoringDetails,
    routes.submittedMonitorings,
  ],

  authRoutes: [
    routes.signIn,
    routes.onboarding,
    routes.personalDetails,
    routes.addressDetails,
    routes.aadhaarVerification,
    routes.organizationDetails,
    routes.documentUpload,
    routes.login,
    routes.forgetPassword,
    routes.resetPassword,
    routes.bankDetails,
    routes.nodalOfficerDetails,
    routes.implementingOfficerDetails,
    routes.agencyDetails,
    routes.entityDetails,
    routes.contactDetails,
  ],
  guestRoutes: [routes.privacyPolicy],
};

export default routesData;
