import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
import { CustomStyles, getStyles } from "src/styles/theme";
import SwipperHeader from "./Header";
import defaultStyles, { StylesClasses } from "./styles";
import { Box } from "@mui/material";

type Anchor = "left" | "right";

type DefaultProps = {
  customStyles?: CustomStyles<StylesClasses>;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  hasValue?: any;
  children: React.ReactNode;
  anchor?: Anchor;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onCrossClick?: () => void;
  onBack?: () => void;
  onNext?: () => void;
  disableNext?: boolean;
  disableBack?: boolean;
  onSubmitForm?: () => void;
  showNext?: boolean;
  title?: string;
};
export type DefaultPropsExt = { disableStepper: true };
export type ConditionalPropExt = {
  currentStep?: number;
  totalStep?: number;
  stepLabel?: { title?: string; details: string };
};
type defaultType = DefaultProps & DefaultPropsExt;
type conditionalType = DefaultProps & ConditionalPropExt;
type SideDrawerProps = defaultType | conditionalType;

const SideDrawer = ({
  customStyles,
  children,
  anchor = "right",
  open = false,
  onOpen,
  onClose,
  onCrossClick,
  title,
}: SideDrawerProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <React.Fragment key={anchor}>
      <SwipeableDrawer
        {...styles("container")}
        anchor={anchor}
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        PaperProps={{
          style: {
            height: "90vh",
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            width: "60%",
          },
        }}
        BackdropProps={{
          onClick: (e) => {
            e.preventDefault();
          },
        }}
      >
        <SwipperHeader onClose={onCrossClick} title={title} />
        <Box
          sx={{
            height: "82.2vh",
            padding: "0px 10px 24px 24px",
          }}
        >
          <Box>{children}</Box>
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default SideDrawer;
