import { Box, Divider } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type StatsCardProps = {
  title: string;
  value: number | string;
  variant?: "horizontal" | "vertical";
  icon?: string;
  area?: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const StatsCard = ({
  customStyles,
  variant = "horizontal",
  title,
  value,
  area,
}: StatsCardProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("valueCard")}>
      <Box {...styles("title")}>{title}</Box>
      <Divider {...styles("divider")} />
      <Box {...styles("iconHalf")}>
        <Box {...styles("value")}>
          <Box {...styles("subHeading")}>AREA(Ha)</Box>
          {area}
        </Box>
        <Box {...styles("value")}>
          <Box {...styles("subHeading")}>NUMBER</Box>
          {value}
        </Box>
      </Box>
    </Box>
  );
};

export default StatsCard;
