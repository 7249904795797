import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, FormHelperText, InputLabel } from "@mui/material";
import { MenuItem, TextField, TextFieldProps } from "@mui/material/";
import {
  Controller,
  FieldErrors,
  FieldPathValue,
  FieldValues,
  Path,
  UnpackNestedValue,
  UseControllerProps,
} from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import { getError } from "src/utils/common";
import defaultStyles, { StylesClasses } from "./styles";
type SelectProps<T> = UseControllerProps<T> &
  TextFieldProps & {
    errors?: FieldErrors;
    options: { label: string; value: string | number; isActive?: boolean }[];
    customStyle?: CustomStyles<StylesClasses>;
    disabled?: boolean;
    height?: string;
    defaultValue?: UnpackNestedValue<FieldPathValue<FieldValues, any>> | string;
    onInputSelect?: (value: string) => void;
  };

export const DropDownIcon = (props, readOnly = true) => {
  // const styles = getStyles(defaultStyles);
  return (
    <KeyboardArrowDownIcon
      {...props}
      sx={{
        fontSize: "35px",
      }}
    />
  );
};

export const Select = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth = true,
  errors,
  customStyle,
  variant = "outlined",
  height = "40px",
  options,
  defaultValue = "",
  disabled,
  rules,
  onInputSelect,
  placeholder,
  ...rest
}: SelectProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyle);
  const error = getError(name, errors);

  return (
    <Box
      {...styles("wrapper", {
        "& .MuiInputBase-root": {
          height: { height },
          borderRadius: "12px",
        },
      })}
    >
      <Controller
        render={({ field }) => (
          <>
            <InputLabel
              shrink
              className="label"
              disabled={disabled}
              {...styles("label")}
              required={!!rules?.required}
              htmlFor={name}
            >
              {label}
            </InputLabel>
            <TextField
              {...styles("input")}
              select
              SelectProps={{
                IconComponent: (props) => <DropDownIcon {...props} />,
                placeholder: "please select",
              }}
              required={true}
              variant={variant}
              fullWidth={fullWidth}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                onInputSelect && onInputSelect(e.target.value);
              }}
              inputRef={field.ref}
              onBlur={field.onBlur}
              disabled={disabled}
              placeholder={placeholder}
              {...rest}
            >
              {options.map(({ label, value, isActive = true }, index) => (
                <MenuItem
                  key={value}
                  value={value}
                  disabled={!isActive}
                  {...styles("menuItems")}
                >
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
        name={name as Path<T>}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        {...rest}
      />

      {!!error && (
        <FormHelperText {...styles("formHelperText")}>
          {error?.message}
        </FormHelperText>
      )}
    </Box>
  );
};
