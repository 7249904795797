import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/common/FormComponents";
import Password from "src/components/common/FormComponents/Password";
import { SuccessModal } from "src/components/common/modal";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import useTimer from "src/hook/useTimer";
import { getStyles } from "src/styles/theme";
import { ResetPasswordType } from "src/types/api";
import { resetPassword } from "src/utils/api/signUp";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type ResetPasswordFormData = {
  password: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const { password: passwordRegex } = regex;
  const navigate = useNavigate();
  const location = useLocation();
  const token = location?.state?.token ?? "";

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  useEffect(() => {
    if (!token.length) {
      navigate(path.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const { control, formState, getValues, watch, handleSubmit } =
    useForm<ResetPasswordFormData>({
      mode: "onTouched",
      defaultValues: {
        password: "",
        confirmPassword: "",
      },
    });

  const { errors, isValid } = formState;
  const [resultTime, reset, isTimedOut] = useTimer(5);
  const { min, sec } = resultTime;

  const height = "52px";

  const resetPasswords = useMutation(
    "resetPass",
    (data: ResetPasswordType) =>
      resetPassword(data, { "x-forgot-password-token": token }),
    {
      onSuccess: (res: AxiosResponse) => {
        reset();
        setOpenModal({
          open: true,
          heading: "",
          subheading: "Your password has been changed!",
          description: "",
          btnText: "Redirect now",
          type: "success",
        });
      },
      onError: (err: AxiosError) => {
        setOpenModal({
          open: true,
          heading: "",
          subheading: "",
          description: err.response.data.message || "Something went wrong",
          btnText: "Redirect now",
          type: "unSuccess",
        });
      },
    }
  );

  useEffect(() => {
    if (openModal.type === "success" && isTimedOut) {
      navigate(path.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimedOut]);

  const handleModalClose = () => {
    if (openModal.type === "success") {
      navigate(path.login);
    }

    setOpenModal({ ...openModal, open: false });
  };

  const onSubmit = (data: ResetPasswordFormData) => {
    resetPasswords.mutate({ password: data.password });
  };

  return (
    <Box {...styles("container")}>
      <Box
        component="form"
        {...styles("logInWrapper")}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box {...styles("logo")}>
          <Box component={"img"} src="/assets/images/coloredLeafIcon.png"></Box>
          <Box {...styles("title")}>GREEN CREDIT</Box>
        </Box>
        <Box {...styles("heading")}>Reset your Password</Box>
        <Box {...styles("input")}>
          <Password
            name="password"
            label="New Password"
            placeholder="Enter new password"
            control={control}
            getValues={getValues}
            showHelperText
            customStyles={{
              customError: defaultStyles.pwdErrorMsg,
            }}
            height={height}
            rules={{
              required: true,
              validate: {
                isLength: (value: string) => value && value.length >= 8,
                isUpper: (value) =>
                  value && passwordRegex.isUpper.test(value.toString()),
                isLower: (value) =>
                  value && passwordRegex.isLower.test(value.toString()),
                isNum: (value) =>
                  value && passwordRegex.isNum.test(value.toString()),
                hasSpecialCharacter: (value) =>
                  value &&
                  passwordRegex.hasSpecialCharacter.test(value.toString()),
              },
            }}
            errors={errors}
          />
        </Box>
        <Box {...styles("input")}>
          <Password
            name="confirmPassword"
            label="Enter Confirm Password"
            placeholder="Enter Confirm Password"
            control={control}
            rules={{
              required: errorMessage.required,
              validate: {
                isConfirm: (value: string) =>
                  value === watch("password") || errorMessage.confirmPassword,
              },
            }}
            errors={errors}
            height={height}
          />
        </Box>

        <Button
          text="Reset Password"
          type="submit"
          disabled={!isValid}
          customStyles={{
            root: defaultStyles.loginBtn,
          }}
        />

        <SuccessModal
          open={openModal.open}
          heading={openModal.heading}
          subheading={openModal.subheading}
          description={openModal.description}
          btnText={openModal.btnText}
          type={openModal.type}
          onClick={() => handleModalClose()}
          handleModalClose={() => handleModalClose()}
        >
          {openModal.type === "success" && (
            <Box {...styles("timerContainer")}>
              Redirecting in&nbsp;
              <Box component={"span"} {...styles("timer")}>
                {min && min > 9 ? min : "0" + min}:
                {sec && sec > 9 ? sec : "0" + sec}s
              </Box>
            </Box>
          )}
        </SuccessModal>
      </Box>
    </Box>
  );
};

export default ResetPassword;
