import { Route, Routes } from "react-router-dom";
import routesData from "src/constants/routes";
import { AuthLayout, DefaultLayout } from "src/layout";
import NotFound from "src/pages/not-found";
import AuthRoute from "./AuthRoute";
import GuestRoute from "./GuestRoute";
import PrivateRoute from "./PrivateRoute";

const AppRouter = () => {
  const { defaultRoutes, authRoutes, guestRoutes } = routesData;

  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        {defaultRoutes.map(({ element, path, index }, idx) => (
          <Route
            key={`default_layout_${path}`}
            element={<PrivateRoute element={element} />}
            path={path}
            index={index}
          />
        ))}
      </Route>
      <Route path="/" element={<AuthLayout />}>
        {authRoutes.map(({ element, path, index }, idx) => (
          <Route
            key={`auth_layout_${path}`}
            element={<AuthRoute element={element} />}
            path={path}
            index={index}
          />
        ))}
      </Route>
      <Route path="/" element={<AuthLayout />}>
        {guestRoutes.map(({ element, path, index }, idx) => (
          <Route
            key={`guest_layout_${path}`}
            element={<GuestRoute element={element} />}
            path={path}
            index={index}
          />
        ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
