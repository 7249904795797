import { ProjectType } from "./common";

export const tableColumns = [
  {
    field: "sno",
    headerName: "S.No",
    width: 90,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION, ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "id",
    headerName: "Id",
    width: 250,
    sortable: false,
    renderCell: "RENDER_VIEW",
    type: [ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "registrationNo",
    headerName: "Reg.No",
    width: 100,
    sortable: false,
    renderCell: "BUTTON",
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "registrationNo",
    headerName: "Reg.No",
    width: 100,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "state",
    headerName: "State Name",
    width: 175,
    sortable: true,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "district",
    headerName: "DISTRICT",
    width: 175,
    sortable: true,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "district",
    headerName: "DISTRICT",
    width: 175,
    sortable: true,
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "division",
    headerName: "DIVISION",
    width: 175,
    sortable: true,
    type: [ProjectType.LAND_LEASED, ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", "ORGANIZATION"],
    tab: [],
  },
  {
    field: "range",
    headerName: "Range",
    width: 150,
    sortable: false,
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "totalArea",
    headerName: "Selected Area(Ha)",
    width: 200,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "finalArea",
    headerName: "Final Area(Ha)",
    width: 170,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["ORGANIZATION"],
    tab: [],
  },
  {
    field: "totalArea",
    headerName: "Net Planned Area(Ha)",
    width: 200,
    sortable: false,
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "occupiedArea",
    headerName: "Area Selected(Ha)",
    width: 200,
    sortable: false,
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "areaAvailable",
    headerName: "Area Available(Ha)",
    width: 200,
    sortable: false,
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "ecoRestorationCost",
    headerName: "Eco-Restoration Cost",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["ORGANIZATION"],
    tab: [],
  },
  {
    field: "adminCharges",
    headerName: "Admin Charges",
    width: 200,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["ORGANIZATION"],
    tab: [],
  },
  {
    field: "demandNote",
    headerName: "Demand Note Amount",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["ORGANIZATION"],
    tab: [],
  },
  {
    field: "totalAmountPaid",
    headerName: "Total Amount Paid",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["ORGANIZATION"],
    tab: [],
  },
  {
    field: "ecoRestorationCostPaid",
    headerName: "Eco-Restoration Cost Paid",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["ORGANIZATION"],
    tab: [],
  },
  {
    field: "adminChargesPaid",
    headerName: "Admin Charges Paid",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["ORGANIZATION"],
    tab: [],
  },
  {
    field: "created_at",
    headerName: "CREATED AT",
    width: 250,
    sortable: false,
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "created_at",
    headerName: "Project Selection Date",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "updated_at",
    headerName: "UPDATED AT",
    width: 250,
    sortable: true,
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "updated_at",
    headerName: "Updated at",
    width: 250,
    sortable: true,
    type: [ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "submitDemandNote",
    headerName: "Update Payment Details",
    width: 250,
    sortable: false,
    renderCell: "DEMAND_DRAFT",
    type: [ProjectType.TREE_PLANTATION],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: ["APPROVED"],
  },
  {
    field: "status",
    headerName: "STATUS",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION, ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
  {
    field: "remark",
    headerName: "Remarks",
    width: 250,
    sortable: false,
    type: [ProjectType.LAND_LEASED],
    userType: ["INDIVIDUAL", null, "ORGANIZATION"],
    tab: [],
  },
];

export const entityColumns = [
  {
    field: "sno",
    headerName: "S.No",
    width: 90,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: ["APPROVED"],
  },
  {
    field: "id",
    headerName: "Id",
    width: 250,
    sortable: false,
    renderCell: "RENDER_VIEW",
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "registrationNo",
    headerName: "Reg.No",
    width: 100,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "state",
    headerName: "State Name",
    width: 175,
    sortable: true,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "district",
    headerName: "DISTRICT",
    width: 175,
    sortable: true,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "division",
    headerName: "DIVISION",
    width: 175,
    sortable: true,
    type: [ProjectType.LAND_LEASED, ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "totalArea",
    headerName: "Selected Area(Ha)",
    width: 200,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "finalArea",
    headerName: "Final Area(Ha)",
    width: 170,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "ecoRestorationCost",
    headerName: "Eco-Restoration Cost",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "adminCharges",
    headerName: "Admin Charges",
    width: 200,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "demandNote",
    headerName: "Demand Note Amount",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],

    tab: [],
  },
  {
    field: "totalAmountPaid",
    headerName: "Total Amount Paid",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "ecoRestorationCostPaid",
    headerName: "Eco-Restoration Cost Paid",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "adminChargesPaid",
    headerName: "Admin Charges Paid",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "updated_at",
    headerName: "Updated at",
    width: 250,
    sortable: true,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
  {
    field: "submitDemandNote",
    headerName: "Update Payment Details",
    width: 250,
    sortable: false,
    renderCell: "DEMAND_DRAFT",
    type: [ProjectType.TREE_PLANTATION],
    tab: ["APPROVED", "VERIFICATION_PENDING"],
  },
  {
    field: "status",
    headerName: "STATUS",
    width: 250,
    sortable: false,
    type: [ProjectType.TREE_PLANTATION],
    tab: [],
  },
];

export const costEstimatetableColumns: any = [
  {
    field: "sno",
    headerName: "S.No",
    width: 90,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
  {
    field: "costEstimateID",
    headerName: "Cost Estimate ID",
    width: 220,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
  {
    field: "year",
    headerName: "Year",
    width: 100,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
  {
    field: "mainComponent",
    headerName: "Main Component",
    width: 300,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
  {
    field: "subComponent",
    headerName: "Sub Component",
    width: 300,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
  {
    field: "item",
    headerName: "Particulars of item of works",
    width: 250,
    sortable: false,
    userType: ["INDIVIDUAL"],
    tab: [],
  },
  {
    field: "unit",
    headerName: "Unit",
    width: 100,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
  {
    field: "costPerUnit",
    headerName: "Cost/unit(Rs.)",
    width: 170,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
  {
    field: "numberOfUnits",
    headerName: "No of Unit Required",
    width: 150,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
  {
    field: "costRequired",
    headerName: "Cost Required(Rs.)",
    width: 150,
    sortable: false,
    userType: ["INDIVIDUAL", null],
    tab: [],
  },
];
