import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganizationDetailsFormData } from "src/components/feature/Onboarding/OrganizationDetails";
import { resetFormAction } from "src/redux/createActions/resetForms";

type FormData = OrganizationDetailsFormData;

interface InitialState {
  formData: FormData;
}

const initialState: InitialState = {
  formData: {
    organizationName: "",
    organizationType: "",
    AuthorizedRepresentativeName: "",
    AuthorizedRepresentativeDesignation: "",
    organizationCertificates: [],
    email: "",
    IdentityProof: [],
    phone: {
      countryCode: "+91",
      number: "",
    },
    password: "",
    /** @todo */
    // verifyEmail: false,
    // verifyPhone: false,
    verifyEmail: true,
    verifyPhone: true,
    emailOtp: "",
    phoneOtp: "",
  },
};

const organizationDetailsSlice = createSlice({
  name: "organizationDetails",
  initialState,
  reducers: {
    updateOrganizationDetailsForm: (state, action: PayloadAction<FormData>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateOrganizationDetailsForm: updateOrganizationDetailsForm } =
  organizationDetailsSlice.actions;

export default organizationDetailsSlice.reducer;
