import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Select,
  SelectProps,
  SxProps,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import { dateToString, range } from "../utils";
import styles from "./styles";

type CalendarProps<T> = {
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  field: ControllerRenderProps<T, Path<T>>;
};

type CalendarIconProps = {
  disabled?: boolean;
};

const CalendarIcon = ({ disabled = false }: CalendarIconProps) => {
  return (
    <Box
      sx={
        {
          ...styles.calendarIconBtn,
          ...(disabled && styles.disabledIcon),
        } as SxProps
      }
      component="img"
      src="/assets/svg/calendar.svg"
      alt="calendar-icon"
    />
  );
};

const CustomSelect = ({
  value,
  options,
  onChange,
  ...rest
}: { options: string[] | number[] } & SelectProps<any>) => {
  return (
    <>
      <Select
        aria-labelledby="change year"
        sx={styles.select}
        variant="outlined"
        value={value}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            sx: styles.paper,
          },
          MenuListProps: {
            sx: styles.menu,
          },
        }}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const Calendar = <T extends FieldValues>({
  maxDate,
  minDate,
  field,
  disabled = false,
}: CalendarProps<T>) => {
  const [calendarAnchor, setCalendarAnchor] = useState<HTMLElement | null>(
    null
  );

  const years = range(minDate.getFullYear(), maxDate.getFullYear(), 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setCalendarAnchor(null);
  };

  const open = !!calendarAnchor;
  const id = open ? "calendar-popover" : undefined;

  return (
    <Box>
      <IconButton
        aria-label="calendar-button"
        sx={styles.calendarIconBtn}
        onClick={handleClick}
        disabled={disabled}
      >
        <CalendarIcon disabled={disabled} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={calendarAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={styles.calendarWrapper}
      >
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <Box sx={styles.customHeader}>
              <IconButton
                sx={styles.arrowIcon}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ArrowLeftIcon />
              </IconButton>

              <CustomSelect
                onChange={({ target: { value } }) => changeYear(+value)}
                value={date.getFullYear()}
                options={years}
              />

              <CustomSelect
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
                options={months}
              />

              <IconButton
                sx={styles.arrowIcon}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <ArrowRightIcon />
              </IconButton>
            </Box>
          )}
          selected={field.value ? new Date(field.value) : null}
          onChange={(date) => {
            field.onChange(dateToString(date));
            handleClose();
          }}
          inline
          maxDate={maxDate}
          minDate={minDate}
        />
      </Popover>
    </Box>
  );
};

export default Calendar;
