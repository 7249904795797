import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { entityDetailsFormData } from "src/components/feature/Onboarding/EntityDetails";
import { resetFormAction } from "src/redux/createActions/resetForms";

interface InitialState {
  formData: entityDetailsFormData;
}

const initialState: InitialState = {
  formData: {
    entityName: "",
    state: "",
    streetAddress: "",
    district: "",
    city: "",
    password: "",
  },
};

const EntityDetailsSlice = createSlice({
  name: "EntityDetails",
  initialState,
  reducers: {
    updateEntityDetails: (
      state,
      action: PayloadAction<entityDetailsFormData>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateEntityDetails } = EntityDetailsSlice.actions;

export default EntityDetailsSlice.reducer;
