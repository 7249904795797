import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    margin: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: {
        xs: "10px",
        sm: "16px",
      },
      color: "custom.label",
    },
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
});

export default styles;
