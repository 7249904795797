import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import {
  Box,
  CircularProgress,
  Pagination as MatPagination,
  PaginationItem,
} from "@mui/material";
import {
  DataGrid as MatDataGrid,
  DataGridProps as MatDataGridProps,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type DataGridProps = MatDataGridProps & {
  toolbar?: boolean;
  emptyTable?: string;
  customStyles?: CustomStyles<StylesClasses>;
  hidePagination?: boolean;
  pageNumber?: number;
  onChangePage?: (v: number) => void;
};

const SortIconAsc = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  return (
    <Box {...styles("iconStyles")}>
      <ArrowDropDownRoundedIcon fontSize="large" />
    </Box>
  );
};

const SortIconDesc = () => {
  const styles = getStyles(defaultStyles);
  return (
    <Box {...styles("iconStyles")}>
      <ArrowDropUpRoundedIcon fontSize="large" />
    </Box>
  );
};

const CustomPagination = ({
  rowsPerPageOptions,
  rowCount,
  hideFooterPagination,
  pageNumber,
  onChangePage,
}) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <Box {...styles("paginationWrapper")}>
      {!hideFooterPagination && (
        <MatPagination
          color="primary"
          count={pageCount || 1}
          {...styles("pagination")}
          page={pageNumber ? pageNumber : page + 1}
          onChange={(event, value) => {
            if (pageNumber && onChangePage) {
              onChangePage(value - 1);
            } else {
              apiRef.current.setPage(value - 1);
            }
          }}
          renderItem={(item) => (
            <PaginationItem {...styles("paginationItem")} {...item} />
          )}
        />
      )}
    </Box>
  );
};

const DataGrid = ({
  rows,
  columns,
  autoHeight = true,
  checkboxSelection = false,
  disableColumnFilter = true,
  disableColumnMenu = true,
  hideFooterPagination = false,
  loading = false,
  toolbar = false,
  customStyles = {},
  rowsPerPageOptions = [5],
  rowCount = 5,
  hidePagination = false,
  emptyTable = "No Data Available",
  paginationMode = "server",
  pageNumber,
  onChangePage,
  ...rest
}: DataGridProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box
      {...styles("dataGridWrapper", {
        height: rows.length === 0 ? "300px" : "auto",
      })}
    >
      <MatDataGrid
        {...styles("grid")}
        rows={rows}
        columns={columns}
        autoHeight={autoHeight}
        checkboxSelection={checkboxSelection}
        disableColumnFilter={disableColumnFilter}
        disableColumnMenu={disableColumnMenu}
        loading={loading}
        sortingOrder={["asc", "desc"]}
        pagination
        density="standard"
        rowsPerPageOptions={rowsPerPageOptions}
        rowCount={rowCount}
        hideFooterPagination={hideFooterPagination}
        paginationMode={paginationMode}
        columnBuffer={20}
        components={{
          LoadingOverlay: () => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ),
          NoResultsOverlay: () => <Box></Box>,
          ColumnResizeIcon: () => null,
          ColumnSortedDescendingIcon: SortIconDesc,
          ColumnSortedAscendingIcon: SortIconAsc,
          Footer: () =>
            CustomPagination({
              rowsPerPageOptions,
              rowCount,
              hideFooterPagination,
              pageNumber,
              onChangePage,
            }),

          Toolbar: toolbar && GridToolbar,
        }}
        {...rest}
      />
    </Box>
  );
};

export default DataGrid;
