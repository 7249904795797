import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    backgroundColor: "custom.text.grey.100",
    borderRadius: "12px",
    padding: "24px 36px 48px 36px",
  },

  childWrapper: {},

  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3%",
  },

  heading: {
    fontSize: "18px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "black",
    marginBottom: "30px",
  },

  fileBox: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
  },

  pwdErrorMsg: {},
  withHelperText: {},
  fieldHelperText: {
    flexDirection: "column",
    color: "custom.text.dark",
    opacity: "0.6",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
  },
  borderStyle: {},
  fileType: {},

  selectWrapper: {
    width: "100%",
  },

  input: {
    flexBasis: { xs: "100%", sm: "75%", lg: "40%" },
    margin: "0 0 24px 0",
  },

  ImageView: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: "20px",
  },
});

export default styles;
