import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UserData = {
  currentAddress: string;
  firstName: string;
  organizationId: string;
  permanentAddress: string;
  userId: string;
  userName: string;
  role: string;
  organizationName: string;
  userType: string;
  state: string;
  stateId: string;
  division: string;
  divsionId: string;
  organizationType: {
    id: string;
    type: string;
  };
};

type User = {
  isLoggedIn: boolean;
  name: string;
  devType: string;
  data: UserData;
};

const initialState: User = {
  isLoggedIn: false,
  name: "Green Credit",
  devType: "INDIVIDUAL",
  data: {
    currentAddress: "",
    firstName: "",
    organizationId: "",
    permanentAddress: "",
    organizationName: "",
    userId: "",
    userName: "",
    userType: "",
    role: "",
    state: "",
    stateId: "",
    division: "",
    divsionId: "",
    organizationType: {
      id: "",
      type: "",
    },
  },
};
const userDetails = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserData>) => {
      state.isLoggedIn = true;
      state.name = "Green Credit";
      state.devType = "INDIVIDUAL";
      state.data = { ...action.payload };
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.name = "";
      state.data = initialState.data;
      localStorage.clear();
    },
  },
});

export const { logout, login } = userDetails.actions;
export default userDetails.reducer;
