import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NewFormMap } from "src/components/feature/CreateProject";
import { setStepDetailsTypes } from "../newProject/types";
import { initialState } from "./initialState";
import {
  setBaselineDataTypes,
  setInitialDetailsTypes,
  setIsErrorTypes,
} from "./types";

const selectNewProjectSetupSlice = createSlice({
  name: "selectNewProjectSetupSlice",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<keyof typeof NewFormMap>) => {
      state.stepName = action.payload;
    },

    setOpen: (state) => {
      state.showModal = true;
    },

    setClose: (state) => {
      state.showModal = false;
    },

    setStepDetails: (state, action: PayloadAction<setStepDetailsTypes>) => {
      state.stepDetail.currentStep = action.payload.currentStep;
      state.stepDetail.stepName = action.payload.stepName;
      state.stepDetail.totalStep = action.payload.totalStep;
    },

    setInitialDetails: (
      state,
      action: PayloadAction<setInitialDetailsTypes>
    ) => {
      state.formData.initialDetails = action.payload;
    },

    setBaselineData: (state, action: PayloadAction<setBaselineDataTypes>) => {
      state.formData.baselineData = action.payload;
    },

    uploadedIds: (state, action: PayloadAction<any>) => {
      state.formData.uploadedIds.gpsBoundaries = {
        id: action.payload.KMLFiles[0].key,
        url: action.payload.KMLFiles[0].file,
      };

      // state.formData.uploadedIds.gpsBoundariesCsv = {
      //   id: action.payload.csvUpload[0].key,
      //   url: action.payload.csvUpload[0].file,
      // };
    },

    uploadImages: (state, action: PayloadAction<any>) => {
      state.formData.uploadedImages = action.payload;
    },

    setIsLandFreeFromEncumbrances: (state, action: PayloadAction<any>) => {
      state.formData.otherDetails.landFreeFromEncumbrances = action.payload;
    },

    setTotalNoOfGrids: (state, action: PayloadAction<any>) => {
      state.formData.otherDetails.totalNoOfGrids = action.payload;
    },

    resetProjectForm: (state, action: PayloadAction<{ open?: boolean }>) => {
      state.showModal = action.payload.open ?? false;
      state.formData = { ...initialState.formData };
      state.stepDetail = { ...initialState.stepDetail };
      state.stepName = "ProjectDetails";
      state.projectType = "TREE_PLANTATION";
    },

    setIsError: (state, action: PayloadAction<setIsErrorTypes>) => {
      state.isError.isError = action.payload.isError;
      state.isError.msg = action.payload.msg;
    },

    setProjectType: (state, action: PayloadAction<setIsErrorTypes>) => {
      state.projectType = action.payload;
    },
  },
});

export const {
  setOpen,
  setClose,
  setStep,
  setIsError,
  uploadedIds,
  setStepDetails,
  setBaselineData,
  setProjectType,
  resetProjectForm,
  setInitialDetails,
  setTotalNoOfGrids,
  uploadImages,
  setIsLandFreeFromEncumbrances,
} = selectNewProjectSetupSlice.actions;

export default selectNewProjectSetupSlice.reducer;
