import { useDispatch } from "react-redux";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { setOpen } from "src/redux/slices/newProjectSetup";
import { useAppSelector } from "src/redux/store";
// import { setOpen } from "src/redux/slices/newProject";
import { getStyles } from "src/styles/theme";
import { ProjectInterface } from "src/types/common";
import defaultStyles, { StylesClasses } from "../styles";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { Box } from "@mui/material";

type RenderTableProps = {
  rows: {
    data: ProjectInterface;
    id: string;
    status: string;
    chipTitle: string;
    registrationNo: number;
    activityId: number;
    activityInitiationDate: string;
    totalDuration: string;
    totalCredit: number;
    areaUnit: string;
    updatedAt: string;
    remarks: string;
  }[];
  hideFooterPagination?: boolean;
  isLoading?: boolean;
  columns: any;
  rowCount?: number;
  onPageChange?: (e: number) => void;
  handleSortModelChange?: (e: any) => void;
  pageNumber?: number;
};

const RenderTable = ({
  rows,
  hideFooterPagination,
  isLoading,
  columns,
  rowCount,
  onPageChange,
  handleSortModelChange,
  pageNumber,
}: RenderTableProps) => {
  const dispatch = useDispatch();
  const styles = getStyles<StylesClasses>(defaultStyles);

  const user = useAppSelector((state) => state.root.user);

  return (
    <>
      <>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : !!rows.length ? (
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooterPagination={hideFooterPagination}
            rowCount={rowCount}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            pageSize={1}
            onChangePage={onPageChange}
            pageNumber={pageNumber}
            loading={isLoading}
          />
        ) : (
          <EmptyView
            heading="No Activity Found"
            subheading=""
            onClick={() => {}}
            buttonText={""}
          />
        )}
      </>
    </>
  );
};

export default RenderTable;
