import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { FormsMap } from "src/components/feature/CreateProject";
import { ProjectType } from "src/constants/common";
import { ProjectConstantTypes } from "src/types/common";
import { initialState } from "./initialState";
import { setIsErrorTypes, setStepDetailsTypes } from "./types";

const selectProjectTypeSlice = createSlice({
  name: "selectProjectTypeSlice",
  initialState,
  reducers: {
    setToggleSideBar: (state, action: PayloadAction<boolean>) => {
      state.isSideBarOpen = action.payload;
    },
    setStep: (state, action: PayloadAction<keyof typeof FormsMap>) => {
      state.stepName = action.payload;
    },
    selectProjectType: (state, action: PayloadAction<ProjectConstantTypes>) => {
      state.formData.projectType = action.payload;
      if (action.payload === ProjectType.TREE_PLANTATION) {
        state.stepName = "ProjectAreaDetails";
        state.stepDetail.totalStep = 3;
      } else if (action.payload === ProjectType.LAND_LEASED) {
        state.stepName = "ProjectAreaDetails";
        state.stepDetail.stepName = "ProjectAreaDetails";
        state.stepDetail.totalStep = 3;
      } else {
        state.stepName = "ProjectAreaDetails";
        state.stepDetail.stepName = "ProjectAreaDetails";
        state.stepDetail.totalStep = 4;
      }
    },

    setOrganizationDetails: (state, action: PayloadAction<any>) => {
      const {
        annualIncome,
        // declarationType,
        employeeCount,
        industryTypeId,
        projectName,
      } = action.payload;
      state.formData.name = projectName;
      state.formData.organizationAffiliationDetail.annualTurnover =
        Number(annualIncome);
      // state.formData.organizationAffiliationDetail.decelerationType =
      //   declarationType;
      state.formData.organizationAffiliationDetail.noOfEmployee =
        Number(employeeCount);
      state.formData.organizationAffiliationDetail.industryTypeId =
        industryTypeId;
    },

    setIndividualDetails: (state, action: PayloadAction<any>) => {
      const { incomeBracketRangeId, gender, projectName } = action.payload;
      state.formData.name = projectName;
      state.formData.organizationAffiliationDetail.incomeBracketRangeId =
        incomeBracketRangeId;
      state.formData.organizationAffiliationDetail.gender = gender;
    },

    setProjectAreaDetails: (state, action: PayloadAction<any>) => {
      state.formData.address = {
        ...state.formData.address,
        ...action.payload.address,
      };

      state.formData.organizationAffiliationDetail.industryTypeId =
        action.payload.org.industryTypeId;
      // state.saveImages.PLOT_LAYOUT = [...action.payload.documentUrl];

      // state.formData.documentDetail = [
      //   ...state.formData.documentDetail,
      //   ...action.payload.documentUrl.map((item: any) => {
      //     return { documentUrl: item.key, type: item.type };
      //   }),
      // ];
    },

    setAgencyDetails: (state, action: PayloadAction<any>) => {
      state.formData.agencyDetails = { ...action.payload };
    },

    setPlotAreaDetails: (state, action: PayloadAction<any>) => {
      state.formData.landArea = Number(action.payload.landArea);
      state.formData.landCategoryId = action.payload.landCategoryId;
      state.formData.layout = action.payload.layoutPlantation;
      state.formData.landOwnershipTypeId = action.payload.landOwnershipTypeId;
      state.formData.projectDuration = Number(action.payload.projectDuration);
      state.formData.projectStartDate = action.payload.startDate;
      state.formData.activityPurpose = action.payload.activityPurpose;
      state.formData.landTypeId = action.payload.landTypeId;

      state.PlotAreaDetails.layoutPlantationImg =
        action.payload?.layoutPlantationImg;
      state.PlotAreaDetails.ownershipImg = action.payload?.ownershipImg;
      state.PlotAreaDetails.sourceSaplingImg = action.payload?.sourceSaplingImg;

      state.saveImages.AREA_SHAPE = action.payload?.layoutPlantationImg;

      state.formData.documentDetail = [
        ...state.formData.documentDetail,
        ...(action.payload?.layoutPlantationImg?.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }) ?? []),
        ...(action.payload?.ownershipImg?.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }) ?? []),
      ];

      const newTree = action.payload.newTreeDetails.map((item) => {
        return {
          treeSpeciesId: item?.treeSpeciesId,
          treeCount: Number(item?.treeCount),
          treeType: 1,
        };
      });

      const existingTree = action.payload.existingTrees.map((item) => {
        return {
          speciesName: item.speciesName,
          treeCount: Number(item.treeCount),
          treeType: 0,
        };
      });

      state.treeSpecificationDetail = [...newTree, ...existingTree];

      state.newTreeDetails = newTree;
      state.existingTrees = existingTree;
    },

    setBankDetails: (state, action: PayloadAction<any>) => {
      state.formData.bankAccountDetail = {
        ...action.payload,
      };
      state.formData.documentDetail = [
        ...state.formData.documentDetail,
        ...action.payload.documentDetailUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
      ];
    },

    setWaterStructureDetails: (state, action: PayloadAction<any>) => {
      const { length, depth, width } = action.payload;
      const capacity = Number(length * depth * width);

      state.waterConservationDetail.length = Number(length);
      state.waterConservationDetail.width = Number(width);
      state.waterConservationDetail.depth = Number(depth);

      state.formData.projectDuration = Number(action.payload.projectDuration);
      state.formData.projectStartDate = "2023-11-13T14:30:00+03:00";
      // state.waterConservationDetail.materialDetailId = action.payload.material;
      state.waterConservationDetail.constructionDuration = Number(
        action.payload.constructionDuration
      );
      state.waterConservationDetail.constructionStartDate =
        action.payload.constructionStartDate;
      state.waterConservationDetail.cost = Number(action.payload.cost);
      state.waterConservationDetail.potentialCapacity = capacity;
      state.waterConservationDetail.structureTypeId = action.payload.type;
      state.formData.layout = action.payload.layout;
      // state.saveImages.DPRimg = [...action.payload.DPRimg];

      state.saveImages.PROJECT_LAYOUT = [...action.payload.DPRimg];
      state.saveImages.WORKING_PERMIT = [...action.payload.HandmadeDrawing];
      //
      state.formData.documentDetail = [
        ...state.formData.documentDetail,
        ...action.payload.DPRimg.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
      ];
    },

    setDeclaration: (state, action: PayloadAction<any>) => {
      const data = action.payload;

      state.declarations = {
        age: data.age,
        checkboxes: { ...data.checkboxes },
        fatherName: data.fatherName,
      };
    },

    setStepDetails: (state, action: PayloadAction<setStepDetailsTypes>) => {
      state.stepDetail.currentStep = action.payload.currentStep;
      state.stepDetail.stepName = action.payload.stepName;
      state.stepDetail.totalStep = action.payload.totalStep;
    },

    setIsError: (state, action: PayloadAction<setIsErrorTypes>) => {
      state.isError.isError = action.payload.isError;
      state.isError.msg = action.payload.msg;
    },

    setOpen: (state) => {
      state.openProjectView = true;
    },

    setFormData: (state, action: PayloadAction<any>) => {
      const obj = {};
      const fatherName = action.payload?.decelerations[0]?.fatherName;
      const age = action.payload?.decelerations[0]?.age;
      action.payload?.decelerations.forEach((item) => {
        obj[item.decelerationType] = item.isAccepted;
      });

      state.formData = {
        ...action.payload,
      };

      state.formData.address = {
        ...action.payload.address,
        zipCode: action.payload.address.zipCode,
      };
      state.waterConservationDetail = {
        ...action.payload?.waterConservationDetail,
      };

      // state.treeSpecificationDetail = [
      //   ...action.payload?.treeSpecificationDetail,
      // ];

      state.newTreeDetails = action.payload?.treeSpecificationDetail;
      state.existingTrees = action.payload?.treeSpecificationDetail;

      state.declarations = {
        fatherName: fatherName,
        age: age,
        checkboxes: obj,
      };

      state.saveImages = _.groupBy(action.payload.documentDetail, "type");
      state.formData.documentDetail = [...action.payload.documentDetail];
      state.formData.activityPurpose =
        action.payload.organizationAffiliationDetail.decelerationType;
      state.openProjectView = true;
    },

    closeProject: (state) => {
      state.openProjectView = false;
    },

    resetProjectForm: (state, action: PayloadAction<{ open?: boolean }>) => {
      state.openProjectView = action.payload.open ?? false;
      state.formData = { ...initialState.formData };
      state.stepDetail = { ...initialState.stepDetail };
      state.PlotAreaDetails = { ...initialState.PlotAreaDetails };
      state.treeSpecificationDetail = [...initialState.treeSpecificationDetail];
      state.waterConservationDetail = {
        ...initialState.waterConservationDetail,
      };
      state.saveImages = { ...initialState.saveImages };
      state.declarations = { ...initialState.declarations };
      state.apiImages = initialState.apiImages;
      state.newTreeDetails = initialState.newTreeDetails;
      state.isSideBarOpen = initialState.isSideBarOpen;
      state.isError = initialState.isError;
      state.existingTrees = initialState.existingTrees;
      state.stepName = "SelectProject";
    },
  },
});

export const {
  setOpen,
  selectProjectType,
  setStep,
  setIsError,
  setFormData,
  setStepDetails,
  resetProjectForm,
  setOrganizationDetails,
  setIndividualDetails,
  setProjectAreaDetails,
  setAgencyDetails,
  setPlotAreaDetails,
  setBankDetails,
  closeProject,
  setDeclaration,
  setToggleSideBar,
  setWaterStructureDetails,
} = selectProjectTypeSlice.actions;

export default selectProjectTypeSlice.reducer;
