import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  noRows: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    margin: "auto",
    mt: "50px",
  },

  noProject: {
    fontSize: "24px",
    fontWeight: 600,
    color: "custom.text.lighter",
  },

  desc: {
    fontSize: "16px",
    fontWeight: 400,
    color: "custom.rgba.tableCell",
    textAlign: "center",
    width: "400px",
  },

  dimension: {},

  iconStyles: {
    color: "custom.misc.joyBlue",
  },
});

export default styles;
