import { Box, Button, Grid } from "@mui/material";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import ViewForm from "src/components/common/ViewForm";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "react-query";
import { deleteComponentView } from "src/utils/api/dashboard";
import { useDeletionState } from "src/hook/deletion-state";
import { useState } from "react";
import { SuccessModal } from "src/components/common/modal";
import ViewImages from "src/components/common/ViewImage";

export function LandPreparationView({ data, images, submitted }: any) {
  const styles = getStyles(defaultStyles);
  const { setComponentDeletionState } = useDeletionState();
  const landDetail = data;

  const geoImages = {
    "Pitting Images": images?.pitsImages,
    "Sign Board Images": images?.signBoardImages,
    "Site Clearing Images": images.siteClearingImages,
    "Soil Improvement Images": images.soilImprovementImages,
  };

  const viewData = {
    Pitting: {
      "Number of Pits": landDetail.numberOfPits,
      "Pits Spacing (mxm)": landDetail.pitSpacing,
      "Pits Size (mxmxm)": landDetail.pitSize,
      Observations: landDetail.pitsRemark,
    },
    "Sign Board": {
      "Sign Board Placed": landDetail.isSignBoardPlaced ? "Yes" : "No",
      "Sign Board According to Template":
        landDetail.isSignBoardAccordingToTemplate ? "Yes" : "No",
      Observations: landDetail.signBoardRemark,
    },
    "Site Clearing": {
      "Lentana Removal": landDetail.lantanaRemoval ? "Yes" : "No",
      "Other Weed Removal": landDetail.otherWeedRemoval ? "Yes" : "No",
      Observations: landDetail.siteClearingRemark,
    },
    "Soil Improvement": {
      "Soil Imported From Other Place": landDetail.isSoilImportedFromOtherPlace
        ? "Yes"
        : "No",
      "Fertilizers Applied": landDetail.isFertilizerApplied ? "Yes" : "No",
      "Organic Manure Applied": landDetail.isOrganicManureApplied
        ? "Yes"
        : "No",
      "Mulching Carried Out": landDetail.isMulchingCarriedOut ? "Yes" : "No",
      Observations: landDetail.soilImprovementRemark,
    },
  };

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: () => deleteComponentView(data.id),
    onSuccess: () => {
      console.log("Fencing detail deleted successfully.");
      setComponentDeletionState((prev) => !prev);
    },
    onError: (error) => {
      console.error("Error deleting fencing detail:", error);
    },
  });

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "Delete This Component",
    subheading: "Are you sure?",
    description: "",
    btnText: "Confirm",
    type: "Alert",
  });
  const handleResponseModal = () => {
    mutate();
    handleModalClose();
  };
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  return (
    <Box {...styles("viewWrapper")}>
      <Grid container spacing={2} sx={{ padding: "20px" }}>
        {Object.entries(viewData)?.map((item) => (
          <Grid item xs={12} md={6} spacing={2}>
            <Box sx={{ mb: "20px" }}>
              <Box
                sx={{ fontSize: "20px", fontWeight: "600", color: "#36B1A0" }}
              >
                {item[0]}
              </Box>
              <Box sx={{ ml: "20px" }}>
                {Object.entries(item[1])?.map((subItem) => (
                  <ViewForm
                    label={subItem[0]}
                    value={subItem[1]}
                    customStyles={{
                      wrapper: { justifyContent: "space-between" },
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box>
        <Box {...styles("heading")}>Geo Tagged Images</Box>
        {Object.entries(geoImages).map((item) => (
          <Box sx={{ mt: "10px", color: "#36B1A0" }}>
            <Box {...styles("heading")}>{item[0]}</Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
              {item[1].map((image) => (
                <ViewImages fileUrl={image.url} />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {!submitted && (
          <Button
            endIcon={<DeleteIcon />}
            onClick={() => setOpenModal((prev) => ({ ...prev, open: true }))}
            disabled={isLoading}
          >
            {isLoading ? "Deleting..." : "Delete"}
          </Button>
        )}
      </Box>

      {isError && <Box sx={{ color: "red" }}>Failed to delete. Try again.</Box>}
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      />
    </Box>
  );
}
