import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    position: "relative",
  },

  menuOptionIcon: {
    fontSize: "35px",
  },

  input: {
    // height: "52px",

    color: "red",

    "&.Mui-disabled": {
      WebkitTextFillColor: "#000",
      color: "red",
    },

    flexGrow: 1,
    "& ..css-108a452-MuiInputBase-root": {
      mt: 0,
    },
    "& .MuiInputBase-input": {
      MozAppearance: "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
  },

  label: {},

  inputLabel: {},

  errormsg: {
    mb: "5px",
  },
});

export default styles;
