import { Box, Grid } from "@mui/material";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  FileUpload,
  Input,
  Select,
} from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { SuccessModal } from "src/components/common/modal";
import { useState } from "react";
import { useMutation } from "react-query";
import { postMonitoringComponentDetail } from "src/utils/api/dashboard";
import { useSearchParams } from "react-router-dom";
import { useComponentName } from "src/hook/monitoring-component";
import { MultiSelect } from "src/components/common/FormComponents/MultiSelect";
import {
  ENUM_MONITORING_TYPE,
  IRRIGATION_STATUS_TYPE,
  IRRIGATION_TYPE,
} from "src/constants/common";
import { Option } from "src/types/common";

export type FormType = {
  typesOfIrrigations: string;
  status: string;
  remark: string;
  images: ["a", "b"];
};

export function IrrigationForm({ param, yearMonth }: any) {
  const styles = getStyles(defaultStyles);
  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");
  const { setComponentName } = useComponentName();
  const form = useForm<FormType>();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = form;

  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    heading: "",
    subHeading: "",
  });
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };
  const handlePopUpClose = () => {
    if (openModal.type === "success") {
      setComponentName(undefined);
    } else {
      handleModalClose();
    }
  };

  const { mutate } = useMutation(
    ["nursary"],
    ({ data }: { data: any }) => postMonitoringComponentDetail(data),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          type: "success",
          heading: "Success",
          subHeading: "Irrigration Monitoring Detail Submitted Successfully",
        });
      },
      onError: () => {
        setOpenModal({
          open: true,
          type: "unSuccess",
          heading: "Failed",
          subHeading: "Something Went Wrong",
        });
      },
    }
  );
  const onSubmit = (data: any) => {
    const imageKeys = data.images.map((item) => item.key);
    console.log("data", data);
    mutate({
      data: {
        ...data,
        type: ENUM_MONITORING_TYPE.IRRIGATION,
        year: param.year,
        month: param.month,
        landOwnershipEpid,
        yearMonth: yearMonth,
        typesOfIrrigations: data.typesOfIrrigations.join(","),
        status: data.status,
        remark: data.remark,
        images: imageKeys,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        {...styles("formWrapper")}
      >
        <Box {...styles("formElementWrapper")}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MultiSelect
                label="Select Irrigation Types"
                placeholder="Select Irrigation Types"
                control={control}
                rules={{
                  required: "Select One or More Type",
                }}
                name="typesOfIrrigations"
                options={IRRIGATION_TYPE}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Select
                name="status"
                label="Irrigation Status"
                placeholder="Irrigation Status"
                control={control}
                errors={errors}
                options={IRRIGATION_STATUS_TYPE}
                rules={{
                  required: errorMessage.required,
                }}
                customStyle={{
                  wrapper: { mt: "14px" },
                  input: styles("formselect").sx,
                  label: { fontSize: "14px" },
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box {...styles("formElementWrapper")}>
          <Input
            customStyles={{
              label: styles("inputLabel").sx,
              wrapper: styles("inputWrapper").sx,
              input: {
                borderColor: "#36B1A0;",
                boxShadow: "unset",
              },
              // errorMsg: styles("errorMsg").sx,
            }}
            name="remark"
            control={control}
            errors={errors}
            placeholder="Irrigation Operation Observations "
            label="Irrigation Operation Observations "
            rules={{
              required: errorMessage.required,
            }}
          />
        </Box>
        <Box>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={{ margin: "20px 20px " }}
          >
            <FileUpload
              accept="image/jpeg, image/png, image/webp, image/jpg"
              name="images"
              label="Upload GeoTagged Images (Minimum 2 images are required )"
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              maxLimit={10}
              multiple={true}
              required={true}
              supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
              trigger={trigger}
              rules={{
                validate: {
                  isLength: (value) => {
                    return value && value["length"] >= 2;
                  },
                },
              }}
              dragDropHeading=""
            />
          </Grid>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button text="Submit" type="submit" disabled={!isValid} />
        </Box>

        <SuccessModal
          open={openModal.open}
          heading={openModal.heading}
          subheading={openModal.subHeading}
          closeOnBackDrop
          type={openModal.type}
          handleModalClose={() => handlePopUpClose()}
        />
      </Box>
    </FormProvider>
  );
}
