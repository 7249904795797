import { Box } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { AutoComplete, Input } from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { formHeaders } from "src/constants/sampleData";
// import { setStep } from "src/redux/slices/newProject";
import { setInitialDetails, setStep } from "src/redux/slices/newProjectSetup";
import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import {
  getDivisionOptions,
  getindustryOptions,
  getLandTypeOptions,
} from "src/utils/api/createProjectFlow";
import { optionTypeFormatter } from "src/utils/helper";
import FormWrapper from "../FormWrapper";
import defaultStyles, { StylesClasses } from "../NewForms/styles";
import { ProjectAreaDetailsForm } from "../newTypes";
import { getMyProfileData } from "src/utils/api/dashboard";
import { AxiosError, AxiosResponse } from "axios";

const formHeads = formHeaders["ProjectAreaDetails"];

const ProjectDetails = () => {
  const { formData } = useAppSelector(
    (state) => state.root.selectNewProjectSetup
  );

  const projectData = useAppSelector((state) => state.root.projectById.data);
  const userType = useAppSelector((state) => state.root.user.data.userType);
  const userData = useAppSelector((state) => state.root.user.data);
  const stateOption = useAppSelector((state) => state?.root.state.allStates);
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [pincodes, setPincodes] = useState([]);
  const dispatch = useDispatch();

  const user = useAppSelector((state) => state.root.user);

  const { control, getValues, setValue, formState, watch } =
    useForm<ProjectAreaDetailsForm>({
      mode: "onTouched",
      defaultValues: formData.initialDetails,
    });

  const { data: industryOption } = useQuery(
    "industryOption",
    () => getindustryOptions(),
    {
      refetchOnMount: true,
    }
  );

  const industryOptions = optionTypeFormatter(industryOption?.data);
  const stateOptions = optionTypeFormatter(stateOption);

  const { errors, isValid } = formState;

  const district = useMemo(() => {
    const d = !user?.data?.stateId ? getValues("stateName") : user.data.stateId;
    const dist = _.find(stateOption, (i) => i.id === d);
    return dist?.districts.length ? optionTypeFormatter(dist?.districts) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("stateName")]);

  const { data: landTypeOptions } = useQuery("landTypeOption", () =>
    getLandTypeOptions()
  );

  const { data: division } = useQuery(
    ["divisionsOption", user.data.stateId],
    () => getDivisionOptions(user.data.stateId)
  );

  const { data: myProfileData } = useQuery(
    ["myProfile", user.data.userId],
    () => getMyProfileData(user.data.userId),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (err: AxiosError) => {},
      enabled: !!user.data.userId,
      refetchOnMount: true,
    }
  );

  const divisionoptions = myProfileData?.data?.implementingOfficerDetails?.map(
    (item) => {
      return {
        value: item.id,
        label: item.division,
      };
    }
  );

  const landCategoryOption = optionTypeFormatter(landTypeOptions?.data);

  return (
    <FormWrapper
      isValid={isValid}
      onNext={() => {
        setValue("stateName", user?.data?.stateId);
        setValue("division", user?.data?.userId);
        const formValues = getValues();
        dispatch(setInitialDetails(formValues));
        dispatch(setStep("ProjectBaselineData"));
      }}
      onBack={() => {}}
      subHeading={formHeads.details}
      currentStepNumber={1}
      showBackBtn={false}
      showStepper={false}
    >
      <Box {...styles("formTitle")}>{formHeads.title}</Box>
      <Box {...styles("inputContainer")}>
        <Box {...styles("input")}>
          <AutoComplete
            options={landCategoryOption}
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="kindOfDegradedLand"
            rules={{ required: errorMessage.required }}
            control={control}
            placeholder="Select"
            label="Select the type of Degraded Land"
            readOnly={true}
          />
        </Box>
        {!user?.data?.stateId ? (
          <Box {...styles("input")}>
            <AutoComplete
              options={stateOptions}
              onOptionSelect={(e) => {
                if (stateOption?.length && e) {
                  setValue("districtName", "");
                }
              }}
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="stateName"
              rules={{ required: errorMessage.required }}
              control={control}
              placeholder="State"
              label="State"
            />
          </Box>
        ) : (
          <Box {...styles("input")}>
            <Input
              name="stateName"
              control={control}
              label="State"
              value={user.data.state}
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              placeholder="Enter State"
              readOnly={true}
            />
          </Box>
        )}
        <Box {...styles("input")}>
          <AutoComplete
            options={district.length ? district : []}
            rules={{ required: errorMessage.required }}
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="districtName"
            control={control}
            placeholder="District"
            label="District"
          />
        </Box>
        {/* <Box {...styles("input")}>
          <AutoComplete
            options={divisionoptions.length ? divisionoptions : []}
            rules={{ required: errorMessage.required }}
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="division"
            control={control}
            placeholder=" Enter Division"
            label="Division"
          />
        </Box> */}
        <Box {...styles("input")}>
          <Input
            name="division"
            control={control}
            label="Division"
            value={user.data.division}
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            placeholder="Enter Division"
            readOnly={true}
          />
        </Box>
        <Box {...styles("input")}>
          <Input
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="areaSize"
            rules={{
              required: errorMessage.required,
              min: {
                value: 5,
                message: "Area should be greater than 5 hectare",
              },
            }}
            control={control}
            errors={errors}
            placeholder="Enter the Total Land Area Size (in Hectares)"
            label="Total Land Area"
            type="number"
          />
          <Box
            sx={{
              color: "#272727",
              fontSize: "11px",
              fontWeight: "400",
              position: "relative",
              bottom: !!errors.areaSize ? "-4px" : "10px",
            }}
          >
            (Not less than 5 hectares)
          </Box>
        </Box>
        <Box {...styles("input")}>
          <Input
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="range"
            rules={{ required: errorMessage.required }}
            control={control}
            errors={errors}
            placeholder="Enter your Range"
            label="Range"
          />
        </Box>
        <Box {...styles("input")}>
          <Input
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="treeCanopyDensity"
            rules={{
              max: {
                value: 0.4,
                message:
                  "Tree canopy density value should not be greater than 0.40",
              },
              required: errorMessage.required,
            }}
            control={control}
            errors={errors}
            placeholder="Enter your Tree Canopy Density"
            label="Tree Canopy Density"
            type="number"
          />
          <Box
            sx={{
              color: "#272727",
              fontSize: "11px",
              fontWeight: "400",
              position: "relative",
              bottom: !!errors.treeCanopyDensity ? "-4px" : "10px",
            }}
          >
            (in percentage between 0 - 0.4)
          </Box>
        </Box>
        <Box {...styles("input")}>
          <Input
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="compartment"
            control={control}
            errors={errors}
            placeholder="Enter your Compartment No"
            label="Compartment No"
            type="number"
          />
        </Box>
        <Box {...styles("input")}>
          <Input
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="beta"
            control={control}
            errors={errors}
            placeholder="Enter your Beat"
            label="Beat"
          />
        </Box>
        <Box {...styles("input")}>
          <Input
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="khasraNumber"
            control={control}
            errors={errors}
            type="number"
            placeholder="Enter your Survey No"
            label="Survey No"
          />
        </Box>
      </Box>
    </FormWrapper>
  );
};

export default ProjectDetails;
