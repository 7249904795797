import {
  Box,
  FormControl,
  FormControlProps,
  InputBase,
  InputBaseProps,
} from "@mui/material";
import { indexing } from "src/constants/common";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import defaultStyles, { StylesClasses } from "./styles";
import { CustomStyles, getStyles } from "src/styles/theme";

type OtpProps<T> = UseControllerProps<T> &
  InputBaseProps &
  FormControlProps & {
    customStyles?: CustomStyles<StylesClasses>;
    errors?: FieldErrors;
    name: string;
    otpFields?: number;
    setValue: UseFormSetValue<T>;
    getValues: UseFormGetValues<T>;
    loading?: boolean;
    allowCopyPaste?: boolean;
  };

type SetValue<T> = PathValue<T, Path<T> & string>;

const OtpInput = <T extends FieldValues>({
  name,
  type = "text",
  errors,
  rules,
  customStyles,
  inputProps,
  control,
  setValue,
  getValues,
  otpFields = 4,
  loading = false,
  allowCopyPaste = false,
  ...rest
}: OtpProps<T>) => {
  const initialValue = useMemo(() => Array(otpFields).fill(""), [otpFields]);
  const [state, setState] = useState(initialValue);
  const otpIterations = Array(otpFields).fill(0);
  const [focusIndex, setFocusIndex] = useState(0);
  const ref = useRef(null);

  const otpValue = getValues(name);
  useEffect(() => {
    if (!otpValue) setState(initialValue);
  }, [otpValue, initialValue]);

  useEffect(() => {
    if (ref?.current) {
      ref.current.focus();
    }
  }, [focusIndex]);

  useEffect(() => {
    setValue(name, state.join("") as SetValue<T>);
  }, [state, setValue, name]);

  const focusLeft = useCallback((index: number) => {
    let currFocIndex = index > 0 ? index - 1 : -1;
    setFocusIndex(currFocIndex);
  }, []);

  const focusRight = useCallback(
    (index: number) => {
      let currFocIndex = index + 1 === otpFields ? -1 : index + 1;
      setFocusIndex(currFocIndex);
    },
    [otpFields]
  );

  const handleOnChange = (index: number, targetValue: string): void => {
    const asciiValue = targetValue?.charCodeAt(0);
    const isNumeric = asciiValue >= 48 && asciiValue <= 57;

    if (isNumeric) {
      setState((val) => {
        const newState = [...val];
        newState[index] = targetValue;
        return newState;
      });
      focusRight(index);
    }
  };

  const handleKeyDown = (index: number, key: string) => {
    if (key === "Backspace" || key === "Delete") {
      if (state[index] !== "")
        setState((val) => {
          const newState = [...val];
          newState[index] = "";
          return newState;
        });
      else if (key === "Backspace") focusLeft(index);
    }
    if (key === "ArrowRight") focusRight(index);
    if (key === "ArrowLeft") focusLeft(index);
    if (key === "Tab")
      setFocusIndex((prev) => (prev + 1 === otpFields ? -1 : prev + 1));
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    if (!allowCopyPaste) return;
    const pastedData = e.clipboardData
      .getData("text/plain")
      .trim()
      .slice(0, otpFields);
    if (!isNaN(+pastedData)) {
      setState(pastedData.split(""));
      setValue(name, pastedData as SetValue<T>);
    }
  };

  const showError = !!errors[name];
  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        validate: {
          ...rules?.validate,
          isEmpty: (value) =>
            !value ||
            value.length === otpFields ||
            `${otpFields - value.length} fields are empty`,
        },
      }}
      render={({ field }) => (
        <Box {...styles("otpWrapper")}>
          <Box
            component="label"
            htmlFor="verification-code"
            {...styles("hide")}
          >
            Verification Code
          </Box>
          {otpIterations.map((_, index) => (
            <FormControl
              {...styles("otpBox")}
              error={showError}
              variant="outlined"
              key={`otp-field-${index}`}
            >
              <InputBase
                aria-label={`Verification Code ${indexing[index]} digit`}
                id="verification-code"
                autoFocus={index === 0}
                inputRef={index === focusIndex ? ref : field.ref}
                type={type}
                value={state[index]}
                onChange={(event) => {
                  handleOnChange(index, event.nativeEvent["data"]);
                }}
                onClick={() => setFocusIndex(index)}
                onKeyUp={(event) => handleKeyDown(index, event.key)}
                {...styles("otpField")}
                className={`${
                  state[index] || index === focusIndex ? "border" : "no-border"
                } ${state[index] && "filled"}`}
                inputProps={inputProps}
                error={showError}
                onPaste={handlePaste}
                {...rest}
              />
            </FormControl>
          ))}
        </Box>
      )}
      {...rest}
    />
  );
};

export default OtpInput;
