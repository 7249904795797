import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NodalOfficerDetailsFormData } from "src/components/feature/Onboarding/NodalOfficerDetails";
import { resetFormAction } from "src/redux/createActions/resetForms";

interface InitialState {
  formData: NodalOfficerDetailsFormData;
}

const initialState: InitialState = {
  formData: {
    fullName: "",
    designation: "",
    password: "",
    email: "",
    phone: {
      countryCode: "+91",
      number: "",
    },
    mobilePhone: {
      countryCode: "+91",
      number: "",
    },
  },
};

const NodalOfficerDetailsSlice = createSlice({
  name: "NodalOfficerDetails",
  initialState,
  reducers: {
    updateNodalOfficerDetails: (
      state,
      action: PayloadAction<NodalOfficerDetailsFormData>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateNodalOfficerDetails } = NodalOfficerDetailsSlice.actions;

export default NodalOfficerDetailsSlice.reducer;
