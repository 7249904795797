import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentUploadFormData } from "src/components/feature/Onboarding/DocumentUpload";

import { resetFormAction } from "src/redux/createActions/resetForms";

interface InitialState {
  formData: DocumentUploadFormData;
}

const initialState: InitialState = {
  formData: {
    documentType: "",
    documentImage: [],
    documentNumber: "",
    registration: "",
    isDocumentVerified: false,
  },
};

const documentUploadSlice = createSlice({
  name: "documentUploadSlice",
  initialState,
  reducers: {
    updateDocumentUploadForm: (
      state,
      action: PayloadAction<DocumentUploadFormData>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateDocumentUploadForm } = documentUploadSlice.actions;

export default documentUploadSlice.reducer;
