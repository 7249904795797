import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    width: "100%",
  },
  border: {
    "& .css-i4lt5-MuiInputBase-root": {
      borderRadius: "6px",
      borderColor: "custom.border.primary",
    },
    minWidth: "160px",
  },
});

export default styles;
