import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  container: {},
  children: {
    marginTop: "24px",
  },
});

export default styles;
