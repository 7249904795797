import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Input, PhoneNumber } from "src/components/common/FormComponents";
import Password from "src/components/common/FormComponents/Password";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { updateStepper } from "src/redux/actions";
import { updateNodalOfficerDetails } from "src/redux/slices/onboarding/nodalOfficerDetails";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type NodalOfficerDetailsFormData = {
  fullName: string;
  designation: string;
  email: string;
  password: string;
  phone: {
    countryCode: string;
    number: string;
  };
  mobilePhone: {
    countryCode: string;
    number: string;
  };
};

const NodalOfficerDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { formData } = useAppSelector(
    (state) => state.root.nodalOfficerDetails
  );

  const { password: passwordRegex } = regex;

  const { control, formState, handleSubmit, getValues, setValue, watch } =
    useForm<NodalOfficerDetailsFormData>({
      mode: "onTouched",
      defaultValues: {
        ...formData,
      },
    });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: NodalOfficerDetailsFormData) => {
    dispatch(
      updateNodalOfficerDetails({
        ...data,
      })
    );
    dispatch(updateStepper({ id: 4 }));
    navigate(path.implementingOfficerDetails);
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Nodal Officer Details"
        previousRoute={path.onboarding}
        isValid={isValid}
      >
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <Input
              name="fullName"
              label="Name"
              placeholder="Enter your full name"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>
          <Box {...styles("input")}>
            <Password
              name="password"
              label="Password"
              placeholder="Enter your password"
              control={control}
              getValues={getValues}
              showHelperText
              customStyles={{
                customError: defaultStyles.pwdErrorMsg,
              }}
              height="52px"
              rules={{
                required: true,
                validate: {
                  isLength: (value: string) => value && value.length >= 8,
                  isUpper: (value) =>
                    value && passwordRegex.isUpper.test(value.toString()),
                  isLower: (value) =>
                    value && passwordRegex.isLower.test(value.toString()),
                  isNum: (value) =>
                    value && passwordRegex.isNum.test(value.toString()),
                  hasSpecialCharacter: (value) =>
                    value &&
                    passwordRegex.hasSpecialCharacter.test(value.toString()),
                },
              }}
              errors={errors}
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              name="designation"
              label="Designation"
              placeholder="Enter Designation"
              control={control}
              rules={{ required: errorMessage.required }}
              errors={errors}
              height="52px"
            />
          </Box>

          <Box {...styles(["input", "withHelperText"])}>
            <PhoneNumber
              control={control}
              errors={errors}
              name="phone"
              label="Contact Number (Landline)"
              isRequired={false}
            />
          </Box>

          <Box {...styles(["input", "withHelperText"])}>
            <PhoneNumber
              control={control}
              errors={errors}
              name="mobilePhone"
              label="Contact Number (Mobile)"
            />
          </Box>

          <Box {...styles(["input", "withHelperText"])}>
            <Input
              name="email"
              label="Email"
              placeholder="Enter your email"
              control={control}
              rules={{
                required: errorMessage.required,
                pattern: {
                  value: regex.email,
                  message: errorMessage.invalidEmail,
                },
              }}
              errors={errors}
              height="52px"
            />
          </Box>
        </Box>
      </OnboardingWrapper>
    </Box>
  );
};

export default NodalOfficerDetails;
