import { Box, Grid } from "@mui/material";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import Checkbox from "src/components/common/FormComponents/Checkbox";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { SuccessModal } from "src/components/common/modal";
import { useState } from "react";
import { useMutation } from "react-query";
import { postMonitoringComponentDetail } from "src/utils/api/dashboard";
import { useSearchParams } from "react-router-dom";
import { useComponentName } from "src/hook/monitoring-component";
import { ENUM_MONITORING_TYPE } from "src/constants/common";

export type FormType = {
  isNurseryCreated: boolean;
  area: number;
  numberOfBeds: number;
  monthOfCreation: string;
  noOfSeedLingsAvailable: number;
  onsite: boolean;
  offsite: boolean;
  distanceFromTheSite: number;
  remark: string;
  images: ["a", "b"];
};
export function NurseryForm({ param, yearMonth }: any) {
  const styles = getStyles(defaultStyles);
  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");
  const [selected, setSelected] = useState("onsite");
  const { setComponentName } = useComponentName();
  const form = useForm<FormType>({
    defaultValues: {
      isNurseryCreated: false,
      onsite: true,
      offsite: false,
    },
  });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
    watch,
  } = form;

  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    heading: "",
    subHeading: "",
  });
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };
  const handlePopUpClose = () => {
    if (openModal.type === "success") {
      setComponentName(undefined);
    } else {
      handleModalClose();
    }
  };

  const handleCheckbox = (value: string) => {
    if (value === selected) {
      setValue("onsite", false);
      setValue("offsite", false);
      setSelected("");
    } else {
      if (value === "onsite") {
        setValue("onsite", true);
        setValue("offsite", false);
      } else if (value === "offsite") {
        setValue("offsite", true);
        setValue("onsite", false);
      }
      setSelected(value);
    }
  };

  const { mutate } = useMutation(
    ["nursary"],
    ({ data }: { data: any }) => postMonitoringComponentDetail(data),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          type: "success",
          heading: "Success",
          subHeading: "Nursery Detail Submitted Successfully",
        });
      },
      onError: () => {
        setOpenModal({
          open: true,
          type: "unSuccess",
          heading: "Failed",
          subHeading: "Something Went Wrong",
        });
      },
    }
  );
  const onSubmit = (data: any) => {
    const imageKeys = data.images.map((item) => item.key);

    mutate({
      data: {
        ...data,
        type: ENUM_MONITORING_TYPE.NURSERY,
        year: param.year,
        month: param.month,
        landOwnershipEpid,
        yearMonth: yearMonth,
        area: data.isNurseryCreated ? parseInt(data.area, 10) : null,
        numberOfBeds: data.isNurseryCreated
          ? parseInt(data.numberOfBeds, 10)
          : null,
        monthOfCreation: data.isNurseryCreated ? data.monthOfCreation : null,
        noOfSeedLingsAvailable: data.isNurseryCreated
          ? parseInt(data.noOfSeedLingsAvailable, 10)
          : null,
        distanceFromTheSite: data.offsite
          ? parseInt(data.distanceFromTheSite, 10)
          : null,
        isNursingCreated: data.isNurseryCreated,
        onsite: data.onsite,
        offsite: data.offsite,
        images: imageKeys,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        {...styles("formWrapper")}
      >
        <Box {...styles("formElementWrapper")}>
          <Checkbox
            name="isNurseryCreated"
            label="Nursery Created"
            customStyles={{ wrapper: { width: "fit-content" } }}
          />
          {!!watch("isNurseryCreated") && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Input
                    customStyles={{
                      label: styles("inputLabel").sx,
                      wrapper: styles("inputWrapper").sx,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                      // errorMsg: styles("errorMsg").sx,
                    }}
                    name="area"
                    control={control}
                    errors={errors}
                    placeholder="Area (Ha)"
                    label="Area (Ha.)"
                    type="number"
                    rules={{
                      required: errorMessage.required,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Input
                    customStyles={{
                      label: styles("inputLabel").sx,
                      wrapper: styles("inputWrapper").sx,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                      // errorMsg: styles("errorMsg").sx,
                    }}
                    name="numberOfBeds"
                    control={control}
                    errors={errors}
                    placeholder="Number Of Beds (No.)"
                    label="Number of Beds (No.)"
                    type="number"
                    rules={{
                      required: errorMessage.required,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Input
                    customStyles={{
                      label: styles("inputLabel").sx,
                      wrapper: styles("inputWrapper").sx,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                      // errorMsg: styles("errorMsg").sx,
                    }}
                    name="monthOfCreation"
                    control={control}
                    errors={errors}
                    placeholder="Month/Year of Creation"
                    label="Month/Year of Creation"
                    rules={{
                      required: errorMessage.required,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Input
                    customStyles={{
                      label: styles("inputLabel").sx,
                      wrapper: styles("inputWrapper").sx,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                      // errorMsg: styles("errorMsg").sx,
                    }}
                    name="noOfSeedLingsAvailable"
                    control={control}
                    errors={errors}
                    placeholder="Number of Seedlings Available"
                    label="Number of Seedlings Available"
                    type="number"
                    rules={{
                      required: errorMessage.required,
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: "20px" }}>
                <Checkbox
                  name="onsite"
                  label="On Site"
                  isManual
                  handleChange={() => handleCheckbox("onsite")}
                  checked={selected === "onsite"}
                  customStyles={{ wrapper: { width: "fit-content" } }}
                />
                <Checkbox
                  name="offsite"
                  label="Off Site"
                  // control={control}
                  isManual
                  handleChange={() => handleCheckbox("offsite")}
                  checked={selected === "offsite"}
                  customStyles={{ wrapper: { width: "fit-content" } }}
                />
                {!!watch("offsite") && (
                  <Input
                    customStyles={{
                      label: styles("inputLabel").sx,
                      wrapper: styles("inputWrapper").sx,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                      // errorMsg: styles("errorMsg").sx,
                    }}
                    name="distanceFromTheSite"
                    control={control}
                    errors={errors}
                    placeholder="Distance from the Site (km)"
                    label="Distance from the Site (km)"
                    type="number"
                    rules={{
                      required: errorMessage.required,
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>

        {/* <Box {...styles("formElementWrapper")}></Box> */}

        <Box {...styles("formElementWrapper")}>
          <Input
            customStyles={{
              label: styles("inputLabel").sx,
              wrapper: styles("inputWrapper").sx,
              input: {
                borderColor: "#36B1A0;",
                boxShadow: "unset",
              },
              // errorMsg: styles("errorMsg").sx,
            }}
            name="remark"
            control={control}
            errors={errors}
            placeholder="Nursery Operation Observations "
            label="Nursery Operation Observations "
            rules={{
              required: errorMessage.required,
            }}
          />
        </Box>
        <Box>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={{ margin: "20px 20px " }}
          >
            <FileUpload
              accept="image/jpeg, image/png, image/webp, image/jpg"
              name="images"
              label="Upload GeoTagged Images (Minimum 2 images are required )"
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              maxLimit={10}
              multiple={true}
              required={true}
              supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
              trigger={trigger}
              rules={{
                validate: {
                  isLength: (value) => {
                    return value && value["length"] >= 2;
                  },
                },
              }}
              dragDropHeading=""
            />
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button text="Submit" type="submit" disabled={!isValid} />
        </Box>

        <SuccessModal
          open={openModal.open}
          heading={openModal.heading}
          subheading={openModal.subHeading}
          closeOnBackDrop
          type={openModal.type}
          handleModalClose={() => handlePopUpClose()}
        />
      </Box>
    </FormProvider>
  );
}
