import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/common/FormComponents";
import Stepper from "src/components/common/Stepper";
import { getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type OnboardingProps = {
  children?: React.ReactNode;
  hideBackBtn?: boolean;
  heading: string;
  previousRoute?: string;
  isValid?: boolean;
  nextBtnLabel?: string;
};

const OnboardingWrapper = ({
  children,
  hideBackBtn = false,
  heading,
  previousRoute = undefined,
  isValid = true,
  nextBtnLabel = "next",
}: OnboardingProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const navigate = useNavigate();

  const handleBack = () => {
    previousRoute && navigate(previousRoute);
  };

  const marqueeData = [
    {
      text: "Forest dept has started land registration. Registration of entities will start on 6th March 2024.",
    },
  ];

  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("bodyWrapper")}>
        <Box {...styles("stepper")}>
          <Stepper />
        </Box>
        {/* <MarqueHeader data={marqueeData} /> */}
        <Box {...styles("children")}>
          <Box {...styles("heading")}>{heading}</Box>
          <Box {...styles("childrenBody")}>{children}</Box>
        </Box>
        <Box
          {...styles("buttonsContainer", {
            ...(hideBackBtn && { justifyContent: "flex-end" }),
          })}
        >
          {!hideBackBtn && (
            <Button
              text="Back"
              variant="outlined"
              onClick={handleBack}
              customStyles={{ outlined: defaultStyles.btnDimensions }}
            />
          )}
          <Button
            text={nextBtnLabel}
            type="submit"
            disabled={!isValid}
            customStyles={{ contained: defaultStyles.btnDimensions }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingWrapper;
