import { FormControlLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import React from "react";
import defaultStyles, { StylesClasses } from "./styles";
import { CustomStyles, getStyles } from "src/styles/theme";

const RadioInput = ({
  checked,
  handleChange,
  value,
  label,
  customStyles,
}: {
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  value: string;
  label?: string;
  customStyles?: CustomStyles<StylesClasses>;
}) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <FormControlLabel
      {...styles("wrapper")}
      value={value}
      control={
        <Radio
          checked={checked}
          onChange={handleChange}
          value={value}
          name="radio-buttons"
          inputProps={{ "aria-label": "B" }}
          {...styles("wrapper")}
          disableRipple={false}
        />
      }
      label={label}
    />
  );
};

export default RadioInput;
