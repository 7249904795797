import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  root: {
    display: "flex",
    color: "secondary.main",
    fontSize: "20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    minWidth: 100,
  },

  outlined: {
    color: "primary.main",
    fontWeight: "600",

    "&:disabled": {
      backgroundColor: "custom.greyish.5",
      opacity: "0.2",
      border: "none",
    },
  },

  contained: {
    "&:disabled": {
      color: "secondary.main",
      backgroundColor: "primary.main",
      opacity: "0.2",
    },
  },

  text: {
    color: "primary.main",
  },

  label: {
    fontWeight: 600,
  },

  circle: {
    color: "custom.white.100",
  },

  circleForTextBtn: {
    color: "primary.main",
  },

  textButton: {
    fontWeight: 600,
    "&:hover": {
      color: "primary.main",
    },
  },
  iconButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
  buttonIcon: {
    height: "30px",
    width: "30px",
  },
});

export default styles;
