import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  DateInput,
  FileUpload,
  Input,
  Select,
} from "src/components/common/FormComponents";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import {
  DOCUMENT_ENUM,
  documentUpload as documentUploadOptions,
} from "src/constants/common";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { updateDocumentUploadForm, updateStepper } from "src/redux/actions";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { VerifyDocument } from "src/types/api";
import { verifyDocument } from "src/utils/api/signUp";
import defaultStyles, { StylesClasses } from "./styles";

export type DocumentUploadFormData = {
  documentType: string;
  documentImage: any[];
  documentNumber: string;
  isDocumentVerified: boolean;
  registration?: string;
};

const DocumentUpload = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { documentUpload, organizationDetails } = useAppSelector(
    (state) => state.root
  );

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    watch,
  } = useForm<DocumentUploadFormData>({
    mode: "onTouched",
    defaultValues: {
      ...documentUpload.formData,
    },
  });

  const { mutate, isLoading } = useMutation(
    "verifyDocument",
    (data: VerifyDocument) => verifyDocument(data),
    {
      onSuccess: (res: AxiosResponse) => {
        setValue("isDocumentVerified", true);
      },
      onError: (err: AxiosError) => {
        setError("documentNumber", { message: err.response.data.message });
      },
    }
  );

  const isVerifyDisable = !(
    !!watch("documentType") &&
    !!watch("documentImage") &&
    !!watch("documentNumber")
  );

  const handleDocVerification = () => {
    mutate({
      identificationNo: watch("documentNumber"),
      name: organizationDetails.formData.organizationName,
      docType: watch("documentType"),
    });
  };

  const onSelectingDocument = (value: string) => {
    setValue("documentNumber", "");
    setValue("isDocumentVerified", false);
  };

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: DocumentUploadFormData) => {
    dispatch(updateDocumentUploadForm({ ...data }));
    dispatch(updateStepper({ id: 3 }));
    navigate(path.addressDetails);
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Document Upload"
        previousRoute={path.organizationDetails}
        isValid={
          isValid &&
          (watch("documentType") !== DOCUMENT_ENUM.PAN
            ? true //watch("isDocumentVerified")
            : true)
        }
      >
        <Box {...styles("wrapper")}>
          <Box {...styles(["input"])}>
            <Select
              name="documentType"
              label="Document Type"
              control={control}
              onInputSelect={(value) => onSelectingDocument(value)}
              errors={errors}
              customStyle={{ wrapper: defaultStyles.selectWrapper }}
              rules={{ required: errorMessage.required }}
              height="52px"
              disabled={!!watch("documentImage").length}
              options={documentUploadOptions}
            />
          </Box>
          <Box {...styles("fileUploadArea")}>
            <FileUpload
              name="documentImage"
              label={
                watch("documentType") === DOCUMENT_ENUM.PAN
                  ? "Upload Pan Card Image"
                  : "Upload CIN/GSTIN(UIN) Image"
              }
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              maxLimit={1}
              // reqOcr={watch("documentType") === DOCUMENT_ENUM.PAN}
              /*  uploadMetaData={{
                documentType:
                  watch("documentType") === DOCUMENT_ENUM.PAN ? "pan" : "gst",
                type: "front",
                raw: true,
              }} */
              trigger={trigger}
              customStyles={{
                wrapper: defaultStyles.borderStyle,
                fileType: defaultStyles.fileType,
              }}
              rules={{
                validate: {
                  isLength: (value) => {
                    return value && value["length"] >= 1;
                  },
                },
              }}
              dragDropHeading="(document front image)"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              name="documentNumber"
              label={
                watch("documentType") === DOCUMENT_ENUM.PAN
                  ? "Pan Number"
                  : "CIN/GSTIN UIN Number"
              }
              // type="number"
              placeholder={
                watch("documentType") === DOCUMENT_ENUM.PAN
                  ? "Enter your pan number"
                  : "Enter your CIN/GSTIN UIN number"
              }
              control={control}
              disabled={!!watch("isDocumentVerified")}
              height="52px"
              rules={{
                required: errorMessage.required,
                /*  minLength: {
                  value: 5,
                  message: errorMessage.minLength,
                },
                maxLength: {
                  value: 8,
                  message: errorMessage.maxLength,
                }, */
              }}
            />
            {/*    {watch("documentType") !== DOCUMENT_ENUM.PAN && (
              <>
                {isLoading ? (
                  <CircularProgress size={20} {...styles("loading")} />
                ) : (
                  <>
                    {watch("isDocumentVerified") ? (
                      <Box {...styles("verified")}>
                        <Verified accentHeight={10} color="primary" /> Verified
                      </Box>
                    ) : (
                      <Box
                        {...styles(["verified", "verify"], {
                          ...(isVerifyDisable && {
                            cursor: "not-allowed",
                            color: "custom.disabled.btn",
                          }),
                        })}
                        onClick={() =>
                          !isVerifyDisable && handleDocVerification()
                        }
                      >
                        Verify Document
                      </Box>
                    )}
                  </>
                )}
              </>
            )} */}
          </Box>
          {(watch("documentType")?.toLowerCase() === "gst" ||
            watch("documentType")?.toLowerCase() === "cin") && (
            <Box {...styles(["input"])}>
              <DateInput
                name="registration"
                label="Date of registration"
                control={control}
                errors={errors}
                minAge={0}
                rules={{ required: errorMessage.required }}
                clearErrors={clearErrors}
              />
            </Box>
          )}
        </Box>
      </OnboardingWrapper>
    </Box>
  );
};

export default DocumentUpload;
