import { Box, Button } from "@mui/material";
import ViewForm from "src/components/common/ViewForm";
import { getStyles } from "src/styles/theme";
import defautlStyles from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "react-query";
import { deleteComponentView } from "src/utils/api/dashboard";
import { useDeletionState } from "src/hook/deletion-state";
import { useState } from "react";
import { SuccessModal } from "src/components/common/modal";
import ViewImages from "src/components/common/ViewImage";

export function FencingView({ data, images, submitted }: any) {
  const fencingDetail = data;
  const styles = getStyles(defautlStyles);

  const { setComponentDeletionState } = useDeletionState();
  const viewData = {
    "Actual RMT Found (m)": fencingDetail.actualRMTFound,
    "Actual Pillar (No.)": fencingDetail.actualPillars,
    "Fencing Type": fencingDetail.fencingType,
    "Month and Year": fencingDetail.yearMonth,
    Observations: fencingDetail.remark,
  };

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: () => deleteComponentView(data.id),
    onSuccess: () => {
      console.log("Fencing detail deleted successfully.");
      setComponentDeletionState((prev) => !prev);
    },
    onError: (error) => {
      console.error("Error deleting fencing detail:", error);
    },
  });

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "Delete This Component",
    subheading: "Are you sure?",
    description: "",
    btnText: "Confirm",
    type: "Alert",
  });
  const handleResponseModal = () => {
    mutate();
    handleModalClose();
  };
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  return (
    <Box {...styles("viewWrapper")}>
      {Object.entries(viewData).map(([label, value]) => (
        <ViewForm label={label} value={value} key={label} />
      ))}
      <Box {...styles("heading")}>Geo Tagged Images</Box>
      <Box {...styles("imageContainer")}>
        {images?.map((image) => (
          <ViewImages fileUrl={image.url} />
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {!submitted && (
          <Button
            endIcon={<DeleteIcon />}
            onClick={() => setOpenModal((prev) => ({ ...prev, open: true }))}
            disabled={isLoading}
          >
            {isLoading ? "Deleting..." : "Delete"}
          </Button>
        )}
      </Box>

      {isError && <Box sx={{ color: "red" }}>Failed to delete. Try again.</Box>}

      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      />
    </Box>
  );
}
