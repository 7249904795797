import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    flexDirection: { xs: "column", sm: "row" },
    gap: "3%",
  },

  input: {
    flexBasis: { xs: "100%", sm: "75%", lg: "34%" },
    margin: "0 0 24px 0",
  },
  inputPopup: {
    flexBasis: { xs: "100%", sm: "100%", lg: "100%" },
    margin: "0 0 24px 0",
  },

  formWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "20px 0",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.greyish.6",
    padding: "15px 20px",
    borderRadius: "15px",
    maxWidth: { xs: "100%", lg: "60%" },
  },

  projectName: {
    fontSize: "24px",
    fontWeight: "500",
    mb: "20px",
    mt: "20px",
  },

  selectWrapper: {
    minWidth: "80px",
    borderTopRightRadius: "0px",
  },

  heading: {
    fontSize: "18px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "black",
    borderBottom: "1px solid rgb(54, 177, 160)",
    marginBottom: "30px",
  },

  phoneCodeError: {
    whiteSpace: "nowrap",
  },

  label: {
    fontSize: "14px",
    mt: "15px",
  },

  phoneCode: {
    maxWidth: "50px",
  },

  address: {
    flexBasis: "100%",
  },

  select: {
    flexBasis: "45%",
  },

  checkbox: {
    flexBasis: "100%",
  },

  withHelperText: {
    display: "flex",
    flexDirection: "column",
  },

  fieldHelperText: {
    flexDirection: "column",
    color: "custom.text.dark",
    opacity: "0.6",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
  },

  emailLine: {},
  otpLabel: {
    marginBottom: "8px",
    width: "200px",
    textAlign: "left",
  },

  otpSend: {
    color: "custom.text.dark",
    opacity: "0.6",
    marginTop: "-19px",
    fontSize: "14px",
    fontWeight: 400,
  },

  pwdErrorMsg: {
    position: { xs: "static", marginTop: "-6px" },
  },

  description: {
    color: "custom.text.light",
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "10px",
    textAlign: "center",
  },

  inputContainer: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    gap: "2%",
    flexWrap: "wrap",
  },
});

export default styles;
