import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "& .MuiRadio-root": {
      padding: "0px",
    },

    "& .Mui-checked": {
      position: "relative",

      "&::after": {
        position: "absolute",
        content: "''",
        backgroundImage: "url('/icons/selectedRadio.svg')",
        width: "16px",
        height: "16px",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
  },

  labelStyle: {
    fontSize: "14px",
    color: "custom.text.black.100",
  },
  iIcon: {
    "&: hover": {},
  },
});

export default styles;
