import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FileUpload, Input } from "src/components/common/FormComponents";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import { OTP } from "src/components/common/modal";
import { OtpType } from "src/constants/common";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import {
  updateAadhaarVerificationForm,
  updateStepper,
} from "src/redux/actions";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type AadhaarVerificationFormData = {
  aadhaarNumber: string;
  aadhaarOtp: string;
  aadhaarCard: any[];
  aadhaarCardBack: any[];
  compareAadhaar?: string;
  isAadhaarVerified: boolean;
};

const AadhaarVerification = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { aadhaarVerification, personalDetails } = useAppSelector(
    (state) => state.root
  );

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    getFieldState,
    watch,
  } = useForm<AadhaarVerificationFormData>({
    mode: "all",
    defaultValues: {
      ...aadhaarVerification.formData,
    },
  });

  /*  const aadhaarNumber = watch("aadhaarNumber");
  const compareAadhaar = watch("compareAadhaar"); */

  // useEffect(() => {
  //   if (!!compareAadhaar) {
  //     compareAadhaar !== aadhaarNumber
  //       ? setValue("isAadhaarVerified", false)
  //       : setValue("isAadhaarVerified", true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [aadhaarNumber]);

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: AadhaarVerificationFormData) => {
    // const { aadhaarOtp, ...rest } = data;
    dispatch(updateAadhaarVerificationForm({ ...data }));
    dispatch(updateStepper({ id: 3 }));
    navigate(path.addressDetails);
  };

  const handleAadhaarOtp = (otp: string) => {
    setValue("aadhaarOtp", otp);
    setValue("isAadhaarVerified", true);
    setValue("compareAadhaar", watch("aadhaarNumber"));
    trigger();
  };

  const handleUpload = (data) => {
    setValue("isAadhaarVerified", false);
    setValue(
      "aadhaarNumber",
      data.data.parsedData.aadhaar_no ?? data.data.parsedData.doc_id
    );
    trigger("aadhaarNumber");
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Aadhaar Verification"
        previousRoute={path.personalDetails}
        isValid={isValid && !!watch("isAadhaarVerified")}
      >
        <Box {...styles("wrapper")}>
          <FileUpload
            name="aadhaarCard"
            label="Upload Aadhaar Card Image"
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            maxLimit={1}
            disabled={
              watch("isAadhaarVerified") && !!watch("aadhaarCard").length
            }
            trigger={trigger}
            reqOcr
            getFieldState={getFieldState}
            uploadMetaData={{
              documentType: "aadhaar",
              name: personalDetails.formData.fullName,
              type: "front",
              raw: true,
            }}
            onSuccess={(data) => handleUpload(data)}
            customStyles={{
              fileType: defaultStyles.fileType,
            }}
            rules={{
              // required: true,
              validate: {
                isLength: (value) => {
                  return value && value["length"] === 1;
                },
              },
            }}
            dragDropHeading="(Aadhaar Card Front Image)"
          />
          <FileUpload
            name="aadhaarCardBack"
            label="Upload Aadhaar Card Image"
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            maxLimit={1}
            disabled={
              watch("isAadhaarVerified") && !!watch("aadhaarCardBack").length
            }
            required={false}
            reqOcr
            trigger={trigger}
            getFieldState={getFieldState}
            uploadMetaData={{
              documentType: "aadhaar",
              type: "back",
              raw: false,
            }}
            customStyles={{
              fileType: defaultStyles.fileType,
            }}
            /*  rules={{
              validate: {
                isLength: (value) => {
                  return value && value["length"] === 1;
                },
              },
            }} */
            dragDropHeading="(Aadhaar Card Back Image)"
          />
          <Box {...styles(["input", "aadhaarOtpWrapper"])}>
            <Input
              name="aadhaarNumber"
              label="Aadhaar Number"
              placeholder="Enter your aadhaar number"
              type="number"
              control={control}
              disabled={watch("isAadhaarVerified")}
              rules={{
                required: errorMessage.required,
                pattern: {
                  value: regex.aadhaarRegex,
                  message: errorMessage.aadharPattern,
                },
              }}
              height="52px"
            />
            {/* <Box {...styles("sendOTP")}>Send OTP</Box> */}
          </Box>

          <Box {...styles("otpSend")}>
            OTP will be sent on your registered mobile number.
          </Box>
          <OTP
            name="aadhaarOtp"
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            closeOnBackDrop={false}
            // disableSend={!watch("aadhaarCard").length}
            isVerified={watch("isAadhaarVerified")}
            watch={watch}
            type={{
              type: OtpType.AADHAAR,
              aadhaarSerialNo: watch("aadhaarNumber"),
            }}
            verifyOtp={handleAadhaarOtp}
            heading="Verify your Aadhaar"
            subHeading="You might have received an OTP on your Aadhaar registered number. Please enter the same, If not click on resend OTP."
            label="Verify Aadhaar"
            rules={{
              required: true,
            }}
            customStyles={{
              label: defaultStyles.otpLabel,
            }}
          />
        </Box>
      </OnboardingWrapper>
    </Box>
  );
};

export default AadhaarVerification;
