import { Navigate } from "react-router-dom";
import { routes } from "src/constants/routes.module";
import { useAppSelector } from "src/redux/store";

type PrivateRouteProps = {
  element: () => JSX.Element;
  path?: string;
  exact?: boolean;
  index?: boolean;
  isAuthRoute?: boolean;
};

const PrivateRoute = ({
  element: Component,
  isAuthRoute = false,
  path = "/",
  exact = false,
  index = false,
  ...rest
}: PrivateRouteProps) => {
  const { isLoggedIn } = useAppSelector((state) => state.root.user);

  return isLoggedIn ? (
    <Component {...rest} />
  ) : (
    <Navigate to={routes.signIn.path} />
  );
};

export default PrivateRoute;
