import { InitialState } from "./types";

export const initialState: InitialState = {
  formData: {
    initialDetails: {
      kindOfDegradedLand: "82092737-0b4b-4d2a-9adf-64721654b10c",
      stateName: "",
      districtName: "",
      areaSize: null,
      khasraNumber: "",
      division: "",
      range: "",
      grossareaSize: null,
      beta: "",
      compartment: "",
      treeCanopyDensity: null,
      coordinates: {
        lat: "",
        long: "",
      },
    },

    baselineData: {
      edaphicDetails: "",
      slopeAndTerrain: "",
      geologicalFeatures: "",
      hydrologicalConditions: "",
      forestType: "",
    },

    uploadedIds: {
      gpsBoundaries: {
        id: "",
        url: "",
      },
      gpsBoundariesCsv: {
        id: "",
        url: "",
      },
    },

    uploadedImages: [],

    otherDetails: {
      totalNoOfGrids: null,
      landFreeFromEncumbrances: false,
    },
  },
  isError: {
    isError: false,
    msg: "",
  },
  stepName: "ProjectDetails",
  stepDetail: {
    stepName: "ProjectDetails",
    totalStep: 4,
    currentStep: 1,
  },
  showModal: false,
  projectType: "TREE_PLANTATION",
};
