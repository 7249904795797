import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    position: "fixed",
    top: "0px",
    height: "75px",
    zIndex: "1100",
    width: "100%",
    backgroundColor: "custom.header.background",
    padding: "10px 40px",
    justifyContent: "space-between",
  },
  firstHalf: {
    display: "flex",
    gap: "20px",
  },
  secondHalf: {
    display: "flex",
    gap: "20px",
  },
});

export default styles;
