import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import {
  AutoComplete,
  Button,
  DateInput,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { getStyles } from "src/styles/theme";
import {
  getLandDetailsById,
  submitDemandDraft,
} from "src/utils/api/createProjectFlow";
import defaultStyles, { StylesClasses } from "./styles";
import { formatToIndianCurrency } from "src/utils/helper";
import ViewForm from "src/components/common/ViewForm";
import { useEffect } from "react";

export type demandNoteFormData = {
  demandRecipt: {
    type: string;
    key: string;
    file: string;
  }[];
};

const DemandNote = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(
    "changeStatus",
    ({
      imageId,
      id,
      amount,
      transactionDate,
      transactionType,
      transactionNumber,
      bankName,
      branchName,
      ifscCode,
      accountNumber,
      adminChargesTDS,
      ecoRestorationCost,
      adminCharges,
      additionalInfo,
      ecoRestorationCostTDS,
    }: {
      imageId: string;
      id: string;
      amount: number;
      transactionDate: string;
      transactionType: any;
      transactionNumber: string;
      bankName: string;
      adminChargesTDS: number;
      branchName: string;
      ifscCode: string;
      accountNumber: number;
      ecoRestorationCost: number;
      adminCharges: number;
      additionalInfo: string;
      ecoRestorationCostTDS: number;
    }) =>
      submitDemandDraft({
        imageId,
        id,
        amount,
        transactionDate,
        transactionType,
        transactionNumber,
        bankName,
        branchName,
        adminChargesTDS,
        ifscCode,
        accountNumber,
        ecoRestorationCost,
        adminCharges,
        additionalInfo,
        ecoRestorationCostTDS,
      }),
    {
      onSuccess: () => {
        navigate(path.home);
      },
      onError: () => {},
    }
  );

  const { data: landData } = useQuery(
    ["landDetails", id],
    () => getLandDetailsById(id),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res.data);
      },
      onError: (req: AxiosError) => {},
      enabled: !!id,
    }
  );

  const transactionType = [
    {
      label: "NEFT",
      value: "NEFT",
    },
    {
      label: "RTGS",
      value: "RTGS",
    },
    {
      label: "IMPS",
      value: "IMPS",
    },
  ];

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      demandNote: null,
    },
  });

  const ecoRestorationCostTDS = watch("ecoRestorationCostTDS");
  const adminChargesTDS = watch("adminChargesTDS");
  const ecoRestorationCost = watch("ecoRestorationCost");
  const adminCharges = watch("adminCharges");
  const ecoRestoration = watch("ecoRestoration");
  const admin = watch("admin");

  useEffect(() => {
    // setValue(
    //   "totalEcoRestoration",
    //   parseFloat(ecoRestoration || 0) + parseFloat(admin || 0)
    // );

    // Update Total TDS Amount Paid when TDS fields change
    setValue(
      "totalTDSPaid",
      parseFloat(ecoRestorationCostTDS || 0) + parseFloat(adminChargesTDS || 0)
    );

    // Update Total Amount Paid when actual cost fields change
    setValue(
      "amount",
      parseFloat(ecoRestorationCost || 0) + parseFloat(adminCharges || 0)
    );
  }, [
    ecoRestorationCostTDS,
    adminChargesTDS,
    ecoRestorationCost,
    adminCharges,
    setValue,
  ]);

  const handleForm = (data: any) => {
    mutate({
      id,
      imageId: data.demandRecipt[0].key,
      amount: data.amount,
      transactionDate: data.transactionDate,
      transactionType: data.transactionType,
      transactionNumber: data.transactionNumber,
      bankName: data.bankName,
      adminChargesTDS: data.adminChargesTDS,
      branchName: data.branchName,
      ifscCode: data.ifscCode,
      accountNumber: data.accountNumber,
      ecoRestorationCost: data.ecoRestorationCost,
      adminCharges: data.adminCharges,
      additionalInfo: data.additionalInformation,
      ecoRestorationCostTDS: data.ecoRestorationCostTDS,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleForm)}>
      <DashboardWrapper
        title="Update Payment Details"
        customStyles={{
          container: { position: "relative" },
          children: { paddingBottom: "80px" },
        }}
        backPath={`/`}
        showBackBtn
      >
        <Box {...styles("formWrapper")}>
          <Box {...styles("heading")}>ICFRE Bank Account Details</Box>
          <ViewForm
            label="Bank Account Name"
            value={"Green Credit Programme Administrator"}
          />
          <ViewForm label="Account Number" value={"496902010091029"} />
          <ViewForm label="IFSC Code" value={"UBIN0549690"} />
          <ViewForm label="Bank Name" value={"Union Bank of India"} />
          <ViewForm
            label="Branch Name"
            value={"Forest Research Institute, Dehradun"}
          />
        </Box>
        <Box {...styles("formWrapper")}>
          <Box {...styles("heading")}>Demand Note Details</Box>
          <Box
            component="a"
            href={`${"/landownership-details"}?${createSearchParams({
              id: landData?.data?.id,
            })}`}
            sx={{ ...defaultStyles.renderButtonText2 }}
          >
            <ViewForm label="Cost Estimate ID" value={landData?.data?.id} />
          </Box>

          <ViewForm
            label="Eco-Restoration Cost"
            value={formatToIndianCurrency(landData?.data?.demandNote)}
          />
          <ViewForm
            label="Admin Charges"
            value={formatToIndianCurrency(landData?.data?.icfreCharges)}
          />
          <ViewForm
            label=" Demand Note Amount"
            value={`${
              (landData?.data?.demandNote &&
                landData?.data?.icfreCharges &&
                formatToIndianCurrency(
                  +landData?.data?.demandNote + +landData?.data?.icfreCharges
                )) ??
              "-"
            }`}
          />
        </Box>
        <Box sx={{ fontSize: "20px", mb: "10px", mt: "30px", fontWeight: 500 }}>
          Payment Amount Details
        </Box>
        <Box {...styles("wrapper")}>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="ecoRestoration"
              value={landData?.data?.demandNote}
              // rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Eco-Restoration Cost"
              label="Eco-Restoration Cost(As Per Demand Note)"
              type="number"
              readOnly={true}
            />
          </Box>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="ecoRestorationCostTDS"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="TDS Amount (If Any)"
              label="TDS Amount (If Any)"
              type="number"
            />
          </Box>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="ecoRestorationCost"
              rules={{
                required: errorMessage.required,
                max: {
                  value: landData?.data?.demandNote,
                  message: "Cannot be greater than Eco-Restoration Cost",
                },
              }}
              control={control}
              errors={errors}
              placeholder="Actual Eco-Restoration Cost Made"
              label="Actual Eco-Restoration Cost Made"
              type="number"
            />
          </Box>
        </Box>
        <Box {...styles("wrapper")}>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="admin"
              value={landData?.data?.icfreCharges}
              control={control}
              errors={errors}
              placeholder="Admin Cost"
              label="Admin Cost"
              type="number"
              readOnly={true}
            />
          </Box>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="adminChargesTDS"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Admin Cost TDS Amount (If Any)"
              label="Admin Cost TDS Amount (If Any)"
              type="number"
            />
          </Box>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="adminCharges"
              rules={{
                required: errorMessage.required,
                max: {
                  value: landData?.data?.icfreCharges,
                  message: "Cannot be greater than Admin Cost",
                },
              }}
              control={control}
              errors={errors}
              placeholder="Actual Admin Cost Made"
              label="Actual Admin Cost Made"
              type="number"
            />
          </Box>
        </Box>
        <Box {...styles("wrapper")}>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              value={
                +landData?.data?.demandNote + +landData?.data?.icfreCharges
              }
              name="totalEcoRestoration"
              control={control}
              errors={errors}
              placeholder="Demand Note Amount"
              label="Demand Note Amount"
              type="number"
              readOnly={true}
            />
          </Box>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="totalTDSPaid"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Total TDS Amount Paid"
              label="Total TDS Amount Paid"
              type="number"
            />
          </Box>
          <Box {...styles("smallInput")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="amount"
              rules={{
                required: errorMessage.required,
              }}
              control={control}
              errors={errors}
              placeholder="Total Amount Paid"
              label="Total Amount Paid"
              type="number"
            />
          </Box>
        </Box>
        <Box {...styles("wrapper")}>
          <Box {...styles("input")}>
            <AutoComplete
              options={transactionType}
              rules={{ required: errorMessage.required }}
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="transactionType"
              control={control}
              placeholder="Transaction Type"
              label="Transaction Type"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="transactionNumber"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Transaction/UTR Number"
              label="Transaction/UTR Number"
            />
          </Box>
          <Box {...styles("input")}>
            <DateInput
              name="transactionDate"
              label="Transaction Date"
              control={control}
              errors={errors}
              minAge={0}
              rules={{ required: errorMessage.required }}
              clearErrors={clearErrors}
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="bankName"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Bank Name"
              label="Bank Name"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="branchName"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Branch Name"
              label="Branch Name"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="ifscCode"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="IFSC Code"
              label="IFSC Code"
            />
          </Box>
          <Box {...styles("input")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
              }}
              name="accountNumber"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Account Number"
              label="Account Number"
              type="number"
            />
          </Box>

          <Box {...styles("wrapper")}>
            <Box {...styles(["input", "fileUpload"])}>
              <FileUpload
                accept="image/jpeg, image/png, image/webp, image/jpg"
                name="demandRecipt"
                label="Upload Payment Receipt"
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                maxLimit={5}
                trigger={trigger}
                customStyles={{
                  wrapper: defaultStyles.borderStyle,
                  fileType: defaultStyles.fileType,
                  label: {
                    whiteSpace: "wrap",
                  },
                }}
                rules={{
                  validate: {
                    isLength: (value) => {
                      return value && value["length"] >= 1;
                    },
                  },
                }}
                dragDropHeading=""
              />
            </Box>
          </Box>

          <Box {...styles("input")}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: defaultStyles.multilineInput,
              }}
              name="additionalInformation"
              rules={{
                maxLength: {
                  value: 500,
                  message: "More then 500 characters are not allowed",
                },
                required: errorMessage.required,
              }}
              control={control}
              type="text"
              placeholder="(If any TDS is deducted Please Specify in 500 characters)"
              label="Additional Information"
              multiline
              minRows={6}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Button
            text="Submit"
            type="submit"
            // disabled={!isValid}
            customStyles={{
              root: {
                fontSize: "16px",
              },
            }}
          />
        </Box>
      </DashboardWrapper>
    </Box>
  );
};

export default DemandNote;
