import { Box, Grid } from "@mui/material";
import defaultStyles from "./styles";
import { getStyles } from "src/styles/theme";
import {
  Button,
  FileUpload,
  Input,
  Select,
} from "src/components/common/FormComponents";
import { useForm } from "react-hook-form";
import errorMessage from "src/constants/errorMessage";
import { useMutation } from "react-query";
import { postMonitoringComponentDetail } from "src/utils/api/dashboard";
import { useState } from "react";
import { useComponentName } from "src/hook/monitoring-component";
import { useSearchParams } from "react-router-dom";
import { SuccessModal } from "src/components/common/modal";
import {
  ENUM_MONITORING_TYPE,
  WATER_HARVESTING_STRUCTURE_TYPE,
} from "src/constants/common";

type FormType = {
  contourTrench: number;
  waterHarvestingStructures: number;
  otherDetailsCounts: number;
  waterHarvestingStructureType: string;
  otherDetails: string;
  remark: string;
  images: File[];
};

export function SmcForm({ param, monthYear }: any) {
  const styles = getStyles(defaultStyles);
  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");

  const form = useForm<FormType>();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = form;

  const { setComponentName } = useComponentName();

  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    heading: "",
    subHeading: "",
  });
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };
  const handlePopUpClose = () => {
    if (openModal.type === "success") {
      setComponentName(undefined);
    } else {
      handleModalClose();
    }
  };

  const { mutate } = useMutation(
    ["smc"],
    ({ data }: { data: any }) => postMonitoringComponentDetail(data),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          type: "success",
          heading: "Success",
          subHeading: "SMC Monitoring Detail Submitted Successfully",
        });
      },
    }
  );
  const onSubmit = (data: any) => {
    const imageKeys = data.images.map((item) => item.key);

    mutate({
      data: {
        type: ENUM_MONITORING_TYPE.SMC,
        landOwnershipEpid,
        ...param,
        ...monthYear,
        ...data,
        contourTrench: parseInt(data.contourTrench, 10),
        waterHarvestingStructures: parseInt(data.waterHarvestingStructures, 10),
        otherDetailsCounts: parseInt(data.otherDetailsCounts, 10),
        images: imageKeys,
      },
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      {...styles("formWrapper")}
    >
      <Box {...styles("formElementWrapper")}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Input
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="contourTrench"
              control={control}
              errors={errors}
              placeholder="Contour Trench/Staggered Control Trench (No.)"
              label="Contour Trench/Staggered Control Trench (No.)"
              type="number"
              rules={{ required: errorMessage.required }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              name="waterHarvestingStructureType"
              placeholder="Water Harvesting Structure Type"
              label="Water Harvesting Structure Type"
              control={control}
              errors={errors}
              options={WATER_HARVESTING_STRUCTURE_TYPE}
              rules={{
                required: errorMessage.required,
              }}
              customStyle={{
                wrapper: { mt: "14px" },
                input: styles("formselect").sx,
                label: { fontSize: "14px" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              name="waterHarvestingStructures"
              control={control}
              errors={errors}
              placeholder="Water Harvesting Structure Count (No.)"
              label="Water Harvesting Structure Count (No.)"
              type="number"
              rules={{ required: errorMessage.required }}
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box {...styles("formElementWrapper")}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              name="otherDetails"
              control={control}
              errors={errors}
              placeholder="Other Details"
              label="Other Details"
              rules={{ required: errorMessage.required }}
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="otherDetailsCounts"
              control={control}
              errors={errors}
              placeholder="Other Details Count (No.)"
              label="Other Details Count (No.)"
              type="number"
              rules={{ required: errorMessage.required }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box {...styles("formElementWrapper")}>
        <Input
          customStyles={{
            label: styles("inputLabel").sx,
            wrapper: styles("inputWrapper").sx,
            input: {
              borderColor: "#36B1A0;",
              boxShadow: "unset",
            },
          }}
          name="remark"
          control={control}
          errors={errors}
          placeholder="Overall SMC Observations"
          label="Overall SMC Observations"
          rules={{ required: errorMessage.required }}
        />
      </Box>

      <Box>
        <Grid container item spacing={3} xs={12} sx={{ margin: "20px 20px " }}>
          <FileUpload
            accept="image/jpeg, image/png, image/webp, image/jpg"
            name="images"
            label="Upload GeoTagged Images (Minimum 2 images are required )"
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            maxLimit={10}
            multiple={true}
            required={true}
            supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
            trigger={trigger}
            rules={{
              validate: {
                isLength: (value) => {
                  return value && value["length"] >= 2;
                },
              },
            }}
            dragDropHeading=""
          />
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button text="Submit" type="submit" disabled={!isValid} />
      </Box>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subHeading}
        closeOnBackDrop
        type={openModal.type}
        handleModalClose={() => handlePopUpClose()}
      />
    </Box>
  );
}
