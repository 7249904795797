import dataProvider from "src/dataProvider";
import { BaseOptionResponse, TreeSpeciesResponse } from "src/types/api";
import { ProjectInterface, StateOptionResponse } from "src/types/common";

let fetcher = dataProvider("adminInstance");

export const getFiles = (id: string) => {
  return fetcher.get(`/upload/file/${id}`);
};
export const getProjectDetailsById = (id: string) => {
  return fetcher.get<ProjectInterface>(`/projects/${id}`);
};
export const addConsentApi = (project_id: string, data: any) => {
  return fetcher.post(`/projects/${project_id}/consent`, data);
};

export const updateConsentApi = (project_id: string, data: any) => {
  return fetcher.put(`/projects/${project_id}/consent`, data);
};

export const createNewProject = async (data: ProjectInterface) => {
  return fetcher.post<ProjectInterface>("/projects", data);
};
export const updateProject = async (
  project_id: string,
  data: ProjectInterface
) => {
  return fetcher.put<ProjectInterface>(`/projects/${project_id}`, data);
};

export const getProjectType = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/project-type`);
};

export const getLandCategoryOptions = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/land-category`);
};

export const getMaterialDetailsOptions = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/material-detail`);
};

export const getInstitutionTypeOptions = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/institution-type`);
};

export const getOwnerShipOptions = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/land-ownership-type`);
};
export const getStructureTypeOptions = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/structure-type`);
};
export const getTreeSpeciesOptions = async ({
  districtId,
  speciesType,
}: {
  districtId?: string;
  speciesType?: string;
}) => {
  return fetcher.get<TreeSpeciesResponse[]>(
    `/master/tree-species?districtId=${districtId}`
  );
};
export const getStateOptions = async () => {
  return fetcher.get<StateOptionResponse>(`/master/state`);
};
export const getDivisionOptions = async (stateId) => {
  return fetcher.get<any>(`/master/division?stateId=${stateId}`);
};
export const getIncomeRangeOptions = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/income-range`);
};
export const getindustryOptions = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/industry-type`);
};
export const getSelfDeceleration = async () => {
  return fetcher.get(`/master/self-deceleration-form`);
};
export const getLandTypeOptions = async () => {
  return fetcher.get<BaseOptionResponse[]>(`/master/land-type`);
};
export const getProjectOptions = async (data) => {
  return fetcher.get<any>(
    `/projects/location-based?projectType=${data.projectType}&projectStateIds=${data.selectedState}`
  );
};
export const getProjectOptionsDetails = async (data) => {
  return fetcher.get<any>(`/projects/${data.ID}/kml-info`);
};
export const registerTreePlantation = (data: any) => {
  return fetcher.post(`/projects/land-ownership`, data);
};

export const getTreePlantation = (queryParams: any) => {
  return fetcher.get(`/projects/land-ownership`, queryParams);
};

// export const getAllProjects = async (queryParams: any) => {
//   return fetcher.get<{ count: number; rows: ProjectInterface[] }>(
//     `${"/projects"}`,
//     queryParams
//   );
// };

export const submitDemandDraft = (data: {
  imageId: string;
  id: string;
  amount: number;
  transactionDate: string;
  transactionType: string;
  transactionNumber: string;
  bankName: string;
  branchName: string;
  ifscCode: string;
  accountNumber: number;
  ecoRestorationCost: number;
  adminCharges: number;
  additionalInfo: string;
  adminChargesTDS: number;
  ecoRestorationCostTDS: number;
}) => {
  return fetcher.patch<any>(`/projects/land-ownership/${data.id}/payment`, {
    imageId: data.imageId,
    amount: +data.amount,
    transactionDate: data.transactionDate,
    transactionType: data.transactionType,
    transactionNumber: data.transactionNumber,
    bankName: data.bankName,
    branchName: data.branchName,
    ifscCode: data.ifscCode,
    accountNumber: data.accountNumber,
    ecoRestorationCost: data.ecoRestorationCost,
    adminCharges: data.adminCharges,
    additionalInfo: data.additionalInfo,
    adminChargesTDS: data.adminChargesTDS,
    ecoRestorationCostTDS: data.ecoRestorationCostTDS,
  });
};

export const getLandDetailsById = async (id: string) => {
  return fetcher.get<any>(`/projects/land-ownership/${id}`);
};

export const getCSVData = async (id: string) => {
  return fetcher.get<any>(`/upload/read-file/${id}`);
};

export const getAllRegistration = async (queryParams: any) => {
  return fetcher.get(`/projects/land-ownership`, queryParams);
};

export const getFundRequest = async (queryParams: any) => {
  return fetcher.get(`/projects/land-ownership/fund-request`, queryParams);
};

export const getFundApplicationById = async (id: string) => {
  return fetcher.get<any>(`/projects/land-ownership/fund-request/${id}`);
};

export const getCostEstimateDetails = async (queryParams: any) => {
  return fetcher.get(`/monitoring/target`, queryParams);
};
