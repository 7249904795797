import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    flexWrap: "wrap",
    gap: "3%",
    width: { xs: "50%", sm: "90%", lg: "100%" },
    backgroundColor: "#FAFAFA",
    // borderWidth: "1px",
    // borderStyle: "solid",
    // borderColor: "#36B1A0",
    padding: "15px 20px",
    borderRadius: "15px",
    margin: "32px 0px",
  },

  firstFormWrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "3%",
    width: { xs: "50%", sm: "90%", lg: "100%" },
    backgroundColor: "#EBFAF8",
    marginBottom: "30px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#36B1A0",
    padding: "15px 20px",
    borderRadius: "15px",
  },

  subComponentFormWrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    flexWrap: "wrap",
    gap: "3%",
    width: { xs: "50%", sm: "90%", lg: "100%" },
    backgroundColor: "white",
    alignItems: "center",
    borderWidth: "1px",
    borderStyle: "solid",
    // borderColor: "#",
    padding: "10px 20px",
    borderRadius: "15px",
  },

  subSeedlingFormWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: { xs: "column", lg: "row" },
    flexWrap: "wrap",
    gap: "3%",
    backgroundColor: "white",
    marginBottom: "32px",
    borderWidth: "1px",
    borderStyle: "solid",
    // borderColor: "#36B1A0",
    padding: "15px 20px",
    borderRadius: "15px",
  },
  flexInput: {
    display: "flex",
    // backgroundColor: "#E0F5F2",
    alignItems: "center",
    gap: "15px",
    marginTop: "18px",
    flexBasis: { xs: "100%", sm: "75%", lg: "27%" },
  },

  errorCustom: {},

  customWrapper: {
    "& .MuiInputBase-root": {
      border: "none",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      height: "48px",
      width: "80px",
    },
    minWidth: "80px",
    position: "absolute",
    top: "-5px",
    left: "1px",
    zIndex: "100",
  },
  input: {
    margin: "0 0 24px 0",
    flexBasis: { xs: "100%", sm: "75%", lg: "27%" },
  },
  fullInput: {
    margin: "0 0 24px 0",
    flexBasis: { xs: "100%", sm: "75%", lg: "20%" },
  },
  fullWidhtInput: {
    margin: "0 0 24px 0",
    flexBasis: { xs: "100%", sm: "75%", lg: "60%" },
  },
  documentGroup: {
    display: "flex",
    gap: "2%",
    margin: "10px 0",
  },
  phoneNumber: {},
  combine: {
    position: "relative",
  },
  inpGroup: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  selectWrapper: {
    minWidth: "100px",
    borderTopRightRadius: "0px",
    borderColor: "unset",
  },

  fileUpload: {
    flexBasis: "100%",
  },
  borderStyle: {
    borderColor: "custom.border.otpDash",
    border: "2px dashed",
    borderRadius: "12px",
    backgroundColor: "custom.background.secondaryGrey",
  },
  fileType: {
    opacity: "0.5",
    fontSize: "13px",
    fontFamily: "400",
  },
  bottomText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "custom.text.dark",
    opacity: 0.4,
    marginTop: "6px",
  },

  countryCode: {
    "& .MuiInputBase-root": {
      borderTopRightRadius: "0px",
      borderTopRIghtColor: "none",
      borderTopLeftRadius: "12px",
      borderBottomRightRadius: "0px",
      borderBottomRightColor: "none",
      borderBottomLeftRadius: "12px",
    },
    minWidth: "80px",
  },
  phoneCustom: {
    position: "relative",
    top: "-30px",
    "& .MuiInputBase-root": {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    label: {
      position: "relative",
      borderColor: "unset",
    },
    minWidth: "80px",
    marginTop: {
      sm: "31px",
      xs: "28px",
    },
  },

  multilineInput: {
    height: "100%",
  },

  fieldHelperText: {
    flexDirection: "column",
    color: "custom.text.dark",
    opacity: "0.6",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
  },

  otpLabel: {
    whiteSpace: "nowrap",
  },

  label: {
    fontSize: "14px",
    mt: "15px",
  },
  date: {
    marginTop: { xs: "0", lg: "16px" },
  },
  formWrapper: {
    margin: "20px 0",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.greyish.6",
    padding: "15px 20px",
    borderRadius: "15px",
    maxWidth: { xs: "100%", lg: "95%" },
    display: "flex",

    backgroundColor: "#E0F5F2",
  },
  heading: {
    fontSize: "20px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "black",
    marginBottom: "20px",
  },
  renderButtonText2: {
    display: "block",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "600",
    color: "#36B1A0",
    textDecoration: "none",
  },
});

export default styles;
