import { GoogleMap } from "@react-google-maps/api";
import { memo, useEffect, useState } from "react";

type MapComponentProps = {
  kmlUrl: any;
};

const MapComponent = ({ kmlUrl = "" }: MapComponentProps) => {
  const [map, setMap] = useState(null);

  const onLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (map) {
      const kmlLayer = new window.google.maps.KmlLayer({
        url: kmlUrl,
        map: map,
      });

      return () => {
        kmlLayer.setMap(null);
      };
    }
  }, [kmlUrl, map]);

  return (
    <GoogleMap
      mapContainerStyle={{ height: "400px", width: "100%" }}
      center={{ lat: 0, lng: 0 }}
      zoom={2}
      onLoad={onLoad}
    ></GoogleMap>
  );
};

export default memo(MapComponent);
