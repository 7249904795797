import { Box, Button, CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { ComponentProps } from "react";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

export type Action = {
  label?: string;
} & ComponentProps<typeof Button>;

type PopUpProps = {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  actionLeft: Action;
  actionRight: Action;
  heading: string;
  handleRightBtnClick?: () => void;
  loading?: boolean;
  loadingText?: string;
  customStyles?: CustomStyles<StylesClasses>;
  note?: string;
  closeOnBackDrop?: boolean;
};

const PopUp = ({
  open,
  handleClose,
  children,
  actionLeft,
  actionRight,
  handleRightBtnClick,
  heading,
  loading = false,
  loadingText,
  customStyles,
  note,
  closeOnBackDrop = true,
}: PopUpProps) => {
  const {
    label: actionLeftLabel,
    hidden: actionLeftHidden,
    onClick: actionLeftOnClick,
  } = actionLeft;
  const {
    label: actionRightLabel,
    hidden: actionRightHidden,
    color: actionRightColor,
    type: actionRightType,
    disabled: actionRightDisabled,
    onClick: actionRightOnClick,
  } = actionRight;

  const newActionLeft = {
    label: actionLeftLabel || "Cancel",
    onClick: actionLeftOnClick || handleClose,
  };

  const newActionRight = {
    label: actionRightLabel || "Okay",
    color: actionRightColor || "primary",
    type: actionRightType || "button",
    disabled: actionRightDisabled || false,
    onClick: actionRightOnClick || handleClose,
  };

  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick" || closeOnBackDrop) handleClose();
      }}
    >
      <Box {...styles("wrapper")}>
        {loading ? (
          <Box {...styles("progressWrapper")}>
            <CircularProgress />
          </Box>
        ) : (
          <Box {...styles("contentBox")}>
            <Box className="heading"></Box>
            {children}

            {!actionRightHidden && (
              <Box {...styles("actionButtons")}>
                {!actionLeftHidden && !loading && (
                  <Button
                    variant="text"
                    {...styles("actionLeft")}
                    onClick={newActionLeft.onClick}
                  >
                    {newActionLeft?.label}
                  </Button>
                )}
                {!actionRightHidden &&
                  (!loading ? (
                    <Button
                      variant="contained"
                      {...styles("loaderTxtWrapper")}
                      onClick={handleRightBtnClick}
                      color={newActionRight.color}
                      type={newActionRight.type}
                      disabled={newActionRight.disabled}
                    >
                      {newActionRight?.label}
                    </Button>
                  ) : (
                    <Box {...styles("loaderTxtWrapper")}>
                      <Box {...styles("progressWrapper")}>
                        <CircularProgress {...styles("progressStyles")} />
                      </Box>
                      {loadingText}
                    </Box>
                  ))}
              </Box>
            )}

            {note && <Box {...styles("note")}>{note}</Box>}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PopUp;
