import { Box, Chip as MuiChip } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import ToolTip from "../Tooltip";
import defaultStyles, { StylesClasses } from "./styles";

type Status<
  T extends StylesClasses =
    | "success"
    | "error"
    | "draft"
    | "inactive"
    | "active"
> = T;

type SideBarProps = {
  customStyles?: CustomStyles<StylesClasses>;
  label: string;
  onClick?: () => void;
  onDelete?: () => void;
  Icon?: React.ReactNode;
  variant?: "filled" | "outlined";
  status?: Status;
  description: string;
  showTooltip?: boolean;
  tooltipContent?: {
    title: string;
    description: string;
  };
};

const Chip = ({
  customStyles,
  label,
  onClick,
  variant = "filled",
  status = "draft",
  description = "",
  tooltipContent,
  showTooltip,
}: SideBarProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <>
      <MuiChip
        label={label}
        onClick={onClick}
        variant={variant}
        {...styles(status)}
      />
      {showTooltip && (
        <ToolTip title={label} description={description}>
          <Box
            sx={{ marginLeft: "5px" }}
            component={"img"}
            src="/assets/images/RejectIcon.png"
          ></Box>
        </ToolTip>
      )}
    </>
  );
};

export default Chip;
