import { Box } from "@mui/material";
import { Button } from "src/components/common/FormComponents";
import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";

const FormWrapper = ({
  children,
  isValid,
  onBack,
  onNext,
  showButton = true,
  subHeading,
  totalStep,
  currentStepNumber,
  showStepper = true,
  showBackBtn = true,
  btnText = "Next",
  isLoading,
  loaderSize,
}: any) => {
  const styles = getStyles(defaultStyles);
  const stepDetail = useAppSelector(
    (state) => state.root.selectNewProjectSetup.stepDetail
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "80vh",
        paddingBottom: "10px",
        overflow: "hidden",
      }}
    >
      {showStepper ? (
        <Box {...styles("stepperContainer")}>
          <Box {...styles("stepperWrapper")}>
            <Box component="span" {...styles("currentStep")}>
              {currentStepNumber}
            </Box>{" "}
            of <Box component="span">{stepDetail.totalStep}</Box>
          </Box>
          <Box {...styles("stepperLabel")}>{subHeading}</Box>
        </Box>
      ) : (
        <></>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "70vh",
        }}
      >
        <Box {...styles("scrollContent")}>{children}</Box>
        {showButton && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {showBackBtn ? (
              <Button
                text="back"
                variant="outlined"
                onClick={onBack}
                customStyles={{
                  iconButtonContainer: {
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                }}
              />
            ) : (
              <Box></Box>
            )}
            <Button
              text={btnText}
              loading={isLoading}
              type="submit"
              disabled={!isValid}
              onClick={onNext}
              loaderSize={loaderSize}
              customStyles={{
                iconButtonContainer: { fontSize: "14px", fontWeight: "500" },
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FormWrapper;
