import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  Button,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import errorMessage from "src/constants/errorMessage";
import { useMutation } from "react-query";
import { postMonitoringComponentDetail } from "src/utils/api/dashboard";
import { useSearchParams } from "react-router-dom";
import { SuccessModal } from "src/components/common/modal";
import { useState } from "react";
import { useComponentName } from "src/hook/monitoring-component";
import { MultiSelect } from "src/components/common/FormComponents/MultiSelect";
import { ENUM_MONITORING_TYPE, FENCING_TYPE } from "src/constants/common";

export type FormType = {
  rmtFound: string;
  pillars: string;
  images: File[];
  fencingType: string;
  remark: string;
};

export function FencingForm({ param, yearMonth }: any) {
  const styles = getStyles(defaultStyles);
  const form = useForm<FormType>();

  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");
  const { setComponentName } = useComponentName();
  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    heading: "",
    subHeading: "",
  });
  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };
  const handlePopUpClose = () => {
    if (openModal.type === "success") {
      setComponentName(undefined);
    } else {
      handleModalClose();
    }
  };
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = form;
  const { mutate } = useMutation(
    ["fencing"],
    ({ data }: { data: any }) => postMonitoringComponentDetail(data),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          type: "success",
          heading: "Success",
          subHeading: "Fencing Monitoring Detail Submitted Successfully",
        });
      },
      onError: () => {
        setOpenModal({
          open: true,
          type: "unSuccess",
          heading: "Failed",
          subHeading: "Something Went Wrong",
        });
      },
    }
  );
  const onSubmit = (data: any) => {
    const imageKeys = data.images.map((item) => item.key);

    mutate({
      data: {
        type: ENUM_MONITORING_TYPE.FENCING,
        landOwnershipEpid,
        year: param.year,
        month: param.month,
        yearMonth: yearMonth,
        actualRMTFound: parseInt(data.rmtFound, 10),
        actualPillars: parseInt(data.pillars, 10),
        remark: data.remark,
        images: imageKeys,
        fencingType: data.fencingType.join(","),
      },
    });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      {...styles("formWrapper")}
    >
      <Box {...styles("formElementWrapper")}>
        <Grid container item spacing={3} xs={12}>
          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="rmtFound"
              control={control}
              errors={errors}
              placeholder="Actual RMT Found (m)"
              label="Actual RMT Found (m)"
              type="number"
              rules={{ required: errorMessage.required }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="pillars"
              control={control}
              errors={errors}
              placeholder="Actual Pillars (No.)"
              label="Actual Pillars (No.)"
              type="number"
              rules={{
                required: errorMessage.required,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: styles("inputLabel").sx,
                wrapper: styles("inputWrapper").sx,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="remark"
              control={control}
              errors={errors}
              placeholder="Observations"
              label="Observations"
              rules={{
                required: errorMessage.required,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MultiSelect
              name="fencingType"
              placeholder="Type of Fencing"
              label="Type of Fencing"
              control={control}
              errors={errors}
              options={FENCING_TYPE}
              rules={{
                required: errorMessage.required,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container item spacing={3} xs={12} sx={{ margin: "20px 20px " }}>
          <FileUpload
            accept="image/jpeg, image/png, image/webp, image/jpg"
            name="images"
            label="Upload GeoTagged Images (Minimum 2 images are required )"
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            maxLimit={10}
            multiple={true}
            required={true}
            supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg Size Upto 10MB"
            trigger={trigger}
            rules={{
              validate: {
                isLength: (value) => {
                  return value && value["length"] >= 2;
                },
              },
            }}
            dragDropHeading=""
          />
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button text="Submit" type="submit" disabled={!isValid} />
      </Box>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subHeading}
        closeOnBackDrop
        type={openModal.type}
        handleModalClose={() => handlePopUpClose()}
      />
    </Box>
  );
}
