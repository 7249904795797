import { createSlice } from "@reduxjs/toolkit";

type allStates = {
  allStates: {
    description: string | null;
    districts: { id?: string; name?: string; description?: string | null }[];
    id: string;
    name: string;
  }[];

  districts: { id?: string; name?: string; description?: string | null }[];
  bankDistricts: { id?: string; name?: string; description?: string | null }[];
};

const initialState: allStates = {
  allStates: [],
  districts: [],
  bankDistricts: [],
};
const getStates = createSlice({
  name: "states",
  initialState,
  reducers: {
    getStatesData: (state, action) => {
      state.allStates = action.payload;
    },
    setDisticts: (state, action) => {
      state.districts = action.payload;
    },
    setBankDistricts: (state, action) => {
      state.bankDistricts = action.payload;
    },
  },
});

export const { getStatesData, setDisticts, setBankDistricts } =
  getStates.actions;
export default getStates.reducer;
