import { Box, Button, Typography } from "@mui/material";
import { useQuery } from "react-query";
import StatsCard from "src/components/common/StatsCard";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  getDashboardDetails,
  getTableDashboardDetails,
} from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "../styles";
import { getStyles } from "src/styles/theme";
import BarChartComponent from "src/components/common/BarComponent";
import {
  exportEcoRestorationTableToXlsx,
  formatDataForBarChart,
} from "src/utils/helper";
import { Collapse } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  EcoRestoartionDashBoardTable,
  ecoRestorationDashBoardTable,
} from "src/components/common/DashboardTable/ecoRestortationTable";
import { useState } from "react";
import { AxiosResponse } from "axios";

const EcoRestorationDashboard = () => {
  const [tableRows, setTableRows] = useState([]);

  const [tableState, setTableState] = useState(false);

  const [isTabLoading, setIsTabLoading] = useState(true);

  const [queryParams, setQueryParams] = useState<{
    projectStateIds?: string[];
    type: string;
  }>({
    projectStateIds: undefined,
    type: "landLeased",
  });

  const { data: dashboard, isLoading: dashboardLoading } = useQuery(
    ["dashboard"],
    () => getDashboardDetails(),
    {
      onSuccess: () => {},
      onError: () => {},
      refetchOnMount: true,
    }
  );
  const styles = getStyles(defaultStyles);

  const { data: tableDashboard, isLoading } = useQuery(
    ["table-landLeased", queryParams],
    () => getTableDashboardDetails(queryParams),
    {
      onSuccess: (res: AxiosResponse) => {
        setTableRows(
          res?.data?.stats.map((items, idx) => ({
            id: idx,
            sno: idx + 1,
            state: items.state,
            totalAreaNodalVerificationPending:
              items.totalAreaNodalVerificationPending,
            totalNumberNodalVerificationPending:
              items.totalNumberNodalVerificationPending,
            totalAreaVerificationPending: items.totalAreaVerificationPending,
            totalNumberVerificationPending:
              items.totalNumberVerificationPending,
            totalAreaUnderQuery: items.totalAreaUnderQuery,
            totalNumberUnderQuery: items.totalNumberUnderQuery,
            totalAreaNodalUnderQuery: items.totalAreaNodalUnderQuery,
            totalNumberNodalUnderQuery: items.totalNumberNodalUnderQuery,
            totalAreaApproved: items.totalAreaApproved,
            totalNumberApproved: items.totalNumberApproved,
            totalAreaRejected: items.totalAreaRejected,
            totalNumberRejected: items.totalNumberRejected,
            totalAreaRegistered: items.totalAreaRegistered,
            totalNumberRegistered: items.totalNumberRegistered,
          }))
        );
        setIsTabLoading(false);
      },
      onError: () => {},
      refetchOnMount: true,
    }
  );

  const dashboardTableData: ecoRestorationDashBoardTable = {
    topHeader: [
      "",
      "All Registered",
      "SNO Verification",
      "Admin Verification",
      "SNO Query",
      "Under Query",
      "Approved",
      "Rejected",
    ],
    header: [
      "S.No",
      "Division",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
    ],
    data: tableRows,
  };

  // const handleScrolling = () => {
  //   window.scrollTo({
  //     top: document.documentElement.scrollHeight,
  //     behavior: "smooth",
  //   });
  // };
  const formattedData = formatDataForBarChart(dashboard?.data?.stats);

  return (
    <>
      <Box {...styles("topHeader")}>
        {!dashboardLoading ? (
          <Box sx={{ width: "75vw", marginBottom: "20px" }}>
            <Box sx={{ width: "80%", marginRight: "80px" }}>
              <BarChartComponent
                data={formattedData}
                label={"Status OF Eco-Restoration Blocks"}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box {...styles("statsCardWrapper")}>
          {dashboard?.data?.stats.map((stat, index) => (
            <StatsCard
              key={index}
              title={`${stat.description.toUpperCase()}`}
              value={`${stat?.number}`}
              area={stat?.totalArea}
              customStyles={{ valueCard: styles("statsCard").sx }}
            />
          ))}
        </Box>
      </Box>
      <Box {...styles("dashboardTableWrapper")}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" {...styles("heading")}>
            Status of Eco-Restoration Blocks Under GCP
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setTableState(!tableState);
              // handleScrolling();
            }}
          >
            {tableState ? (
              <>
                Compress
                <ArrowUpwardIcon sx={{ marginLeft: "10px" }} />
              </>
            ) : (
              <>
                Expand
                <ArrowDownwardIcon sx={{ marginLeft: "10px" }} />
              </>
            )}
          </Button>
        </Box>
        <Collapse in={tableState}>
          <Button
            variant="outlined"
            onClick={() =>
              exportEcoRestorationTableToXlsx(
                dashboardTableData.data,
                dashboardTableData.header,
                dashboardTableData.topHeader
              )
            }
            sx={{ float: "right", marginBottom: "20px" }}
          >
            Export
          </Button>
          <EcoRestoartionDashBoardTable {...dashboardTableData} />
        </Collapse>
      </Box>
    </>
  );
};

export default EcoRestorationDashboard;
