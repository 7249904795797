import React, { ReactNode } from "react";
// import document from 'next/document'
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { Box, Collapse, Typography } from "@mui/material";
import defaultStyles, { StylesClassNames } from "./styles";
import { CustomStyles, getStyles } from "src/styles/theme";

export type AccordionData = {
  id: string;
  label: string;
  isOpen?: boolean;
  children?: ReactNode;
  onChange?: () => void;
};

type AccordionProps = AccordionData & {
  customStyles?: CustomStyles<StylesClassNames>;
};

export function StyleAccordion({
  label,
  isOpen,
  onChange,
  customStyles,
  children,
}: AccordionProps) {
  const styles = getStyles(defaultStyles, customStyles);

  return (
    <Box {...styles("wrapper")}>
      <Box
        {...styles("toggleWrapper", {
          borderRadius: isOpen ? "12px 12px 0px 0px" : "12px",
        })}
        onClick={onChange}
      >
        <Box {...styles("infoContainer")}>
          <Typography
            variant="body1"
            {...styles("label", {
              fontWeight: isOpen ? "600" : "500",
            })}
          >
            {label}
          </Typography>
        </Box>
        <Box>
          {isOpen ? (
            <KeyboardArrowUpRoundedIcon {...styles("icons")} />
          ) : (
            <KeyboardArrowDownRoundedIcon {...styles("icons")} />
          )}
        </Box>
      </Box>
      <Collapse in={isOpen}>
        <Box {...styles("drawer")}>{children}</Box>
      </Collapse>
    </Box>
  );
}
