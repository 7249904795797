import React, { createContext, useContext, useState, ReactNode } from "react";
import { Option } from "src/types/common";

type ComponentNameContextType = {
  componentName: Option;
  setComponentName: React.Dispatch<React.SetStateAction<Option>>;
};

const ComponentNameContext = createContext<
  ComponentNameContextType | undefined
>(undefined);

export const ComponentNameProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [componentName, setComponentName] = useState();

  return (
    <ComponentNameContext.Provider value={{ componentName, setComponentName }}>
      {children}
    </ComponentNameContext.Provider>
  );
};

// Custom hook to use the context
export const useComponentName = () => {
  const context = useContext(ComponentNameContext);
  if (context === undefined) {
    throw new Error(
      "useComponentName must be used within a ComponentNameProvider"
    );
  }
  return context;
};
