import { ProjectType } from "./common";

export const tableHead = [
  {
    name: "Project Name",
    isSortable: false,
    type: "string",
  },
  {
    name: "STATUS",
    isSortable: false,
    type: "strasdasding",
  },
  {
    name: "Project Initiation date",
    isSortable: false,
    type: "strdasdasing",
  },
  {
    name: "Total duration",
    isSortable: false,
    type: "strdasdasing",
  },
  {
    name: "Total CREDIT to be disbursed",
    isSortable: false,
    type: "stadsaasdring",
  },

  {
    name: "",
    isSortable: false,
    type: "stadsaasdring",
  },
];

export const projectTypeOptions = [
  {
    title: "Register a Parcel of Land",
    description: "Lorem ipsum dolor sit amet consectetur.",
    img: "/assets/images/TreePlantationIcon.png",
    name: ProjectType.LAND_LEASED,
  },
  // {
  //   title: "Water Harvesting based Credits",
  //   description: "Lorem ipsum dolor sit amet consectetur.",
  //   img: "assets/images/waterProject.png",
  //   name: ProjectType.WATER_CONSERVATION,
  // },
  {
    title: "Register a Tree Plantation Activity",
    description: "",
    img: "/assets/images/TreePlantationIcon.png",
    name: ProjectType.TREE_PLANTATION,
  },
];

export const treeTypes = [
  {
    label: "timber Tree",
    value: "timber tree",
  },
  {
    label: "Fruits Tree",
    value: "Fruits Tree",
  },
  {
    label: "Bamboo Tree",
    value: "Bamboo Tree",
  },
];

export const activities = [
  {
    label: "timber Tree",
    value: "timber tree",
  },
  {
    label: "Fruits Tree",
    value: "Fruits Tree",
  },
  {
    label: "Bamboo Tree",
    value: "Bamboo Tree",
  },
];

export const agencyType = [
  {
    label: "Individual",
    value: "Individual",
  },
  {
    label: "Entity",
    value: "Entity",
  },
];

export const plantationTypes = [
  {
    label: "timber Tree",
    value: "timber tree",
  },
  {
    label: "Fruits Tree",
    value: "Fruits Tree",
  },
  {
    label: "Bamboo Tree",
    value: "Bamboo Tree",
  },
];

export const treeSizes = [
  {
    label: "Small",
    value: "Small",
  },
  {
    label: "Medium",
    value: "Medium",
  },
];

export const treeSubHeaders = [
  {
    title: "Entity Details",
    details:
      "Enter Entity/Affiliation details of individual/company/ cooperative details",
  },
  {
    title: "Activity Area Location Details",
    details: "Enter Activity Area Location Details",
  },
  {
    title: "Executing Agency Details",
    details: "Enter Activity Executing Agency Details",
  },
  {
    title: "Plot/ Site/ Area Type Details",
    details: "Enter Plot/ Site/ Area Type Details",
  },
  {
    title: "Bank Details",
    details: "Enter Bank Account Details",
  },
  {
    title: "Green Credit Account Details",
    details: "Pay your fees for Project Creation",
  },
  {
    title: "Self Declaration",
    details: "Self Declaration Acknowledgement",
  },
];

export const waterSubHeaders = [
  {
    title: "Entity Details",
    details:
      "Enter Entity/Affiliation details of individual/company/ cooperative details",
  },
  {
    title: "Project Area Location Details",
    details: "Enter Project Area Location Details",
  },
  {
    title: "Executing Agency Details",
    details: "Enter Project Executing Agency Details",
  },
  {
    title: "Plot/ Site/ Area Type Details",
    details: "Enter Plot/ Site/ Area Type Details",
  },
  {
    title: "Structure/ Project DPR Details",
    details: "Enter Structure/ Project DPR Details",
  },
  {
    title: "Bank Details",
    details: "Enter Bank Account Details",
  },
  {
    title: "Green Credit Account Details",
    details: "Pay your fees for Project Creation",
  },
  {
    title: "Self Declaration",
    details: "Self Declaration Acknowledgement",
  },
];

export const formHeaders = {
  SelectProject: {
    title: "",
    details:
      "Select the type of Green Credit type from the options listed below",
  },
  Organization: {
    title: "Entity Details",
    details:
      "Enter Entity/Affiliation details of individual/company/ cooperative details",
  },
  ProjectAreaDetails: {
    title: "",
    details: "",
  },
  PlantationBlock: {
    title: "Please select State for plantation",
    details: "Selection of land for plantation",
  },
  AgencyDetails: {
    title: "Executing Agency Details",
    details: "Enter Activity Executing Agency Details",
  },
  PlotAreaDetails: {
    title: "Plot/ Site/ Area Type Details",
    details: "Enter Plot/ Site/ Area Type Details",
  },
  BankDetails: {
    title: "Bank Details",
    details: "Enter Bank Account Details",
  },
  GcBankDetails: {
    title: "Green Credit Account Details",
    details: "Pay your fees for Activity Creation",
  },
  SelfDeclaration: {
    title: "Self Declaration",
    details: "Self Declaration Acknowledgement",
  },
  WaterStructureDetails: {
    title: "Structure/ Activity DPR Details",
    details: "Enter Structure/ Activity DPR Details",
  },
};
