import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BankDetailsFormData } from "src/components/feature/Onboarding/BankAccountDetails";
import { resetFormAction } from "src/redux/createActions/resetForms";

interface InitialState {
  formData: BankDetailsFormData;
}

const initialState: InitialState = {
  formData: {
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    organizationCertificates: [],
  },
};

const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {
    updateBankDetailsForm: (
      state,
      action: PayloadAction<BankDetailsFormData>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { updateBankDetailsForm } = bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;
