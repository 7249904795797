import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    border: "1px solid",
    borderColor: "custom.greyish.2",
    boxShadow: "none",
  },
  cell: {
    border: "1px solid",
    borderColor: "custom.greyish.4",
    padding: "5px",
    fontSize: "16px",
  },
  header: {
    backgroundColor: "custom.greyish.3",
  },
});

export default styles;
