import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;
const styles = createStyles({
  selectWrapper: {
    marginBottom: "10px",
  },
  label: {
    fontSize: "14px",
    mt: "15px",
  },
  formTitle: {
    fontSize: "22px",
    fontWeight: "500",
  },
  formSubTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "custom.text.black.70",
  },
  sectionTitle: {
    fontWeight: "600",
    fontSize: "14px",
  },
  inputContainer: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    gap: "2%",
    flexWrap: "wrap",
  },

  input: {
    flexBasis: "46%",
  },

  fileUpload: {
    flexBasis: "94%",
  },
  phone: {
    flexBasis: "94%",
  },

  radio: {
    marginTop: "10px",
  },

  expand: {
    flexBasis: "94%",
  },

  date: {
    marginTop: { xs: "0", lg: "16px" },
  },
  checkboxStyle: {
    display: "flex",
    alignItems: "flex-start",
    mt: "10px",
  },
  selfDeclaration: {
    fontWeight: 500,
    fontSize: "16px",
  },
  accordion: {
    flexBasis: "94%",
  },
  textField: {
    width: "461px",
    height: "120px",
    padding: "12px",
    borderRadius: "8px",
    border: "1px",
    gap: "48px",
  },
  description: {},
  multilineInput: {
    height: "100%",
  },
  documentGroup: {
    display: "flex",
    gap: "2%",
    margin: "10px 0",
  },
});

export default styles;
