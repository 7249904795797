import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { path } from "src/constants/path";
import { logout } from "src/redux/slices/userDetails";
import { useAppSelector } from "src/redux/store";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type UserInfoProps = {
  customStyles?: CustomStyles<StylesClasses>;
};

export const UserInfo = ({ customStyles }: UserInfoProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userInfo = useAppSelector((state) => state.root.user.data);
  const [arrowReverse, isArrowReverse] = useState(false);
  const dispatch = useDispatch();

  // function reverseArrow() {
  //   isArrowReverse(!arrowReverse);
  // }

  const navigate = useNavigate();

  return (
    <Box {...styles("wrapper")}>
      <Avatar src="/assets/images/logo2.png" alt="user" {...styles("avatar")} />
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        {...styles("name")}
      >
        {!!userInfo ? userInfo.firstName : ""}
        <KeyboardArrowDownIcon
          {...styles("dropDown", {
            fontSize: "30px",
            transform: arrowReverse ? "rotate(180deg)" : "rotate(0deg)",
          })}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(path.myProfile);
            handleClose();
          }}
        >
          My Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(path.changePassword);
            handleClose();
          }}
        >
          Change Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(logout());
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};
