import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    width: { xs: "100%", sm: "80%", lg: "60%" },
  },

  input: {
    flexBasis: "46%",
    margin: "0 0 24px 0",
  },

  address: {
    flexBasis: "100%",
  },

  select: {
    flexBasis: "45%",
  },
  fileUploadArea: {
    // display: "flex",
    // border: "2px solid",
    // justifyContent: "flex-start",
    // gap: "5%",
    // width: "100%",
  },

  selectWrapper: {
    // width: "40%",
  },
  borderStyle: {
    borderColor: "custom.border.otpDash",
    border: "2px dashed",
    borderRadius: "12px",
    backgroundColor: "custom.background.secondaryGrey",
  },
  fileType: {
    opacity: "0.5",
    fontSize: "13px",
    fontFamily: "400",
  },
  verified: {
    color: "primary.main",
    display: "flex",
    justifyContent: "flex-start",
    width: "100px",
    fontWeight: "600",
    whiteSpace: "nowrap",
  },

  verify: {
    cursor: "pointer",
    marginTop: "10px",
  },

  loading: {
    fontSize: "10px",
    marginLeft: "10px",
  },
});

export default styles;
