import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;
const styles = createStyles({
  description: {
    fontSize: "14px",
    fontWeight: 500,
    color: "custom.text.dark",
  },
  linkArea: {
    display: "flex",
    gap: "4%",
    marginTop: "10px",
  },

  link: {
    textDecoration: "none",
    color: "custom.text.link",
  },
  formWrapper: {
    overflow: "auto",
    height: "60vh",

    "&::-webkit-scrollbar": {
      // display: "none",
    },
  },

  stepperContainer: {
    marginTop: "2px",
  },
  stepperWrapper: {
    color: "custom.greyish.4",
    fontSize: "14px",
  },
  stepperLabel: {
    color: "custom.greyish.4",
    fontSize: "14px",
  },
  stepperLabelTitle: {
    color: "primary.main",
    fontWeight: "500",
    mb: "10px",
  },
  currentStep: {
    color: "primary.main",
    fontSize: "18px",
  },
  scrollContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "60vh",
    paddingRight: "10px",
    "&::-webkit-scrollbar": {
      // display: "none",
    },
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
  },
});

export default styles;
