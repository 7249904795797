import { useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";

import { Input } from "../../common/FormComponents";
import { debounce } from "../../../utils/debouncing";
import defaultStyles, { StylesClasses } from "./styles";
import { CustomStyles, getStyles } from "src/styles/theme";

type FormInputs = {
  search: string;
};

type SearchProps = {
  getText: (txt: string) => void;
  customStyles?: CustomStyles<StylesClasses>;
};

const SearchBar = ({ getText, customStyles }: SearchProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      search: null,
    },
  });

  const styles = getStyles(defaultStyles, customStyles);

  const inputVal = watch("search");

  const searchText = useCallback(
    (val) => debounce(() => getText(val), 700),
    [getText]
  );

  useEffect(() => {
    if (inputVal?.length === 0 || inputVal?.length >= 2) {
      searchText(inputVal);
    }
  }, [inputVal, searchText]);

  const onSubmitForm = (formData: FormInputs) => formData;

  return (
    <Box {...styles("wrapper")}>
      <form onSubmit={handleSubmit(onSubmitForm)} style={{ width: "100%" }}>
        <Input
          control={control}
          labelPos
          labelPosStyles
          name="search"
          showAdornment
          adornmentPos="start"
          className="search"
          errors={errors}
          rules={{}}
          placeholder="Search by Registration Number"
          customStyles={{ wrapper: defaultStyles.border }}
        />
      </form>
    </Box>
  );
};

export default SearchBar;
