import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import defualtStyles from "./styles";
import { getStyles } from "src/styles/theme";

const CombinedMonthYearPicker = ({
  startYear = 2023,
  endYear = new Date().getFullYear(),
  onChange,
}) => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const styles = getStyles(defualtStyles);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    onChange(e.target.value, selectedYear);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    onChange(selectedMonth, e.target.value);
  };

  return (
    <Box sx={{ maxWidth: 500 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth {...styles("formControl")}>
            <InputLabel id="month-picker-label" {...styles("inputLabel")}>
              Month
            </InputLabel>
            <Select
              labelId="month-picker-label"
              value={selectedMonth}
              onChange={handleMonthChange}
              label="Month"
              notched={false}
              {...styles("select")}
            >
              {months.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth {...styles("formControl")}>
            <InputLabel id="year-picker-label" {...styles("inputLabel")}>
              Year
            </InputLabel>
            <Select
              labelId="year-picker-label"
              value={selectedYear}
              onChange={handleYearChange}
              label="Year"
              notched={false}
              {...styles("select")}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CombinedMonthYearPicker;
